import axios from "axios";
import { TokenService } from "../utils/token";
// import store from '../store';

const defaultOptions = {
  // baseURL: "https://app.ampifi.co",
  // baseURL: "http://lvh.me:3005",
  baseURL: "https://ampifie-0ce4970a1dfb.herokuapp.com", // for heroku staging
  headers: {
    "Content-Type": "application/json",
  },
};
export { defaultOptions };

export let mainAxios = axios.create(defaultOptions);

mainAxios.interceptors.request.use((config) => {
  const token = TokenService.getToken();
  config.headers.Authorization = token ? `Token ${token}` : "";
  return config;
});

mainAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      // console.log("unauthorized, logging out ...");
      //   await store.dispatch('Logout');
    }
    return Promise.reject(error.response);
  }
);

export { mainAxios as axios };
