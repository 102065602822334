import React from "react";
import { Circle, Group, Image, Line, Rect, RegularPolygon, Text, Label, Tag } from "react-konva";
import { fontSize } from "../config/theme/Helper";
import { store } from "../store";
import Konva from "konva";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
	CircularVisualization,
	GreenVisualizationElement,
	StickerElement,
	StringLineVisualization,
	UpperVisualizationElement,
} from "../types/canvasStoreTypes/CanvesTypes";

import {
	AllCanvasElements,
	RectangleElement,
	CircleElement,
	TriangleElement,
	LineElement,
	ImageElement,
	TextElement,
	GroupElement,
} from "../types/canvasStoreTypes/CanvesTypes";
import { IS_MOBILE } from "./responsive";
import UpperHalfVisualization from "../components/canves/visualization/UpperHalfVisualization";
import {
	StringCircularVisualization,
	CircularProgressBar,
	SquareProgressBar,
	StraightProgressBar,
	fullCongestedVisualization,
	midCongestedVisualization,
} from "../types/canvasStoreTypes/CanvesTypes";
import FullCongestedVisualization from "../components/canves/visualization/fullCongestedVisualization";
import MidlCongestedVisualization from "../components/canves/visualization/midCongestedVisualization";
import { CongestedVisualization } from "../types/canvasStoreTypes/CanvesTypes";

export function renderElementsHighlights(highlightedElements: AllCanvasElements[]) {
	const res: JSX.Element[] = [];
	// if (highlightedElements[0] == undefined) return;
	// debugger;
	for (const ele of highlightedElements) {
		switch (ele.type) {
			case "Rectangle":
				res.push(rectangleHighlight(ele));
				break;
			case "Circle":
				res.push(circleHighlight(ele));
				break;

			case "Triangle":
				res.push(triangleHighlight(ele));
				break;

			case "Line":
				res.push(LineHighlight(ele));
				break;
			case "Image":
				res.push(rectangleHighlight(ele));
				break;
			case "Sticker":
				res.push(rectangleHighlight(ele));
				break;
			case "Text":
				res.push(<TextHighlight ele={ele} />);
				break;
			case "Group":
				let groupElementChild = ele.children;
				let resR: JSX.Element[] = [];
				resR.push(...renderElementsHighlights(Object.values(groupElementChild)));
				// debugger;
				res.push(
					<Group
						listening={false}
						key={ele.id}
						x={ele.x}
						data-id={ele.id}
						y={ele.y}
						rotation={ele.rotationDeg}
						height={ele.height * ele.scaleY}
						width={ele.width * ele.scaleX}
						scaleX={ele.scaleX}
						scaleY={ele.scaleY}
						stroke={highlightColor}
						strokeWidth={1}
						skewX={ele.skewX}
						children={resR}
						skewY={ele.skewY}></Group>,
				);
				break;
			case "GreenVisualizationElement":
				res.push(GreenVisualizationHighlight(ele));
				break;
			case "CircularVisualization":
				res.push(CircularVisualizationHighlight(ele));
				break;
			case "UpperVisualizationElement":
				res.push(UpperHalfVisualizationHighlight(ele));
				break;
			case "StringLineVisualization":
				res.push(StringLineVisualizationHighlight(ele));
				break;
			case "StringCircularVisualization":
				res.push(StringCircularVisualizationHighlight(ele));
				break;
			case "CircularProgressBar":
				res.push(CircularProgressBarHighlight(ele));
				break;
			case "SquareProgressBar":
				res.push(SquareProgressBarHighlight(ele));
				break;
			case "StraightProgressBar":
				res.push(StraightProgressBarHighlight(ele));
				break;
			case "fullCongestedVisualization":
				res.push(FullCongestedVisualizationHighlight(ele));
				break;
			case "midCongestedVisualization":
				res.push(MidCongestedVisualizationHighlight(ele));
				break;
			case "CongestedVisualization":
				res.push(CongestedVisualizationHighlight(ele));
				break;
			default:
				break;
		}
	}

	return res;
}

const highlightColor = "#ff3d8e";

export function rectangleHighlight(ele: RectangleElement | ImageElement | GroupElement | StickerElement) {
	// console.log("rect ele", ele);

	return (
		<Rect
			listening={false}
			key={ele.id}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			rotation={ele.rotationDeg}
			height={ele.height * ele.scaleY}
			width={ele.width * ele.scaleX}
			// scaleX={ele.scaleX}
			// scaleY={ele.scaleY}
			stroke={highlightColor}
			strokeWidth={1}
			skewX={ele.skewX}
			skewY={ele.skewY}
		/>
	);
}

export function circleHighlight(ele: CircleElement) {
	return (
		<Circle
			listening={false}
			key={ele.id}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			rotation={ele.rotationDeg}
			height={ele.height * ele.scaleX}
			width={ele.width * ele.scaleX}
			// scaleX={ele.scaleX}
			// scaleY={ele.scaleY}
			stroke={highlightColor}
			strokeWidth={1}
			skewX={ele.skewX}
			skewY={ele.skewY}
		/>
	);
}

export function triangleHighlight(ele: TriangleElement) {
	return (
		<RegularPolygon
			key={ele.id}
			listening={false}
			sides={ele.sides}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			rotation={ele.rotationDeg}
			radius={ele.radius}
			lineJoin="bevel"
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			stroke={highlightColor}
			strokeWidth={1 / Math.max(ele.scaleX, ele.scaleY)}
			skewX={ele.skewX}
			skewY={ele.skewY}
		/>
	);
}
export function TextHighlight({ ele }: { ele: TextElement }) {
	let w = ele.width;
	let wr;
	if (w) {
		wr = w + 5;
	}

	const ref = React.useRef<Konva.Tag>(null);

	const highlightMarginX = 6;
	const highlightMarginY = 3;

	return (
		<Label listening={false} skewX={ele.skewX} skewY={ele.skewY} key={ele.id} x={ele.x} data-id={ele.id} y={ele.y} rotation={ele.rotationDeg}>
			<Tag stroke={highlightColor} strokeWidth={1 / Math.max(ele.scaleX, ele.scaleY)}></Tag>
			{/* <Tag fill={"#ffffff00"} strokeWidth={1 / Math.max(ele.scaleX, ele.scaleY)} ref={ref}></Tag>

			{ref.current && (
				<Rect
					x={-(highlightMarginX / 2)}
					y={-highlightMarginY * 0.8}
					height={ref.current?.attrs.height + highlightMarginY}
					width={ref.current?.attrs.width + highlightMarginX}
					stroke={highlightColor}
					strokeWidth={1 / Math.max(ele.scaleX, ele.scaleY)}
					fill={highlightColor}></Rect>
			)} */}
			<Text
				listening={false}
				text={ele.text}
				fontSize={ele.fontSize}
				fontFamily={ele.fontFamily}
				fontStyle={ele.fontStyle}
				width={wr}
				fill={"#ffffff00"}
			/>
		</Label>
	);
}
export function LineHighlight(ele: LineElement) {
	return (
		<Line
			listening={false}
			data-id={ele.id}
			key={ele.id}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			stroke={highlightColor}
			strokeWidth={ele.strokeWidth}
			points={ele.points}
			skewX={ele.skewX}
			skewY={ele.skewY}
		/>
	);
}

export function GreenVisualizationHighlight(ele: GreenVisualizationElement) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * ele.scaleY}
			width={cameraW * 0.6 * ele.scaleX}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function CircularVisualizationHighlight(ele: CircularVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			height={cameraH * 0.4 * ele.scaleY}
			width={cameraW * 0.4 * ele.scaleX}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function UpperHalfVisualizationHighlight(ele: UpperVisualizationElement) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			listening={false}
			key={ele.id}
			// draggable
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * ele.scaleY}
			width={cameraW * 0.6 * ele.scaleX}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function StringLineVisualizationHighlight(ele: StringLineVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;

	// console.log("StringLineVisualizationHighlight", { ele, sx: ele.scaleX });

	return (
		<Rect
			listening={false}
			key={ele.id}
			// draggable
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * (ele.scaleY || 1)}
			width={cameraW * 0.6 * (ele.scaleX || 1)}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function StringCircularVisualizationHighlight(ele: StringCircularVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;

	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.4 * ele.scaleY}
			width={cameraW * 0.4 * ele.scaleX}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}

export function FullCongestedVisualizationHighlight(ele: fullCongestedVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * (ele.scaleY || 1)}
			width={cameraW * 0.6 * (ele.scaleX || 1)}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function MidCongestedVisualizationHighlight(ele: midCongestedVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * (ele.scaleY || 1)}
			width={cameraW * 0.6 * (ele.scaleX || 1)}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function CongestedVisualizationHighlight(ele: CongestedVisualization) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;

	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	return (
		<Rect
			key={ele.id}
			listening={false}
			x={ele.x}
			data-id={ele.id}
			y={ele.y}
			stroke={highlightColor}
			strokeWidth={1}
			height={cameraH * 0.3 * (ele.scaleY || 1)}
			width={cameraW * 0.6 * (ele.scaleX || 1)}
			fill="#ffffff00"
			rotation={ele.rotationDeg}
		/>
	);
}
export function CircularProgressBarHighlight(ele: CircularProgressBar) {
	return (
		<Group listening={false} key={ele.id} x={ele.x} data-id={ele.id} y={ele.y} rotation={ele.rotationDeg}>
			<Circle stroke={highlightColor} strokeWidth={1} radius={ele.radius * ele.scaleX} fill="#ffffff00"></Circle>
		</Group>
	);
}
export function SquareProgressBarHighlight(ele: SquareProgressBar) {
	let width = 200;
	let height = 20;
	return (
		<Group listening={false} key={ele.id} x={ele.x} data-id={ele.id} y={ele.y} scaleX={ele.scaleX} scaleY={ele.scaleY} rotation={ele.rotationDeg}>
			<Rect stroke={highlightColor} strokeWidth={1} x={-150} y={50} height={width} width={width} fill={"#ff000000"}></Rect>
		</Group>
	);
}
export function StraightProgressBarHighlight(ele: StraightProgressBar) {
	return (
		<Group listening={false} key={ele.id} x={ele.x} data-id={ele.id} y={ele.y} rotation={ele.rotationDeg}>
			<Rect stroke={highlightColor} strokeWidth={1} height={ele.height * ele.scaleY} width={ele.width * ele.scaleX}></Rect>
		</Group>
	);
}
