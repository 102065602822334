import { AudioStore } from "../../types/canvasStoreTypes/StoreTypes";
import { action } from "easy-peasy";
import React from "react";

export const audioStore: AudioStore = {
	AudioFile: null,
	isValidAudio: true,
	inputRef: null,
	fileName: null,

	fadeInOn: false,
	fadeOutOn: false,
	regionStart: null,
	regionEnd: null,

	originalAudio: null,
	resultAudio: null,
	originalAudioUrl: null,
	resultAudioUrl: null,
	original: null,
	result: null,

	setIsValidAudio: action((state, payload) => {
		state.isValidAudio = payload;
	}),
	setAudioFile: action((state, payload) => {
		state.AudioFile = payload;
	}),
	setInputRef: action((state, payload) => {
		state.inputRef = payload;
	}),
	setFadeInOn: action((state, payload) => {
		state.fadeInOn = payload;
	}),
	setFadeOutOn: action((state, payload) => {
		state.fadeOutOn = payload;
	}),
	setFileName: action((state, payload) => {
		state.fileName = payload;
	}),
	setOriginalAudio: action((state, payload) => {
		state.originalAudio = payload;
	}),
	setOriginalAudioUrl: action((state, payload) => {
		state.originalAudioUrl = payload;
	}),
	setRegionEnd: action((state, payload) => {
		state.regionEnd = payload;
	}),
	setRegionStart: action((state, payload) => {
		state.regionStart = payload;
	}),
	setResultAudio: action((state, payload) => {
		state.resultAudio = payload;
	}),
	setResultAudioUrl: action((state, payload) => {
		state.resultAudioUrl = payload;
	}),
	setOriginal: action((state, payload) => {
		state.original = payload;
	}),

	setResult: action((state, payload) => {
		state.result = payload;
	}),

	resetToNull: action((state, payload) => {
		state.AudioFile = null;
		state.fileName = null;
		state.fadeInOn = false;
		state.fadeOutOn = false;
		state.regionStart = null;
		state.regionEnd = null;
		state.originalAudio = null;
		state.resultAudio = null;
		state.originalAudioUrl = null;
		state.resultAudioUrl = null;
		state.original = null;
		state.result = null;
	}),
};
