export const fontsNameList = [
	"Roboto",
	"Open Sans",
	"Noto Sans JP",
	"Lato",
	"Montserrat",
	"Source Sans Pro",
	"Roboto Condensed",
	"Poppins",
	"Oswald",
	"Roboto Mono",
	"Noto Sans",
	"Raleway",
	"Nunito",
	"Ubuntu",
	"Merriweather",
	"PT Sans",
	"Roboto Slab",
	"Playfair Display",
	"Rubik",
	"Mukta",
	"Noto Sans KR",
	"Inter",
	"Open Sans Condensed",
	"Lora",
	"Work Sans",
	"Nunito Sans",
	"Fira Sans",
	"Quicksand",
	"Nanum Gothic",
	"PT Serif",
	"Titillium Web",
	"Noto Serif",
	"Barlow",
	"Hind Siliguri",
	"Karla",
	"Noto Sans TC",
	"Inconsolata",
	"Heebo",
	"Libre Franklin",
	"PT Sans Narrow",
	"Libre Baskerville",
	"IBM Plex Sans",
	"Dosis",
	"Arimo",
	"Source Code Pro",
	"Josefin Sans",
	"Oxygen",
	"Cabin",
	"Mulish",
	"Bitter",
	"Dancing Script",
	"DM Sans",
	"Lobster",
	"EB Garamond",
	"Anton",
	"Cairo",
	"Noto Sans SC",
	"Bebas Neue",
	"Varela Round",
	"Source Serif Pro",
	"Hind",
	"Prompt",
	"Abel",
	"Comfortaa",
	"Fjalla One",
	"Kanit",
	"Barlow Condensed",
	"Arvo",
	"Exo 2",
	"Pacifico",
	"Architects Daughter",
	"Merriweather Sans",
	"Slabo 27px",
	"Maven Pro",
	"Hind Madurai",
	"Overpass",
	"Indie Flower",
	"Asap",
	"Signika Negative",
	"Teko",
	"Shadows Into Light",
	"Balsamiq Sans",
	"Abril Fatface",
	"Yanone Kaffeesatz",
	"Saira Condensed",
	"Cormorant Garamond",
	"Questrial",
	"IBM Plex Serif",
	"Manrope",
	"Noto Serif JP",
	"Rajdhani",
	"Assistant",
	"Patrick Hand",
	"Fira Sans Condensed",
	"Catamaran",
	"Permanent Marker",
	"Caveat",
	"Acme",
	"Exo",
	"Tajawal",
];
