import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEasyActions } from "./store/hooks";
import { login } from "./utils/Api/login";
import { useToast } from "@chakra-ui/react";
import { TokenService } from "../src/utils/token";
import { theme } from "./config/theme/baseDesign";
import { AiOutlineMail } from "react-icons/ai";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { history } from "./app/router/history";
import { useParams } from "react-router";
import { basePath } from "./constants";
import { useQuery } from "./store/hooks/useQuery";
export interface LoginSignupProps {}

const LoginSignup: React.FC<LoginSignupProps> = (props) => {
	// debugger;

	const { setToken, setUserEmail, setCardId, setSubscriptionDate } = useEasyActions((state) => state.userDataStore);
	const toast = useToast();
	const [name, setName] = useState<string | null>(null);
	const [password, setPassword] = useState<string | null>(null);
	// const { sub } = useParams<{ sub: string }>();
	// console.log(sub, "sub");
	let queryParams = useQuery();
	async function LoginInfo(email: string, pasward: string) {
		let response = await login(email, pasward);
		// debugger;
		// console.log("response", response);
		if (response instanceof Error) {
			if (response.message.includes("Email not verified")) {
				toast({
					status: "error",
					title: "Error!",
					description: "Email not verified.",
				});
				return;
			} else {
				toast({
					status: "error",
					title: "Error!",
					description: "Invalid Email or password.",
				});
				return;
			}
			
		}
		// debugger;
		let token = response.data.data.attributes.authentication_token;
		if ("admin" in response.data.data.attributes) {
			let user_role = response.data.data.attributes.admin;
			TokenService.setRole("" + user_role);
		} else {
			TokenService.setRole("false");
		}
		TokenService.setToken(token);
		setUserEmail(response.data.data.attributes.email);
		TokenService.setUserNAME(response.data.data.attributes.full_name);

		if ("valid_till" in response.data.data.attributes.subscription_details) {
			setSubscriptionDate(response.data.data.attributes.subscription_details?.valid_till);
		}
		// debugger;
		if (queryParams.get("sub") != null && token) {
			history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
			return;
		}
		toast({
			status: "success",
			description: "You are successfully logged in.",
		});
		if (response.data.data.attributes.role === null){
			history.push(basePath + "/step_one");
		} else {
			history.push(basePath + "/dashboard");
		}
	}

	return (
		<Flex flexDir="column" h="100vh" background={theme.colors.primary} opacity="1">
			<Flex pr="6px" justifyContent="flex-end" h="30px" w="100%" m="30px 50px 0 0">
				<img style={{ color: "#ffffff" }} src={Logo} />
				{queryParams.get("sub")}
			</Flex>
			<Flex justifyContent="center" flex={1} alignItems="center">
				<Flex h="30rem" w="30rem" background="white" rounded="md">
					<Flex w="100%" flexDir="column" justifyContent="center" mx="3rem">
						<Flex justifyContent="center" alignItems="center" mb="5px">
							<Text fontSize="2rem" fontWeight="700">
								Welcome back!
							</Text>
						</Flex>
						<Flex flexDir="column" marginBottom="30px">
							<Flex textAlign="center" flex={1} justifyContent="center" mb="20px">
								<Text letterSpacing="0.5px" fontSize="12px" fontWeight="500">
									Don’t have an account yet?{" "}
									<Text
										cursor="pointer"
										color={theme.colors.primary}
										onClick={() => {
											// history.push(`${basePath}/signup?sub=${queryParams.get("sub")}`);
											history.push(`${basePath}/signup${!!queryParams.get("sub") ? "?sub=" + queryParams.get("sub") : ""}`);
										}}
										display="inline-block">
										{" "}
										Sign up here
									</Text>
								</Text>
							</Flex>

							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Email
							</Text>

							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdMailOutline opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="text"
									onChange={(e) => {
										setName(e.target.value);
									}}
									placeholder="Enter your email"
								/>
							</InputGroup>
						</Flex>
						<Flex flexDir="column" marginBottom="30px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Password
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdLockOutline opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="password"
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder="Enter password"
								/>
							</InputGroup>
						</Flex>
						<Button
							h="3rem"
							onClick={async () => {
								// debugger;
								if (name === null || password === null) {
									toast({
										status: "info",
										title: "Alert!",
										description: "please fill the following option",
									});
									return;
								}
								await LoginInfo(name, password);
							}}
							background={theme.colors.primary}
							color="white"
							_hover={{
								bg: theme.colors.primary,
							}}>
							Log In
						</Button>
						{/* <Flex my="8px" justifyContent="center" fontSize="12px" color="gray.300">
                            ------------or------------
                        </Flex>
                        <Button
                            h="3rem"
                            onClick={async () => {
                                history.push(basePath + "/signup");
                            }}
                            background={theme.colors.primary}
                            color="white"
                            _hover={{
                                bg: theme.colors.primary,
                            }}
                        >
                            Sign Up
                        </Button>
                        <Flex
                            cursor="pointer"
                            onClick={() => {
                                history.push(basePath + "/Upload");
                            }}
                            my="8px"
                            justifyContent="flex-end"
                            fontSize="12px"
                            color="gray.400"
                        >
                            Try without login
                        </Flex> */}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default LoginSignup;
