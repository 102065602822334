import {
	RectangleElement,
	TriangleElement,
	LineElement,
	ImageElement,
	TextElement,
	TypeTextElement,
	AllCanvasElements,
	GroupElement,
	TypeSubtitleElement,
	GifElement,
	StickerElement,
} from "../types/canvasStoreTypes/CanvesTypes";
import { Circle, Group, Image as KonvaImage, Line, Rect, RegularPolygon, Text, Label, Tag } from "react-konva";
import React, { useEffect } from "react";
import { CircleElement, SubtitleElement, WaterMarkElement } from "../types/canvasStoreTypes/CanvesTypes";
import useImage from "use-image";
import { RectConfig, Rect as Rec } from "konva/lib/shapes/Rect";
import { KonvaEventObject, Node } from "konva/lib/Node";
import { SceneCanvas } from "konva/lib/Canvas";
import { useEasyActions, useEasyState } from "../store/hooks";
import { Context } from "konva/lib/Context";
import { Stage } from "konva/lib/Stage";
import Konva from "konva";
import { Transformer } from "konva/lib/shapes/Transformer";
import { store } from "../store";
import { v4 as uuid } from "uuid";
import { UpdateCommand } from "../Commands/CanvasCommanda/UpdateCommand";
import { fontSize } from "../config/theme/Helper";
import { IS_MOBILE } from "./responsive";
import { selectableElement } from "./Konva/index";
import useRef from "react";
import "gifler";
import SuperGif from "libgif";
import { convertImgToBase64URL } from "./base64ToImage";

// console.log({ SuperGif });

export function reactObjectToElement(ele: RectangleElement, key: string, isStatic: boolean) {
	let extra = {};
	if (isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}

	return (
		<Rect
			data-id={ele.id}
			key={key}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			height={ele.height}
			width={ele.width}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			fill={ele.fill}
			stroke={ele.stroke}
			strokeWidth={ele.strokeWidth}
			skewX={ele.skewX}
			skewY={ele.skewY}
			{...extra}
		/>
	);
}

export function circleObjectToElement(ele: CircleElement, key: string, isStatic: boolean) {
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}
	return (
		<Circle
			key={key}
			data-id={ele.id}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			height={ele.height}
			width={ele.width}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			fill={ele.fill}
			stroke={ele.stroke}
			strokeWidth={ele.strokeWidth}
			radius={ele.radius}
			skewX={ele.skewX}
			skewY={ele.skewY}
			{...extra}
		/>
	);
}

export function TriangleObjectToElement(ele: TriangleElement, key: string, isStatic: boolean) {
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}

	return (
		<RegularPolygon
			sides={ele.sides}
			key={key}
			data-id={ele.id}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			fill={ele.fill}
			stroke={ele.stroke}
			strokeWidth={ele.strokeWidth}
			radius={ele.radius}
			skewX={ele.skewX}
			skewY={ele.skewY}
			lineJoin="bevel"
			{...extra}
		/>
	);
}

export function LineObjectToElement(ele: LineElement, key: string, isStatic: boolean) {
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}
	return (
		<Line
			key={key}
			data-id={ele.id}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			fill={ele.fill}
			stroke={ele.stroke}
			strokeWidth={ele.strokeWidth}
			points={ele.points}
			skewX={ele.skewX}
			skewY={ele.skewY}
			{...extra}
		/>
	);
}

export function ImageObjectToElement({
	ele,
	key,
	isStatic,
}: {
	ele: ImageElement | StickerElement | WaterMarkElement;
	key: string;
	isStatic: boolean;
}) {
	const [image] = useImage(ele.url, ele.type === "Image" ? "anonymous" : "anonymous");
	// if (ele.type === "Sticker" && ele.scaleX >= 1.5 && ele.scaleY >= 1.5) {
	//     ele.scaleX = 1.4;
	//     ele.scaleY = 1.4;
	// }

	let extra = {};
	let imageExtras = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: isStatic,
		};
		// console.log(isStatic, "isStatic");

		imageExtras = {
			// name: "object",
		};
	}
	return (
		<Group
			key={key}
			data-id={ele.id}
			height={ele.height}
			width={ele.width}
			x={ele.x}
			fill="#aaa"
			y={ele.y}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			{...extra}
			clipFunc={(ctx: Context) => {
				const bigSide = Math.max(ele.height, ele.width);
				const smallSide = Math.min(ele.height, ele.width);

				const altitude = smallSide / 2;

				const base = bigSide / 2;

				const hypogeous = Math.sqrt(altitude ** 2 + base ** 2);

				ctx.arc(ele.width / 2, ele.height / 2, hypogeous * ele.br, 0, Math.PI * 2, true);

				// ctx.fill();
			}}>
			<KonvaImage {...imageExtras} height={ele.height} width={ele.width} image={image} />
		</Group>
	);
}

export function TextObjectToElement({
	ele,
	key,
	konvaStageInstance,
	isStatic,
}: {
	ele: TypeTextElement;
	key: string;
	konvaStageInstance: Stage | null;
	konvaTransformerInstance: Transformer | null;
	isStatic: boolean;
}) {
	let canvasHWS = store.getState().canvasStore.canvasHWS;
	const ref = React.useRef<Konva.Tag>(null);
	const highlightMarginX = 8;
	const highlightMarginY = 10;
	let scale = canvasHWS.scale;
	// if (key == null) {
	// 	key = uuid();
	// }
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}

	let dblClickingText = (e: KonvaEventObject<Event>) => {
		// debugger;
		const konvaTransformerInstance = store.getState().canvasStore.konvaTransformerInstance;

		if (konvaStageInstance == null || konvaTransformerInstance == null) {
			return;
		}

		const LabelInstance = store.getState().canvasStore.selectedElementInstance! as Konva.Label;
		LabelInstance.children?.forEach((c) => {
			c.hide();
		});

		const textNode = e.currentTarget as Konva.Text;
		const stage = konvaStageInstance;
		const tr = konvaTransformerInstance;

		// hide text node and transformer:
		// textNode.hide();
		tr.hide();
		// create textarea over canvas with absolute position
		// first we need to find position for textarea
		// how to find it?
		// at first lets find position of text node relative to the stage:
		var textPosition = LabelInstance.absolutePosition();
		const [{ left, top }] = Array.from(stage.container().getClientRects());
		// so position of textarea will be the sum of positions above:
		var areaPosition = {
			x: left + textPosition.x,
			y: top + textPosition.y + window.scrollY,
		};

		//create and add function
		// create textarea and style it
		var textarea = document.createElement("textarea");
		document.body.appendChild(textarea);

		// apply many styles to match text on canvas as close as possible
		// remember that text rendering on canvas and on the textarea can be different
		// and sometimes it is hard to make it 100% the same. But we will try...
		textarea.value = textNode.text();
		textarea.style.position = "absolute";
		textarea.style.top = areaPosition.y + "px";
		textarea.style.left = areaPosition.x + "px";
		// textarea.style.width = textNode.width() - textNode.padding() * 2 + "px";
		textarea.style.height = textNode.height() - textNode.padding() + "px";
		textarea.style.fontSize = textNode.fontSize() * scale + "px";
		textarea.style.border = "none";
		// textarea.style.scale = "" + scale;
		textarea.style.padding = "0px";
		textarea.style.margin = "0px";
		textarea.style.overflow = "hidden";
		textarea.style.background = "transparent";
		textarea.style.outline = "none";
		textarea.style.resize = "none";
		textarea.style.lineHeight = textNode.lineHeight().toString();
		textarea.style.fontFamily = textNode.fontFamily();
		textarea.style.transformOrigin = "left top";
		textarea.style.textAlign = textNode.align();
		textarea.style.color = textNode.fill();
		textarea.classList.add("TextareaElementForKonvaText");
		const rotation = textNode.rotation();
		var transform = "";
		if (rotation) {
			transform += "rotateZ(" + rotation + "deg)";
		}

		var px = 0;
		// also we need to slightly move textarea on firefox
		// because it jumps a bit
		var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
		if (isFirefox) {
			px += 2 + Math.round(textNode.fontSize() / 20);
		}
		transform += "translateY(-" + px + "px)";

		textarea.style.transform = transform;

		// reset height
		textarea.style.height = "auto";
		// after browsers resized it we can set actual value
		textarea.style.height = textarea.scrollHeight + 3 + "px";

		textarea.focus();

		function removeTextarea() {
			if (textarea?.parentNode == null) {
				return;
			}

			textarea.parentNode.removeChild(textarea);
			window.removeEventListener("click", handleOutsideClick);
			// window.removeEventListener("touchend click", handleOutsideClick);
			store.getState().canvasStore?.konvaBackgroundInstance?.off("tap", handleOutsideClick);

			LabelInstance.children?.forEach((c) => {
				c.show();
			});

			// textNode.parent?.show();
			tr.show();
			tr.forceUpdate();
		}

		function setTextareaWidth(newWidth?: number) {
			if (!newWidth) {
				// set width for placeholder
				newWidth = (textNode as any).placeholder.length * textNode.fontSize();
			}
			// some extra fixes on different browsers
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
			if (isSafari || isFirefox) {
				newWidth = Math.ceil(newWidth);
			}

			var isEdge = /Edge/.test(navigator.userAgent);
			if (isEdge) {
				newWidth += 1;
			}
			textarea.style.width = newWidth + "px";
		}

		textarea.addEventListener("keydown", function (e) {
			// hide on enter
			// but don't hide on shift + enter
			if (e.keyCode === 13 && !e.shiftKey) {
				textNode.text(textarea.value);
				removeTextarea();
			}
			// on esc do not set value back to node
			if (e.keyCode === 27) {
				removeTextarea();
			}
		});

		// textarea.addEventListener("keydown", function (e) {
		// 	const scale = textNode.getAbsoluteScale().x;
		// 	setTextareaWidth(textNode.width() * scale);
		// 	textarea.style.height = "auto";
		// 	textarea.style.height = textarea.scrollHeight + textNode.fontSize() + "px";
		// });

		textarea.onchange = (e) => {
			const { value }: { value: string } = e?.target! as any;

			//debugger;
			const { executeCommand } = store.getActions().canvasStore;
			executeCommand(
				new UpdateCommand<TextElement>([
					{
						id: ele.id,
						text: value == "" ? "No Text" : value,
					},
				]),
			);
		};

		textarea.onfocus = (e) => {
			textarea.style.width = "500px";
		};

		// textarea.onblur = (e) => {
		// 	textarea.style.width = "auto";
		// };

		// const sizeMeasureSpan = document.createElement("span");
		// sizeMeasureSpan.style.position = "absolute";
		// sizeMeasureSpan.style.left = Number.MAX_SAFE_INTEGER + "px";
		// document.body.appendChild(sizeMeasureSpan);
		// textarea.oninput = (e) => {
		// 	const { value }: { value: string } = e?.target! as any;

		// 	sizeMeasureSpan.innerText = value;
		// 	sizeMeasureSpan.style.fontFamily = textNode.fontFamily();
		// 	sizeMeasureSpan.style.fontSize = textNode.fontSize() + "px";
		// 	// sizeMeasureSpan.style.fontWeight = textNode.fontStyle() ;

		// 	console.log("sizeMeasureSpan.clientWidth", sizeMeasureSpan.clientWidth);
		// 	const width = Math.min(sizeMeasureSpan.clientWidth, window.innerWidth / 2);
		// 	const extraWidth = 2;

		// 	textNode.width(width + extraWidth);
		// 	textarea.style.width = width + extraWidth - textNode.padding() * 2 + "px";
		// };

		function handleOutsideClick(e: any) {
			if (e.target !== textarea) {
				textNode.text(textarea.value);
				removeTextarea();
			}
		}
		setTimeout(() => {
			window.addEventListener("click", handleOutsideClick);
			// window.addEventListener("touchend click", handleOutsideClick);
			store.getState().canvasStore?.konvaStageInstance?.on("tap", handleOutsideClick);
		});

		setTimeout(() => textarea.focus(), 500);
	};

	return (
		<Label
			data-id={ele.id}
			key={key}
			x={ele.x}
			y={ele.y}
			rotation={ele.rotationDeg}
			{...extra}
			opacity={ele.opacity}
			pointerHeight={20}
			onTransform={(e) => {
				const LabelInstance = store.getState().canvasStore.selectedElementInstance! as Konva.Label;

				const { executeCommand } = store.getActions().canvasStore;
				executeCommand(
					new UpdateCommand<TextElement>([
						{
							id: ele.id,
							width: LabelInstance.width() * LabelInstance.scaleX(),
						},
					]),
				);
			}}>
			{ref.current && (
				<Rect
					x={-(highlightMarginX / 2 - 2)}
					y={-highlightMarginY * 0.5}
					height={ref.current?.attrs.height + highlightMarginY}
					width={ref.current?.attrs.width + highlightMarginX}
					fill={ele.bgColor}></Rect>
			)}
			<Tag fill={"#ffffff00"} ref={ref}></Tag>
			<Text
				id="TextElement"
				width={ele.width}
				fill={ele.fill}
				align={ele.alignment}
				text={ele.text}
				fontStyle={ele.fontStyle}
				fontFamily={ele.fontFamily}
				fontSize={ele.fontSize}
				onDblClick={(e) => {
					// dblClickingText(e);
					if (!IS_MOBILE) {
						dblClickingText(e);
					}
				}}
				onDblTap={(e) => {
					//debugger;
					console.log("hello text onDblTap");

					if (IS_MOBILE && store.getState().canvasStore.selectedElement?.type == "Text") {
						dblClickingText(e);
					}
				}}
				// onDblTap={(e) => {
				// 	if (ele) {
				// 		console.log("onDblTap", e);
				// 	}

				// 	// konvaTransformerInstance.nodes([]);
				// 	// setSelectedElementsIds([]);
				// 	// dblClickingText(e);
				// }}
			/>
		</Label>
	);
}

export function SubtitleObjectToElement({ ele, key, isStatic, text }: { ele: TypeSubtitleElement; key: string; isStatic: boolean; text: string }) {
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}
	return (
		<Text
			key={key}
			data-id={ele.id}
			fill={ele.fill}
			draggable
			x={ele.x}
			align="center"
			lineHeight={1.2}
			y={ele.y}
			width={ele.width}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			name="object"
			fontSize={20 * ele.scaleY}
			text={text}
			{...extra}
			onTransform={(e) => {
				const LabelInstance = store.getState().canvasStore.selectedElementInstance! as Konva.Label;

				// LabelInstance.setAttrs({
				// 	width: LabelInstance.width() * LabelInstance.scaleX(),
				// 	scaleX: 1,
				// });

				// LabelInstance.children?.forEach((c) => {
				// 	c.setAttrs({
				// 		width: LabelInstance.width() * LabelInstance.scaleX(),
				// 		scaleX: 1,
				// 	});
				// });

				const { executeCommand } = store.getActions().canvasStore;
				executeCommand(
					new UpdateCommand<SubtitleElement>([
						{
							id: ele.id,
							width: LabelInstance.width() * LabelInstance.scaleX(),
						},
					]),
				);
			}}
			// onTransform={(e) => {
			// 	e.target.setAttrs({
			// 		width: e.target.width() * e.target.scaleX(),
			// 		scaleX: 1,
			// 	});
			// }}
		/>
	);
}

// export function GifObjectToElement({ ele, key, isStatic }: { ele: GifElement; key: string; isStatic: boolean }) {
// 	// //debugger;
// 	let layer = store.getState().canvasStore.konvaLayerInstance;

// 	if (layer == null) {
// 		alert("Layer is not loaded");
// 		throw new Error("Layer is not loaded");
// 	}

// 	let extra = {};
// 	if (isStatic) {
// 		extra = {
// 			"data-id": ele.id,
// 			name: "object",
// 			draggable: true,
// 		};
// 	}

// 	useEffect(() => {
// 		console.log("User edd0000000000000");
// 	}, []);

// 	// // const imageRef = React.useRef<null | any>(null);

// 	// const [fakeParent] = document.getElementsByClassName("fakeParent");

// 	// let templateImage = new Image();

// 	// fakeParent.appendChild(templateImage);
// 	// // let node = document.layer.appendChild(templateImage);

// 	// templateImage.onload = function () {
// 	// 	// image  has been loaded
// 	// 	drawKonva(templateImage);
// 	// };
// 	// templateImage.src = ele.url;

// 	// let canvas: any;
// 	// function drawKonva(templateImage: HTMLImageElement) {
// 	// 	let gif = new SuperGif({
// 	// 		gif: templateImage,
// 	// 		progressbar_height: 0,
// 	// 		auto_play: true,
// 	// 		loop_mode: true,
// 	// 		draw_while_loading: true,
// 	// 	});

// 	// 	gif.load();
// 	// 	// document.querySelectorAll(".jsgif").forEach((el) => el.remove());
// 	// 	let gif_canvas = gif.get_canvas(); // the lib canv3.36+6+3+as
// 	// 	// a copy of this canvas which will be appended to the doc
// 	// 	canvas = gif_canvas;
// 	// 	// let context = canvas.getContext("2d");
// 	// 	// //debugger;
// 	// 	// function anim() {
// 	// 	// 	if (layer == null) {
// 	// 	// 		alert("Layer is not loaded");
// 	// 	// 		throw new Error("Layer is not loaded");
// 	// 	// 	}

// 	// 	// 	// our animation loop
// 	// 	// 	context.clearRect(0, 0, canvas.width, canvas.height); // in case of transparency ?
// 	// 	// 	context.drawImage(gif_canvas, 0, 0); // draw the gif frame
// 	// 	// 	layer.draw();
// 	// 	// 	requestAnimationFrame(anim);
// 	// 	// }

// 	// 	// anim();
// 	// }

// 	var canvas = document.createElement("canvas");
// 	// use external library to parse and draw gif animation
// 	function onDrawFrame(ctx: any, frame: any) {
// 		if (layer == null) {
// 			alert("Layer is not loaded");
// 			throw new Error("Layer is not loaded");
// 		}
// 		// update canvas size
// 		canvas.width = frame.width;
// 		canvas.height = frame.height;
// 		// update canvas that we are using for Konva.Image
// 		ctx.drawImage(frame.buffer, 0, 0);
// 		// redraw the layer
// 		layer.draw();
// 	}

// 	gifler(ele.url).frames(canvas, onDrawFrame);

// 	return (
// 		<KonvaImage
// 			key={key}
// 			draggable
// 			data-id={ele.id}
// 			height={ele.height}
// 			width={ele.width}
// 			x={ele.x}
// 			fill="#aaa"
// 			y={ele.y}
// 			rotation={ele.rotationDeg}
// 			scaleX={ele.scaleX}
// 			scaleY={ele.scaleY}
// 			opacity={ele.opacity}
// 			image={canvas}
// 		/>
// 	);
// }

export function GifObjectToElement({ ele, key, isStatic }: { ele: GifElement; key: string; isStatic: boolean }) {
	let extra = {};

	if (isStatic) {
		extra = {
			"data-id": ele.id,
			name: "object",
			draggable: true,
		};
	}

	const imageRef = React.useRef<Konva.Image | null>(null);
	const canvas = React.useMemo(() => {
		const node = document.createElement("canvas");
		return node;
	}, []);

	React.useEffect(() => {
		// save animation instance to stop it on unmount
		let anim: any;
		window.gifler(ele.url).get((a: any) => {
			anim = a;
			anim.animateInCanvas(canvas);
			anim.onDrawFrame = (ctx: any, frame: any) => {
				ctx.drawImage(frame.buffer, frame.x, frame.y);
				imageRef?.current?.getLayer()?.draw();
			};
		});
		return () => anim.stop();
	}, [ele.url, canvas]);

	return (
		<KonvaImage
			key={key}
			draggable
			data-id={ele.id}
			height={ele.height}
			width={ele.width}
			x={ele.x}
			{...extra}
			fill="#aaa"
			y={ele.y}
			rotation={ele.rotationDeg}
			scaleX={ele.scaleX}
			scaleY={ele.scaleY}
			opacity={ele.opacity}
			image={canvas}
			ref={imageRef}
		/>
	);
}
