import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { StripCardResponse } from "../../types/ApiTypex/StripCardTypes";
import { TokenService } from "../token";

export async function changeSubscription(subId: number, cardId: string) {
    let token = TokenService.getToken;

    return await catchTry(
        mainAxios.post<StripCardResponse>(
            `${defaultOptions.baseURL}/api/subscriptions/change`,
            {
                subscription: {
                    subscription_id: subId,
                    card_id: cardId,
                },
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    );
}
