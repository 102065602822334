import Konva from "konva";
import { Line } from "konva/lib/shapes/Line";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect } from "react-konva";
import { store } from "../../../store";
import { useEasyState } from "../../../store/hooks";
import { StringLineVisualization as StringLineVisualizationType } from "../../../types/canvasStoreTypes/CanvesTypes";
import { getBarCount, getBarGap, interpolate } from "../../../utils/canvasHelpers";

export interface StringLineVisualizationProps {
	canvasWidth: number;
	canvasHeight: number;
	waveSurfer: WaveSurfer | null;
	konvaLayerInstance: Konva.Layer | null;
	isPlaying: boolean;
	ele: StringLineVisualizationType;
	analyser: AnalyserNode | null;
	key: string;
	isStatic: boolean;
	fill: string;
}

const StringLineVisualization: React.FC<StringLineVisualizationProps> = (props) => {
	// const { audio } = useEasyState((store) => store.visualizationStore);
	const WIDTH = props.canvasWidth * 0.6, // 80% of canvas width
		HEIGHT = props.canvasHeight * 0.3; // 25% of canvas width

	// const vGroupInstance = useRef<Konva.Group | null>(null);
	const [vGroupInstance, setVGroupInstance] = useState<Konva.Group | null>(null);
	const requestAnimationFrameId = useRef<number | null>(null);

	const fftSize = getBarCount(8);
	const noOfBars = fftSize / 2;
	const barMinHeight = 3;

	const colorRef = useRef<string>(props.ele.fill);
	const [line, setLine] = useState<Konva.Line | null>(null);
	useEffect(() => {
		if (vGroupInstance?.children?.[1] instanceof Line) {
			setLine(vGroupInstance?.children?.[1]);
			return;
		}

		initialize();
	}, [vGroupInstance, props.konvaLayerInstance]);
	useEffect(() => {
		// start();

		if (!props.isPlaying) {
			return () => {};
		}

		// console.log("rectGroup", vGroupInstance);
		const stop = render();
		return () => {
			setTimeout(stop, 1000);
		};
	}, [props.konvaLayerInstance, props.waveSurfer, props.isPlaying, line]);

	useEffect(() => {
		if (line) {
			line.fill(props.fill);
		}
	}, [line, props.fill]);
	function initialize() {
		if (vGroupInstance && props.konvaLayerInstance) {
			let gap = getBarGap(WIDTH, 90, noOfBars);
			let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

			let x = 0;

			const group = vGroupInstance;

			const points: number[] = [];

			for (let i = 0; i < noOfBars; i++) {
				points.push(x);
				points.push(HEIGHT - barMinHeight);

				x += barWidth + gap;
			}

			let line = new Konva.Line({
				width: barWidth,
				height: barMinHeight,
				x: 0,
				y: 0,
				stroke: props.fill,
				listening: false,
				strokeWidth: 2,
				points: points,
			});
			//debugger;

			group.add(line);

			setLine(line);
		}
	}

	function render() {
		// console.log("bars", line);

		if (!props.waveSurfer || line == null) {
			return () => {};
		}
		const konvaLayerInstance = props.konvaLayerInstance;
		if (props.analyser == null) {
			return () => {};
		}
		const analyser = props.analyser;
		// analyser.fftSize = fftSize;
		// let bufferLength = analyser.frequencyBinCount;
		let dataArray = new Uint8Array(noOfBars);

		analyser.getByteFrequencyData(dataArray);

		let gap = getBarGap(WIDTH, 80, noOfBars);
		let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

		let isRunning = true;
		function renderFrame() {
			if (isRunning) {
				requestAnimationFrame(renderFrame);
			}

			let x = 0;

			analyser.getByteFrequencyData(dataArray);

			let points: number[] = [];

			for (var i = 0; i < noOfBars; i++) {
				let barHeight = dataArray[i] * interpolate(HEIGHT, 0, 255 - barMinHeight, 0, dataArray[i] / (255 - barMinHeight)) + barMinHeight;
				points.push(x);
				points.push(HEIGHT - barHeight);
				x += barWidth + gap;
			}

			// console.log("bar", Boolean(bar), i, bufferLength);
			//debugger;
			line?.points(points);

			// konvaLayerInstance.draw();
		}

		renderFrame();

		return () => {
			isRunning = false;
		};
	}
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}
	return (
		<Group
			key={props.ele.id}
			data-id={props.ele.id}
			ref={setVGroupInstance}
			opacity={props.ele.opacity}
			{...extra}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			y={props.ele.y}
			x={props.ele.x}
			height={HEIGHT}
			width={WIDTH}
			rotation={props.ele.rotationDeg}>
			<Rect height={HEIGHT} width={WIDTH} fill="#ffffff00" />
		</Group>
	);
};

export default StringLineVisualization;
