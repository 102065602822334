import React, { useRef, useState, useEffect } from "react";
import { Flex, Grid, useToast } from "@chakra-ui/react";
import downArrow from "../../../assets/images/Chevron.svg";
import { Text } from "@chakra-ui/layout";
import rightArrow from "../../../assets/images/ChevronRight.svg";
import leftArrow from "../../../assets/images/ChevronLeft.svg";
import { Datum } from "../../../types/ApiTypex/Giphy";
import { GiphyStickersSearch } from "../../../utils/Api/Giphy";

export interface GiphyStickersProps {}

const GiphyStickers: React.FC<GiphyStickersProps> = (props) => {
	const scrollParent = useRef<null | HTMLDivElement>(null);
	const [hasMore, setHasMore] = useState(false);
	const [limit, setLimit] = useState(10);
	const toast = useToast();
	const [apiRes, setApiRes] = useState<Datum[] | null>();
	function programmaticallyScroll(dir: "left" | "right") {
		if (!scrollParent.current) {
			return;
		}

		scrollParent.current.scrollBy({
			top: 0,
			left: 100 * (dir === "left" ? -1 : 1),
			behavior: "smooth",
		});
	}
	let apiKey = "4EqYUwuzIG9S3YYEitymw3bgwiN8m5ic";

	async function getStickers(text: string) {
		const response = await GiphyStickersSearch(limit, text, apiKey);

		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}
		setApiRes(response.data.data);
		setHasMore(response.data.data.length > 0);
	}
	useEffect(() => {
		getStickers("youtube");
		return () => {
			//your cleanup code codes here
			setApiRes([]);
		};
	}, []);
	return (
		<Flex flexDir="column">
			<Text mx="30px" mb="10px" variant="md">
				Select GIF
			</Text>

			<Flex mx="20px" h="50px" justifyContent="center" alignItems="center">
				<Flex
					cursor="pointer"
					alignItems="center"
					colorscheme="black"
					mr="5px"
					onClick={() => programmaticallyScroll("left")}
					aria-label="Search database">
					<img src={leftArrow} alt="arrow" style={{ height: "22px" }} />
				</Flex>
				<Flex ref={scrollParent} overflow="auto">
					<Grid
						// overflow="auto"
						// className="hideScroll"
						m="2px"
						id="gridImg"
						w="180px"
						gridTemplateColumns="repeat(4, 1fr)"
						gap="5px">
						{/* {apiRes?.map((item, index) => {
              //debugger;
              let url = item?.images?.downsized_medium.url;
              let width = item.images.downsized_medium.width;
              let height = item.images.downsized_medium.height;
              // console.log("url", url);
              return (
                <Flex
                  _hover={{
                    boxShadow: "0px 0px 2px 1px #0095a8",
                    transition: "transform .3s ease-out",
                  }}
                  //   ref={lastImgRef}
                  cursor="pointer"
                  key={index}
                >
                  <img
                    onClick={() => {
                      //   addGif(url, Number(height), Number(width));
                    }}
                    // ref={gifRef}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                    src={url}
                  />
                </Flex>
              );
            })} */}
					</Grid>
				</Flex>

				<Flex
					cursor="pointer"
					alignItems="center"
					colorscheme="black"
					ml="5px"
					onClick={() => programmaticallyScroll("right")}
					aria-label="Search database">
					<img src={rightArrow} alt="arrow" style={{ height: "22px" }} />
				</Flex>
			</Flex>
		</Flex>
	);
};
export default GiphyStickers;
