import React, { useState } from "react";
import { ModelBoxMenu } from "../../components/home/ModelBoxMenu";
import { Button, Box, Flex, Text, useDisclosure, Input } from "@chakra-ui/react";
import { useEasyActions } from "../../store/hooks";

export interface SaveTemplateModelProps {
    titleName: string;
    titleDes: string;
    buttonName: string;
    isOpen: boolean;
    onClose: () => void;
    SetDescription: (e: string) => void;
    SetTitle: (e: string) => void;
    createCanvasData: () => void;
    des?: string;
    name?: string;
}

const SaveTemplateModel: React.FC<SaveTemplateModelProps> = ({
    name,
    des,
    isOpen,
    onClose,
    SetDescription,
    SetTitle,
    createCanvasData,
    titleName,
    titleDes,
    buttonName,
}) => {
    // debugger;

    return (
        <ModelBoxMenu isopen={isOpen} onclose={onClose}>
            <Flex mt="10px" flexDir="column">
                <Text m="10px">{titleName}</Text>
                <Input value={name} onChange={(e) => SetTitle(e.target.value)} />
            </Flex>
            <Flex flexDir="column">
                <Text m="10px">{titleDes}</Text>
                <Input value={des} onChange={(e) => SetDescription(e.target.value)} />
            </Flex>
            <Flex m="10px" flex={1} justifyContent="center">
                <Button
                    mt="10px"
                    variant="styled"
                    onClick={() => {
                        createCanvasData();
                        onClose();
                    }}
                >
                    {buttonName}
                </Button>
            </Flex>
        </ModelBoxMenu>
    );
};

export default SaveTemplateModel;
