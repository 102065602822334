import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CanvasRootObject } from "../../types/ApiTypex/AllCanvas";
import { TokenService } from "../token";

export async function duplicateApi(id: string, name: string, description: string, type: string, rendered: boolean) {
    let token = TokenService.getToken;
    let bodyFormData = new FormData();
    bodyFormData.append("canvas[name]", type + ":" + name);
    bodyFormData.append("canvas[description]", description);
    bodyFormData.append("canvas[rendered]", rendered.toString());

    return await catchTry(
        mainAxios.post<CanvasRootObject>(`${defaultOptions.baseURL}/api/canvas/${id}/duplicate`, bodyFormData, {
            headers: {
                Authorization: token,
            },
        })
    );
}
