import React from "react";

import styles from "./styles.module.scss";
import { Box, Flex } from "@chakra-ui/react";
import { PermissionsInfo, PermissionsStoreType } from "../../../../types/User/permissons";

type Props = {
    isStartFadeOn: boolean;
    isEndFadeOn: boolean;
    onToggleFadeStart: () => void;
    onToggleFadeEnd: () => void;
    permissionsData: PermissionsStoreType;
};

export default (props: Props) => (
    <Flex direction="column">
        <Flex alignItems="baseline" className="d-flex align-items-baseline">
            <input
                type="radio"
                readOnly
                checked={props.permissionsData.audio_fade ? props.isStartFadeOn : false}
                className={styles["radio-button"]}
            />
            <Box as="label" ml="15px" className={`ml-3 ${styles["radio-button-label"]}`} onClick={props.onToggleFadeStart}>
                Fade first 3 seconds
            </Box>
        </Flex>
        <Flex alignItems="baseline" className="d-flex align-items-baseline">
            <input type="radio" readOnly checked={props.permissionsData.audio_fade ? props.isEndFadeOn : false} className={styles["radio-button"]} />
            <Box as="label" ml="15px" className={`ml-3 ${styles["radio-button-label"]}`} onClick={props.onToggleFadeEnd}>
                Fade last 3 seconds
            </Box>
        </Flex>
    </Flex>
);
