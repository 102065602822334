import { Button, Flex, Grid, Heading, Text, border, useMediaQuery } from "@chakra-ui/react";
import { history } from "../../app/router/history";
import React, { useRef } from "react";
import OverFlowBox from "../../components/common/OverFlowBox";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { useEasyActions, useEasyState } from "../../store/hooks";
import greenV from "../../assets/images/Two.svg";
import equalHalf from "../../assets/images/Three_new.svg";
import half from "../../assets/images/One.svg";
import circularString from "../../assets/images/Four.svg";
import circle from "../../assets/images/Five.svg";
import thread from "../../assets/images/Seven.svg";
import biV from "../../assets/images/six_new.svg";
import eight from "../../assets/images/eight.svg";
import progressbar1 from "../../assets/images/Progress1.svg";
import progressbar2 from "../../assets/images/Progress2.svg";
import progressbar3 from "../../assets/images/Progress3.svg";
import { theme } from "../../config/theme/baseDesign";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { basePath } from "../../constants";
import Cursor from "../Audio/cursor/index";
export interface AudioVisualizerProps {}

const AudioVisualizer: React.FC<AudioVisualizerProps> = (props) => {
	const { canvasHWS } = useEasyState((store) => store.canvasStore);
	const { executeCommand, setSelectedElementsIds } = useEasyActions((store) => store.canvasStore);
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	const scrollParent = useRef<null | HTMLDivElement>(null);
	const [isSmallScroll] = useMediaQuery("(max-width: 620px)");
	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;
	function programmaticallyScroll(dir: "left" | "right") {
		if (!scrollParent.current) {
			return;
		}

		scrollParent.current.scrollBy({
			top: 0,
			left: 100 * (dir === "left" ? -1 : 1),
			behavior: "smooth",
		});
	}
	let minH = !isSmall ? "360px" : "0px";
	function greenVisualizer() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "GreenVisualizationElement",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#19ff19",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function upperHalfVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "UpperVisualizationElement",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#ffee06",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function CongestedVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "CongestedVisualization",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#9e60e6",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function fullCongestedVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "fullCongestedVisualization",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#0556c0",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function midCongestedVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "midCongestedVisualization",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#34ebe8",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function CircularVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "CircularVisualization",
				x: (cameraW / 2) * Math.random() - 50,
				y: (cameraH / 2) * Math.random(),
				fill: "#d10073",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function StringCircularVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "StringCircularVisualization",
				x: (cameraW / 2) * Math.random() - 50,
				y: (cameraH / 2) * Math.random(),
				fill: "#d15400",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 10,
				width: 10,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	function StraightProgressBar() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "StraightProgressBar",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#d0ff62",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 50,
				width: 350,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
				fillBar: "#fffc62",
			}),
		);
	}
	function CircularProgressBar() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "CircularProgressBar",
				x: (cameraW / 2) * Math.random() + 80,
				y: (cameraH / 2) * Math.random() + 80,
				fill: "#0fb9fc",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				radius: 50,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
				fillBar: "#6efaff",
			}),
		);
	}

	function SquareProgressBar() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "SquareProgressBar",
				x: cameraW / 2 + 80 * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#ea82ff",
				scaleX: 0.6,
				scaleY: 0.6,
				radius: 50,
				opacity: 1,
				height: 150,
				width: 150,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
				fillBar: "#d858ff",
			}),
		);
	}

	function StringLineVisualization() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "StringLineVisualization",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				fill: "#eba096",
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				height: 150,
				width: 150,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
	}
	// let col = "column";
	// if (isSmall) {
	// 	col = "row";
	// }
	return (
		<Flex flexDir="column" flex={1}>
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end">
					<Flex
						cursor="pointer"
						onClick={() => history.push(basePath + "/design")}
						w="40px"
						justifyContent="flex-end"
						mr={["30px", "30px", "20px"]}
						mt="10px">
						✖
					</Flex>
				</Flex>
				<Heading mb="30px" ml="30px" variant="mdb">
					Visualization
				</Heading>
			</Flex>
			<Text mx="30px" variant="md" mb="10px">
				Waveforms
			</Text>
			<Flex mx="30px" flexDir="column" mb="20px" flex={1} overflow="auto">
				{/* {isSmallScroll && (
					<Flex
						cursor="pointer"
						alignItems="center"
						colorscheme="black"
						onClick={() => programmaticallyScroll("left")}
						aria-label="Search database">
						<RiArrowLeftSLine color="black" size={25} />
					</Flex>
				)} */}
				<OverFlowBox>
					<Grid
						gridTemplateColumns={["1fr 1fr", "1fr 1fr "]}
						// {isSmall &&		(gridAutoFlow={"column"}) }
						// gridAutoFlow={"column"}
						// gridTemplateRows={"1fr"}
						gap={3}
						// justifyContent="center"
						alignItems="center">
						<Flex
							Cursor="pointer"
							h="100px"
							bg={theme.colors.gray1}
							justifyContent="center"
							onClick={() => {
								greenVisualizer();
							}}>
							<img src={greenV} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px"
							onClick={() => {
								upperHalfVisualization();
							}}>
							<img src={equalHalf} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px"
							onClick={() => {
								CongestedVisualization();
							}}>
							<img src={half} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px"
							onClick={() => {
								fullCongestedVisualization();
							}}>
							<img src={biV} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px"
							onClick={() => {
								CircularVisualization();
							}}>
							<img src={circle} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							onClick={() => {
								StringCircularVisualization();
							}}
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px">
							<img src={circularString} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							onClick={() => {
								StringLineVisualization();
							}}
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px">
							<img src={thread} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
						<Flex
							Cursor="pointer"
							onClick={() => {
								midCongestedVisualization();
							}}
							bg={theme.colors.gray1}
							justifyContent="center"
							h="100px">
							<img src={eight} style={{ width: "40px", opacity: "0.5" }} />
						</Flex>
					</Grid>
				</OverFlowBox>
				{/* {isSmallScroll && (
					<Flex
						cursor="pointer"
						alignItems="center"
						colorscheme="black"
						onClick={() => programmaticallyScroll("right")}
						aria-label="Search database">
						<RiArrowRightSLine size={25} color="black" />
					</Flex>
				)} */}
			</Flex>

			<Text mx="30px" variant="md" mb="10px">
				Progress bars
			</Text>

			<Flex mx="30px" flexDir="column" flex={1} overflow="auto" data-simplebar>
				<Grid gridTemplateColumns={["1fr 1fr", "1fr 1fr"]} gap={3} justifyContent="center" alignItems="center">
					<Flex
						Cursor="pointer"
						h="100px"
						bg={theme.colors.gray1}
						justifyContent="center"
						onClick={() => {
							CircularProgressBar();
						}}>
						<img src={progressbar1} style={{ width: "40px", opacity: "0.5" }} />
					</Flex>
					<Flex
						Cursor="pointer"
						bg={theme.colors.gray1}
						justifyContent="center"
						h="100px"
						onClick={() => {
							StraightProgressBar();
						}}>
						<img src={progressbar2} style={{ width: "40px", opacity: "0.5" }} />
					</Flex>
					<Flex
						Cursor="pointer"
						bg={theme.colors.gray1}
						justifyContent="center"
						h="100px"
						onClick={() => {
							SquareProgressBar();
						}}>
						<img src={progressbar3} style={{ width: "40px", opacity: "0.5" }} />
					</Flex>
				</Grid>
			</Flex>

			{!isSmall && (
				<Flex flexDir="column">
					<Flex justifyContent="center" mb="5px" mt="10px">
						<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled" textDecoration="none">
							Back
						</Button>
					</Flex>
					<Flex justifyContent="center" mb="30px">
						<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled">
							Continue
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default AudioVisualizer;
