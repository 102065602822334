import React, { useCallback, useEffect, useState } from "react";
import { Button, Flex, Grid, Heading, InputGroup, InputLeftElement, Input, useDisclosure } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { theme } from "../../config/theme/baseDesign";
import OverFlowBox from "../common/OverFlowBox";
import { FiSearch } from "react-icons/fi";
import ChooseTemplateCard from "./ChooseTemplateCard";
import { canvasDetailApi } from "../../utils/Api/CanvasDetails";
import { useToast } from "@chakra-ui/react";
import { Datum } from "../../types/ApiTypex/AllCanvas";
import { history } from "../../app/router/history";
import { updateCanvasApi } from "../../utils/Api/UpdateCanvas";
import SaveTemplateModel from "../../pages/Generate/SaveTemplateModel";
import { TokenService } from "../../utils/token";
import LoadingContainer from "../../utils/LoadingContainer";
import { ModelBoxMenu } from "../home/ModelBoxMenu";
import { basePath } from "../../constants";

export interface ChooseTemplateModelProps {
	getAllCanvas: () => Promise<void>;
	allTemplate: Datum[];
	setTemplate: (val: Datum[]) => void;
	isloading: boolean;
}

const ChooseTemplateModel: React.FC<ChooseTemplateModelProps> = ({ allTemplate, getAllCanvas, setTemplate, isloading }) => {
	const { templatesKind } = useEasyState((store) => store.templateStore);
	const { selectedCanvasId, canvasState, templatesType, selectedCanvasDes, selectedCanvasName } = useEasyState((store) => store.canvasStore);
	const { setSelectedCanvas, setSelectedCanvasId, setTemplatesType } = useEasyActions((store) => store.canvasStore);
	const toast = useToast();
	const [title, SetTitle] = useState<string | null>(selectedCanvasName);
	const [description, SetDescription] = useState<string | null>(selectedCanvasDes);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenSignIn, onOpen: onOpenSignIn, onClose: onCloseSignIn } = useDisclosure();
	const [text, setText] = useState("");

	useEffect(() => {
		SetTitle(selectedCanvasName);
		SetDescription(selectedCanvasDes);
	}, [selectedCanvasName, selectedCanvasDes]);

	async function updateCanvas(name: string, description: string, id: string) {
		let response = await updateCanvasApi({ id: id, name: name, description: description, type: templatesType });
		

		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}

		toast({
			status: "success",
			title: "Your template is sucessfully updated",
			description: "",
		});
	}

	let landscape: Datum[] = [];
	let square: Datum[] = [];
	let portrait: Datum[] = [];

	// useMemo(() => {
	if (allTemplate != []) {
		allTemplate
			.filter((n) => n.attributes.name.toLowerCase().includes(text.toLowerCase()))
			.forEach((tem, ind) => {
				let name = tem.attributes.name;
				let splited = name.split(":");

				let type = splited[0];
				if (type === "landscape") {
					landscape.unshift({
						...tem,
						attributes: {
							...tem.attributes,
							name: splited[1],
						},
					});
				}
				if (type === "portrait") {
					portrait.unshift({
						...tem,
						attributes: {
							...tem.attributes,
							name: splited[1],
						},
					});
				}
				if (type === "square") {
					square.unshift({
						...tem,
						attributes: {
							...tem.attributes,
							name: splited[1],
						},
					});
				}
			});
	}
	// }, [allTemplate, templatesType]);

	let allDataTemplates: JSX.Element[] = [<></>];
	if (templatesType === "landscape") {
		if (landscape != []) {
			landscape.forEach((temp, ind) =>
				allDataTemplates.push(
					<ChooseTemplateCard
						key={temp.id}
						selectedElement={selectedCanvasId}
						selected={temp.id === selectedCanvasId}
						type="landscape"
						temp={temp}
						ind={ind}
						onDel={() => {
							const newArr = [...allTemplate];
							newArr.splice(
								newArr.findIndex((i) => i.id == temp.id),
								1,
							);
							setTemplate(newArr);
						}}
						getAllCanvas={getAllCanvas}
						rendered={true}
					/>,
				),
			);
		}
	}
	if (templatesType === "portrait") {
		if (portrait != [])
			portrait.forEach((temp, ind) =>
				allDataTemplates.push(
					<ChooseTemplateCard
						key={temp.id}
						selectedElement={selectedCanvasId}
						selected={temp.id === selectedCanvasId}
						type="portrait"
						temp={temp}
						ind={ind}
						onDel={() => {
							const newArr = [...allTemplate];
							newArr.splice(
								newArr.findIndex((i) => i.id == temp.id),
								1,
							);
							setTemplate(newArr);
						}}
						getAllCanvas={getAllCanvas}
						rendered={true}
					/>,
				),
			);
	}
	if (templatesType === "square") {
		if (square != [])
			square.forEach((temp, ind) =>
				allDataTemplates.push(
					<ChooseTemplateCard
						key={temp.id}
						selectedElement={selectedCanvasId}
						selected={temp.id === selectedCanvasId}
						type="square"
						temp={temp}
						ind={ind}
						onDel={() => {
							const newArr = [...allTemplate];
							newArr.splice(
								newArr.findIndex((i) => i.id == temp.id),
								1,
							);
							setTemplate(newArr);
						}}
						getAllCanvas={getAllCanvas}
						rendered={true}
					/>,
				),
			);
	}
	let role = TokenService.getRole();

	async function canvasDetail(id: string) {
		let response = await canvasDetailApi(id);
		
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some problem please try later.",
			});
			return;
		}
		// console.log(response.data.data.attributes.details, "setSelectedCanvas");
		let parsedState = JSON.parse(response.data.data.attributes.details);
		setSelectedCanvas(parsedState);
		// console.log(canvasState, "canvasState");
	}

	const blur = useCallback((e: MouseEvent) => {
		if (
			e.target &&
			!document.getElementById("templateContainer")?.contains(e.target as any) &&
			!document.getElementById("continueButton")?.contains(e.target as any) &&
			!document.getElementById("editButton")?.contains(e.target as any)
		) {
			// console.log("card inside");
			setSelectedCanvasId(null);
		}
	}, []);

	useEffect(() => {
		let parent = document.getElementById("templatesdataParent");
		if (selectedCanvasId === null) {
			parent?.removeEventListener("click", blur);
			return;
		}
		parent?.addEventListener("click", blur);
		return () => {
			parent?.addEventListener("click", blur);
		};
	}, [selectedCanvasId]);
	return (
		<Flex flex={1} my="20px" mx="10px" flexDir="column" id="templatesdataParent">
			<Flex flexWrap="wrap" pl="25px" mb="20px" flex={1}>
				<Flex minW="300px" mr="10px" alignItems="center">
					<Heading variant="smb">Select a template</Heading>
				</Flex>

				<Flex flex={1} alignItems="flex-start">
					<InputGroup justifyContent="flex-start" mr="50px">
						<InputLeftElement mt="5px" alignItems="center" pointerEvents="none" rounded="md" children={<FiSearch size={20} />} />
						<Input
							my="5px"
							type="text"
							w="200px"
							onChange={(e) => {
								setText(e.target.value);
							}}
						/>
					</InputGroup>
				</Flex>
			</Flex>

			<Grid gridTemplateColumns={["1fr", null, "3fr 1fr", "3fr 1fr"]} gridTemplateRows={["1fr auto ", null, null, "1fr"]} height="100%" gap={2}>
				<Flex flex={1} flexDir="column">
					<Flex borderBottom="1px solid #e5e4e4" alignItems="flex-start" justifyContent="space-around" mx="12px" mb="5px">
						<Flex
							cursor="pointer"
							onClick={() => {
								setTemplatesType("landscape");
								// await getAllCanvas();
							}}
							background={templatesType === "landscape" ? "#f3f3f3" : "white"}
							color={templatesType === "landscape" ? "black" : theme.colors.gray3}
							flex={1}
							// boxShadow="0px 0px 4px 1px #e5e4e4"
							justifyContent="center"
							// mx="4px"
							rounded="sm"
							p="5px">
							Landscape
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => {
								setTemplatesType("portrait");
								// await getAllCanvas();
							}}
							background={templatesType === "portrait" ? "#f3f3f3" : "white"}
							color={templatesType === "portrait" ? "black" : theme.colors.gray3}
							flex={1}
							// boxShadow="0px 0px 4px 1px #e5e4e4"
							justifyContent="center"
							// mx="4px"
							rounded="sm"
							p="5px">
							Portrait
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => {
								setTemplatesType("square");
								// await getAllCanvas();
							}}
							flex={1}
							background={templatesType === "square" ? "#f3f3f3" : "white"}
							color={templatesType === "square" ? "black" : theme.colors.gray3}
							// boxShadow="0px 0px 4px 1px #e5e4e4"
							justifyContent="center"
							// mx="4px"
							rounded="sm"
							p="5px">
							Square
						</Flex>
					</Flex>
					<OverFlowBox>
						<LoadingContainer isLoading={isloading}>
							<Grid
								placeItems="flex-start"
								justifyItems="center"
								h="100%"
								gridTemplateColumns={!(templatesType === "landscape") ? ["1fr", "1fr", "1fr 1fr", "1fr 1fr"] : "1fr"}
								overflow="auto">
								{allDataTemplates}
							</Grid>
						</LoadingContainer>
					</OverFlowBox>
				</Flex>
				<Flex mt="15px" flex={1}>
					<Flex mx="20px" overflow="auto" w="100%" alignItems="center" flexDir="column" justifyContent="center">
						<Button
							id="continueButton"
							disabled={selectedCanvasId == null}
							onClick={() => {
								let token = TokenService.getToken();
								if (token === null) {
									onOpenSignIn();

									return;
								}
								if (!selectedCanvasId) {
									return;
								}
								canvasDetail(selectedCanvasId);
								history.push(basePath + "/design");
							}}
							bg={theme.colors.primary}
							color="white"
							w={["150px", "150px"]}>
							Continue
						</Button>

						{
							<ModelBoxMenu isopen={isOpenSignIn} onclose={onCloseSignIn}>
								<Flex flexDir="column">
									<Flex flex={1} justifyContent="center" my="20px">
										Create a free account to use templates
									</Flex>
									<Flex flex={1} justifyContent="space-around" my="10px" mb="10px">
										<Button
											onClick={onCloseSignIn}
											bg={theme.colors.primary}
											color="white"
											w={["150px", "150px"]}
											_hover={{ bg: theme.colors.primary }}>
											Cancel
										</Button>
										<Button
											onClick={() => {
												history.push(basePath + "/signup");
											}}
											bg={theme.colors.primary}
											color="white"
											w={["150px", "150px"]}
											_hover={{ bg: theme.colors.primary }}>
											Sign up
										</Button>
									</Flex>
								</Flex>
							</ModelBoxMenu>
						}
						{(role === "true" && templatesKind === "default" && selectedCanvasId !== null) ||
							(role === "false" && templatesKind === "saved" && selectedCanvasId !== null && (
								<Button
									id="editButton"
									mt="15px"
									bg={theme.colors.primary}
									color="white"
									w={["150px", "150px"]}
									onClick={() => {
										onOpen();
									}}>
									Edit Name
								</Button>
							))}
						{selectedCanvasId && title && description != null && (
							<SaveTemplateModel
								buttonName="Edit template name"
								titleDes="Edit template description"
								titleName="Edit"
								isOpen={isOpen}
								des={description}
								name={title}
								onClose={onClose}
								createCanvasData={async () => {
									await updateCanvas(title, description, selectedCanvasId);

									await getAllCanvas();
								}}
								SetTitle={SetTitle}
								SetDescription={SetDescription}
							/>
						)}
					</Flex>
				</Flex>
			</Grid>
		</Flex>
	);
};

export default ChooseTemplateModel;
