import Konva from "konva";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect } from "react-konva";
import { store } from "../../../store";
import { useEasyState } from "../../../store/hooks";
import { getBarCount, getBarGap, interpolate } from "../../../utils/canvasHelpers";

export interface GreenVisualizationProps {
	canvasWidth: number;
	canvasHeight: number;
	waveSurfer: WaveSurfer | null;
	konvaLayerInstance: Konva.Layer | null;
	isPlaying: boolean;
	ele: any;
	analyser: AnalyserNode | null;
	key: string;
	fill: string;
	isStatic: boolean;
}

const GreenVisualization: React.FC<GreenVisualizationProps> = (props) => {
	const WIDTH = props.canvasWidth * 0.6, // 60% of canvas width
		HEIGHT = props.canvasHeight * 0.3; // 30% of canvas width

	// const vGroupInstance = useRef<Konva.Group | null>(null);
	const [vGroupInstance, setVGroupInstance] = useState<Konva.Group | null>(null);
	const requestAnimationFrameId = useRef<number | null>(null);

	const fftSize = getBarCount(7);
	const noOfBars = fftSize / 2;
	const barMinHeight = 3;

	const colorRef = useRef<string>(props.ele.fill);
	const [bars, setBars] = useState<Konva.Rect[]>([]);
	useEffect(() => {
		//debugger;

		initialize();
	}, [vGroupInstance, props.konvaLayerInstance]);
	let barHeight;
	useEffect(() => {
		// start();
		debugger;
		// console.log("render effect", !!props.konvaLayerInstance, !!props.waveSurfer, !!props.waveSurfer?.isPlaying, !!props.analyser);

		if (!props.isPlaying) {
			// bars = [];
			// barHeight = 0;
			// setVGroupInstance(null);
			// initialize();
			return () => {};
		}

		// console.log("rectGroup", vGroupInstance);
		const stop = render();

		return () => {
			setTimeout(stop, 1000);
		};
	}, [props.konvaLayerInstance, props.waveSurfer, props.isPlaying, bars]);

	useEffect(() => {
		if (bars) {
			for (const bar of bars) {
				bar.fill(props.fill);
			}
		}
	}, [bars, props.fill]);

	function initialize() {
		// //debugger;
		// console.log("initialize", bars);

		if (vGroupInstance && bars.length === 0) {
			let gap = getBarGap(WIDTH, 80, noOfBars);
			let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

			let x = 0;

			const group = vGroupInstance;

			const bars: Konva.Rect[] = [];

			for (let i = 0; i < noOfBars; i++) {
				let bar = new Konva.Rect({
					key: i,
					width: barWidth,
					height: barMinHeight,
					x: x,
					y: HEIGHT / 2 - barMinHeight / 2,
					fill: props.fill,
					listening: false,
				});

				bars.push(bar);
				// console.log("Bars added in group", i);

				group.add(bar);

				x += barWidth + gap;
			}

			setBars(bars);
			// console.log("setBars", bars);
		}
	}

	function render() {
		// console.log("bars", bars);
		// console.log("renderFrame();", props.analyser, bars.length);

		if (!props.waveSurfer || bars.length === 0) {
			return () => {};
		}
		const konvaLayerInstance = props.konvaLayerInstance;

		if (props.analyser == null) {
			return () => {};
		}

		const analyser = props.analyser;
		// const analyser = getAudioAnalyser(props.audio);
		// analyser.fftSize = fftSize;
		// let bufferLength = analyser.frequencyBinCount;
		let dataArray = new Uint8Array(noOfBars);

		analyser.getByteFrequencyData(dataArray);

		let gap = getBarGap(WIDTH, 80, noOfBars);
		let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

		let isRunning = true;
		function renderFrame() {
			if (isRunning) {
				requestAnimationFrame(renderFrame);
			}
			let x = 0;
			//debugger;
			analyser.getByteFrequencyData(dataArray);
			// console.log("dataArray", dataArray);

			for (var i = 0; i < noOfBars; i++) {
				barHeight = dataArray[i] * interpolate(HEIGHT, 0, 255 - barMinHeight, 0, dataArray[i] / (255 - barMinHeight)) + barMinHeight;

				const bar = bars[i];
				// console.log("bar", Boolean(bar), i, bufferLength);

				bar.height(barHeight);
				bar.width(barWidth);
				bar.y(HEIGHT / 2 - barHeight / 2);

				x += barWidth + gap;
			}

			// konvaLayerInstance.draw();
		}

		renderFrame();

		return () => {
			isRunning = false;
		};
	}
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}

	return (
		<Group
			key={props.ele.id}
			data-id={props.ele.id}
			ref={setVGroupInstance}
			opacity={props.ele.opacity}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			y={props.ele.y}
			x={props.ele.x}
			height={HEIGHT}
			width={WIDTH}
			rotation={props.ele.rotationDeg}
			{...extra}>
			<Rect height={HEIGHT} width={WIDTH} fill="#ffffff00" />
		</Group>
	);
};

export default GreenVisualization;
