// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
import { pxToRem, fontSize } from "./Helper";
// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
    colors: {
        primary: "#0095A8",
        secondary: "#FF876C",
        gray1: "#E5E5E5",
        gray2: "#C4C4C4",
        gray3: "#6C6C6C",
        white: "#fff",
        black: "#000",
    },
    fonts: {
        body: "Helvetica",
        heading: "Helvetica",
    },
    border: {},

    components: {
        Heading: {
            variants: {
                lg: {
                    fontSize: "2rem",
                    fontWeight: "700",
                },
                md: {
                    fontSize: "1.2rem",
                    fontWeight: "400",
                },
                mdb: {
                    fontSize: "1.2rem",
                    fontWeight: "700",
                },
                sm: {
                    fontSize: "18px",
                    fontWeight: "400",
                },
                smb: {
                    fontSize: "16px",
                    fontWeight: "700",
                },
                arrHead: {
                    fontSize: ["2rem", "2.3rem", "2.5rem", , "3rem"],
                },
            },
        },
        Text: {
            variants: {
                xxxxxlg: {
                    fontSize: "96px",
                    fontWeight: "700",
                },
                xxxlg: {
                    fontSize: "36px",
                    fontWeight: "700",
                },
                xxlg: {
                    fontSize: "22px",
                    fontWeight: "700",
                },
                xlg: {
                    fontSize: "22px",
                    fontWeight: "400",
                },
                lg: {
                    fontSize: "18px",
                    fontWeight: "400",
                },
                lgb: {
                    fontSize: "14px",
                    fontWeight: "600",
                },
                lgbm: {
                    fontSize: "16px",
                    fontWeight: "400",
                },
                md: {
                    fontSize: "12px",
                    fontWeight: "400",
                },
                sm: {
                    fontSize: "10px",
                    fontWeight: "400",
                },
            },
        },
        Button: {
            variants: {
                styled: {
                    cursor: "pointer",
                    bg: "primary",
                    color: "white",
                    radius: "0.9rem",
                    height: pxToRem(40),
                    minW: ["100px", "130px", "180px"],
                    _hover: {
                        bg: "primary",
                    },
                },
                iconBtn: {
                    height: "60px",
                    width: "60px",
                    bg: "primary",
                },
            },
        },

        Box: {
            variants: {},
        },
    },
});

export type AppColors = keyof typeof theme.colors;
