import Konva from "konva";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect } from "react-konva";
import { store } from "../../../store";
import { useEasyState } from "../../../store/hooks";
import { getBarCount, getBarGap, interpolate } from "../../../utils/canvasHelpers";

export interface CircularVisualizationProps {
	canvasWidth: number;
	waveSurfer: WaveSurfer | null;
	konvaLayerInstance: Konva.Layer | null;
	canvasHeight: number;
	isPlaying: boolean;
	ele: any;
	analyser: AnalyserNode | null;
	key: string;
	isStatic: boolean;
	fill: string;
}

const CircularVisualization: React.FC<CircularVisualizationProps> = (props) => {
	// const { audio } = useEasyState((store) => store.visualizationStore);
	const WIDTH = props.canvasWidth * 0.4, // 40% of canvas width
		HEIGHT = props.canvasHeight * 0.4; // 40% of canvas width

	// const vGroupInstance = useRef<Konva.Group | null>(null);
	const [vGroupInstance, setVGroupInstance] = useState<Konva.Group | null>(null);
	const requestAnimationFrameId = useRef<number | null>(null);

	const fftSize = getBarCount(7);
	let noOfBars = fftSize / 2;
	const barMinHeight = 3;
	const fullCircle = 360;
	const eachBarAngle = fullCircle / noOfBars;
	const rad = (WIDTH / 2) * 0.3;

	const colorRef = useRef<string>(props.ele.fill);
	const [bars, setBars] = useState<Konva.Rect[]>([]);
	useEffect(() => {
		initialize();
	}, [vGroupInstance, props.konvaLayerInstance]);
	useEffect(() => {
		// start();
		//debugger;
		if (!props.isPlaying) {
			return () => {};
		}

		const stop = render();
		return () => {
			setTimeout(stop, 1000);
		};
	}, [props.konvaLayerInstance, props.waveSurfer, props.isPlaying, bars]);

	useEffect(() => {
		if (bars) {
			for (const bar of bars) {
				bar.fill(props.fill);
			}
		}
	}, [bars, props.fill]);
	function initialize() {
		if (vGroupInstance && props.konvaLayerInstance && bars.length === 0) {
			// let gap = getBarGap(WIDTH, 50, noOfBars);
			// let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

			const group = vGroupInstance;

			const bars: Konva.Rect[] = [];

			for (let i = 0; i < noOfBars; i++) {
				const theta = i * eachBarAngle + 270;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = Math.cos(thetaRad) * rad;
				const yOffset = Math.sin(thetaRad) * rad;

				let bar = new Konva.Rect({
					width: eachBarAngle - eachBarAngle * 0.5,
					height: barMinHeight,
					x: WIDTH / 2 + xOffset,
					y: HEIGHT / 2 + yOffset,
					// fill: `hsl(${theta}, 70%, 50%)`,
					fill: props.fill,
					text: i.toString(),
					listening: false,
					rotation: theta - 90,
				});

				bars.push(bar);
				group.add(bar);
			}

			setBars(bars);
		}
	}

	function render() {
		//debugger;

		if (!props.waveSurfer || bars.length === 0) {
			return () => {};
		}
		const konvaLayerInstance = props.konvaLayerInstance;
		if (props.analyser == null) {
			return () => {};
		}
		const analyser = props.analyser;
		// analyser.fftSize = fftSize;
		// let bufferLength = analyser.frequencyBinCount;
		let dataArray = new Uint8Array(noOfBars);

		analyser.getByteFrequencyData(dataArray);

		let isRunning = true;

		const maxHeight = HEIGHT / 2 - rad;

		function renderFrame() {
			//debugger;
			if (isRunning) {
				requestAnimationFrame(renderFrame);
			}

			analyser.getByteFrequencyData(dataArray);

			for (var i = 0; i < noOfBars; i++) {
				let barHeight = dataArray[i] * interpolate(maxHeight, 0, 255 - barMinHeight, 0, dataArray[i] / (255 - barMinHeight)) + barMinHeight;

				const bar = bars[i];
				const theta = i * eachBarAngle + 270;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = Math.cos(thetaRad) * rad;
				const yOffset = Math.sin(thetaRad) * rad;

				// console.log(theta);

				bar.height(barHeight);
				bar.width(eachBarAngle - eachBarAngle * 0.5);
				bar.y(HEIGHT / 2 + yOffset);
				bar.x(WIDTH / 2 + xOffset);
				bar.rotation(theta - 90);
			}

			// konvaLayerInstance.batchDraw();
		}

		renderFrame();

		return () => {
			isRunning = false;
		};
	}
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}
	return (
		<Group
			key={props.ele.id}
			data-id={props.ele.id}
			ref={setVGroupInstance}
			opacity={props.ele.opacity}
			{...extra}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			y={props.ele.y}
			x={props.ele.x}
			height={HEIGHT}
			width={WIDTH}
			rotation={props.ele.rotationDeg}>
			<Rect height={HEIGHT} width={WIDTH} fill="#ffffff00" />
		</Group>
	);
};

export default CircularVisualization;
