import Konva from "konva";
import { Layer } from "konva/lib/Layer";
import { Stage } from "konva/lib/Stage";
import { store } from "../../store";
import { isSpecialKeyPressing, Vector } from "../../types";
import { debounce } from "../debounce";

export function konvaZoomMain({
	konvaStageInstance,
	konvaLayerInstance,
	setIsStatic,
}: {
	konvaStageInstance: Stage;
	konvaLayerInstance: Layer;
	setIsStatic: (val: boolean) => void;
}) {
	const stage = konvaStageInstance;
	const { setStagePosition: setStagePositionNoDebounce, setStageScale: setStageScaleNoDebounce } = store.getActions().canvasStore;

	const setStagePosition = debounce(setStagePositionNoDebounce, 400);
	const setStageScale = debounce(setStageScaleNoDebounce, 400);

	var scaleBy = 1.015;

	stage.on("wheel", (e) => {
		e.evt.preventDefault();

		if (!isSpecialKeyPressing(e.evt)) return;
		var oldScale = stage.scaleX();

		var pointer = stage.getPointerPosition()!;

		var mousePointTo = {
			x: (pointer.x - stage.x()) / oldScale,
			y: (pointer.y - stage.y()) / oldScale,
		};

		var newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : Math.max(oldScale / scaleBy, 1);

		stage.scale({ x: newScale, y: newScale });
		setStageScale({ x: newScale, y: newScale });

		var newPos = {
			x: pointer.x - mousePointTo.x * newScale,
			y: pointer.y - mousePointTo.y * newScale,
		};

		stage.position(newPos);
		setStagePosition(newPos);
	});

	let iSMouseDown = false;

	let startPoint: Vector = {
		x: 0,
		y: 0,
	};

	let lastStagePosition: Vector = {
		x: 0,
		y: 0,
	};

	let tempOffset: Vector = {
		x: 0,
		y: 0,
	};

	stage.on("mousedown", (e) => {
		// console.log("hello");

		e.evt.preventDefault();
		if (!isSpecialKeyPressing(e.evt)) return;

		iSMouseDown = true;
		// console.log({ x: e.evt.offsetX, y: e.evt.offsetY }, "mousedown offset");

		startPoint = { x: e.evt.offsetX, y: e.evt.offsetY };
		lastStagePosition = stage.getAbsolutePosition();
	});

	stage.on("mouseup", (e) => {
		e.evt.preventDefault();
		iSMouseDown = false;
	});

	// stage.on("click", (e) => {
	stage.on("mousemove", (e) => {
		if (!isSpecialKeyPressing(e.evt) || !iSMouseDown) return;
		document.body.style.cursor = "grabbing";

		e.evt.preventDefault();
		const currentPointer = stage.getPointerPosition()!;

		const displacement: Vector = {
			x: startPoint.x - currentPointer.x,
			y: startPoint.y - currentPointer.y,
		};

		const newPosition: Vector = {
			x: lastStagePosition.x - displacement.x,
			y: lastStagePosition.y - displacement.y,
		};

		stage.position(newPosition);
		setStagePosition(newPosition);
	});
}
