import { Flex } from "@chakra-ui/react";
import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames";

type Props = {
	onZoomIn: () => void;
	onZoomOut: () => void;
	className: string;
};

export default (props: Props) => (
	<Flex className={classNames('', props.className || "d-flex")}>
		<button className={`btn text-white ${styles["zoom-btn"]}`} onClick={props.onZoomIn}>
			<span>+</span>
		</button>
		<button className={`btn text-white ml-9 ${styles["zoom-btn"]}`} onClick={props.onZoomOut}>
			<span>-</span>
		</button>
	</Flex>
);
