import { Flex } from "@chakra-ui/react";
import { motion, useMotionValue } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { MotionFlex } from "./MotionFlex";

export interface SeekBarProps {
	completeRatio: number;

	onChange: (ratio: number) => void;
}

const SeekBar: React.FC<SeekBarProps> = (props) => {
	const [seekBarParentRef, setSeekBarParentRef] = useState<null | HTMLDivElement>(null);
	const seekBarParentRect = seekBarParentRef?.getBoundingClientRect();

	const [seekBartRef, setSeekBarRef] = useState<null | HTMLElement | SVGElement>(null);
	const fillWidth = useMotionValue(0);

	const [sliderValue, setSliderValue] = useState(0);
	useEffect(() => {
		if (seekBarParentRect == null || seekBartRef == null) {
			return () => {};
		}
		const seekBarRect = seekBartRef?.getBoundingClientRect();
		fillWidth.set(seekBarRect?.x - seekBarParentRect.x + 30);
		setSliderValue((seekBarParentRect.width - 30) * props.completeRatio);
	}, [props.completeRatio]);

	return (
		<Flex
			flexShrink={0}
			h="35px"
			bg="gray1"
			w="100%"
			ref={setSeekBarParentRef}
			onClick={(e) => {
				if (seekBarParentRect == null || seekBartRef == null) {
					return;
				}
				// console.log(e.pageX);

				const newKnobPos = e.pageX - seekBarParentRect.x;

				setSliderValue(newKnobPos - 15);
				fillWidth.set(newKnobPos);

				props.onChange(newKnobPos / seekBarParentRect.width);
			}}
			onMouseEnter={() => {
				if (seekBarParentRect == null || seekBartRef == null) {
					return;
				}

				const seekBarRect = seekBartRef?.getBoundingClientRect();
				fillWidth.set(seekBarRect?.x - seekBarParentRect.x + 30);
				// console.log("MouseUP", seekBarRect?.x - seekBarParentRect.x + 30);
			}}>
			{seekBarParentRect && (
				<>
					<MotionFlex
						position="absolute"
						h="35px"
						ref={setSeekBarRef}
						style={{ width: fillWidth as any }}
						bg="hsl(0, 0%, 42.35294117647059%)"
						borderRadius="10px"></MotionFlex>
					<MotionFlex
						ref={setSeekBarRef}
						height="100%"
						w="30px"
						bg="#6c6c6c00"
						top={seekBarParentRect.top}
						bottom={seekBarParentRect.bottom}
						borderRadius="10px"
						onDrag={() => {
							if (seekBarParentRect == null || seekBartRef == null) {
								return;
							}

							const seekBarRect = seekBartRef?.getBoundingClientRect();
							const currentOffset = seekBarRect?.x - seekBarParentRect.x;
							fillWidth.set(currentOffset + 30);
							setSliderValue(seekBarParentRect.x);
							props.onChange(currentOffset / seekBarParentRect.width);
						}}
						onMouseLeave={() => {
							setTimeout(() => {
								if (seekBarParentRect == null || seekBartRef == null) {
									return;
								}

								const seekBarRect = seekBartRef?.getBoundingClientRect();
								fillWidth.set(seekBarRect?.x - seekBarParentRect.x + 30);
							}, 100);
						}}
						drag={"x"}
						dragElastic={false}
						dragMomentum={false}
						animate={{ x: sliderValue }}
						dragConstraints={{ left: 0, right: seekBarParentRect.width - 30 }}
						transition={{
							duration: 0.01,
						}}
					/>
				</>
			)}
		</Flex>
	);
};

export default SeekBar;
