import { Layer, Stage, Group, Transformer, Rect } from "react-konva";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { theme } from "../../config/theme/baseDesign";
import Konva from "konva";
import { useEasyState, useEasyActions } from "../../store/hooks/index";
import { KonvaEventObject, Node, NodeConfig } from "konva/lib/Node";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { RemoveCommand } from "../../Commands/CanvasCommanda/RemoveCommand";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { konvaMain } from "../../utils/RawKonvaImplimentations";
import { store } from "../../store";
import ResizeObserver from "resize-observer-polyfill";
import OverFlowBox from "../common/OverFlowBox";
import CanvasZoomInOutButtons from "../../pages/Home/CanvasZoomInOutButtons";
import { renderElementsHighlights } from "../../utils/CanvesElementHighlightsElements";
import { selectableElement } from "../../utils/Konva";

import useImage from "use-image";
import { debounce } from "../../utils/debounce";
import AllCanvasElementRender from "./AllCanvasElementRender";
import { permissionApi } from "../../utils/Api/PermissionApi";
import { updateCanvasApi } from "../../utils/Api/UpdateCanvas";
import { useToast } from "@chakra-ui/react";
import { dataUrlToFile } from "../../utils/base64ToImage";
import { allUnrenderedCanvas } from "../../utils/Api/AllUnrenderedCanvas";

export interface CanvasEditorProps {
  // sectionId: string;
}

const biggestLength = 6000;
const biggestLengthHalf = 6000 / 2;

type SelectedElement = {
  ref: Konva.Node;
  obj: AllCanvasElements;
};

const CanvasEditor: React.FC<CanvasEditorProps> = (props) => {
  const ICON =
    "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDI2LjY2NyA0MjYuNjY3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MjYuNjY3IDQyNi42Njc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNMjEzLjMzMyw4NS4zMzNWMEwxMDYuNjY3LDEwNi42NjdsMTA2LjY2NywxMDYuNjY3VjEyOGM3MC43MiwwLDEyOCw1Ny4yOCwxMjgsMTI4cy01Ny4yOCwxMjgtMTI4LDEyOHMtMTI4LTU3LjI4LTEyOC0xMjgNCgkJCUg0Mi42NjdjMCw5NC4yOTMsNzYuMzczLDE3MC42NjcsMTcwLjY2NywxNzAuNjY3UzM4NCwzNTAuMjkzLDM4NCwyNTZTMzA3LjYyNyw4NS4zMzMsMjEzLjMzMyw4NS4zMzN6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=";
  const toast = useToast();

  const {
    templatesType,
    canvasState,
    konvaTransformerInstance,
    selectedElementInstance,
    selectedElementsInstance,
    selectedElementsIds,
    konvaStageInstance,
    konvaLayerInstance,
    selectedElement,
    selectedElements,
    selectedElementId,
    konvaCurrentHoverElement,
    stagePosition,
    stageScale,
    highlightingElements,
    canvasParentHW,
    canvasHWS,
    isStatic,
    selectedCanvasId,
    screenShot,
  } = useEasyState((store) => store.canvasStore);
  // console.log("-------->", { selectedElementsIds });
  const {
    currentSubtitle,
    isPlaying,
    analyser,
    waveSurfer,
    videoFinishRatio,
    subtitleWithTime,
  } = useEasyState((store) => store.visualizationStore);
  const { setPermissionsData } = useEasyActions((store) => store.userDataStore);
  const { audioRecordId } = useEasyState((store) => store.userDataStore);
  const { setUnrenderedList } = useEasyActions(
    (store) => store.unrenderedStore
  );
  const { unrenderedTitle } = useEasyState((store) => store.unrenderedStore);
  const { resultAudio } = useEasyState((store) => store.audioStore);

  const [isSmallCanvas] = useMediaQuery("(max-width: 993px)");

  const {
    setCanvasParentHW,
    scaleRecheck,
    setSelectedElementsIds,
    setIsReplaceable,
    executeCommand,
    setKonvaCurrentHoverElement,
    setHighlightingElements,
    setIsStatic,
    setScreenShot,
  } = useEasyActions((state) => state.canvasStore);
  function DblTapDebounce() {
    setDoubleTap(0);
    // console.log("onTouchEnd", doubleTap);
  }

  const onDblTapDebounce = useCallback(debounce(DblTapDebounce, 500), []);

  // const [[stageDimensionsX, stageDimensionsY], setStageDimensions] = useState<[number, number]>([500, 500]);

  const { height: stageDimensionsY, width: stageDimensionsX } = canvasParentHW;

  const parentWrapperRef = useRef<HTMLDivElement | null>(null);
  // const [[camH, camW], SetHW] = useState<[number, number]>([500, 500]);

  const cameraGroup = useRef<null | Konva.Group>(null);
  const { unrenderedCanvasId } = useEasyState((store) => store.unrenderedStore);

  const [
    elementInCanvasWhileSelectingArea,
    setElementInCanvasWhileSelectingArea,
  ] = useState<Konva.Node[]>([]);
  const [doubleTap, setDoubleTap] = useState(0);
  const selectionRectRef = React.useRef<Konva.Rect | null>();

  const selection = React.useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  // window.innerHeight - 200
  // cameraH * (16 / 9)

  let cameraH = canvasHWS.height;
  let cameraW = canvasHWS.width;

  const cameraX = stageDimensionsX / 2 - cameraW / 2;
  const cameraY = stageDimensionsY / 2 - cameraH / 2;
  const [icon] = useImage(ICON);
  useEffect(() => {
    for (const ele of Object.entries(canvasState)) {
      if (ele[1].type === "WaterMark") {
        Object.keys(canvasState);
        selectedElementsIds.push(ele[0]);
        executeCommand(new RemoveCommand(selectedElementsIds));
      }
    }
  }, []);
  useEffect(() => {
    if (konvaStageInstance && konvaLayerInstance) {
      konvaMain({ konvaStageInstance, konvaLayerInstance, setIsStatic });
    }
  }, [konvaStageInstance, konvaLayerInstance]);

  useEffect(() => {
    const deleteEvent = (e: KeyboardEvent) => {
    //   console.log("delete Event call");
      if (e.code !== "Backspace" && e.code !== "Delete") {
        return;
      }
      deleteElement();
    };

    window.addEventListener("keydown", deleteEvent);
    return () => {
      window.removeEventListener("keydown", deleteEvent);
    };
  }, []);

  useEffect(() => {
    if (!icon || konvaTransformerInstance == null) {
      return;
    }
    const tr: Konva.Transformer = konvaTransformerInstance;
    //debugger;
    let rot: Konva.Rect = tr.findOne(".rotater");
    if (!rot) {
      return;
    }
    const iconCanvas = document.createElement("canvas");

    iconCanvas.width = rot.width();
    iconCanvas.height = rot.height();
    const ctx = iconCanvas.getContext("2d");
    if (!ctx) {
      return;
    }
    // tr.nodes([selectionRectRef.current]);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, iconCanvas.width, iconCanvas.height);

    ctx.drawImage(icon, 0, 0, iconCanvas.width, iconCanvas.height);
    tr.update = function () {
      // console.log("update", tr);

      Konva.Transformer.prototype.update.call(tr);
      let rot: Konva.Rect = this.findOne(".rotater");
      // disaable fill
      rot.fill("");
      rot.stroke("");

      // enable icon
      rot.fillPatternImage(iconCanvas);
    };

    tr.update();
    tr?.getLayer()?.draw();
  }, [icon]);

  useEffect(() => {
    if (!parentWrapperRef.current) {
      return () => {};
    }

    const element = parentWrapperRef.current;

    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        // const { width, height } = element.getBoundingClientRect();

        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          setCanvasParentHW({ width: width, height: height });
          // SetHW([width / 2, height / 2]);
          // console.log(width, "width", height, "height", canvasParentHW, "canvasParentHW");

          // setStageDimensions([width, height]);
        }
        scaleRecheck("");
      }
    );

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [parentWrapperRef.current]);

  //@ts-ignore
  const Konva = window.Konva as Konva;

  const deleteElement = () => {
    //debugger;
    let SleEleId = store.getState().canvasStore.selectedElementsIds;
    let SleEle = store.getState().canvasStore.selectedElement;
    // const element = document.getElementsByClassName("TextareaElementForKonvaText");
    // console.log(element, "element");
    if (
      SleEleId &&
      !(
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement
      )
    ) {
      executeCommand(new RemoveCommand(SleEleId));
      setSelectedElementsIds([]);
    }
  };

  const canvasElementsToRender: JSX.Element = useMemo(() => {
    // debugger;
    return (
      <AllCanvasElementRender stateToRender={canvasState} isStatic={isStatic} />
    );
  }, [
    canvasState,
    subtitleWithTime,
    isPlaying,
    waveSurfer,
    videoFinishRatio,
    isStatic,
    konvaTransformerInstance,
    canvasHWS,
  ]);

  const canvasElementHighlights = useMemo(() => {
    return renderElementsHighlights(Object.values(highlightingElements));
  }, [highlightingElements]);

  const checkDeselect = (e: KonvaEventObject<Event>) => {
    // console.log("checkDeselect !st layer");
    //debugger;
    if (
      !selectionRectRef.current ||
      !konvaLayerInstance ||
      !konvaTransformerInstance
    ) {
      return;
    }
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedElementsIds([]);
      konvaTransformerInstance.nodes([]);
      konvaTransformerInstance.getLayer()?.batchDraw();
    }
  };

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;

    if (!node) return;

    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)",
    });
    node.getLayer()?.batchDraw();
  };

  const onMouseDown = (e: KonvaEventObject<globalThis.Event>) => {
    if (!(e.target instanceof Konva.Stage)) {
      return;
    }
    setIsReplaceable(false);

    const isElement = e.target.findAncestor("object");

    const isTransformer = e.target.findAncestor("Transformer");
    const pos = e?.target?.getStage()?.getRelativePointerPosition();

    if (isElement || isTransformer || !pos) {
      return;
    }

    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();

    if (!konvaLayerInstance) {
      return;
    }

    setElementInCanvasWhileSelectingArea(konvaLayerInstance.find(".object"));
  };

  const onMouseMove = (e: KonvaEventObject<globalThis.Event>) => {
    const pos = e?.target?.getStage()?.getRelativePointerPosition();
    if (!selection.current.visible || !pos) {
      return;
    }
    if (!selectionRectRef.current || !konvaLayerInstance) {
      return;
    }

    const selBox = selectionRectRef.current.getClientRect();

    let isNewElement = false;
    const elements: Record<string, AllCanvasElements> = {};

    for (let i = 0; i < elementInCanvasWhileSelectingArea.length; i++) {
      const elementNode = elementInCanvasWhileSelectingArea[i];

      const elBox = elementNode.getClientRect();

      if (
        Konva.Util.haveIntersection(selBox, elBox) &&
        elementNode.attrs.name === "object"
      ) {
        const elementId = elementNode.attrs["data-id"];

        if (elementId != null && !highlightingElements[elementId]) {
          isNewElement = true;
        }

        elements[elementId] = canvasState[elementId];
      }
    }

    if (
      isNewElement ||
      Object.keys(highlightingElements).length !== Object.keys(elements).length
    ) {
      setHighlightingElements({ ...elements });
    }

    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = (
    evt:
      | KonvaEventObject<globalThis.MouseEvent>
      | KonvaEventObject<globalThis.TouchEvent>
  ) => {
    setHighlightingElements({});
    if (
      !selection.current.visible ||
      !selectionRectRef.current ||
      !konvaLayerInstance ||
      !konvaTransformerInstance
    ) {
      return;
    }

    const selBox = selectionRectRef.current.getClientRect();

    const elements: Node<NodeConfig>[] = [];
    konvaLayerInstance.find(".object").forEach((elementNode) => {
      const elBox = elementNode.getClientRect();

      if (
        Konva.Util.haveIntersection(selBox, elBox) &&
        elementNode.attrs.name === "object"
      ) {
        elements.push(elementNode);
      }
    });

    const metaPressed = evt.evt.shiftKey || evt.evt.ctrlKey || evt.evt.metaKey;
    let allSelectedElements = elements;

    if (metaPressed) {
      allSelectedElements = [
        ...elements,
        ...Object.values(selectedElementsInstance),
      ];
    }

    konvaTransformerInstance.nodes(allSelectedElements);
    setSelectedElementsIds(allSelectedElements.map((e) => e.attrs["data-id"]));

    selection.current.visible = false;
    // disable click event
    Konva.listenClickTap = false;
    updateSelectionRect();
  };

  function onClick(evt: KonvaEventObject<PointerEvent>) {
    // console.log("onClick on 2rst layer");

    const clickedOnEmpty = evt.target === evt.target.getStage();

    if (konvaTransformerInstance == null) {
      return;
    }
    const element = selectableElement(evt.target);
    const metaPressed = evt.evt.shiftKey || evt.evt.ctrlKey || evt.evt.metaKey;

    if (element == null) {
      konvaTransformerInstance.nodes([]);
      setSelectedElementsIds([]);
      return;
    }

    if (!metaPressed) {
      konvaTransformerInstance.nodes([element]);
      setSelectedElementsIds([element.attrs["data-id"]]);
      return;
    }

    if (element.attrs.name !== "object") {
      return;
    }

    if (!konvaTransformerInstance || !konvaLayerInstance) {
      return;
    }
    let selectEle: string[] = [
      ...selectedElementsIds,
      element.attrs["data-id"],
    ];
    setSelectedElementsIds(selectEle);
    konvaTransformerInstance.nodes([
      evt.target,
      ...Object.values(selectedElementsInstance),
    ]);
    konvaTransformerInstance.getLayer()?.batchDraw();
  }
  useMemo(() => {
    if (!isStatic) {
      document.body.style.cursor = "grab";
    }
    document.body.style.cursor = "default";
  }, [isStatic]);

  async function permissionApiData() {
    let response = await permissionApi();
    if (response instanceof Error) return;
    if (!("data" in response.data)) {
      setPermissionsData(response.data.permissions);
    } else {
      setPermissionsData(response.data.data.attributes.permissions);
    }
  }

  useEffect(() => {
    permissionApiData();
  }, []);

  async function imageUrl() {
    // debugger;
    // console.log("scrreenShot token");
    let canvas = document.querySelector<HTMLCanvasElement>(
      ".EditorsCanvas canvas:nth-child(2)"
    );
    // console.log(canvas, "EditorsCanvas");
    if (!canvas) return;
    let dataURL = canvas?.toDataURL();
    if (!dataURL) return console.log("no screen shot");
    let imgUrl = await dataUrlToFile(dataURL, "img.png");
    // console.log(imageUrl, "imageUrl");
    return imgUrl;
  }

  async function updateCanvas(id: string, screenShot: File) {
    let canvasStoreState = store.getState().canvasStore.canvasState;
    let unrenderedCanvasTitle =
      store.getState().unrenderedStore.unrenderedTitle;
    let canvasStateString = JSON.stringify(canvasStoreState);
    // console.log("STRING>>>>>", store.getState().canvasStore.canvasState);
    // console.log("JSON>>>>>", canvasStoreState);
    // debugger;
    if (!unrenderedCanvasTitle) return;
    if (!screenShot) return;
    // console.log(unrenderedCanvasTitle, templatesType, "unrenderedCanvasTitle");
    let response = await updateCanvasApi({
      id: id,
      name: unrenderedCanvasTitle,
      details: canvasStateString,
      recordId: audioRecordId,
      type: templatesType,
      screenShot: screenShot,
    });
    // debugger;
    // console.log("response", response);

    if (response instanceof Error) {
      // toast({
      //     status: "error",
      //     title: "Error!",
      //     description: "There is some problem .",
      // });
      return;
    }
  }

  useEffect(() => {
    const id = setInterval(async () => {
      if (!unrenderedCanvasId) {
        return;
      }
    //   console.log(resultAudio, "originalAudio");
      // debugger;
      let imgUrl = await imageUrl();
      // console.log(imgUrl, "imgUrl2");

      if (imgUrl instanceof File) updateCanvas(unrenderedCanvasId, imgUrl);
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [unrenderedCanvasId]);

  return (
    <OverFlowBox>
      <Box
        className="EditorsCanvas"
        position="relative"
        h="100%"
        flex={1}
        mb="15px"
        ref={parentWrapperRef}
      >
        <Stage
          // { doubleTap === 2 && styles = {{"touch-action":"none"}}}
          name="mainStage"
          width={stageDimensionsX}
          height={stageDimensionsY}
          // width={camW}
          // height={camH}
          style={{ background: theme.colors.white }}
          onDblTap={(e) => {
            checkDeselect(e);
          }}
          onTap={() => console.log("Tap Tapp")}
          scale={stageScale}
          x={stagePosition.x}
          y={stagePosition.y}
          onMouseDown={(e) => {
            if (e.evt.ctrlKey) {
              setIsStatic(false);
              return;
            //   console.log(e.evt.ctrlKey, "e.evt.ctrlKey");
            }
            onMouseDown(e);
          }}
          onMouseUp={(e) => {
            // if (!e.evt.ctrlKey) {
            setIsStatic(true);
            // }
            // console.log(e.evt.ctrlKey, "e.evt.ctrlKey");

            onMouseUp(e);
          }}
          onMouseMove={(e) => {
            // setIsStatic(false);
            if (e.evt.ctrlKey) return;
            onMouseMove(e);
          }}
          onTouchStart={(e) => {
            onMouseDown(e);
            if (doubleTap == 2) {
              // document.body.style.overflow = "hidden";
            }

            setDoubleTap((doubleTap) => doubleTap + 1);
          }}
          onTouchMove={(e) => {
            if (doubleTap == 2) {
              document.body.style.overflow = "hidden";

              onMouseMove(e);
            }
            onDblTapDebounce();
          }}
          onTouchEnd={(e) => {
            if (doubleTap == 2) {
              onMouseUp(e);
              document.body.style.overflow = "revert";
            }
          }}
          onDragEnd={(evt) => {
            const id = evt.target.attrs["data-id"];
            if (id) {
              const command = new UpdateCommand([
                { id, x: evt.target.attrs.x, y: evt.target.attrs.y },
              ]);
              executeCommand(command);
            }
          }}
        >
          <Layer onTap={() => console.log("Tap Tapp1")}>
            <Group
              onTap={() => console.log("Tap Tapp2")}
              onClick={() => {
                // console.log("clickBG Group");
              }}
              x={cameraX}
              y={cameraY}
              height={cameraH}
              width={cameraW}
            >
              <Rect
                onTap={() => console.log("Tap Tapp2")}
                name="konvaBackground"
                listening={false}
                x={-biggestLengthHalf}
                y={-biggestLengthHalf}
                height={biggestLength}
                width={biggestLength}
                fill="#e6e6e6"
              />

              <Rect
                onTap={() => console.log("Tap Tapp2")}
                onClick={() => {
                //   console.log("click2 CamerHW");
                }}
                listening={false}
                x={0}
                y={0}
                height={cameraH}
                width={cameraW}
                fill="#fff"
              />
            </Group>
          </Layer>
          <Layer
            onClick={onClick}
            onDblTap={onClick}
            // onDblTap={(e) => {
            // }}
            // onClick={(evt) => onClickToSelectMultiple(evt)}
            name="elementsLayer"
            onMouseOver={(e) => {
              // debugger;
              document.body.style.cursor = "pointer";
              // let ele = e.target.parent ? e.target.parent : e.target;
              setKonvaCurrentHoverElement(e.target);
              let element = selectableElement(e.target);
              // console.log("layermove");
              // if (!element) return;
              // if (element.attrs["data-id"] != canvasState[element.attrs["data-id"]]) {
              //     element = element.parent;
              // }

              if (element?.attrs["name"] === "object") {
                setHighlightingElements({
                  [element.attrs["data-id"]]:
                    canvasState[element.attrs["data-id"]],
                });
              }
            }}
            onDragStart={(e) => {
              setHighlightingElements({});
            }}
            onMouseOut={(e) => {
              document.body.style.cursor = "default";
              setHighlightingElements({});
              // setKonvaCurrentHoverElement(null);
            }}
            onMouseDown={(e) => {
              document.body.style.cursor = "default";
              // setKonvaCurrentHoverElement(null);
              setHighlightingElements({});
            }}
          >
            <Group
              onTap={() => console.log("Tap Tapp2")}
              border="1px solid black"
              onDblTap={(e) => {
                // console.log("onClick on 2rst group", e);
              }}
              name="cameraGroup"
              x={cameraX}
              y={cameraY}
              height={cameraH}
              width={cameraW}
              //
              // clipFunc={(ctx: Context) => {
              // 	ctx.rect(0, 0, cameraW, cameraH);
              // 	// ctx.fill();
              // }}
            >
              {canvasElementsToRender}
            </Group>
          </Layer>
          <Layer name="2ndLayer">
            <Group x={cameraX} y={cameraY} height={cameraH} width={cameraW}>
              {canvasElementHighlights}
            </Group>

            <Transformer
              // ref={trRef}

              borderDash={[3, 3]}
              name="transformerElement"
              {...selectedElement?.transformProperties}
              onTransformStart={(evt) => {}}
              onTransformEnd={(evt) => {
                // debugger;
                const id = evt.target.attrs["data-id"];
                const { x, y } = evt.target.attrs;
                if (id) {
                  const command = new UpdateCommand(
                    selectedElementsIds.map((e) => ({
                      id: selectedElementsInstance[e].attrs["data-id"],
                      x: selectedElementsInstance[e].x(),
                      y: selectedElementsInstance[e].y(),
                      scaleX: Math.min(
                        selectedElementsInstance[e].scaleX(),
                        selectedElement?.transformProperties?.maxS ?? Infinity
                      ),
                      scaleY: Math.min(
                        selectedElementsInstance[e].scaleY(),
                        selectedElement?.transformProperties?.maxS ?? Infinity
                      ),
                      skewX: selectedElementsInstance[e].skewX(),
                      skewY: selectedElementsInstance[e].skewY(),
                      rotationDeg: selectedElementsInstance[e].rotation(),
                      height: selectedElementsInstance[e].height(),
                      width: selectedElementsInstance[e].width(),
                    }))
                  );
                  executeCommand(command);
                }
              }}
              rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
              anchorStrokeWidth={2}
              anchorCornerRadius={15}
              keepRatio={true}
            />
            <Rect
              fill="rgba(0,0,255,0.5)"
              ref={(ref) => {
                if (!selectionRectRef.current) {
                  selectionRectRef.current = ref;
                }
              }}
            />
          </Layer>
        </Stage>
        <CanvasZoomInOutButtons />
      </Box>
    </OverFlowBox>

    // </Flex>
  );
};

export default CanvasEditor;
