import React from "react";
import { Flex, Text, Grid } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../../store/hooks";
import { history } from "../../../app/router/history";
import { basePath } from "../../../constants";

export interface MobileStickersProps {}

const MobileStickers: React.FC<MobileStickersProps> = (props) => {
	const { executeCommand, setSelectedElementsIds, setIsReplaceable } = useEasyActions((store) => store.canvasStore);
	const { selectedElementId, selectedElement, konvaTransformerInstance } = useEasyState((store) => store.canvasStore);
	return (
		<Flex overflow="auto" mx="30px" flexDir="column">
			<Flex flex={1} flexDir="column" my="20px">
				<Flex justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							history.push(basePath + "/design");
							setSelectedElementsIds([]);
							konvaTransformerInstance?.nodes([]);
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Text fontSize="18px" fontWeight="600">
					Select GIF
				</Text>
			</Flex>
			<Flex justifyContent="center">
				<Grid
					gridAutoFlow="row"
					gridTemplateColumns="repeat(auto-fit,120px)"
					overflow="auto"
					w="100%"
					className="hideScroll"
					m="2px"
					justifyContent="center"
					id="gridImg"
					gap="10px">
					<Flex rounded="lg" h="60px" w="100%" bg="gray.200"></Flex>
					<Flex rounded="lg" h="60px" w="100%" bg="gray.200"></Flex>
					<Flex rounded="lg" h="60px" w="100%" bg="gray.200"></Flex>
					<Flex rounded="lg" h="60px" w="100%" bg="gray.200"></Flex>
				</Grid>
			</Flex>
		</Flex>
	);
};

export default MobileStickers;
