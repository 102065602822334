import { action, createStore, thunk, persist, debug } from "easy-peasy";

import { StoreModel, TemplateStore } from "../types/canvasStoreTypes/StoreTypes";
import { userData } from "./ApiData/ApiData";
import { audioStore } from "./AudioStore/AudioStore";
import { canvasStore } from "./CanvasStore/canvasStore";
import { imageStore } from "./ImageStore/ImageStore";
import { template } from "./Template/TemplateStore";
import { textStore } from "./TextStore/TextStore";
import { unrenderedStore } from "./Unrendered/undrenderedStore";
import { visualizationStore } from "./Visualization/visualization";

const offline = <T extends object>(store: T) =>
	persist(store, {
		storage: "localStorage",
	});

export const store = createStore<StoreModel>(
	{
		canvasStore: canvasStore,
		audioStore: audioStore,
		imageStore: imageStore,
		textStore: textStore,
		visualizationStore: visualizationStore,
		templateStore: template,
		userDataStore: offline(userData),
		unrenderedStore: unrenderedStore,
	},

	{
		version: 1,
	},
);

//@ts-ignore
window.debug = debug;
//@ts-ignore
window.reduxStore = store;
