import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { useEasyState } from "../../store/hooks";
import { SignUpResponse } from "../../types/ApiTypex/Signup";

export async function signup(email: string, password: string, confirmPassword: string) {
	return await catchTry(
		mainAxios.post<SignUpResponse>(`${defaultOptions.baseURL}/users.json`, {
			user: {
				email: email,
				password: password,
				password_confirmation: confirmPassword,
			},
		}),
	);
}
