export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: "image/png" });
}
export async function videoUrlToFile(dataUrl: string, fileName: string): Promise<File> {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: "image/png" });
}

// ***Here is the code for converting "image source" (url) to "Base64".***

let url = "https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg";
const toDataURL = async (url: string): Promise<string | ArrayBuffer | null> => {
	const response = await fetch(url);
	const blob = await response.blob();

	return await new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
};

// ***Here is code for converting "Base64" to javascript "File Object".***

// function dataURLtoFile(dataurl, filename) {
//     var arr = dataurl.split(","),
//         mime = arr[0].match(/:(.*?);/)[1],
//         bstr = atob(arr[1]),
//         n = bstr.length,
//         u8arr = new Uint8Array(n);
//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new File([u8arr], filename, { type: mime });
// }

// *** Calling both function ***

export function convertImgToBase64URL(url: string, outputFormat?: string) {
	return new Promise((res, rej) => {
		var img = new Image();
		img.crossOrigin = "Anonymous";
		img.onload = function () {
			let canvas: HTMLCanvasElement | null = document.createElement("CANVAS") as HTMLCanvasElement,
				ctx = canvas.getContext("2d"),
				dataURL;

			if (ctx == null) {
				throw new Error("Please change browser");
			}

			canvas.height = img.height;
			canvas.width = img.width;
			ctx.drawImage(img, 0, 0);
			dataURL = canvas.toDataURL(outputFormat);
			canvas = null;

			res(dataURL);
		};
		img.src = url;
	});
}

export async function urlToFile(url: string): Promise<File> {
	const dataUrl = await convertImgToBase64URL(url);
	if (typeof dataUrl !== "string") {
		throw new Error("Cant convert the url to file URL:" + url);
	}

	// console.log("Here is Base64 Url", dataUrl);
	var fileData = await dataUrlToFile(dataUrl, "imageName.png");

	return fileData;
}

export const b64toBlob = (dataURI: string) => {
	// const res: Response = await fetch(dataURI);
	// const blob: Blob = await res.blob();
	// return new File([blob], "fileName", { type: "image/png" });
	// function convertURIToBinary(dataURI: string) {
	let BASE64_MARKER = ";base64,";
	let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
	let base64 = dataURI.substring(base64Index);
	let btoa = window.btoa(base64);
	let raw = window.atob(btoa);
	let rawLength = raw.length;
	let arr = new Uint8Array(new ArrayBuffer(rawLength));

	for (let i = 0; i < rawLength; i++) {
		arr[i] = raw.charCodeAt(i);
	}
	return arr;
	// }
};
export async function videoUrlToVideoFile(mediaBlobUrl: string) {
	const mediaBlob = await fetch(mediaBlobUrl).then((response) => response.blob());
	const myFile = new File([mediaBlob], "demo.mp4", { type: "video/webm" });
	return myFile;
}

function dataUrlToVideoFile(dataUrl: string) {
	let binary = atob(dataUrl.split(",")[1]),
		data = [];

	for (let i = 0; i < binary.length; i++) data.push(binary.charCodeAt(i));

	return new File([new Uint8Array(data)], "recorded-video.webm", {
		type: "video/webm",
	});
}
