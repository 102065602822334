import { Button } from "@chakra-ui/button";
import { Flex, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import { history } from "../../app/router/history";
import { theme } from "../../config/theme/baseDesign";
import HomeLayout from "../../Layout/HomeLayout";
import { useEasyState } from "../../store/hooks";
import { getVideoApi } from "../../utils/Api/GetVideo";
import { calculateCanvasTransform } from "../../utils/canvasHelpers";
import email from "../../assets/images/email 1.svg";
import twitter from "../../assets/images/twitter 1.svg";
import fb from "../../assets/images/facebook 1.svg";
import OverFlowBox from "../../components/common/OverFlowBox";
import Logo from "../../assets/images/AmpifiLogo.svg";
import { basePath } from "../../constants";

export interface GeneratedVideoProps {}

const GeneratedVideo: React.FC<GeneratedVideoProps> = (props) => {
	const toast = useToast();

	const { videoId } = useParams<{ videoId: string }>();
	// console.log("videoId", videoId);

	const [vidUrl, setUrl] = useState<string | null>(null);

	async function postVideoApi() {
		let response = await getVideoApi(videoId);
		if (response instanceof Error) {
			toast({
				status: "error",
				description: "There is some problem.",
			});
			return;
		}
		setUrl(response.data.data.attributes.video_url);
	}

	useEffect(() => {
		postVideoApi();
	}, []);

	let sideMenu = (
		<Flex mb="20px" mt="80px" flexDir="column">
			<Text ml="20px" flex={1} cursor="pointer">
				My dashboard
			</Text>
			<Text
				ml="20px"
				flex={1}
				cursor="pointer"
				onClick={() => {
					history.push(basePath + "/design");
				}}>
				Start new project
			</Text>
		</Flex>
	);
	return (
		// <HomeLayout sideMenu={sideMenu}>
		<Flex
			// overflow="hidden"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			flex={1}>
			<Flex flex={1} justifyContent="flex-end" h="30px" w="100%" m="10px 50px 0 0">
				<img src={Logo} />
			</Flex>
			<Flex justifyContent="center" alignItems="center" flexWrap="wrap" flex={1}>
				<Flex justifyContent="center" alignItems="center" height="92vh">
					{vidUrl ? (
						<video controls style={{ boxShadow: " 0px 0px 3px 2px #e6e6e6", height: "90%" }}>
							<source src={vidUrl} type="video/ogg" />
							<source src={vidUrl} type="video/webm" />
							<source src={vidUrl} type="video/mp4" />
						</video>
					) : (
						<h1>Invalid Url</h1>
					)}
				</Flex>
				<Flex flexDirection="column" justifyContent="center" alignItems="center" margin="20px" flex={0.5}>
					{/* <Button
						_hover={{
							bg: "primary",
						}}
						cursor="pointer"
						bg="primary"
						color="white"
						radius="0.8rem"
						height="40px"
						w="200px"
						// m="30px 30px 30px 0"
						onClick={() => {
							if (!vidUrl) return;
							const a = document.createElement("a");
							document.body.appendChild(a);
							//@ts-ignore
							a.style = "diaplay:none";
							a.href = vidUrl;
							a.download = "video.mp4";
							a.click();
						}}>
						Download
					</Button> */}
					<Flex m="20px 20px 0 20px" justifyContent="center">
						<Text fontSize="14px" fontWeight="600" color={theme.colors.primary}>
							Share
						</Text>
						<Flex justifyContent="space-between" w="120px">
							<FacebookShareButton url={`https://ampify.netlify.app/video/${videoId}`}>
								<img src={fb} style={{ height: "25px", marginRight: "15px", marginLeft: "10px" }} />
							</FacebookShareButton>
							<TwitterShareButton url={`https://ampify.netlify.app/video/${videoId}`}>
								<img src={twitter} style={{ height: "25px", marginRight: "15px" }} />
							</TwitterShareButton>
							<EmailShareButton url={`https://ampify.netlify.app/video/${videoId}`}>
								<img src={email} style={{ height: "25px", marginRight: "15px" }} />
							</EmailShareButton>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
		// </HomeLayout>
	);
};

export default GeneratedVideo;
