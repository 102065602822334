import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CanvasRootObject } from "../../types/ApiTypex/AllCanvas";
import { TokenService } from "../token";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";

export async function deleteCanvasApi(id: string) {
    let token = TokenService.getToken;
    // let bodyFormData = new FormData();
    // bodyFormData.append("canvas[name]", name);
    // bodyFormData.append("canvas[description]", description);
    // bodyFormData.append("canvas[details]", canvasobject);
    // bodyFormData.append("canvas[picture]", screenShot);
    return await catchTry(
        mainAxios.delete<CanvasRootObject>(`${defaultOptions.baseURL}/api/canvas/${id}`, {
            headers: {
                Authorization: token,
            },
        })
    );
}
