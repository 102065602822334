import { UserTypes } from "../components/ChooseFormat/FakeDataArray";
import { TemplatesTypes } from "../types";
import { calculateCanvasTransform } from "./canvasHelpers";

let quality = {
    "720p": {
        landscape: [1280, 720] as const,
        portrait: [720, 1280] as const,
        square: [720, 720] as const,
    },
    "1080p": {
        landscape: [1920, 1080] as const,
        portrait: [1080, 1920] as const,
        square: [1280, 1280] as const,
    },
};

export function videoQualityCheck(type: "720p" | "1080p", templatesType: TemplatesTypes) {
    const [x, y] = quality[type][templatesType];
    return calculateCanvasTransform(templatesType, x, y);
}
