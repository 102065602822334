import { useEasyState } from "../../store/hooks";
import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { State } from "easy-peasy";
import { useEasyActions } from "../../store/hooks/index";

export class AddCommand implements Command<State<CanvasStore>, "AddElement"> {
	name = "AddElement" as const;
	constructor(public newElement: AllCanvasElements) {}

	execute(state: State<CanvasStore>): void {
		state.canvasState[this.newElement.id] = this.newElement;
	}
	undo(state: State<CanvasStore>): void {
		delete state.canvasState[this.newElement.id];
		if (state.selectedElementId === this.newElement.id) {
			state.selectedElementId = null;
		}
	}
}
