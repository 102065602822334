import React, { useCallback, useState, useEffect } from "react";
import { Button, Flex, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { debounce } from "../../utils/debounce";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { VisualizationElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { history } from "../../app/router/history";
import ColorSelector from "../common/ColorSelector";
import { ColorPicker, useColor } from "react-color-palette";
import { basePath } from "../../constants";

export interface AudioVisualizerPropertiesProps {}

const AudioVisualizerProperties: React.FC<AudioVisualizerPropertiesProps> = (props) => {
	const { removeElement, updateElement, setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);
	const { canvasState, selectedElement, selectedElementId } = useEasyState((store) => store.canvasStore);
	let selectedVisualElement = selectedElement as VisualizationElement;

	const [transparency, setTransparency] = useState(selectedVisualElement?.opacity ?? 0);
	const [fillColor, setFillColor] = useState(selectedVisualElement?.fill ?? "#ffff");
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	const [displayColorPickerFill, setDisplayColorPickerFill] = useState(false);
	const [colorFill, setColorFill] = useState(selectedVisualElement?.fill);
	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	function onChangeFill(e: any) {
		// console.log(e);
		if (selectedVisualElement.id == null) {
			return;
		}
		executeCommand(new UpdateCommand<VisualizationElement>([{ id: selectedVisualElement.id, fill: e.hex }]));
		setFillColor(e);
	}

	const blurColor = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayColorPicker(false);
		}
	}, []);

	useEffect(() => {
		if (displayColorPicker == false) {
			window.removeEventListener("click", blurColor);
			return;
		}

		window.addEventListener("click", blurColor);
		return () => {
			window.removeEventListener("click", blurColor);
		};
	}, [displayColorPicker]);
	function transparencyFun(e: any) {
		if (selectedElementId == null) {
			return;
		}

		executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
	}

	const transparencyDebounce = useCallback(debounce(transparencyFun, 500), [selectedElementId]);

	const onChangeFillDebounce = useCallback(debounce(onChangeFill, 500), [onChangeFill]);
	return (
		<Flex flexDir="column" flex={1} mb="20px">
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							setSelectedElementsIds([]);
							history.push(basePath + "/design");
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Heading ml="35px" mb="40px" variant="mdb">
					Visualizations
				</Heading>
			</Flex>

			<Flex flexDir="column" mx="35px" flex={1}>
				<Flex mb="30px">
					<Flex justifyContent="space-between" flex={1}>
						<Text variant="lg">Color</Text>

						<Flex
							w="25px"
							h="25px"
							bg={colorFill}
							position="relative"
							borderRadius="40%"
							onClick={() => {
								// if (displayColorPickerHighlight) {
								// 	setDisplayColorPickerHighlight(false);
								// }
								setDisplayColorPicker(true);
								// console.log("fill", displayColorPicker);
							}}>
							{displayColorPicker && (
								<Flex id="ColorContainer" bottom="0" left="-150" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={colorFill}
											onChange={(e) => {
												setColorFill(e.hex);
												onChangeFillDebounce(e);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>

						{/* <input
							value={fillColor}
							type="color"
							onChange={(e) => {
								onChangeFillDebounce(e.target.value);
							}}
						/> */}
					</Flex>
					<Flex flex={1}></Flex>
				</Flex>

				<Flex flexDir="column">
					<Flex flexDir="row">
						<Flex mb="20px" flex={1} variant="lg">
							Transparency
						</Flex>
						<Text variant="md">{Math.floor(transparency * 100)}%</Text>
					</Flex>
					{/* <Slider
						min={0}
						max={1}
						step={0.01}
						mt="20px"
						defaultValue={transparency}
						onChange={(e) => {
							setTransparency(e);
						}}
						onChangeEnd={(e) => {
							if (selectedVisualElement == null) {
								return;
							}
							setTransparency(e);
							executeCommand(new UpdateCommand([{ id: selectedVisualElement.id, opacity: e }]));
							console.log(e);
						}}
						aria-label="slider-ex-1">
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb bg="primary" />
					</Slider> */}

					<input
						type="range"
						id="vol"
						name="vol"
						min="0"
						max="1"
						step={0.01}
						value={transparency}
						// defaultValue={transparency}
						onChange={(e) => {
							let value = Number(e.target.value);

							transparencyDebounce(value);
							setTransparency(value);
						}}></input>
				</Flex>
			</Flex>
			{!isSmall && (
				<Flex flexDir="column">
					<Flex justifyContent="center" mb="5px">
						<Button
							cursor="pointer"
							onClick={() => {
								setSelectedElementsIds([]);
								history.push(basePath + "/design");
							}}
							variant="styled"
							textDecoration="none">
							Back
						</Button>
					</Flex>
					<Flex justifyContent="center" mb="30px">
						<Button
							cursor="pointer"
							onClick={() => {
								setSelectedElementsIds([]);
								history.push(basePath + "/design");
							}}
							variant="styled">
							Continue
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default AudioVisualizerProperties;
