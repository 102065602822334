import React, { useCallback } from "react";
import { Flex, Heading, Text, Input, Button, InputGroup, InputLeftElement, Grid, Box, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions } from "../../store/hooks";
import { useEasyState } from "../../store/hooks/index";
import { history } from "../../app/router/history";
import { FiSearch } from "react-icons/fi";
import { useState, useEffect, useMemo } from "react";
import { unSplash } from "../../utils/Api/Unsplash";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { debounce } from "../../utils/debounce";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { AllCanvasElements, ImageElement } from "../../types/canvasStoreTypes/CanvesTypes";
import downArrow from "../../assets/images/Chevron.svg";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { basePath } from "../../constants";

export interface ImagesOptionMenuProps {
	replaceAbleEle?: AllCanvasElements | null;
	isReplaceable?: boolean;
}

const ImagesOptionMenu: React.FC<ImagesOptionMenuProps> = (props) => {
	const toast = useToast();
	const fileUpload = useRef<null | HTMLInputElement>(null);
	const { apiRes } = useEasyState((state) => state.imageStore);
	const { setApiRes } = useEasyActions((state) => state.imageStore);
	const { executeCommand, setSelectedElementsIds, setIsReplaceable } = useEasyActions((store) => store.canvasStore);
	const { selectedElementId, selectedElement, konvaTransformerInstance, canvasHWS } = useEasyState((store) => store.canvasStore);

	const inputRef = useRef<null | HTMLInputElement>(null);
	const [isSmall] = useMediaQuery("(max-width: 993px)");

	const [hasMore, setHasMore] = useState(false);

	const [page, setPage] = useState(1);
	const imageRef = useRef(null);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [text, setText] = useState("birds");
	let clientId = "QCyDLk_RGFLJ78XHE3E-Mru2nr3KuXG11Fp9lZDcuNo";
	
	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;
	const observer = useRef<IntersectionObserver | null>(null);
	const lastImgRef = useCallback(
		(node) => {

			// console.log(node);
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage((page) => page + 1);
				}
			});
			if (node) {
				observer.current.observe(node);
			}
		},
		[hasMore],
	);

	function onChangeTextHandler(text: string) {
		onClickHandler(text);
		setText(text);
	}

	async function onClickHandler(text: string) {
		const response = await unSplash(text, clientId, page);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}
		setApiRes([...apiRes, ...response.data.results]);
		setHasMore(response.data.results.length > 0);
	}
	const onChangeInputTextDebounce = useCallback(
		debounce((t: string) => {
			setApiRes([]);
			onChangeTextHandler(t);
		}, 500),
		[onChangeTextHandler],
	);

	useMemo(() => onChangeTextHandler(text), [page, text]);

	useEffect(() => {
		onClickHandler(text);
		return () => {
			setApiRes([]);
		};
	}, []);

	function addImage(url: string, h: number, w: number) {
		const shrink = 300 / Math.max(h, w);
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Image",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				br: 1,
				url: url,
				height: h * shrink,
				width: w * shrink,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
				zIndex: 1,
			}),
		);

	}
	function file(e: any) {
		const file = e.target.files[0];
		setImageFile(file);
		// console.log("file", file);

		const reader = new FileReader();
		// console.log("reader", reader);
		reader.onloadend = function () {
			// console.log("result", reader.result);
			if (typeof reader.result === "string") {
				var img = new Image();
				img.src = reader.result;
				// console.log("img", img);
				img.onload = () => {
					addImage(img.src, img.height, img.width);
				};
			}
		};
		if (file) {
			reader.readAsDataURL(file);
		}
	}
	let scroll = document.querySelector(".scrollIcon");
	let time: any;
	scroll?.addEventListener("mousedown", function (event) {
		time = setInterval(function () {
			window.scrollBy(0, 1); // May need to be -1 to go down
		}, 2);
	});
	let doc = document.querySelector(".document");
	doc?.addEventListener("mouseup", function () {
		clearInterval(time);
		return false;
	});

	const scrollParent = useRef<null | HTMLDivElement>(null);

	function programmaticallyScroll(dir: "down") {
		if (!scrollParent.current) {
			return;
		}
		// scrollParent.current.scrollBy(0, 700);
		scrollParent.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}

	function ReplaceImage(e: string, h: number, w: number) {
		const shrink = 300 / Math.max(h, w);
		if (selectedElementId && selectedElement)
			executeCommand(
				new UpdateCommand<ImageElement>([
					{
						id: selectedElementId,
						url: e,
						x: selectedElement.x,
						y: selectedElement.y,
						height: h * shrink,
						width: w * shrink,
					},
				]),
			);
	}

	return (
		<Flex flexDir="column" h="calc(100vh - 100px)" overflow="auto">
			<Flex flexDir="column" flex={1}>
				<Flex flexDir="column">
					<Flex justifyContent="flex-end" mr="20px">
						<Flex
							cursor="pointer"
							onClick={() => {
								history.push(basePath + "/design");
								setSelectedElementsIds([]);
								konvaTransformerInstance?.nodes([]);
								setIsReplaceable(false);
							}}
							w="40px"
							mt="10px"
							mr={["10px", "10px", "0px"]}
							// mr="20px"
							justifyContent="flex-end">
							✖
						</Flex>
					</Flex>
					<Heading ml="30px" mb="30px" variant="mdb">
						Images
					</Heading>
				</Flex>
				<Flex id="body" ref={scrollParent} overflow="auto" mx="29px" flexDir="column" mb="10px">
					<Flex flexDir="column">
						<Text variant="md">Upload</Text>
						<Flex
							w="100%"
							flexGrow={0}
							mb="5px"
							color="grey"
							alignItems="center"
							border="1px solid #dfdfdf"
							h="40px"
							rounded="lg"
							pl="10px">
							<Text nooflines={1}>{imageFile?.name.replace(/[/n/r]/g, "")}</Text>
						</Flex>

						<Flex flex={1} my="5px">
							<Button
								onClick={() => {
									fileUpload.current?.click();
								}}
								flex={[null, 1]}
								width="100%"
								variant="styled">
								Browse
							</Button>
							<Box display="none">
								<Input multiple={false} ref={fileUpload} type="file" id="upFile" onChange={(e) => file(e)} />
							</Box>
						</Flex>
					</Flex>
					<Flex flexDir="column" mt="30px">
						<Text variant="md">Select image</Text>

						<InputGroup>
							<InputLeftElement pointerEvents="none" mt="5px" children={<FiSearch size={20} />} />
							<Input
								my="5px"
								type="text"
								ref={inputRef}
								// value={text}
								onChange={(e) => {
									onChangeInputTextDebounce(e.target.value);
								}}
							/>
						</InputGroup>

						<Text variant="md" color="gray">
							Browse the Unsplash library
						</Text>
					</Flex>

					<Grid
						m="2px"
						id="gridImg"
						mt="20px"
						gridTemplateColumns="repeat(2, 1fr)"
						gridTemplateRows="repeat(2, 1fr)"
						gap="5px"
						mb="20px"
						h="21vh">
						{apiRes?.map((item, index) => {
							let url = item?.urls.small;
							if (apiRes?.length === index + 1) {
								return (
									<Flex cursor="pointer" ref={lastImgRef} key={index}>
										<img
											ref={imageRef}
											onClick={(e) => {
												if (props.isReplaceable == true) {
													ReplaceImage(item?.urls?.small, item?.height, item?.width);
													// console.log("img", props.isReplaceable, item?.urls?.small, item?.height, item?.width);
													return;
												}
												if (item?.urls) {
													addImage(item?.urls?.small, item?.height, item?.width);
												}
											}}
											style={{
												objectFit: "cover",
												width: "100%",
												height: "100%",
											}}
											src={url}
										/>
									</Flex>
								);
							} else {
								return (
									<Flex
										_hover={{
											boxShadow: "0px 0px 2px 1px #0095a8",
											transition: "transform .3s ease-out",
										}}
										key={index}
										cursor="pointer">
										<img
											ref={imageRef}
											onClick={(e) => {
												if (props.isReplaceable == true) {
													ReplaceImage(item?.urls?.small, item?.height, item?.width);
													// console.log("img", props.isReplaceable, item?.urls?.small, item?.height, item?.width);
													return;
												}
												if (item?.urls) {
													addImage(item?.urls?.small, item?.height, item?.width);
												}
											}}
											style={{
												objectFit: "cover",
												width: "100%",
												height: "100%",
											}}
											src={url}
										/>
									</Flex>
								);
							}
						})}
					</Grid>
				</Flex>
				<Flex onClick={() => programmaticallyScroll("down")} id="scrollIcon" cursor="pointer" justifyContent="center" flex={1} mb="10px">
					<img src={downArrow} alt="arrow" style={{ height: "12px" }} />
				</Flex>
			</Flex>
			{!isSmall && (
				<Flex flexDir="column">
					<Flex justifyContent="center" mb="5px">
						<Button
							cursor="pointer"
							onClick={() => {
								history.push(basePath + "/design");
							}}
							variant="styled"
							textDecoration="none">
							Back
						</Button>
					</Flex>
					<Flex justifyContent="center" mb="30px">
						<Button
							cursor="pointer"
							onClick={() => {
								history.push(basePath + "/design");
							}}
							variant="styled">
							Continue
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default ImagesOptionMenu;
