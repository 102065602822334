import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import React, { useEffect, useState, useCallback } from "react";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import home_one from "./assets/images/home_one.svg";
import BlackLogo from "./assets/images/ampifi_logo_black_1.svg";
import GoogleLogo from "./assets/images/google_logo.svg";
import MicrosoftLogo from "./assets/images/microsoft.svg";
import { signup } from "./utils/Api/signup";
import { useToast } from "@chakra-ui/react";
import { history } from "./app/router/history";
import { TokenService } from "./utils/token";
import { useQuery } from "./store/hooks/useQuery";
import { basePath } from "./constants";
import { Link } from "react-router-dom";
import { roleTypesArr } from "./types";
import {GoogleLogin, GoogleLogout} from 'react-google-login'
import {gapi} from 'gapi-script';
import axios from 'axios';
import NavigationBar from "./NavigationBar";
import useScreenWidth from "./components/useScreenWidth";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { AUTHENTICATE_SOCIAL_AUTH_USER } from "./utils/api_routes";
export interface SignupProps {}
const clientId = process.env.GOOGLE_CLIENT_ID

const msalConfig = {
    auth: {
        clientId: process.env.MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: process.env.MICROSOFT_REDIRECT_URI,

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};
const msalInstance = new PublicClientApplication(msalConfig);
const Signup: React.FC<SignupProps> = (props) => {
	const toast = useToast();
	const screenWidth = useScreenWidth();
	const [name, setName] = useState<string | null>(null);
	const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
	const [selectedRole, setSelectedRole] = useState<string>(roleTypesArr[0]);
	const [password, setPassword] = useState<string | null>(null);
	const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
	const [csrfToken, setCsrfToken] = useState<string>('');
	let queryParams = useQuery();
	let token = TokenService.getToken();
	let canvaStep = TokenService.getCanvaStep();

	useEffect(() => {
		
		if (queryParams.get("sub") != null && token) {
			

			history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
		}

		function start() {
			gapi.client.init({
				clientId: clientId,
				scope: "profile email",
				cookie_policy: 'single_host_origin',
				response_type: 'code',
			})
		};
		gapi.load('client:auth2', start);

		const fetchCsrfToken = async () => {
			try {
			  const response = await axios.get('/csrf_token');
			  setCsrfToken(response.data.authenticity_token);
			} catch (error) {
			  console.error('Error fetching CSRF token:', error);
			}
		  };
	  
		  fetchCsrfToken();
	}, []);

	const onSuccess = (res) => {
		// console.log("Login Success! Current User: ", res.profileObj);
	}
	const onFailure = (res) => {
		// console.log("Login Failed! res: ", res);
	}

	async function signupInfo(email: string, pasward: string, confirm: string) {
		
		let response = await signup(email, pasward, confirm);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "Password mismatch or email already registered",
			});
			return;
		}

		TokenService.setToken(response.data.data.attributes.authentication_token);
		TokenService.setUserNAME(response.data.data.attributes.full_name);
		TokenService.setRole(String(Boolean(response.data.data.attributes.admin)));
		toast({
			status: "success",
			description: "You have signed up successfully!",
		});
		if (queryParams.get("sub") !== null && response?.data?.data?.attributes?.authentication_token) {
			history.push(basePath + canvaStep);
		} else {
			history.push(basePath + canvaStep);
		}
	}

	const handleGoogleLogin = async (response) => {
		if (response && !response.error) {
			try {
				var data = {
					provider: "google_oauth2",
					uid: response.Ca,
					id_token: response.tokenId,
					info: {
						email: response.profileObj.email
					}
				}
				const serverResponse = await fetch(AUTHENTICATE_SOCIAL_AUTH_USER, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken,
					},
					body: JSON.stringify(data)
				});
				const responseData = await serverResponse.json();
				TokenService.setToken(responseData.data.attributes.authentication_token);
				TokenService.setUserNAME(responseData.data.attributes.full_name);
				TokenService.setRole(String(Boolean(responseData.data.attributes.admin)));
				if (responseData.data.attributes.full_name === null){
					history.push(basePath + "/step_one");
				} else {
					history.push(basePath + "/dashboard");
				}
				toast({
					status: "success",
					description: "You are successfully signed up.",
				});
			} catch (error) {
				// Handle errors from the server request
				console.error('Error sending data to server:', error);
			}
		} else {
			// Google authentication failed
			console.error('Google authentication failed');
		}
	};

    const handleMicrosoftLogin = async () => {
        try {
            const loginResponse = await msalInstance.loginPopup({
                scopes: ['openid', 'profile', 'user.read'],
            });

            const response = await fetch(AUTHENTICATE_SOCIAL_AUTH_USER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                body: JSON.stringify({
                    idToken: loginResponse.idToken.rawIdToken,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                TokenService.setToken(data.token);
                history.push(basePath + "dashboard");
                toast({
                    status: "success",
                    description: "You have logged in successfully!",
                });
            } else {
                // Handle error response from server
                console.error('Failed to authenticate with Microsoft:', response.statusText);
                toast({
                    status: "error",
                    title: "Error!",
                    description: "Failed to authenticate with Microsoft.",
                });
            }
        } catch (error) {
            console.error('Error logging in with Microsoft:', error);
            toast({
                status: "error",
                title: "Error!",
                description: "Failed to authenticate with Microsoft.",
            });
        }
    };



	return (
		<>
		<NavigationBar />
		<Flex id="parentContainer" flexWrap='wrap' justifyContent="flex-end"
		h="auto" background={theme.colors.white} opacity="1"
		>

			<Flex h="100%" w="100%"
			background={theme.colors.white} gap='10px' justifyContent='space-evenly'>
				{screenWidth <= 955 ? <> </> :
				<Flex h="80%" w="45%"
				 justifyContent='center' background={theme.colors.white}>
					<Flex h="500px" w="500px"
					background={theme.colors.white}>
						<img style={{ color: "#ffffff" }} src={home_one} />
					</Flex>
				</Flex>
				}

				<Flex h="80%" w="45%"
				 background={theme.colors.white} justifyContent='center'>
					<Flex
						h="590px" w="560px"
						m="50px 0 0 0"
						background={theme.colors.secondary}
						border={`4px solid ${theme.colors.black}`}
						borderRadius={"35px"}
						justifyContent='center'
						flexWrap='wrap'
					>
						<Flex
							h="55px" w="90%"
							 m="15px 0 0 0"
							justifyContent='center'
						>
							<Flex
								h="55px" w="289px" m="0 0 0 0"
							>
								<img style={{ color: "#ffffff" }} src={BlackLogo} />
							</Flex>
						</Flex>

						<Flex
							h="110px" w="400px" m="25px 0 0 0"
							justifyContent='center'
						>
							<Flex
								h="100px" w="389px" m="0 0 0 25px"
								justifyContent='center'
							>
								<Text variant="lg">
									Amplify your message using engaging audio visuals; transforming audio into shareable content.
								</Text>
							</Flex>

						</Flex>

						<Flex
							h="52px" w="390px" m="0 0 0 0"
							background={theme.colors.white}
							border={`3px solid ${theme.colors.black}`}
							borderRadius={"10px"}
							alignItems={'center'}
						>
							<GoogleLogin
								clientId={clientId}
								onSuccess={handleGoogleLogin}
								onFailure={onFailure}
								cookiePolicy={'single-host-origin'}
								isSignedIn={false}
								render={renderProps => (
									<Button
										h="100%"
										w="100%"
										onClick={renderProps.onClick}
										borderRadius={"10px"}
										color={`${theme.colors.black}`}
										>
										<Flex
											h="25px" w="25px" m="0 0 0 0"
										>
											<img src={GoogleLogo} />
										</Flex>
										<Flex
											h="25px" w="200px"
											m="0 0 0 40px"
											alignItems={'center'}
										>
											<Text variant="xlg">
												Sign up with Google
											</Text>

										</Flex>
									</Button>
								)}
							>

							</GoogleLogin>
						</Flex>

						<Flex
							h="52px" w="390px" m="0 0 0 0"
							background={theme.colors.white}
							border={`3px solid ${theme.colors.black}`}
							borderRadius={"10px"}
							alignItems={'center'}
						>
							<Button
                                h="100%"
                                w="100%"
                                onClick={handleMicrosoftLogin}
                                borderRadius={"10px"}
                                color={`${theme.colors.black}`}
                                >
								<Flex
									h="25px" w="25px"
									m="0 0 0 44px"
								>
									<img src={MicrosoftLogo} />
								</Flex>
								<Flex
									h="25px" w="250px"
									m="0 0 0 40px"
									alignItems={'center'}
								>
									<Text variant="xlg">
										Sign up with Microsoft
									</Text>

								</Flex>
							</Button>
						</Flex>

						<Flex
							h="34px" w="390px" m="5px 0 0 0"
							alignItems={'center'}
							justifyContent='center'
						>
							<Text variant="xxlg" color={theme.colors.black}>
								OR
							</Text>
						</Flex>
						<Flex
							h="52px" w="390px"
							background={theme.colors.black}
							border={`3px solid ${theme.colors.black}`}
							borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
                            <Button
                                h="100%"
                                w="100%"
                                onClick={async () => {history.push(basePath + "/sign_up_with_email");}}
                                background={theme.colors.black}
                                borderRadius={"10px"}
                                border={`3px solid ${theme.colors.black}`}
                                color={`${theme.colors.black}`}
                                _hover={{
                                    bg: theme.colors.black,
                                }}>
                                    <Flex
                                        h="25px" w="100%" m="0 0 0 0"
                                        alignItems={'center'}
                                        justifyContent='center'
                                    >
                                        <Text variant="xlg" color={theme.colors.white}>
											Sign up with email
                                        </Text>

                                    </Flex>
                                {/* Sign Up */}
                            </Button>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
		</>
	);
};

export default Signup;
