import { history } from "../../app/router/history";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Link from "../common/Link";
``;
export interface ModelBoxMenuProps {
	isopen: boolean;
	onclose: () => void;
}

export const ModelBoxMenu: React.FC<ModelBoxMenuProps> = (props) => {
	return (
		<Modal
			isCentered
			onClose={() => {
				props.onclose();
			}}
			isOpen={props.isopen}
			motionPreset="slideInBottom">
			{/* <ModalOverlay /> */}
			<ModalContent border="1px solid grey" rounded="xl" w={["300px", "400px", "450px", "500px"]} pb="10px">
				<ModalCloseButton
					onClick={() => {
						props.onclose();
					}}
				/>
				<ModalBody>{props.children}</ModalBody>
			</ModalContent>
		</Modal>
	);
};
