import { Box, Flex, Grid, Heading, HStack, Image, SimpleGrid, Text, VStack, Center, useMediaQuery, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import FakeIcon from "./common/FakeIcon";
import Link, { LinkProps } from "./common/Link";
import { pxToRem } from "../config/theme/Helper";
import { AnimateSharedLayout } from "framer-motion";
import { MotionFlex } from "./common/MotionFlex";
import { useLocation } from "react-router-dom";
import { history } from "../app/router/history";
import { AiOutlineFileAdd } from "react-icons/ai";
import { HiOutlineTemplate } from "react-icons/hi";
import { ImPencil } from "react-icons/im";
import { BsFileEarmarkCheck } from "react-icons/bs";
import Design from "../assets/images/Design-icon.svg";
import Upload from "../assets/images/upload-icon.svg";
import Format from "../assets/images/Format-icon.svg";
import Generate from "../assets/images/generate-icon.svg";
import { useEasyActions, useEasyState } from "../store/hooks/index";
import { basePath } from "../constants";

export interface SideBarProps {}

const SideBar: React.FC<SideBarProps> = (props) => {
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	const [selectedTab, setSelectedTab] = useState(3);
	const path = useLocation();

	const { originalAudio, isValidAudio } = useEasyState((store) => store.audioStore);
	const { setTemplatesType } = useEasyActions((store) => store.canvasStore);

	const disableMessage = originalAudio == null ? "Please upload audio first" : null;

	if (!isSmall) {
		return (
			<VStack spacing={pxToRem(25)} flex={1} align="center" justify="center">
				<SideBarButton  to={basePath + "/upload"} isActive={path.pathname.includes(basePath + "/upload")} title="Upload audio">
					<Image
						boxSize='50px'
						objectFit='cover'
						src={Upload}
						alt='Upload audio'
					/>
				</SideBarButton>
				<SideBarButton
					disableMessage={disableMessage}
					isActive={path.pathname.includes(basePath + "/design")}
					to={basePath + "/design"}
					title="Design">
					<Image
						boxSize='50px'
						objectFit='cover'
						src={Design}
						alt='Design'
					/>
				</SideBarButton>
				<SideBarButton isActive={path.pathname === basePath + "/Generate"} to={basePath + "/Generate"} title="Generate">
					<Image
						boxSize='50px'
						objectFit='cover'
						src={Generate}
						alt='Generate'
					/>
				</SideBarButton>
			</VStack>
		);
	}

	return (
		<HStack spacing={pxToRem(25)} flex={1} justify="center" alignItems="center" h={pxToRem(85)}>
			<SideBarButtonMobile
				onClick={() => setSelectedTab(1)}
				to={basePath + "/upload"}
				isActive={path.pathname === basePath + "/upload"}
				h={50}
				w={50}>
				<img src={Upload} />
			</SideBarButtonMobile>
			<SideBarButtonMobile
				disableMessage={disableMessage}
				onClick={() => {
					setSelectedTab(3);
				}}
				to={basePath + "/design"}
				isActive={path.pathname === basePath + "/design"}
				h={50}
				w={50}>
				<img src={Design} />
			</SideBarButtonMobile>
			<SideBarButtonMobile
				isActive={path.pathname === basePath + "/Generate"}
				onClick={() => setSelectedTab(4)}
				to={basePath + "/Generate"}
				h={50}
				w={50}>
				<img src={Generate} />
			</SideBarButtonMobile>
		</HStack>
	);
};

export interface SideBarButtonProps {
	title?: string;
	to: string;
	isActive?: boolean;
	h?: number;
	w?: number;
	color?: string;

	disableMessage?: string | null;
}

const SideBarButton: React.FC<SideBarButtonProps> = (props) => {
	const toast = useToast();
	const { originalAudio, isValidAudio } = useEasyState((store) => store.audioStore);

	return (
		<Flex
			align="center"
			flexDirection="column"
			onClick={() => {
				if (!isValidAudio || !originalAudio) {
					return;
				}
				if (props.disableMessage != null) {
					toast({ description: props.disableMessage });
					return;
				}

				history.push(props.to);
			}}
			cursor="pointer">
			<Center mx="auto" w={pxToRem(60)} h={pxToRem(60)} bg={props.isActive ? "primary" : "gray2"} rounded={13}>
				{props.children}
			</Center>
			{props.title && (
				<Text mt={pxToRem(6)} fontSize='sm' textAlign="center" variant="lg">
					{props.title}
				</Text>
			)}
		</Flex>
	);
};

export interface SideBarButtonMobileProps {
	title?: string;
	to: string;
	isActive?: boolean;
	h?: number;
	w?: number;

	disableMessage?: string | null;
	onClick: () => void;
}

const SideBarButtonMobile: React.FC<SideBarButtonMobileProps> = (props) => {
	const toast = useToast();
	const { setTemplatesType } = useEasyActions((store) => store.canvasStore);
	const { originalAudio, isValidAudio } = useEasyState((store) => store.audioStore);

	return (
		<Flex
			cursor="pointer"
			align="center"
			flexDirection="column"
			onClick={() => {
				if (!isValidAudio || !originalAudio) {
					return;
				}
				if (props.disableMessage != null) {
					toast({ description: props.disableMessage, position: "top" });
					return;
				}
				history.push(props.to);
				props.onClick();
			}}>
			<Center mx="auto" w={pxToRem(props.h ?? 70)} h={pxToRem(props.w ?? 70)} rounded={(props.h ?? 70) * (13 / 70)} position="relative">
				{props.children}
				<Box
					w={pxToRem(props.h ?? 70)}
					h={pxToRem(props.w ?? 70)}
					rounded={(props.h ?? 70) * (13 / 70)}
					position="absolute"
					zIndex={-10}
					bg="gray2"></Box>
				{props.isActive && (
					<MotionFlex
						layoutId="selectionBar"
						w={"100vw"}
						h={pxToRem(props.w ?? 70)}
						rounded={(props.h ?? 70) * (13 / 70)}
						position="absolute"
						zIndex={-5}
						right={0}
						bg="primary"></MotionFlex>
				)}
			</Center>
			{props.title && (
				<Text mt={pxToRem(6)} textAlign="center" variant="lg">
					{props.title}
				</Text>
			)}
		</Flex>
	);
};

export default SideBar;

