import { Button, Flex, useMediaQuery } from "@chakra-ui/react";
import { theme } from "../../config/theme/baseDesign";
import React, { useRef, useState } from "react";
import { useEasyState } from "../../store/hooks";
import Konva from "konva";
import { useEasyActions } from "../../store/hooks/index";

export interface CanvasZoomInOutButtonsProps {}

const CanvasZoomInOutButtons: React.FC<CanvasZoomInOutButtonsProps> = (props) => {
    const { konvaStageInstance, stageScale, canvasHWS, canvasPos } = useEasyState((store) => store.canvasStore);
    const [isSmallCanvas] = useMediaQuery("(max-width: 993px)");

    const { setStageScale, setStagePosition } = useEasyActions((state) => state.canvasStore);
    const [scale, setScale] = useState(1);

    return (
        <Flex justifyContent="flex-end" position="absolute" bottom={0} right={5} pb="10px">
            <Button
                h="34px"
                cursor="pointer"
                bg="primary"
                color="white"
                _hover={{
                    background: theme.colors.primary,
                    color: "white",
                }}
                onClick={() => {
                    if (!konvaStageInstance) {
                        return;
                    }

                    const stage = konvaStageInstance;

                    var oldScale = stage.scaleX();

                    var center = {
                        x: stage.width() / 2,
                        y: stage.height() / 2,
                    };

                    var relatedTo = {
                        x: (center.x - stage.x()) / oldScale,
                        y: (center.y - stage.y()) / oldScale,
                    };

                    const newScale = { x: stageScale.x + 0.1, y: stageScale.y + 0.1 };

                    setStageScale({
                        x: newScale.x,
                        y: newScale.y,
                    });

                    var newPos = {
                        x: center.x - relatedTo.x * newScale.x,
                        y: center.y - relatedTo.y * newScale.y,
                    };

                    setStagePosition(newPos);
                }}
            >
                +
            </Button>
            <Button
                h="34px"
                mx="10px"
                cursor="pointer"
                onClick={() => {
                    setStageScale({ x: canvasHWS.scale, y: canvasHWS.scale });
                    setStagePosition(canvasPos);
                }}
            >
                {Math.floor((stageScale.x / canvasHWS.scale) * 100)}%
            </Button>
            <Button
                h="34px"
                cursor="pointer"
                bg="primary"
                color="white"
                _hover={{
                    background: theme.colors.primary,
                    color: "white",
                }}
                onClick={() => {
                    if (!konvaStageInstance) {
                        return;
                    }

                    const stage = konvaStageInstance;

                    var oldScale = stage.scaleX();

                    var center = {
                        x: stage.width() / 2,
                        y: stage.height() / 2,
                    };

                    var relatedTo = {
                        x: (center.x - stage.x()) / oldScale,
                        y: (center.y - stage.y()) / oldScale,
                    };

                    const newScale = { x: stageScale.x - 0.1, y: stageScale.y - 0.1 };

                    setStageScale({
                        x: newScale.x,
                        y: newScale.y,
                    });

                    var newPos = {
                        x: center.x - relatedTo.x * newScale.x,
                        y: center.y - relatedTo.y * newScale.y,
                    };

                    setStagePosition(newPos);
                }}
            >
                -
            </Button>
        </Flex>
    );
};

export default CanvasZoomInOutButtons;
