import React from "react";
import { Flex, FlexProps } from "@chakra-ui/layout";
import { motion } from "framer-motion";
export const MotionFlex = motion<Omit<FlexProps, "transition">>(Flex);
// function Example() {
// 	return (
// 		<MotionFlex
// 			height="40px"
// 			bg="red.300"
// 			drag="x"
// 			dragConstraints={{ left: -100, right: 100 }}
// 			whileHover={{ scale: 1.1 }}
// 			whileTap={{ scale: 0.9 }}
// 		/>
// 	);
// }

// type A = Omit<FlexProps, "transition">;
