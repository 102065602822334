import { Point2D } from "framer-motion";
import Konva from "konva";
import { Layer } from "konva/lib/Layer";
import { Stage } from "konva/lib/Stage";

const GUIDELINE_OFFSET = 5;

export function konvaGridSnapMain({konvaStageInstance ,konvaLayerInstance}:{ konvaStageInstance:Stage ,konvaLayerInstance:Layer }){
	

	const stage = konvaStageInstance;

   // were can we snap our objects?
	function getLineGuideStops(skipShape: any) {
		// we can snap to stage borders and the center of the stage
		const vertical:[number,number,number][] = [[0 , stage.width() / 2, stage.width()]] ;
		const horizontal:[number,number,number][] =[ [0 , stage.height() / 2, stage.height()]];

		// and we snap over edges and center of each object on the canvas
		stage.find('.object').forEach((guideItem: { getClientRect: () => any; }) => {
			if (guideItem === skipShape) {
				return;
			}
			var box = guideItem.getClientRect();
			// and we can snap to all edges of shapes
			vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
			horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
		});
		return {
			vertical: vertical.flat(),
			horizontal: horizontal.flat(),
		};
	}

	// what points of the object will trigger to snapping?
	// it can be just center of the object
	// but we will enable all edges and center
	function getObjectSnappingEdges(node: { getClientRect: () => any; absolutePosition: () => any; }) {
		var box = node.getClientRect();
		var absPos = node.absolutePosition();

		return {
			vertical: [
				{
					guide: Math.round(box.x),
					offset: Math.round(absPos.x - box.x),
					snap: 'start',
				},
				{
					guide: Math.round(box.x + box.width / 2),
					offset: Math.round(absPos.x - box.x - box.width / 2),
					snap: 'center',
				},
				{
					guide: Math.round(box.x + box.width),
					offset: Math.round(absPos.x - box.x - box.width),
					snap: 'end',
				},
			],
			horizontal: [
				{
					guide: Math.round(box.y),
					offset: Math.round(absPos.y - box.y),
					snap: 'start',
				},
				{
					guide: Math.round(box.y + box.height / 2),
					offset: Math.round(absPos.y - box.y - box.height / 2),
					snap: 'center',
				},
				{
					guide: Math.round(box.y + box.height),
					offset: Math.round(absPos.y - box.y - box.height),
					snap: 'end',
				},
			],
		};
	}

	// find all snapping possibilities
	function getGuides(lineGuideStops: { vertical: any; horizontal: any; }, itemBounds: { vertical: any; horizontal: any; }) {
		var resultV: { lineGuide: any; diff: number; snap: any; offset: any; }[] = [];
		var resultH: { lineGuide: any; diff: number; snap: any; offset: any; }[] = [];

		lineGuideStops.vertical.forEach((lineGuide: number) => {
			itemBounds.vertical.forEach((itemBound: { guide: number; snap: any; offset: any; }) => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				// if the distance between guild line and object snap point is close we can consider this for snapping
				if (diff < GUIDELINE_OFFSET) {
					resultV.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset,
					});
				}
			});
		});

		lineGuideStops.horizontal.forEach((lineGuide: number) => {
			itemBounds.horizontal.forEach((itemBound: { guide: number; snap: any; offset: any; }) => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				if (diff < GUIDELINE_OFFSET) {
					resultH.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset,
					});
				}
			});
		});

		var guides = [];

		// find closest snap
		var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
		var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
		if (minV) {
			guides.push({
				lineGuide: minV.lineGuide,
				offset: minV.offset,
				orientation: 'V',
				snap: minV.snap,
			});
		}
		if (minH) {
			guides.push({
				lineGuide: minH.lineGuide,
				offset: minH.offset,
				orientation: 'H',
				snap: minH.snap,
			});
		}
		return guides;
	}

function drawGuides ( guides: any[] )
{
	if (! konvaLayerInstance )
	{
			return
	}
		guides.forEach((lg: { orientation: string; lineGuide: any; }) => {
			if (lg.orientation === 'H') {
				var line = new Konva.Line({
					points: [-6000, 0, 6000, 0],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [4, 6],
				});
				konvaLayerInstance.add(line);
				line.absolutePosition({
					x: 0,
					y: lg.lineGuide,
				});
			} else if (lg.orientation === 'V') {
				var line = new Konva.Line({
					points: [0, -6000, 0, 6000],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [4, 6],
				});
				konvaLayerInstance.add(line);
				line.absolutePosition({
					x: lg.lineGuide,
					y: 0,
				});
			}
		});
	}

	konvaLayerInstance.on('dragmove', function (e: { target: { absolutePosition: ( arg0?: Point2D ) => Point2D; }; }) {
		// clear all previous lines on the screen
		konvaLayerInstance.find('.guid-line').forEach((l: { destroy: () => any; }) => l.destroy());

		// find possible snapping lines
		var lineGuideStops = getLineGuideStops(e.target);
		// find snapping points of current object
		var itemBounds = getObjectSnappingEdges(e.target as any);

		// now find where can we snap current object
		var guides = getGuides(lineGuideStops, itemBounds);

		// do nothing of no snapping
		if (!guides.length) {
			return;
		}

		drawGuides(guides);

		var absPos = e.target.absolutePosition();
		// now force object position
		guides.forEach((lg) => {
			switch (lg.snap) {
				case 'start': {
					switch (lg.orientation) {
						case 'V': {
							absPos.x = lg.lineGuide + lg.offset;
							break;
						}
						case 'H': {
							absPos.y = lg.lineGuide + lg.offset;
							break;
						}
					}
					break;
				}
				case 'center': {
					switch (lg.orientation) {
						case 'V': {
							absPos.x = lg.lineGuide + lg.offset;
							break;
						}
						case 'H': {
							absPos.y = lg.lineGuide + lg.offset;
							break;
						}
					}
					break;
				}
				case 'end': {
					switch (lg.orientation) {
						case 'V': {
							absPos.x = lg.lineGuide + lg.offset;
							break;
						}
						case 'H': {
							absPos.y = lg.lineGuide + lg.offset;
							break;
						}
					}
					break;
				}
			}
		});
		e.target.absolutePosition(absPos);
	});

	konvaLayerInstance.on('dragend', function (e: any) {
		// clear all previous lines on the screen
		konvaLayerInstance.find('.guid-line').forEach((l: { destroy: () => any; }) => l.destroy());
	});

}