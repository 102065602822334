import SideBar from "../components/SideBar";
import React from "react";

export interface CanvasLayoutProps {}

const CanvasLayout: React.FC<CanvasLayoutProps> = (props) => {
	return (
		<SideBar />
		// {props.children}
	);
};

export default CanvasLayout;
