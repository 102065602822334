import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { State, debug } from "easy-peasy";

export class UpdateCommand<T extends AllCanvasElements>
  implements Command<State<CanvasStore>, "UpdateElement">
{
  name = "UpdateElement" as const;
  oldElementsProperties: Record<string, Record<string, any>> = {};

  constructor(
    public elementsProps: (Partial<Omit<T, "type">> & { id: string })[]
  ) {}

  execute(state: State<CanvasStore>): void {
    for (const elementProps of this.elementsProps) {
      const element: T = state.canvasState[elementProps.id] as T;

      for (const key in elementProps) {
        if (!this.oldElementsProperties[elementProps.id]) {
          this.oldElementsProperties[elementProps.id] = {};
        }
        this.oldElementsProperties[elementProps.id][key] =
          element[key as keyof typeof element];
      }

      const updatedElement: T = { ...element, ...elementProps };
      state.canvasState[updatedElement.id] = updatedElement;
    }
  }

  undo(state: State<CanvasStore>): void {
    for (const id in this.oldElementsProperties) {
      state.canvasState[id] = {
        ...state.canvasState[id],
        ...this.oldElementsProperties[id],
      };
    }
  }
}
