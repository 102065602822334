import { commandInstanceToObj, objToCommandInstance } from "../../Commands/CanvasCommanda/commandsLIst";
import { removeArrayElement } from "../../utils/arrayFuns";
import { action, createStore, thunk, computed, debug } from "easy-peasy";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { Group } from "konva/lib/Group";
import { Shape, ShapeConfig } from "konva/lib/Shape";
import { findKonvaElementByName } from "../../utils/Konva";
import Konva from "konva";
import { template } from "../Template/TemplateStore";
import { calculateCanvasTransform, calculateCanvasPosition } from "../../utils/canvasHelpers";

export const canvasStore: CanvasStore = {
	canvasState: {},
	stagePosition: { x: 0, y: 0 },
	stageScale: { x: 1, y: 1 },
	isReplaceable: false,
	isStatic: true,
	highlightingElements: {},
	selectedElementsIds: [],
	canvasParentHW: { height: 500, width: 500 },
	templatesType: "landscape",
	selectedTemplatesType: "landscape",
	selectedCanvasId: null,
	selectedCanvasName: null,
	selectedCanvasDes: null,
	clickedGenerateCanvas: false,
	screenShot: null,
	videoUUid: null,
	cameraGroupInstance: computed((state) => findKonvaElementByName("cameraGroup", state.konvaLayerInstance?.children ?? []) ?? null),
	generateGroupInstance: computed((state) => findKonvaElementByName("generateGroup", state.konvaLayerInstance?.children ?? []) ?? null),
	konvaBackgroundInstance: computed(
		(state) =>
			findKonvaElementByName("konvaBackground", (state.konvaStageInstance?.children?.[0]?.children?.[0] as Group)?.children ?? []) ?? null,
	),
	konvaTransformerInstance: computed((state) => findKonvaElementByName("transformerElement", state.konva2ndLayerInstance?.children ?? [])),
	konva2ndLayerInstance: computed((state) => findKonvaElementByName("2ndLayer", state.konvaStageInstance?.children ?? [])),
	KonvaGenerateStageInstance: computed((state) => findKonvaElementByName("generateStage", state.konvaInstance.stages)),
	konvaLayerInstance: computed((state) => findKonvaElementByName("elementsLayer", state.konvaStageInstance?.children ?? [])),
	konvaStageInstance: computed((state) => findKonvaElementByName("mainStage", state.konvaInstance.stages)),
	KonvaGenerateLayer: computed((state) => findKonvaElementByName("generateLayer", state.konvaStageInstance?.children ?? [])),
	konvaInstance: window.Konva,

	konvaCurrentHoverElement: null,

	history: [],
	redoHistory: [],

	selectedElementId: computed((state) => (state.selectedElementsIds.length === 1 ? state.selectedElementsIds[0] : null)),
	selectedElement: computed((state) => state.canvasState[state.selectedElementId ?? ""]),
	selectedElements: computed((state) => {
		const res: Record<string, AllCanvasElements> = {};
		state.selectedElementsIds.forEach((id) => (res[id] = state.canvasState[id]));

		return res;
	}),

	scaleRecheck: thunk((actions, payload, { getStoreActions, getState }) => {
		//debugger;

		let { konvaStageInstance, canvasPos, canvasHWS } = getState();
		let { setStageScale, setStagePosition } = getStoreActions().canvasStore;

		if (!konvaStageInstance) {
			return;
		}

		const stage = konvaStageInstance;
		const scale = canvasHWS;

		setStagePosition(calculateCanvasPosition(stage.width(), stage.height(), scale.scale));

		setStageScale({
			x: scale.scale,
			y: scale.scale,
		});
	}),
	canvasPos: computed([(s) => s.canvasHWS, (s) => s.konvaStageInstance, (s) => s.canvasParentHW], (canvasHWS, konvaStageInstance) => {
		//debugger;
		if (!konvaStageInstance) {
			return { x: 0, y: 0 };
		}

		const stage = konvaStageInstance;
		const scale = canvasHWS.scale;

		return calculateCanvasPosition(stage.width(), stage.height(), scale);
	}),
	canvasHWS: computed([(s) => s.templatesType, (s) => s.canvasParentHW], (type, parentHW) => {
		//debugger;
		// let minQ = Math.min(parentHW.height, parentHW.width) * 0.8;
		let padding = Math.min(parentHW.height, parentHW.width) * 0.2;

		let maxHeight = parentHW.height - padding;
		let maxWidth = parentHW.width - padding;

		return calculateCanvasTransform(type, maxWidth, maxHeight);
	}),

	selectedElementInstance: computed((state) => state.cameraGroupInstance?.children?.find((e) => e.attrs["data-id"] === state.selectedElementId)),
	selectedElementsInstance: computed((state) => {
		if (!state.cameraGroupInstance) {
			return {};
		}

		const res: Record<string, Group | Shape<ShapeConfig>> = {};

		const instances = state.cameraGroupInstance.children?.filter((ch) => state.selectedElements[ch.attrs["data-id"]]) ?? [];

		instances.forEach((i) => (res[i.attrs["data-id"]] = i));

		return res;
	}),
	setHighlightingElements: action((state, payload) => {
		state.highlightingElements = payload;
	}),
	setSelectedCanvas: action((state, payload) => {
		state.canvasState = payload;
	}),
	setClickedGenerateCanvas: action((state, payload) => {
		state.clickedGenerateCanvas = payload;
	}),
	setCanvasParentHW: action((state, payload) => {
		state.canvasParentHW = { height: payload.height, width: payload.width };
	}),

	setTemplatesType: action((state, payload) => {
		state.templatesType = payload;
	}),
	setSelectedTemplates: action((state, payload) => {
		state.selectedTemplatesType = payload;
	}),
	setKonvaInstance: action((state, payload) => {
		state.konvaInstance = payload;
	}),
	setIsReplaceable: action((state, payload) => {
		state.isReplaceable = payload;
	}),
	setIsStatic: action((state, payload) => {
		state.isStatic = payload;
	}),
	setVideoUUid: action((state, payload) => {
		state.videoUUid = payload;
	}),
	setSelectedCanvasId: action((state, payload) => {
		state.selectedCanvasId = payload;
	}),
	setSelectedCanvasName: action((state, payload) => {
		state.selectedCanvasName = payload;
	}),
	setSelectedCanvasDes: action((state, payload) => {
		state.selectedCanvasDes = payload;
	}),
	setKonvaCurrentHoverElement: action((state, payload) => {
		state.konvaCurrentHoverElement = payload;
	}),

	addElement: action((state, payload) => {
		state.canvasState[payload.id] = payload;
	}),

	removeElement: action((state, payload) => {
		delete state.canvasState[payload];
	}),

	updateElement: action((state, payload) => {
		state.canvasState[payload.id] = {
			...state.canvasState[payload.id],
			...payload,
		};
	}),

	setSelectedElementsIds: action((state, payload) => {
		state.selectedElementsIds = [...new Set(payload)];
	}),

	executeCommand: action((state, payload) => {
		payload.execute(state);
		state.history.push(commandInstanceToObj(payload));
		state.redoHistory = [];
	}),

	undoCommand: action((state, payload) => {
		const command = state.history.pop();

		if (command == null) {
			return;
		}

		const commandInstance = objToCommandInstance(debug(command));
		commandInstance.undo(state);
		const obj = commandInstanceToObj(commandInstance);
		state.redoHistory.push(obj);
	}),
	setScreenShot: action((state, payload) => {
		state.screenShot = payload;
	}),
	redoCommand: action((state, payload) => {
		const command = state.redoHistory.pop();

		if (command == null) {
			return;
		}

		const commandInstance = objToCommandInstance(debug(command));
		commandInstance.execute(state);
		const obj = commandInstanceToObj(commandInstance);
		state.history.push(obj);
	}),

	setStagePosition: action((state, payload) => {
		state.stagePosition = payload;
	}),

	setStageScale: action((state, payload) => {
		state.stageScale = payload;
	}),
};
