import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { Subscription } from "../../types/ApiTypex/login";
import { useEasyState } from "../../store/hooks";

export async function subscribe(plan: string) {
	return await catchTry(
		mainAxios.post<Subscription>(`${defaultOptions.baseURL}/api/subscriptions`, {
			user: {
				plan: plan,
			},
		}),
	);
}