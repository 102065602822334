import { TextStore } from "../../types/canvasStoreTypes/StoreTypes";
import { action } from "easy-peasy";
export const textStore: TextStore = {
	apiRes: [],
	labelRef: null,
	isAutoCompleteOpen: false,
	setApiRes: action((state, payload) => {
		state.apiRes = payload;
	}),

	selectedFont: "Arial",
	setSelectedFont: action((state, payload) => {
		state.selectedFont = payload;
	}),
	setIsAutoCompleteOpen: action((state, payload) => {
		state.isAutoCompleteOpen = payload;
	}),
};
