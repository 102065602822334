import React from "react";

const Text = ({ fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8359 56.5821C19.6836 56.5821 18.75 57.5157 18.75 58.668C18.75 59.2188 18.9727 59.75 19.3633 60.1407C19.7539 60.5313 20.2812 60.7501 20.836 60.7501H62.504H62.5001C63.0548 60.7501 63.5821 60.5313 63.9728 60.1407C64.3634 59.7501 64.5861 59.2188 64.5861 58.668C64.5861 58.1133 64.3634 57.586 63.9728 57.1953C63.5821 56.8047 63.0548 56.582 62.5001 56.582L20.8359 56.5821Z"
        fill={fill}
      />
      <path
        d="M25 48.2501C23.8516 48.2501 22.918 49.1837 22.918 50.3321C22.918 50.8868 23.1367 51.4141 23.5273 51.8048C23.918 52.1954 24.4492 52.4181 25 52.4181H62.5C63.0547 52.4181 63.582 52.1954 63.9727 51.8048C64.3634 51.4141 64.586 50.8868 64.586 50.3321C64.586 49.7813 64.3634 49.2501 63.9727 48.8594C63.5821 48.4688 63.0548 48.25 62.5 48.25L25 48.2501Z"
        fill={fill}
      />
      <path
        d="M33.3359 39.918C32.1836 39.918 31.25 40.8477 31.25 42C31.25 42.5508 31.4727 43.082 31.8633 43.4727C32.2539 43.8634 32.7812 44.0821 33.336 44.0821H62.504H62.5001C63.0548 44.0821 63.5821 43.8634 63.9728 43.4727C64.3634 43.0821 64.5861 42.5509 64.5861 42C64.5861 41.4492 64.3634 40.9181 63.9728 40.5274C63.5821 40.1367 63.0548 39.918 62.5001 39.918L33.3359 39.918Z"
        fill={fill}
      />
      <path
        d="M31.25 31.5821C30.1016 31.5821 29.168 32.5157 29.168 33.668C29.168 34.2188 29.3867 34.75 29.7773 35.1407C30.168 35.5314 30.6992 35.7501 31.25 35.7501H62.5C63.0547 35.7501 63.582 35.5313 63.9727 35.1407C64.3634 34.7501 64.586 34.2188 64.586 33.668C64.586 33.1133 64.3634 32.586 63.9727 32.1953C63.5821 31.8047 63.0548 31.582 62.5 31.582L31.25 31.5821Z"
        fill={fill}
      />
      <path
        d="M20.8359 23.2501C19.6836 23.2501 18.75 24.1837 18.75 25.3321C18.75 25.8868 18.9727 26.4141 19.3633 26.8048C19.7539 27.1954 20.2812 27.4181 20.836 27.4181H62.504H62.5001C63.0548 27.4181 63.5821 27.1954 63.9728 26.8048C64.3634 26.4141 64.5861 25.8868 64.5861 25.3321C64.5861 24.7813 64.3634 24.2501 63.9728 23.8594C63.5821 23.4688 63.0548 23.25 62.5001 23.25L20.8359 23.2501Z"
        fill={fill}
      />
      <path
        d="M2.0859 0.332031C0.9336 0.332031 0 1.26562 0 2.41793V14.9179C0 16.0663 0.93359 16.9999 2.0859 16.9999H6.25V66.9999H2.0859C0.9336 66.9999 0 67.9335 0 69.0819V81.5819C0 82.7342 0.93359 83.6678 2.0859 83.6678H14.5859C15.7343 83.6678 16.6679 82.7342 16.6679 81.5819V77.4139H66.6679V81.5819C66.6679 82.7342 67.6015 83.6678 68.7499 83.6678H81.2499C82.4022 83.6678 83.3358 82.7342 83.3358 81.5819V69.0819C83.3358 67.9335 82.4022 66.9999 81.2499 66.9999H77.0858V16.9999H81.2538H81.2499C82.4022 16.9999 83.3358 16.0663 83.3358 14.9179V2.41793C83.3358 1.26563 82.4022 0.332031 81.2499 0.332031H68.7499C67.6015 0.332031 66.6679 1.26562 66.6679 2.41793V6.58203H16.6679V2.41793C16.6679 1.26563 15.7343 0.332031 14.5859 0.332031H2.0859ZM4.1679 4.50003H12.4999V12.8359L8.3358 12.832H4.1678L4.1679 4.50003ZM70.8359 4.50003H79.1679V12.832H70.8359V4.50003ZM16.6679 10.75H66.6679V14.918C66.6679 16.0664 67.6015 17 68.7499 17H72.9179V67H68.7499C67.6015 67 66.6679 67.9336 66.6679 69.082V73.25H16.6679V69.082C16.6679 67.9336 15.7343 67 14.5859 67H10.4179V17H14.5859C15.7343 17 16.6679 16.0664 16.6679 14.918V10.75ZM4.1679 71.168H12.4999V79.5H4.1679V71.168ZM70.8359 71.168H79.1679V79.5H70.8359V71.168Z"
        fill={fill}
      />
    </svg>
  );
};

export default Text;
