import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { Attributes, CanvasObject } from "../../types/ApiTypex/CreateCanvas";
import { TemplatesTypes } from "../../types";

type createCanvasApiType = {
    name: string;
    description: string;
    canvasobject?: string;
    screenShot?: File;
    type: TemplatesTypes;
    isDefault?: string;
    rendered?: string;
};

export async function createCanvas({
    name,
    description,
    canvasobject = "{}",
    screenShot,
    type = "landscape",
    isDefault,
    rendered = "false",
}: createCanvasApiType) {
    let token = TokenService.getToken;
    let bodyFormData = new FormData();
    // debugger;
    bodyFormData.append("canvas[name]", type + ":" + name);
    // if (canvasobject) {
    bodyFormData.append("canvas[details]", canvasobject);
    // }
    bodyFormData.append("canvas[description]", description);
    if (screenShot) {
        bodyFormData.append("canvas[picture]", screenShot);
    }
    if (isDefault) {
        bodyFormData.append("canvas[default]", isDefault);
    }
    if (rendered) {
        bodyFormData.append("canvas[rendered]", rendered);
    }

    return await catchTry(
        mainAxios.post<CanvasObject>(`${defaultOptions.baseURL}/api/canvas`, bodyFormData, {
            headers: {
                Authorization: token,
            },
        })
    );
}
