import React, { useRef, useState } from "react";
import { Box, Button, Flex, Heading, Input, toast, useToast, Text } from "@chakra-ui/react";
import { history } from "../../app/router/history";
import srtParser2 from "srt-parser-2";
import { parseTimestamp } from "../../utils/subtitles/subtitles";
import { useEasyActions, useEasyState } from "../../store/hooks/index";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { CreateRecord } from "../../utils/Api/CreateRecord";
import { RecordDetails } from "../../utils/Api/RecordDetail";
import { Attributes } from "../../types/ApiTypex/RecordDetailsTypes";
import { sleep } from "../../utils";
import { Subtitle } from "../../types/index";
import { Type } from "../../types/ApiTypex/Unslpash";
import { theme } from "../../config/theme/baseDesign";
import { fontSize } from "../../config/theme/Helper";
import { basePath } from "../../constants";
export interface SubtitlesProps {}

const Subtitles: React.FC<SubtitlesProps> = (props) => {
	const fileUpload = useRef<null | HTMLInputElement>(null);
	const { setSubtitles, setIsLoadingSubtitles } = useEasyActions((state) => state.visualizationStore);
	const { originalAudio, regionStart, regionEnd } = useEasyState((state) => state.audioStore);
	const { setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);
	const { permissionsData } = useEasyState((store) => store.userDataStore);
	const { currentSubtitle, isLoadingSubtitles, subtitles } = useEasyState((store) => store.visualizationStore);
	const toast = useToast();
	function file(e: any) {
		const reader = new FileReader();
		reader.readAsText(e.target.files[0]);

		reader.onload = function () {
			const parser = new srtParser2();
			const result = parser
				.fromSrt(reader.result as string)
				.map((node) => ({ ...node, endTime: parseTimestamp(node.endTime), startTime: parseTimestamp(node.startTime) }));
			setSubtitles(result);
		};
		reader.onerror = function (error) {
			// console.log("Error: ", error);
		};

		onText();
	}

	function onText() {
		// console.log("Subtitle run");

		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Subtitle",
				rotationDeg: 0,
				x: 200,
				y: 20,
				width: 280,
				fontSize: 20,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				skewX: 0,
				skewY: 0,
				fill: "#1f1f1f",
				transformProperties: {
					enabledAnchors: ["middle-left", "middle-right"],
				},
			}),
		);
	}

	async function autoGenerate() {
		//debugger;
		if (!originalAudio) {
			return;
		}
		onText();
		if (subtitles.length != 0) return;
		setIsLoadingSubtitles(true);
		// debugger;
		if (regionEnd == null || regionStart == null) {
			return;
		}
		const response = await CreateRecord(originalAudio, regionEnd, regionStart);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some error please try la7ter.",
			});
			return;
		}

		while (true) {
			await sleep(5000);
			// debugger;
			const detailRes = await RecordDetails(response.data.data.attributes.token);
			if (detailRes instanceof Error) {
				toast({
					status: "error",
					title: "Error!",
					description: "There is some error please try la7ter.",
				});
				return;
			}
			if (detailRes.data.data.attributes.transcription_details.status !== "COMPLETED") {
				continue;
			}
			let subtitles = detailRes.data.data.attributes.transcription_details.transcript_details;

			// console.log(detailRes.data.data.attributes.transcription_details.transcript_details);
			let subtitleArr: Subtitle[] = [];

			for (const [i, SubtitleRes] of subtitles.entries()) {
				const Subtitle: Subtitle = {
					endTime: +SubtitleRes.end_time,
					startTime: +SubtitleRes.start_time,
					text: SubtitleRes.transcript,
					id: i.toString(),
				};

				subtitleArr.push(Subtitle);
			}
			setIsLoadingSubtitles(false);
			setSubtitles(subtitleArr);
			break;
		}
	}

	return (
		<Flex flexDir="column" flex={1}>
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end">
					<Flex
						cursor="pointer"
						onClick={() => history.push(basePath + "/design")}
						w="40px"
						justifyContent="flex-end"
						mt="10px"
						mr={["30px", "30px", "20px"]}>
						✖
					</Flex>
				</Flex>
				<Heading mb="30px" ml="30px" variant="mdb">
					Subtitles
				</Heading>
				<Flex mx="30px" flexDir="column" overflow="auto" mb="10px">
					<Flex flexDir="column">
						<Flex
							flexGrow={0}
							w="100%"
							mb="5px"
							color="grey"
							alignItems="center"
							border="1px solid #c2c2c2"
							h="40px"
							rounded="lg"
							pl="10px">
							<Text nooflines={1}></Text>
						</Flex>
						<Flex flex={1} my="5px">
							<Button
								cursor="pointer"
								onClick={() => {
									fileUpload.current?.click();
								}}
								flex={[null, 1]}
								width="100%"
								variant="styled">
								Browse
							</Button>
						</Flex>

						<Box display="none">
							<Input multiple={false} ref={fileUpload} type="file" id="upFile" onChange={(e) => file(e)} />
						</Box>
					</Flex>
					<Flex flex={1} my="5px">
						<Button
							mt="20px"
							cursor="pointer"
							isLoading={isLoadingSubtitles}
							disabled={isLoadingSubtitles || !originalAudio}
							_hover={{}}
							flex={[null, 1]}
							width="100%"
							variant="styled"
							onClick={() => {
								if (!permissionsData.add_subtitles) {
									toast({
										status: "info",
										description: "This is available in Pro Plan.",
									});
									return;
								}
								// console.log("subtitles", subtitles);
								// debugger;

								autoGenerate();
							}}>
							Auto Generate
						</Button>
					</Flex>
					{originalAudio == null && (
						<Flex justifyContent="center" flex={1}>
							<Text variant="lg" color={theme.colors.gray2}>
								There is no Audio Selected
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Subtitles;
