import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { VideoRootObject } from "../../types/ApiTypex/VideoTypes";

export async function getVideoApi(videoUuid: string) {
    let token = TokenService.getToken;

    return await catchTry(mainAxios.get<VideoRootObject>(`${defaultOptions.baseURL}/api/videos/${videoUuid}`, {}));
}
