import React from "react";

const Audio = ({ fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 69 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1562 59.6449H33.7222V8.23493C33.7222 6.50833 34.2457 4.89113 35.191 3.54353C36.1285 2.20373 37.4683 1.15683 39.1012 0.559133C40.7301 -0.0385271 42.4254 -0.101027 43.9996 0.316943C45.5816 0.738823 47.0269 1.63334 48.1441 2.96144L66.6711 24.8954C67.7141 26.1298 68.3469 27.5751 68.55 29.0829C68.7531 30.5907 68.5227 32.1493 67.8469 33.6102C67.1672 35.075 66.1203 36.2547 64.8352 37.079C63.5461 37.8994 62.0344 38.3524 60.429 38.3524H38.308V78.8014C38.308 84.0709 36.1557 88.8604 32.683 92.3284C29.2103 95.8011 24.4252 97.9534 19.152 97.9534C13.8825 97.9534 9.093 95.8011 5.625 92.3284C2.1523 88.8557 0 84.0706 0 78.8014C0 73.5322 2.1523 68.7424 5.625 65.2704C9.0977 61.7977 13.8828 59.6454 19.152 59.6454L19.1562 59.6449Z"
        fill={fill}
      />
    </svg>
  );
};

export default Audio;
