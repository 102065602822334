import React from "react";
import { ModelBoxMenu } from "./ModelBoxMenu";

export interface ConfirmationToGenerateModelProps {}

const ConfirmationToGenerateModel: React.FC<ConfirmationToGenerateModelProps> = (props) => {
	return <div></div>;
};

export default ConfirmationToGenerateModel;
