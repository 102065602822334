import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { history } from "../app/router/history";
import { ModelBoxMenu } from "../components/home/ModelBoxMenu";
import { theme } from "../config/theme/baseDesign";
import exclamation from "../assets/images/exclamation.svg";
import { basePath } from "../constants";

export interface GeneratePopUpProps {
	isOpen: boolean;
	onClose: () => void;
}

const GeneratePopUp: React.FC<GeneratePopUpProps> = ({ isOpen, onClose }) => {
	return (
		<ModelBoxMenu isopen={isOpen} onclose={onClose}>
			<Flex mt="10px">
				<Flex>
					<img src={exclamation} />
				</Flex>
				<Flex justifyContent="center" flex={1}>
					<Text w="220px" textAlign="center">
						Are you ready to generate? You can’t edit after this stage.
					</Text>
				</Flex>
			</Flex>
			<Flex cursor="pointer" mt="20px" color="white">
				<Flex
					onClick={() => {
						history.push(basePath + "/design");
						onClose();
					}}
					mr="50px"
					rounded="lg"
					alignItems="center"
					justifyContent="center"
					w="200px"
					bg={theme.colors.primary}
					p="1px">
					<Text textAlign="center" w="100px">
						No, I still need to edit
					</Text>
				</Flex>
				<Flex
					cursor="pointer"
					onClick={() => {
						history.push(basePath + "/Generate");
					}}
					rounded="lg"
					justifyContent="center"
					alignItems="center"
					w="200px"
					bg={theme.colors.primary}
					p="1px">
					<Text textAlign="center" w="110px">
						Yes, let’s do it!
					</Text>
				</Flex>
			</Flex>
		</ModelBoxMenu>
	);
};

export default GeneratePopUp;
