import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Flex, Heading, Input, InputGroup, InputLeftElement, Grid, useToast } from "@chakra-ui/react";
import { history } from "../../app/router/history";
import { Text } from "@chakra-ui/layout";
import { FiSearch } from "react-icons/fi";
import downArrow from "../../assets/images/Chevron.svg";
import Emoji from "./stickers/Emoji";
import { Datum } from "../../types/ApiTypex/Giphy";
import { Giphy } from "../../utils/Api/Giphy";
import { debounce } from "../../utils/debounce";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { basePath } from "../../constants";
export interface StickersProps {}

const Stickers: React.FC<StickersProps> = (props) => {
	const { executeCommand, setSelectedElementsIds } = useEasyActions((store) => store.canvasStore);
	const { canvasHWS } = useEasyState((store) => store.canvasStore);

	const scrollParent = useRef<null | HTMLDivElement>(null);
	const scrollParentDown = useRef<null | HTMLDivElement>(null);
	const [text, setText] = useState("nature");
	const [apiRes, setApiRes] = useState<Datum[] | null>();
	const gifRef = useRef(null);
	const inputRef = useRef<null | HTMLInputElement>(null);
	const toast = useToast();
	const [hasMore, setHasMore] = useState(false);
	const [limit, setLimit] = useState(10);
	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;
	let apiKey = "4EqYUwuzIG9S3YYEitymw3bgwiN8m5ic";

	function onChangeTextHandler(text: string) {
		getGIFs(text);
		setText(text);
	}

	const onChangeInputTextDebounce = useCallback(
		debounce((t: string) => {
			setApiRes([]);
			onChangeTextHandler(t);
		}, 500),
		[],
	);
	// debugger;
	const observer = useRef<IntersectionObserver | null>(null);
	const lastImgRef = useCallback(
		(node) => {
			// debugger;

			// console.log(node);
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setLimit((limit) => limit + 10);
				}
			});
			if (node) {
				observer.current.observe(node);
			}
		},
		[hasMore],
	);

	async function getGIFs(text: string) {
		const response = await Giphy(limit, text, apiKey);

		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}
		setApiRes(response.data.data);
		setHasMore(response.data.data.length > 0);
	}

	useEffect(() => {
		getGIFs(text);
		return () => {
			//your cleanup code codes here
			setApiRes([]);
		};
	}, []);
	useMemo(() => onChangeTextHandler(text), [limit, text]);
	let scroll = document.querySelector(".scrollIcon");
	let time: any;
	scroll?.addEventListener("mousedown", function (event) {
		time = setInterval(function () {
			window.scrollBy(0, 1); // May need to be -1 to go down
		}, 2);
	});
	let doc = document.querySelector(".document");
	doc?.addEventListener("mouseup", function () {
		clearInterval(time);
		return false;
	});

	function programmaticallyScrollDown(dir: "down") {
		if (!scrollParentDown.current) {
			return;
		}
		// scrollParentDown.current.scrollBy(0, 700);
		scrollParentDown.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}

	function addGif(url: string, h: number, w: number) {
		const shrink = 300 / Math.max(h, w);
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Gif",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				url: url,
				height: h * shrink,
				width: w * shrink,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
		// setImage(imageRef.current);
	}

	return (
		<Flex flexDir="column" overflow="auto">
			<Flex justifyContent="flex-end" mr="20px">
				<Flex
					cursor="pointer"
					onClick={() => {
						setSelectedElementsIds([]);
						history.push(basePath + "/design");
					}}
					w="40px"
					justifyContent="flex-end"
					mt="10px">
					✖
				</Flex>
			</Flex>
			<Heading mb="20px" ml="30px" variant="mdb">
				Stickers
			</Heading>
			<Flex flexDir="column" flex={1}>
				<Flex flex={1} mx="30px" flexDir="column">
					<Flex overflow="auto" ref={scrollParentDown} mb="5px" flexDir="column">
						<Flex flexDir="column">
							<Text my="10px" variant="md">
								Select GIF
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" mt="5px" children={<FiSearch size={20} />} />
								<Input
									my="5px"
									type="text"
									ref={inputRef}
									// value={text}
									onChange={(e) => {
										onChangeInputTextDebounce(e.target.value);
									}}
								/>
							</InputGroup>
							<Text mt="10px" variant="md" color="gray">
								Browse Giphy
							</Text>
						</Flex>
						<Flex m="2px" flexDir="column">
							<Grid
								// className="hideScroll"
								p="2px"
								id="gridImg"
								mt="15px"
								gridTemplateColumns="repeat(2, 1fr)"
								gap="5px"
								mb="5px"
								h="20vh">
								{apiRes?.map((item, index) => {
									// debugger;
									let url = item?.images?.downsized_medium.url;
									let width = item.images.downsized_medium.width;
									let height = item.images.downsized_medium.height;
									// console.log("url", url);
									return (
										<Flex
											_hover={{
												boxShadow: "0px 0px 2px 1px #0095a8",
												transition: "transform .3s ease-out",
											}}
											ref={lastImgRef}
											cursor="pointer"
											key={index}>
											<img
												onClick={() => {
													addGif(url, Number(height), Number(width));
												}}
												ref={gifRef}
												style={{
													objectFit: "cover",
													width: "100%",
													height: "100%",
												}}
												src={url}
											/>
										</Flex>
									);
								})}
							</Grid>
						</Flex>
					</Flex>
					<Flex
						aria-label="Search database"
						onClick={() => programmaticallyScrollDown("down")}
						id="scrollIcon"
						cursor="pointer"
						justifyContent="center"
						flex={1}
						mb="10px">
						<img src={downArrow} alt="arrow" style={{ height: "12px" }} />
					</Flex>
				</Flex>
				<Emoji />
			</Flex>

			{/* <GiphyStickers /> */}
		</Flex>
	);
};

export default Stickers;
