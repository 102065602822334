import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flex, InputGroup, Text, InputLeftElement, Input, Grid, useToast } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { theme } from "../../../config/theme/baseDesign";
import { Datum } from "../../../types/ApiTypex/Giphy";
import { debounce } from "../../../utils/debounce";
import { Giphy } from "../../../utils/Api/Giphy";
import { useEasyActions, useEasyState } from "../../../store/hooks";
import { history } from "../../../app/router/history";
import { AddCommand } from "../../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import downArrow from "../../../assets/images/Chevron.svg";
import { basePath } from "../../../constants";

export interface MobileGIFProps {}

const MobileGIF: React.FC<MobileGIFProps> = (props) => {
	const { executeCommand, setSelectedElementsIds, setIsReplaceable } = useEasyActions((store) => store.canvasStore);
	const { selectedElementId, selectedElement, konvaTransformerInstance, canvasHWS } = useEasyState((store) => store.canvasStore);
	const scrollParentDown = useRef<null | HTMLDivElement>(null);
	const [text, setText] = useState("nature");
	const [apiRes, setApiRes] = useState<Datum[] | null>();
	const gifRef = useRef(null);
	const inputRef = useRef<null | HTMLInputElement>(null);
	const toast = useToast();
	const [hasMore, setHasMore] = useState(false);
	const [limit, setLimit] = useState(10);
	let apiKey = "4EqYUwuzIG9S3YYEitymw3bgwiN8m5ic";
	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;
	function onChangeTextHandler(text: string) {
		getGIFs(text);
	}

	async function getGIFs(text: string) {
		const response = await Giphy(limit, text, apiKey);

		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}
		setApiRes(response.data.data);
		setHasMore(response.data.data.length > 0);
	}
	const onChangeInputTextDebounce = useCallback(
		debounce((t: string) => {
			setApiRes([]);
			setLimit(10);
			setText(t);
			onChangeTextHandler(t);
		}, 500),
		[],
	);

	function programmaticallyScrollDown(dir: "down") {
		if (!scrollParentDown.current) {
			return;
		}
		scrollParentDown.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}

	const observer = useRef<IntersectionObserver | null>(null);
	const lastImgRef = useCallback(
		(node) => {
			// console.log(node);
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setLimit((limit) => limit + 10);
				}
			});
			if (node) {
				observer.current.observe(node);
			}
		},
		[hasMore],
	);

	useMemo(() => onChangeTextHandler(text), [limit, text]);

	useEffect(() => {
		getGIFs("nature");
		setLimit(10);
		return () => {
			//your cleanup code codes here
			setApiRes([]);
		};
	}, []);

	function addGif(url: string, h: number, w: number) {
		const shrink = 300 / Math.max(h, w);
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Gif",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				url: url,
				height: h * shrink,
				width: w * shrink,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
		// setImage(imageRef.current);
	}

	return (
		<Flex mt="10px" mx="10px" flexDir="column">
			<Flex flex={1} flexDir="column">
				<Flex justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							history.push(basePath + "/design");
							setSelectedElementsIds([]);
							konvaTransformerInstance?.nodes([]);
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Text fontSize="18px" fontWeight="600" mb="10px">
					Select GIF
				</Text>
				<Flex flex={1}>
					<InputGroup>
						<InputLeftElement pointerEvents="none" mt="10px" children={<FiSearch size={20} />} />
						<Input
							my="5px"
							type="text"
							h="50px"
							// ref={inputRef}
							// value={text}
							onChange={(e) => {
								setText(e.target.value);
								onChangeInputTextDebounce(e.target.value);
								// console.log("e.target.value", e.target.value, text);
							}}
						/>
					</InputGroup>
				</Flex>

				<Text color="gray" my="10px">
					Browse Giphy
				</Text>
			</Flex>
			<Flex overflow="auto" ref={scrollParentDown} flexDir="column" height="200px">
				<Flex flex={1} justifyContent="space-around" m="2px" id="gridImg" flexWrap="wrap">
					{apiRes?.map((item, index) => {
						//debugger;
						let url = item?.images?.downsized_medium.url;
						let width = item.images.downsized_medium.width;
						let height = item.images.downsized_medium.height;
						// console.log("url", url);
						return (
							<Flex
								w={["110px", "130px", "130px"]}
								h={["110px", "130px", "130px"]}
								m="10px"
								_hover={{
									boxShadow: "0px 0px 2px 1px #0095a8",
									transition: "transform .3s ease-out",
								}}
								ref={lastImgRef}
								cursor="pointer"
								key={index}>
								<img
									onClick={() => {
										addGif(url, Number(height), Number(width));
									}}
									ref={gifRef}
									style={{
										objectFit: "cover",
										width: "100%",
										height: "100%",
									}}
									src={url}
								/>
							</Flex>
						);
					})}
				</Flex>
			</Flex>
			<Flex
				aria-label="Search database"
				onClick={() => programmaticallyScrollDown("down")}
				id="scrollIcon"
				cursor="pointer"
				justifyContent="center"
				flex={1}
				my="10px">
				<img src={downArrow} alt="arrow" style={{ height: "12px" }} />
			</Flex>
		</Flex>
	);
};

export default MobileGIF;
