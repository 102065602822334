import { action } from "easy-peasy";
import { UnrenderedStore } from "../../types/canvasStoreTypes/StoreTypes";

export const unrenderedStore: UnrenderedStore = {
	unrenderedDes: "This is an unrendered project",
	setUnrenderedDes: action((state, payload) => {
		state.unrenderedDes = payload;
	}),
	unrenderedTitle: "Unrendered Project",
	setUnrenderedTitle: action((state, payload) => {
		state.unrenderedTitle = payload;
	}),
	unrenderedCanvasId: null,
	setUnrenderedCanvasId: action((state, payload) => {
		state.unrenderedCanvasId = payload;
	}),
	unrenderedList: [],
	setUnrenderedList: action((state, payload) => {
		state.unrenderedList = payload;
	}),
};
