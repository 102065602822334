import { Button } from "@chakra-ui/button";
import { Input, InputGroup } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import { useEasyActions, useEasyState } from "./store/hooks";
import { TokenService } from "./utils/token";
import { updateName } from "./utils/Api/UpdateName";
import { useToast } from "@chakra-ui/react";
import { cancleSubscription } from "./utils/Api/CancleSubscription";
import { currentUserDetailsApi } from "./utils/Api/currentUserApi";
import CustomButton from "../../CustomButton";
export interface AccountSettingProps { }

const AccountSetting: React.FC<AccountSettingProps> = (props) => {
	const { allSubs, userEmail, currentUserDetails, permissionsData, bankCard, cardId, subscriptionDate, stripeCardId } = useEasyState(
		(state) => state.userDataStore,
	);
	const { setToken, setUserEmail, setCurrentUserDetails, setBankCards, setPermissionsData } = useEasyActions((state) => state.userDataStore);
	const [name, setName] = useState<string | null>(TokenService.getName());
	const [isEditAble, setIsEditAble] = useState<boolean>(false);
	const toast = useToast();
	let token = TokenService.getToken();
	async function updateNameApi(name: string) {
		let res = await updateName(name);
		if (res instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some error please try again",
			});
			return;
		}
		// console.log(res.data.data.attributes.full_name);
		// console.log(res.data.data.attributes.email);
		TokenService.setUserNAME(res.data.data.attributes.full_name);
		toast({
			status: "success",
			description: "Changed successfully",
		});
	}

	useEffect(() => {
		currentUserDetailsApiData();
	}, []);

	async function currentUserDetailsApiData() {
		if (!token) return;
		let response = await currentUserDetailsApi();
		if (response instanceof Error) return;
		// console.log(response);

		let currentUserData = response.data.data.attributes.subscriptions.find((sp) => sp.status === "active");
		let bankCard = response.data.data.attributes.bank_cards[0];
		setCurrentUserDetails(currentUserData);
		setBankCards(bankCard);
		setUserEmail(response.data.data.attributes.email);
		setPermissionsData(response.data.data.attributes.permissions);
		// console.log("currentUserDetails?.subscription_plan?.name", currentUserDetails?.subscription_plan?.name);
	}
	function remainingTimeSec() {
		let getRemTime = (Math.floor(Number(permissionsData?.remaining_monthly_seconds)) / 60).toFixed(2);
		let timeWithoutPoint = Number(getRemTime).toFixed(0);
		// console.log(timeWithoutPoint, "num");
		let subtractedTime = Number(getRemTime) - Number(timeWithoutPoint);
		let sec = subtractedTime * 60;
		return sec.toFixed(0);
	}

	function getMonthName(date) {
		return date.toLocaleString('en-US', { month: 'long' });
	}

	function subDate() {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth();
		let nextYear = currentYear;
		let nextMonth = currentMonth + 1;
		if (nextMonth > 11) {
			nextMonth = 0;
			nextYear++;
		}
		const startDate = new Date(nextYear, nextMonth, 1);
		return startDate;
	}
	return (
		<Flex flexDir="column" h="100vh" background={theme.colors.primary} opacity="1">
			<Flex pr="6px" justifyContent="flex-end" h="30px" w="100%" m="30px 50px 0 0">
				<img src={Logo} />
			</Flex>
			<Flex justifyContent="center" alignItems="center" flex={1}>
				<Flex h="30rem" w={["20rem", "24rem", "26rem", "28rem"]} background="white" borderRadius="5%">
					<Flex w="100rem" flexDir="column" justifyContent="center" mx="3rem">
						<Flex justifyContent="center" alignItems="center">
							<Flex  flexDir="column" alignItems="center">
								<Text fontSize="2rem" fontWeight="600" mb="10px">
									{currentUserDetails?.subscription_plan?.name ? currentUserDetails?.subscription_plan?.name : "Free Plan"}
								</Text>
								<Flex alignItems="center" flexDir="row" flex={1} mb="15px" >
									{currentUserDetails && subscriptionDate ? (
										<Text lineHeight="16px" fontSize="12px">
											You have {(Math.floor(Number(permissionsData?.remaining_monthly_seconds)) / 60).toFixed(0)} min{" "} {remainingTimeSec()} sec remaining Resets on
											<b>
												{subDate()?.getDay()}, {subDate()?.getMonth()} {subDate()?.getFullYear()}
											</b>
										</Text>
									) : (
										<Text lineHeight="16px" mt="15px" fontSize="12px">
											You have{" "}
											<Text as="mark">
												{Number(permissionsData.minutes_per_month) / 60} minutes remaining
											</Text>{" "}
											Resets on{" "}
											<Text as="mark">
												{subDate()?.getDay()}, {getMonthName(subDate())} {subDate()?.getFullYear()}
											</Text>
										</Text>
									)}
								</Flex>

								{!currentUserDetails?.subscription_plan?.name || currentUserDetails?.subscription_plan?.name === "free plan" ? (
									<a href="https://www.ampifi.co/pricing-upgrade">
										<CustomButton
											background={theme.colors.primary}
											color="white"
											_hover={{ bg: 'darkblue' }}
										>
											Upgrade your account
										</CustomButton>
									</a>
								) : (
									<a href="https://www.ampifi.co/pricing-upgrade">
										<CustomButton
											background={theme.colors.primary}
											color="white"
											_hover={{ bg: 'darkblue' }}
										>
											Downgrade your account
										</CustomButton>
									</a>
								)}
							</Flex>
						</Flex>

						<Flex flexDir="column" my="20px">
							<Flex>
								<Text flex={1} fontSize="13px" color="#292d34" mb="10px" fontWeight="600" lineHeight="11px" padding="0 0 8px 0">
									Name
								</Text>
								<Flex flex={1} justifyContent="flex-end" alignItems="flex-end">
									{!isEditAble ? (
										<Text
											onClick={() => setIsEditAble(true)}
											cursor="pointer"
											fontSize="14px"
											color={theme.colors.primary}
											mb="10px"
											padding="0 0 8px 0">
											Edit
										</Text>
									) : (
										<Text
											onClick={() => {
												if (!name) return;
												updateNameApi(name);
												setIsEditAble(false);
											}}
											cursor="pointer"
											fontSize="14px"
											color={theme.colors.primary}
											mb="10px"
											padding="0 0 8px 0">
											Save
										</Text>
									)}
								</Flex>
							</Flex>

							<InputGroup>
								<Input
									letterSpacing="0.9px"
									fontWeight="400"
									border="none"
									fontSize="14px"
									w="100%"
									type="text"
									value={!name ? "" : name}
									borderBottom={isEditAble ? "1px solid #bdbdbd" : "none"}
									onChange={(e) => {
										if (isEditAble) {
											setName(e.target.value);
										}
									}}
									placeholder="Enter your email"
								/>
							</InputGroup>
						</Flex>
						<Flex flexDir="column" marginBottom="20px">
							<Text fontSize="13px" color="#292d34" mb="10px" fontWeight="600" lineHeight="11px" padding="0 0 8px 0">
								Email
							</Text>
							<InputGroup>
								<Input
									letterSpacing="0.9px"
									fontWeight="400"
									border="none"
									fontSize="14px"
									w="100%"
									type="text"
									value={!userEmail ? "No Data" : userEmail}
									onChange={(e) => { }}
									placeholder="Enter your email"
								/>
							</InputGroup>
						</Flex>
						<Button
							mb="5px"
							h="3rem"
							onClick={async () => {
								currentUserDetailsApiData();
								if (currentUserDetails?.subscription_plan.name == null || bankCard?.stripe_id == null) return;

								const subscription = allSubs.find((sub) => sub.attributes.name === currentUserDetails?.subscription_plan.name);

								if (subscription == null) {
									// console.log("Unable to find the subscription");

									return;
								}

								let id = Number(subscription.id);
								let res = await cancleSubscription(id, bankCard.stripe_id);
								await currentUserDetailsApiData();
								if (res instanceof Error) {
									toast({
										status: "error",
										title: "Error!",
										description: "There is some error please try again",
									});
									return;
								}
								toast({
									status: "success",
									description: "Canceled Subscription",
								});
							}}
							background='red'
							color="white"
							>
							Remove your account
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default AccountSetting;
