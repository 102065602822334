import React from 'react';

import PAUSE_SRC from './icons/pause.svg';
import PLAY_SRC from './icons/play.svg';

import styles from './styles.module.scss';

type Props = {
  onTogglePlay: () => void,
  isPlaying: boolean,
};

export default (props: Props) => (
  <div className="d-flex justify-content-center">
    <button
      className={`bg-transparent border-0 p-0 ${styles['playback-btn']}`}
      onClick={props.onTogglePlay}
    >
      {props.isPlaying ?
        <img src={PAUSE_SRC} alt="pause" /> :
        <img src={PLAY_SRC} alt="play" />
      }
    </button>
  </div>
);
