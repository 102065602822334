import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { State } from "easy-peasy";

export class ChangeZIndexCommand implements Command<State<CanvasStore>, "ChangeZIndex"> {
    name = "ChangeZIndex" as const;
    constructor(public elementId: string, public position: "Up" | "Down") {}

    execute(state: State<CanvasStore>): void {
        if (this.position === "Up") {
            this.getElement(state).moveUp();
            return;
        }

        this.getElement(state).moveDown();
    }
    undo(state: State<CanvasStore>): void {
        if (this.position === "Up") {
            this.getElement(state).moveDown();
            return;
        }

        this.getElement(state).moveUp();
    }

    getElement(state: State<CanvasStore>) {
        const element = state.cameraGroupInstance?.children?.find((e) => e.attrs["data-id"] === this.elementId);

        if (!element) throw new Error("This element does not exit.");

        return element;
    }
}
