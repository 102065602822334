import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { allSubscriptionsResponse } from "../../types/ApiTypex/AllSubscriptionsType";

export async function getAllSubscriptions() {
	let token = TokenService.getToken();

	return await catchTry(
		mainAxios.get<allSubscriptionsResponse>(`${defaultOptions.baseURL}/api/subscriptions`, {
			headers: {
				Authorization: token,
			},
		}),
	);
}
