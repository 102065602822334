import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text, Spacer } from "@chakra-ui/layout";
import React, { useEffect, useState, useCallback } from "react";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import stepTwo from "./assets/images/step_two.svg";
import PrimaryLogo from "./assets/images/primaryLogo.svg";
import GoogleLogo from "./assets/images/google_logo.svg";
import MicrosoftLogo from "./assets/images/microsoft.svg";
import { signup } from "./utils/Api/signup";
import { useToast } from "@chakra-ui/react";
import { history } from "./app/router/history";
import { TokenService } from "./utils/token";
import { useQuery } from "./store/hooks/useQuery";
import { basePath } from "./constants";
import { Link } from "react-router-dom";
import { roleTypesArr } from "./types";
import NavigationBar from "./NavigationBar";
import useScreenWidth from "./components/useScreenWidth";
export interface SignupProps {}

const SignUpWithEmail: React.FC<SignupProps> = (props) => {
	const toast = useToast();
  const screenWidth = useScreenWidth();
	const [email, setEmail] = useState<string | null>(null);
	const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
	// const [selectedRole, setSelectedRole] = useState<string>(roleTypesArr[0]);
	const [password, setPassword] = useState<string | null>(null);
	const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
	let queryParams = useQuery();
	let token = TokenService.getToken();
	let canvaStep = TokenService.getCanvaStep();

	useEffect(() => {
		// if (!token) {
		// debugger;	
		// 	history.push(`${basePath}/login}`);
		// }

		if (queryParams.get("sub") != null && token) {


			history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
		}
	}, []);

	async function signupInfo(email: string, pasward: string, confirm: string) {

		let response = await signup(email, pasward, confirm);
		// console.log("response", response);
		debugger;
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "Password mismatch or email already registered",
			});
			return;
		}

		// TokenService.setToken(response.data.data.attributes.authentication_token);
		// TokenService.setUserNAME(response.data.data.attributes.full_name);
		// TokenService.setRole(String(Boolean(response.data.data.attributes.admin)));
		// TokenService.setRole("" + role);
		toast({
			status: "success",
			description: "You have signed up successfully!",
		});
		history.push(basePath + "/holding", { email });
		// if (queryParams.get("sub") !== null && response?.data?.data?.attributes?.authentication_token) {
		// 	// history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
		// 	history.push(basePath + canvaStep);
		// } else {
		// 	history.push(basePath + canvaStep);
		// }
	}

	return (
		<>
		<NavigationBar />
		<Flex id="parentContainer" flexWrap='wrap' justifyContent="flex-end" /* Set to row direction */
			// h="100vh" 
			background={theme.colors.white} opacity="1"
		>

			<Flex h="100%" w="100%" 
			// m="35px 50px 0 20px" 
			background={theme.colors.white} gap='10px' justifyContent='space-evenly'>
				
				{screenWidth <= 955 ? <> </> :
				<Flex h="80%" w="45%" m="35px 50px 0 0" justifyContent='center' background={theme.colors.white}>
					<Flex h="500px" w="500px" 
					// m="50px 0 0 0" 
					background={theme.colors.white}>
						<img style={{ color: "#ffffff" }} src={stepTwo} />
					</Flex>
				</Flex>
			    }
				<Flex h="70%" w="45%" 
				// m="35px 0 0 0"
				 background={theme.colors.white} justifyContent='center'>
					<Flex
						h="650px" w="560px" 
						// m="50px 0 0 0"
						background={theme.colors.white}
						borderRadius={"35px"}
						justifyContent='center'
						flexWrap='wrap'
					>

						<Flex
							h="52px" w="430px" 
							m="15px 0 0 0"
							background={theme.colors.white}
							borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
							<Text variant="xxxlg">
								Sign up
							</Text>
						</Flex>

						<Flex h="52px" w="419px" m="30px 0 50px 0"
							background={theme.colors.white}>
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
							</Text>

							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdMailOutline opacity="0.4" color="#292d34" size="25px" />} />
								<Input
									letterSpacing="0.9px"
									fontWeight="400"
									fontSize="14px"
									variant='flushed'
									w="100%"
									type="text"
									borderBottomWidth='1px'
									borderColor={theme.colors.black}
									color="black"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									placeholder="example@gmail.com"
								/>
							</InputGroup>
						</Flex>
						<Flex h="52px" w="419px" m="30px 0 50px 0"
							background={theme.colors.white}>
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
							</Text>

							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdLockOutline opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="password"
									variant="flushed"
									borderBottomWidth='1px'
									borderColor={theme.colors.black}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder="password"
								/>
							</InputGroup>
						</Flex>
						<Flex h="52px" w="419px" m="30px 0 50px 0"
							background={theme.colors.white}>
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdLockOutline opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="password"
									variant="flushed"
									borderBottomWidth='1px'
									borderColor={theme.colors.black}
									onChange={(e) => {
										setConfirmPassword(e.target.value);
									}}
									placeholder="Confirm Password"
								/>
							</InputGroup>
						</Flex>
						<Flex
							h="52px" w="409px" m="30px 0 0 0"
							background={theme.colors.white}
							// border={`3px solid ${theme.colors.primary}`}
							// borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
							<Button
								h="100%"
								w="100%"
								background={theme.colors.secondary}
								borderRadius={"10px"}
								border={`3px solid ${theme.colors.black}`}
								color={`${theme.colors.black}`}
								_hover={{
									bg: theme.colors.secondary,
								}}
								onClick={async () => {
									if (email === null || password === null || confirmPassword == null) {
										toast({
											status: "info",
											title: "Alert!",
											description: "please fill the following option",
										});
										return;
									}
									if (!(password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/))) {
										toast({
											status: "info",
											title: "Alert!",
											description: "Password should contain capital, small and alphanumeric character",
										});
										return;
									}
									if (password != confirmPassword) {
										toast({
											status: "info",
											title: "Alert!",
											description: "Password and confirm password should match.",
										});
										return;
									}
									if (password.length < 6) {
										toast({
											status: "info",
											title: "Alert!",
											description: "Password should contain atleast six character",
										});
										return;
									}
									await signupInfo(email, password, confirmPassword);
								}}>
								<Flex
									h="25px" w="100%" m="0 0 0 0"
									alignItems={'center'}
									justifyContent='center'
								>
									<Text variant="xlg" color={theme.colors.black}>
										Continue
									</Text>

								</Flex>
								{/* Sign Up */}
							</Button>
						</Flex>

						<Flex
							h="110px" w="400px" m="25px 0 10px 0"
							justifyContent='center'
						>
							<Flex
								h="100px" w="389px" m="0 0 0 25px"
								justifyContent='center'
								flexWrap='wrap'
							>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
		</>
	);
};

export default SignUpWithEmail;