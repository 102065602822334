import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { RecordApiRes } from "../../types/ApiTypex/CreateRecordTypes";
import { stringify } from "uuid";

export async function CreateRecord(file: Blob, regionEnd: number, regionStart: number) {
	var bodyFormData = new FormData();
	let startTrim = regionStart.toString();
	let endTrim = regionEnd.toString();
	bodyFormData.append("record[original_audio]", file);
	bodyFormData.append("record[start_trim]", startTrim);
	bodyFormData.append("record[end_trim]", endTrim);

	return await catchTry(
		mainAxios.post<RecordApiRes>(`${defaultOptions.baseURL}/api/records`, bodyFormData, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		}),
	);
}
