import React from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { store } from "../../store";
import WaveSurfer from "wavesurfer.js";
import { ActionCreator } from "easy-peasy";

let chunks: any = [];
let aStream: MediaStream | null = null;
let vid: HTMLAudioElement | null = null;
let recorder: MediaRecorder | null = null;
// let canvas = document.querySelector(".konvajs-content canvas") as HTMLCanvasElement | null;
let canvas = document.querySelector<HTMLCanvasElement>(".konvajs-content canvas");

export function loadVideo(
	click: boolean,
	canvas: HTMLCanvasElement,
	waveSurfer: WaveSurfer,
	setIsPlaying: ActionCreator<boolean>,
	audioStream: MediaStream,
) {
	let audioBlob = store.getState().audioStore.resultAudio;

	if (audioBlob == null) {
		// console.log("audioBlob is null");

		return;
	}

	let audioUrl = URL.createObjectURL(audioBlob);
	if (!audioUrl) return;
	vid = document.createElement("video");
	vid.crossOrigin = "anonymous";

	vid.oncanplay = () => {
		if (!vid) return;
		alert("Can start playing video");
		initAudioStream(vid, click, canvas, waveSurfer, setIsPlaying, audioStream);
	};
	vid.src = audioUrl;
}
function initAudioStream(
	vid: HTMLMediaElement,
	click: boolean,
	canvas: HTMLCanvasElement,
	waveSurfer: WaveSurfer,
	setIsPlaying: ActionCreator<boolean>,
	audioStream: MediaStream,
) {
	if (!WaveSurfer) return null;
	//@ts-ignore
	let audioCtx = new AudioContext();
	var sourceNode = audioCtx.createMediaElementSource(vid);
	let dest = audioCtx.createMediaStreamDestination();
	// connect our video element's output to the stream
	sourceNode.connect(dest);
	aStream = dest.stream;
	clickHandler(click, canvas, waveSurfer, setIsPlaying, audioStream);
	vid.play();
}
export function clickHandler(
	click?: boolean,
	canvas?: HTMLCanvasElement,
	waveSurfer?: WaveSurfer,
	setIsPlaying?: ActionCreator<boolean>,
	audioStream?: MediaStream,
) {
	debugger;
	// let audioStream = store.getState().visualizationStore.audioStream;
	// let setIsPlaying = store.getActions().visualizationStore.setIsPlaying;
	// let waveSurfer = store.getState().visualizationStore.waveSurfer;

	// const { setIsPlaying } = useEasyActions((store) => store.visualizationStore);

	// this.textContent = "stop recording";
	//debugger;
	if (!setIsPlaying) {
		//debugger;
		return;
	}
	setIsPlaying(true);
	waveSurfer?.play();

	// if (!audioStream) {
	//     //debugger;
	//     return;
	// }

	if (!canvas) {
		return;
	}
	// if (!aStream) {
	//     return;
	// }

	// console.log("running");

	let cStream = canvas.captureStream(30);
	// cStream.addTrack(aStream.getAudioTracks()[0]);

	// var mixedStream = "MediaStream" in window ? new MediaStream([cStream.getVideoTracks()[0], audioStream.getAudioTracks()[0]]) : cStream;

	// recorder = new MediaRecorder(mixedStream);
	recorder = new MediaRecorder(cStream);
	if (click === false) {
		stopRecording();
		waveSurfer?.pause();
	}

	//@ts-ignore
	recorder.start(1000);
	recorder.ondataavailable = (e) => {
		saveChunks(e);
		// console.log("getdata");
	};
	recorder.onstop = exportStream;

	// this.onclick = stopRecording;
}
function stopRecording() {
	// if (!vid) return;
	// vid.pause();
	// // this.parentNode.removeChild(this);
	if (!recorder) return null;

	//@ts-ignore
	recorder.stop();
	// exportStream();
}
function saveChunks(e: any) {
	// console.log(e, "e");
	e.data.size && chunks.push(e.data);
	// console.log("runningsaveChunks");
}

function exportStream() {
	// console.log("runningexportStream");

	if (chunks.length) {
		let blob = new Blob(chunks);
		let vidURL = URL.createObjectURL(blob);

		const a = document.createElement("a");
		document.body.appendChild(a);
		// a.style = "diaplay:none";
		a.href = vidURL;
		a.download = "test.webm";
		a.click();
	} else {
		document.body.insertBefore(document.createTextNode("no data saved"), canvas);
	}
}

export function record(canvas: HTMLCanvasElement, time: number) {
	let recordedChunks: any = [];
	debugger;
	return new Promise(function (res, rej) {
		var stream = canvas.captureStream(25 /*fps*/);
		//@ts-ignore
		let mediaRecorder = new MediaRecorder(stream, {
			mimeType: "video/webm; codecs=vp9",
		});

		//ondataavailable will fire in interval of `time || 4000 ms`
		//@ts-ignore
		mediaRecorder.start(time || 4000);
		// console.log("started");

		mediaRecorder.ondataavailable = function (event) {
			recordedChunks.push(event.data);
			// after stop `dataavilable` event run one more time
			//@ts-ignore
			if (mediaRecorder.state === "recording") {
				//@ts-ignore
				mediaRecorder.stop();
			}
		};

		//@ts-ignore
		mediaRecorder.onstop = function (event) {
			var blob = new Blob(recordedChunks, { type: "video/webm" });
			var url = URL.createObjectURL(blob);
			res(url);
		};
	});
}
