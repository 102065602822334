import React from "react";
import { Group, Rect } from "react-konva";

export interface StraightProgressBarProps {
	isStatic: boolean;
	ele: any;
	videoFinishRatio: number;
}

const StraightProgressBar: React.FC<StraightProgressBarProps> = (props) => {
	// console.log(props.videoFinishRatio * 100);
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}

	return (
		<Group
			{...extra}
			data-id={props.ele.id}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			opacity={props.ele.opacity}
			key={props.ele.id}
			x={props.ele.x}
			y={props.ele.y}
			rotation={props.ele.rotationDeg}
			height={props.ele.height}
			width={props.ele.width}>
			<Rect height={props.ele.height} width={props.ele.width} fill={props.ele.fill}></Rect>
			<Rect height={props.ele.height} width={props.videoFinishRatio * props.ele.width} fill={props.ele.fillBar}></Rect>
		</Group>
	);
};

export default StraightProgressBar;
