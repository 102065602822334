import React from "react";
import ReactDOM from "react-dom";

import { WaveSurferType } from "../../types/index";

import Cursor from "./cursor";

export const insertNameElement = (name: string) => {
	//debugger;
	// TODO rewrite using react.dom
	let nameNode = document.querySelector(".file-name");

	if (!nameNode) {
		nameNode = document.createElement("p");
		nameNode.classList.add("file-name", "text-light", "p-3");

		const regionNode = document.querySelector("region");

		if (regionNode == null) {
			throw new Error("there is no region element");
		}
		regionNode.appendChild(nameNode);
	}

	nameNode.textContent = name;
};

export const insertTimelineMiddle = () => {
	// TODO rewrite using react.dom
	const isExist = document.querySelector("#timeline timeline .timeline-middle");

	if (isExist) return;

	const timeline = document.querySelector("#timeline");
	if (timeline == null) {
		throw new Error("there is no timeline id exist");
	}

	const middleLine = document.createElement("div");

	middleLine.classList.add("timeline-middle");
	timeline.appendChild(middleLine);
};

export const insertDraggableCursor = (wavesurfer: WaveSurfer) => {
	const playbackContainer = document.querySelector("wave > wave");

	ReactDOM.render(<Cursor wavesurfer={wavesurfer} />, playbackContainer);
};
