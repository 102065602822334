import React, { useEffect, useState, useMemo, useRef } from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.js";
import { audioStore } from "../../store/AudioStore/AudioStore";
import { debounce } from "../../utils/debounce";
import { copy, cut } from "../../utils/Audio/manipulate";
import {
  insertDraggableCursor,
  insertNameElement,
  insertTimelineMiddle,
} from "./insert-elements";
import { WaveSurferType } from "../../types";
import { IS_MOBILE } from "../../utils/responsive";
import styles from "./styles.module.scss";
import AudioControls from "./controls";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Text,
  FormGroup,
  InputGroup,
  Classes,
  Position,
  Props,
} from "@blueprintjs/core";
import { sleep } from "../../utils/index";
import { history } from "../../app/router/history";
import { CreateRecord } from "../../utils/Api/CreateRecord";
import { basePath } from "../../constants";
import { handlesAudio } from "./WaveSurferAudioFormation";
import { store } from "../../store";
import { fontSize } from "../../config/theme/Helper";
import useScreenWidth from "../useScreenWidth";
import UploadText from "../Text/UploadText";
import { Box, Textarea } from "@chakra-ui/react";
import { TokenService } from "../../utils/token";

export const waveSurferInitOptions = {
  cursorColor: "transparent",
  waveColor: "transparent",
  progressColor: "transparent",
  cursorWidth: 2,
  mediaControls: true,
  loopSelection: true,
  scrollParent: true,
  skipLength: 1,
};

export const timelineInitOptions = {
  container: "#timeline",
  primaryColor: "#6C6C6C",
  secondaryColor: "transparent",
};

export interface AudioPlayerProps {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void; 
}
const regionId = "Trim";

const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
  const {
    fadeInOn,
    fadeOutOn,
    originalAudio,
    originalAudioUrl,
    fileName,
    regionStart,
    regionEnd,
    result,
    original,
    resultAudio,
    AudioFile,
  } = useEasyState((state) => state.audioStore);
  const {
    setAudioFile,
    setFadeInOn,
    setFadeOutOn,
    setRegionStart,
    setRegionEnd,
    setFileName,
    setOriginalAudio,
    setResultAudio,
    setOriginalAudioUrl,
    setResultAudioUrl,
    setInputRef,
    setOriginal,
    setResult,
    resetToNull,
    setIsValidAudio,
  } = useEasyActions((store) => store.audioStore);
  const [duration, setDuration] = useState(0);
  const [name, setName] = useState(fileName || "");
  const [isRegionCreated, setRegionCreated] = useState(false);
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);

  const {
    setWaveSurfer: setWaveSurferStore,
    setCurrentTime,
    setSubtitles,
  } = useEasyActions((store) => store.visualizationStore);
  const { inputRef, isValidAudio } = useEasyState((store) => store.audioStore);
  const { setAudioRecordId } = useEasyActions((store) => store.userDataStore);
  const { permissionsData } = useEasyState((store) => store.userDataStore);
  const {
    currentTime: stateCurrentTime,
    subtitles,
    waveSurfer: waveSurferStore,
  } = useEasyState((store) => store.visualizationStore);
  const waveformRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    waveSurferStore?.pause();
    setWaveSurfer(null);
  }, []);

  window.waveSurfer = waveSurfer;

  const isSmall = useMediaQuery({ maxWidth: 993 });

  const trimTime = () => {
    if (waveSurfer == null) {
      return;
    }

    const currentTime = waveSurfer.getCurrentTime();

    const region = waveSurfer.regions.list[regionId as any];
    const isEnd = currentTime >= region.end;
    const isBeforeStart = currentTime < region.start;
    setCurrentTime(currentTime);
    if (isBeforeStart) {
      waveSurfer.setCurrentTime(region.start);

      return;
    }

    if (isEnd) {
      waveSurfer.pause();
      waveSurfer.setCurrentTime(region.start);
    }
  };
  const handleUpdateConfig = debounce(() => {
    if (!waveSurfer) return;

    let trimmedBlob;
    const region = waveSurfer.regions.list[regionId as any];
    const { start, end } = region;
    const duration = waveSurfer.getDuration();
    const regionDuration = end - start;

    if (duration === regionDuration) {
      trimmedBlob = copy(region, waveSurfer);
    } else {
      trimmedBlob = cut(region, waveSurfer);
    }

    trimmedBlob.name = "result_audio.mp3";

    setResultAudio(trimmedBlob);
    setRegionStart(start);
    setRegionEnd(end);
  }, 500);
  const createRegion = () => {
    if (!waveSurfer) {
      return;
    }

    insertTimelineMiddle();
    insertDraggableCursor(waveSurfer);
    setRegionCreated(false);

    const start = regionStart || 0;
    const end = regionEnd || waveSurfer.getDuration();

    waveSurfer.addRegion({ id: regionId, start, end, color: "#6C6C6C" });

    setDuration(end - start);
    setRegionCreated(true);
    handleUpdateConfig();
  };
  const handleRegionUpdate = () => {
    if (!waveSurfer) {
      return;
    }

    const { start, end } = waveSurfer.regions.list[regionId as any];

    trimTime();
    setDuration(end - start);
    setResult(true);
    handleUpdateConfig();
  };
  const handleChangeFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setFileName(e.target.value);
  };

  async function createAudioRecord(
    file: Blob,
    regionEnd: number,
    regionStart: number
  ) {
    const response = await CreateRecord(file, regionEnd, regionStart);
    if (response instanceof Error) {
      return;
    }
    setAudioRecordId(response.data.data.id);
  }

  useEffect(() => {
    if (!AudioFile) {
      return;
    }
    if (regionEnd == null || regionStart == null) {
      return;
    }
    createAudioRecord(AudioFile, regionEnd, regionStart);
  }, [AudioFile, regionEnd, regionStart]);

  const triggerFileLoad = () => {
    if (inputRef && inputRef) inputRef.click();
  };

  useEffect(() => {
    if (document == null) {
      return;
    }

    const handels = Array.from(
      document?.querySelectorAll<HTMLElement>(
        "#waveform > wave  handle.wavesurfer-handle"
      )
    );

    for (const handel of handels) {
      if (handel == null) return;

      handel.ontouchstart = () => {
        const bottom = document?.querySelector<HTMLElement>("#waveform wave");
        if (bottom == null || bottom.style == null) return;

        bottom.style.overflow = "hidden";
      };

      handel.ontouchend = () => {
        const bottom = document?.querySelector<HTMLElement>("#waveform wave");
        if (bottom == null || bottom.style == null) return;

        bottom.style.overflow = "auto hidden";
      };
    }
  }, [isRegionCreated]);

  useEffect(() => {
    setWaveSurferStore(waveSurfer);
  }, [waveSurfer]);

  useEffect(() => {
    if (waveSurfer && AudioFile) {
      const reader = new FileReader();

      reader.onload = (evt: ProgressEvent<FileReader>) => {
        waveSurfer.pause();

        const blob = new window.Blob([new Uint8Array(evt.target.result)]);

        waveSurfer.loadBlob(blob);

        waveSurfer.setCurrentTime(2);
        waveSurferStore?.pause();
        setWaveSurfer(waveSurfer);
        setWaveSurferStore(waveSurfer);
      };

      reader.onerror = function (err) {
        console.error("An error ocurred reading the file: ", err);
      };

      reader.readAsArrayBuffer(AudioFile);
    }

    if (!waveSurfer && waveformRef.current) {
      const newWave = WaveSurfer.create({
        ...waveSurferInitOptions,
        container: waveformRef.current,
        height: IS_MOBILE ? 130 : 190,
        plugins: [
          RegionsPlugin.create(),
          TimelinePlugin.create(timelineInitOptions),
        ],
      });
      setWaveSurfer(newWave);
      setWaveSurferStore(waveSurfer);
    }
  }, [waveSurfer]);
  useEffect(() => {
    if (waveSurfer && !waveSurfer.regions.list.length && originalAudioUrl) {
      waveSurfer.load(originalAudioUrl);
    }

    if (waveSurfer) {
      waveSurfer.on("ready", () => {
        createRegion();
      });
      waveSurfer.on("region-updated", () => {
        handleRegionUpdate();
      });
      waveSurfer.on("audioprocess", () => {
        trimTime();
      });
    }
  }, [waveSurfer]);
  useEffect(() => {
    if (isRegionCreated) insertNameElement(name);
  }, [name, isRegionCreated]);

  function handleConfigAudio() {
    handlesAudio(
      setAudioFile,
      setRegionStart,
      setRegionEnd,
      setFileName,
      setOriginalAudio,
      setResultAudio,
      setOriginalAudioUrl,
      setOriginal,
      setResult,
      resetToNull,
      setSubtitles,
      waveSurfer,
      inputRef,
      setRegionCreated,
      setName,
      props.setSelectedFile
    );

  }

  useEffect(() => {
    if (inputRef != null) {
      setInputRef(null);
    }
  }, []);

  useEffect(() => {
    let permission =
      store.getState().userDataStore.permissionsData?.max_video_length;
    if (regionEnd && permissionsData && regionStart != null) {
      let num = Number(permission);
      let totalDiff = regionEnd - regionStart;
      let isvalid = totalDiff <= num;
      setIsValidAudio(isvalid);
    }
  }, [regionEnd, permissionsData, regionStart]);
  const screenWidth = useScreenWidth();

  const handleSrtContentChange = (event) => {
    props.setSrtContent(event.target.value);
  };

  const icon = "● ●";

  const transformSrtContent = (content) => {
    const lines = content.split("\n");
    let transformedContent = "";

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      if (!isNaN(line)) {
        continue;
      }

      if (line.includes("-->")) {
        transformedContent += `${icon}\n`;
        continue;
      }

      const cleanedText = line.replace(/<\/?[^>]+(>|$)/g, "");
      transformedContent += `${cleanedText}\n`;
    }

    return transformedContent.trim();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const text = await file.text();
      const transformed = transformSrtContent(text);
      props.setSrtContent(transformed);
    } else {
      alert("Please upload a valid .srt file.");
    }
  };



  return (
    <div style={{ width: "100%" }} className={styles["upload-step"]} key="1">
      <FormGroup fill style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup
            fill
            Intent
            disabled={false}
            placeholder={
              props?.selectedTab === "audio"
                ? "Supported Formats : audio/mpeg, audio/wav, audio/m4a, audio/mp3, audio/aac"
                : "Supported Formats : .srt"
            }
            value={
              name.replace(/[/n/r]/g, "") ||
              `Supported formats: ${
                props?.selectedTab === "audio" ? " MP3, WAV, AAC, FLAC" : ".srt"
              }`
            }
            readOnly
            small={screenWidth < 500}
            style={{
              borderRadius: "7px",
              height: "43px",
              flex: 1,
              boxSizing: "border-box",
            }}
          />
          <input
            ref={(e: HTMLInputElement | null) => {
              if (inputRef != null && e == null) {
                return;
              }
              setInputRef(e);
            }}
            type="file"
            accept={
              props?.selectedTab === "audio"
                ? "audio/mpeg, audio/wav, audio/m4a, audio/mp3, audio/aac"
                : ".srt"
            }
            style={{ display: "none" }}
            onChange={(event) => {
              if (props.selectedTab === "audio") {
                const file = event.target.files[0];
                if (file) {
                  props.setSelectedFile(file); 
                  handleConfigAudio(); 
                  props.handleSubmit(file); 
                }
              } else {
                handleFileUpload(event);
              }
            }}
          />

          <Button
            minimal
            style={{
              marginLeft: "10px",
              background: "none",
              boxShadow: "none",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#0095a8",
              height: "43px",
              color: "white",
              borderRadius: "6px",
              width: "150px",
              fontWeight: 500,
            }}
            onClick={() => {
              triggerFileLoad();
            }}
          >
            Browse
          </Button>
        </div>
      </FormGroup>

      {props?.selectedTab === "audio" ? (
        <div overflow="hidden" p="15px">
          <div
            overflow="auto"
            mb="20px"
            className={`${styles["waveform-content"]} `}
          >
            <div id="timeline" className={styles.timeline} />
            <div ref={waveformRef} id="waveform" position="relative" />
          </div>
          <AudioControls
            waveSurfer={waveSurfer}
            duration={duration}
            regionId={regionId}
          />
          {props?.isLoading ? 
          "Loading...":
         props?.formattedTranscripts?.length > 0 &&  <Box border={"1px solid black"} borderRadius="8px" padding={2}>
         {props?.formattedTranscripts?.map((item:any)=>(
           <Box>
             {item.icon} {item?.transcript}
             </Box>
         ))}
       </Box>
           }
        </div>
      ) : (
        <UploadText
          srtContent={props.srtContent}
          setSrtContent={props.setSrtContent}
          handleSrtContentChange={handleSrtContentChange}
        />
      )}
      {isSmall && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginRight: "40px",
          }}
        >
          {!isValidAudio && originalAudio && (
            <div style={{ marginRight: "10px" }}>
              <Text
                tagName="div"
                style={{
                  fontSize: "12px",
                  color: isValidAudio ? "black" : "red",
                  fontWeight: "500",
                  borderBottom: "1px solid",
                }}
              >
                Audio limit: {permissionsData?.max_video_length} secs
              </Text>
            </div>
          )}
          <Button
            onClick={() => {
              if (!isValidAudio || !originalAudio) {
                return;
              }
              history.push(basePath + "/design");
            }}
            disabled={!isValidAudio || !originalAudio}
            style={{
              opacity: isValidAudio && originalAudio ? "1" : "0.5",
              cursor: "pointer",
            }}
          >
            <Text style={{ fontSize: "16px" }}>Continue</Text>
          </Button>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
