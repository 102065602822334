import CanvasCropTools from "../../components/CanvasCropTools";
import AppLayout from "../../Layout/AppLayout";
import { Box, Text } from "@chakra-ui/layout";
import { Button, Flex, Heading, SimpleGrid, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { ChangeZIndexCommand } from "../../Commands/CanvasCommanda/ChangeZIndexCommand";

export interface Page2Props {}

const Page2: React.FC<Page2Props> = (props) => {
	const { removeElement, updateElement, setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);

	const {
		canvasState,
		selectedElement,
		selectedElementId,
		konvaStageInstance,
		selectedElementsIds,
		konvaTransformerInstance,
		konvaLayerInstance,
		selectedElements,
		selectedElementsInstance,
	} = useEasyState((store) => store.canvasStore);

	let maxWidth = "993px";
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	let canvasTools = (
		<Flex flexDir="row" flex={1} justifyContent="center" alignItems="center" border="1px solid #ff00c8">
			<CanvasCropTools onClick={() => selectedElementId && executeCommand(new ChangeZIndexCommand(selectedElementId, "Up"))} text="Forward" />
			<CanvasCropTools
				onClick={() => selectedElementId && executeCommand(new ChangeZIndexCommand(selectedElementId, "Down"))}
				text="Backward"
			/>
			{/* <CanvasCropTools onClick={() => addGroup() text="Group" />
			<CanvasCropTools text="Duplicate" />
			<CanvasCropTools text="Delete" /> */}
		</Flex>
	);
	let undoRedo = (
		<Flex border="1px" flexDir="row" p="10px" flex={1} justifyContent="center" alignItems="center">
			<Flex border="1px" mr={["8px", "15px", "20px"]}>
				<Text variant="sm">Undo</Text>
			</Flex>
			<Flex border="1px" ml={["8px", "15px", "20px"]}>
				<Text variant="sm">Redo</Text>
			</Flex>
		</Flex>
	);

	return (
		<AppLayout
			maxWidth={maxWidth}
			optionMenuContentMobile={<Flex>This is just a Dummy screen for testing only</Flex>}
			heading="Page  2."
			headingContent={(!isSmall && canvasTools) || undoRedo}
			optionMenuContent={
				<Flex flexDir="column" border="2px solid orange" flex={1}>
					<Heading variant="mdb">Tools</Heading>
				</Flex>
			}>
			<Box border="1px">azsdfadsf</Box>
		</AppLayout>
	);
};

export default Page2;
