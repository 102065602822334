import React, { useMemo } from "react";
import "../assets/css/main.css";
import "react-color-palette/lib/css/styles.css";

import { TokenService } from "../utils/token";
import PublicApp from "./Public";
import AuthApp from "./Auth";
import { useEffect } from "react";
import { useEasyActions } from "../store/hooks/index";
import { history } from "./router/history";
import { basePath } from "../constants";

function App() {
	const { setKonvaInstance } = useEasyActions((state) => state.canvasStore);
	useEffect(() => {
		// window.location.assign(window.location.origin + basePath + "/upload");
		if (![`/video/`, "/login", "/signup"].some((str) => history.location.pathname.includes(str))) {
			let token = TokenService.getToken();
			// debugger;
			if (token) {
				let loginUserName = TokenService.getName();
				// history.push(basePath);
				if (loginUserName == null || loginUserName == 'null') {
					history.push(basePath + "/step_one");
				} else {
					history.push(basePath + "/dashboard");
				}
			} else {
				history.push(basePath + "/login");
			}
			// history.push(basePath + "/upload");
		}
	}, []);
	useEffect(() => {
		setTimeout(() => setKonvaInstance(window.Konva), 300);
	}, [window.Konva]);

	return <AuthApp />;
}

export default App;
