import HomePage from "../pages/Home/HomePage";
import Page2 from "../pages/Home/Page2";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import { Box } from "@chakra-ui/react";
import { useStoreRehydrated } from "easy-peasy";
import { AnimateSharedLayout } from "framer-motion";
import React from "react";
import { Switch, Route } from "react-router-dom";
import CanvasLayout from "../Layout/CanvasLayout";
import AudioPage from "../pages/Audio/AudioPage";
import HomeLayout from "../Layout/HomeLayout";
import Generate from "../pages/Generate/Generate";
import Login from "../Login";
import GeneratedVideo from "../pages/Generate/GeneratedVideo";
import Signup from "../Signup";
import HoldingPage from "../HoldingPage";
import StepOne from "../StepOne";
import StepTwo from "../StepTwo";
import SignUpWithEmail from "../SignUpWithEmail";
import StepThree from "../StepThree";
import StepFour from "../StepFour";
import StepFive from "../StepFive";
import Dashboard from "../components/Dashboard";
import UnrenderedCanvasList from "../pages/Unrendered/unrenderedCanvasList";
import SubscriptionsForm from "../SubscriptionsForm";
import AccountSetting from "../AccountSetting";
import { basePath } from "../constants";

function AuthApp() {
	const isHydrated = useStoreRehydrated();

	if (!isHydrated) {
		return <Box>Loading...</Box>;
	}

	return (
		<AnimateSharedLayout>
			<Switch>
				<Route path="/page2" component={Page2} />
				<Route path={basePath + "/upload"} component={AudioPage} />
				<Route path={basePath + "/Generate"} component={Generate} />
				<Route path={basePath + "/design"} component={HomePage} />
				<Route path={basePath + "/dashboard"} component={Dashboard} />
				<Route path={basePath + "/login"} component={Login} />
				<Route path={basePath + "/signup"} component={Signup} />
				<Route path={basePath + "/sign_up_with_email"} component={SignUpWithEmail} />
				<Route path={basePath + "/holding"} component={HoldingPage} />
				<Route path={basePath + "/step_one"} component={StepOne} />
				<Route path={basePath + "/step_two"} component={StepTwo} />
				<Route path={basePath + "/step_three"} component={StepThree} />
				<Route path={basePath + "/step_four"} component={StepFour} />
				<Route path={basePath + "/step_five"} component={StepFive} />
				<Route path={basePath + "/subscription/:sub"} component={SubscriptionsForm} />
				<Route path={basePath + "/accountsetting"} component={AccountSetting} />
				<Route path={basePath + "/unrendered"} component={UnrenderedCanvasList} />
				<Route path="/video/:videoId" component={GeneratedVideo} />
				<Route component={NotFoundPage} />
			</Switch>
			<div className="fakeParent"></div>
		</AnimateSharedLayout>
	);
}

export default AuthApp;
