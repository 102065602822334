import React from "react";

export interface UnderLineProps {
	color: string;
}

const UnderLine: React.FC<UnderLineProps> = (props) => {
	return (
		<div>
			<svg width="21" height="2" viewBox="0 0 21 2" fill="none" xmlns="http://www.w3.org/2000/svg">
				<line x1="0.304688" y1="1" x2="20.5945" y2="1" stroke={props.color} strokeWidth="2" />
			</svg>
		</div>
	);
};

export default UnderLine;
