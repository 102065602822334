import React from "react";

export interface SelectTempelateModelProps {}

const SelectTempelateModel: React.FC<SelectTempelateModelProps> = (props) => {
	return (
		<div>
			<p>SelectTempelateModel</p>
		</div>
	);
};

export default SelectTempelateModel;
