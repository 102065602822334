import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flex, Grid, Input, InputGroup, InputLeftElement, useToast } from "@chakra-ui/react";
import downArrow from "../../../assets/images/Chevron.svg";
import { Text } from "@chakra-ui/layout";
// import list from "../../../assets/images/svg/list.json";
import { jsx } from "@emotion/react";
import { AddCommand } from "../../../Commands/CanvasCommanda/AddShapes";
import { useEasyActions, useEasyState } from "../../../store/hooks";
import { v4 as uuid } from "uuid";
import OverFlowBox from "../../common/OverFlowBox";
import { emojiAllDataList } from "../../../utils/Api/EmojiAllList";
import { FiSearch } from "react-icons/fi";
import { debounce } from "../../../utils/debounce";
import { emojiNames } from "../../../data/emojiNames";
import { convertImgToBase64URL } from "../../../utils/base64ToImage";
import { gitEmojiList, gitEmojiMap } from "../../../data/gitEmojiObj";

export interface EmojiProps {}
let imageInOneTime = 3 * 6;

const Emoji: React.FC<EmojiProps> = (props) => {
    const scrollParent = useRef<null | HTMLDivElement>(null);
    const [emojiList, setGitEmojiList] = useState<string[]>(gitEmojiList as string[]);

    const [text, setText] = useState("");
    const imageRef = useRef<null | HTMLImageElement>(null);
    const [numberOfImages, setNumberOfImages] = useState(18);
    const { canvasHWS } = useEasyState((store) => store.canvasStore);
    const { permissionsData } = useEasyState((store) => store.userDataStore);
    const { executeCommand } = useEasyActions((store) => store.canvasStore);
    const [apiRes, setApiRes] = useState<string[] | null>();
    const [hasMore, setHasMore] = useState(false);
    let cameraH = canvasHWS.height;
    let cameraW = canvasHWS.width;
    const observer = useRef<IntersectionObserver | null>(null);
    const toast = useToast();
    const lastImgRef = useCallback(
        (node) => {
            // console.log(node);
            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setNumberOfImages((numberOfImages) => numberOfImages + 18);
                }
            });
            if (node) {
                observer.current.observe(node);
            }
        },
        [hasMore]
    );

    function programmaticallyScrollDown(dir: "down") {
        if (!scrollParent.current) {
            return;
        }
        // scrollParent.current.scrollBy(0, 700);
        scrollParent.current.scrollBy({
            top: 100,
            left: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        // loadImages();
        // console.log("loaded");
    }, []);
    function getFilteredData() {
        let filteredData = emojiList.filter((n) => n.includes(text.trim() === "" ? "face" : text));
        let slicedData = filteredData.slice(0, numberOfImages);
        setApiRes(slicedData);
        setHasMore(filteredData.length > 0);
    }
    useMemo(() => getFilteredData(), [numberOfImages, text, emojiList]);

    function addImage(url: string) {
        executeCommand(
            new AddCommand({
                id: uuid(),
                type: "Sticker",
                x: (cameraW / 2) * Math.random(),
                y: (cameraH / 2) * Math.random(),
                scaleX: 1,
                scaleY: 1,
                opacity: 1,
                br: 1,
                url: url,
                height: 45,
                width: 45,
                skewY: 0,
                skewX: 0,
                rotationDeg: 0,
            })
        );
        // setImage(imageRef.current);
    }

    return (
        <Flex flex={1} mx="30px" flexDir="column">
            <Flex flex={1} flexDir="column" ref={scrollParent} overflow="auto">
                <OverFlowBox h="100%">
                    <Text my="20px" variant="md">
                        Select emoji
                    </Text>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none" mt="5px" children={<FiSearch size={20} />} />
                        <Input
                            my="5px"
                            type="text"
                            // ref={inputRef}
                            value={text}
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <Flex justifyContent="center" alignItems="center" flexDir="column">
                        <Grid
                            justifyContent="center"
                            alignItems="center"
                            m="2px"
                            id="gridImg"
                            mt="10px"
                            gridTemplateColumns="repeat(3, 1fr)"
                            columnGap="27px"
                            rowGap="20px"
                            // mb="20px"
                            // h="100%"
                        >
                            {apiRes?.map((img, idx) => {
                                // console.log(img);
                                return (
                                    <Flex ref={lastImgRef} key={img} cursor="pointer" h="40px" w="40px">
                                        <img
                                            onError={() => {
                                                console.count("Image Broken");
                                                const newEmojiList = [...emojiList];
                                                debugger;
                                                newEmojiList.splice(newEmojiList.indexOf(img), 1);
                                                setGitEmojiList(newEmojiList);
                                            }}
                                            ref={imageRef}
                                            onClick={(e) => {
                                                if (!permissionsData.add_emojis) {
                                                    toast({
                                                        status: "info",
                                                        description: "This is available in Pro Plan.",
                                                    });
                                                    return;
                                                }
                                                addImage(gitEmojiMap[img as keyof typeof gitEmojiMap]);
                                            }}
                                            style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            src={gitEmojiMap[img as keyof typeof gitEmojiMap]}
                                        />
                                    </Flex>
                                );
                            })}
                        </Grid>
                    </Flex>
                </OverFlowBox>
            </Flex>

            <Flex
                onClick={() => {
                    programmaticallyScrollDown("down");

                    setNumberOfImages(numberOfImages + imageInOneTime);
                }}
                id="scrollIcon"
                cursor="pointer"
                justifyContent="center"
                my="10px"
            >
                <img src={downArrow} alt="arrow" style={{ height: "12px" }} />
            </Flex>
        </Flex>
    );
};

export default Emoji;
