import { border, Box, Button, Flex, Heading, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { AiOutlineLine } from "react-icons/ai";
import { GiCircle, GiSquare } from "react-icons/gi";
import { IoTriangleOutline } from "react-icons/io5";
import Link from "../common/Link";
import { useEasyActions, useEasyState } from "../../store/hooks/index";
import { v4 as uuid } from "uuid";
import Rectangle from "../../assets/images/Rectangle.svg";
import Circle from "../../assets/images/Ellipse.svg";
import Triangle from "../../assets/images/Polygon.svg";
import Line from "../../assets/images/LineShape.svg";
import { Text } from "react-konva";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { history } from "../../app/router/history";
import { basePath } from "../../constants";

export interface ShapesOptionMenuProps {}

export const ShapesOptionMenu: React.FC<ShapesOptionMenuProps> = (props) => {
	const { addElement, removeElement, executeCommand, setSelectedElementsIds } = useEasyActions((store) => store.canvasStore);
	const { canvasState, selectedElement, selectedElementId, canvasHWS } = useEasyState((store) => store.canvasStore);

	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;

	function addRect() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Rectangle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				height: 40,
				width: 40,
				skewX: 0,
				skewY: 0,
			}),
		);
		// console.log(cameraW / 2);
		// console.log(cameraH / 2);
	}

	function addCircle() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Circle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				height: 40,
				width: 40,
				radius: 20,
				skewX: 0,
				skewY: 0,
				transformProperties: {
					enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"],
				},
			}),
		);
	}

	function addTriangle() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Triangle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				radius: 60,
				sides: 3,
				skewX: 0,
				skewY: 0,
			}),
		);
	}

	function addLine() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Line",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#ffffff",
				stroke: "#35b4f3",
				strokeWidth: 2,
				points: [73, 70, 340, 70],
				skewX: 0,
				skewY: 0,
				transformProperties: {
					enabledAnchors: ["middle-left", "middle-right"],
				},
			}),
		);
	}

	return (
		<Flex flexDir="column" flex={1}>
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end" mr="20px">
					<Flex cursor="pointer" onClick={() => history.push(basePath + "/design")} w="40px" justifyContent="flex-end" mt="10px">
						✖
					</Flex>
				</Flex>
				<Heading mb="30px" ml="30px" variant="mdb">
					Shapes
				</Heading>
			</Flex>

			<Flex flexDir="column" flex={1}>
				<Flex flexDir="row" mx="40px" mt="20px">
					<Flex flexDir="column" onClick={addRect} cursor="pointer" flex={1}>
						<Flex alignItems="center" justifyContent="center">
							<img src={Rectangle} alt="forward" style={{ height: "30px" }} />
						</Flex>
						<Flex mt="10px" justifyContent="center" fontSize={12}>
							Rectangle
						</Flex>
					</Flex>

					<Flex flexDir="column" onClick={addLine} cursor="pointer" alignItems="center" justifyContent="center" flex={1}>
						<Flex alignItems="center" justifyContent="center">
							<img src={Line} alt="forward" style={{ height: "30px" }} />
						</Flex>
						<Box mt="10px" fontSize={12}>
							Line
						</Box>
					</Flex>
				</Flex>

				<Flex flexDir="row" mx="40px" mt="30px">
					<Flex flexDir="column" onClick={addCircle} flex={1} cursor="pointer">
						<Flex alignItems="center" justifyContent="center">
							<img src={Circle} alt="forward" style={{ height: "30px" }} />
						</Flex>
						<Flex mt="10px" fontSize={12} justifyContent="center">
							Circle
						</Flex>
					</Flex>

					<Flex flexDir="column" cursor="pointer" onClick={addTriangle} alignItems="center" justifyContent="center" flex={1}>
						<Flex alignItems="center" justifyContent="center">
							<img src={Triangle} alt="forward" style={{ height: "30px" }} />
						</Flex>
						<Flex mt="10px" fontSize={12}>
							Triangle
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			<Flex justifyContent="center" mb="5px">
				<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled" textDecoration="none">
					Back
				</Button>
			</Flex>
			<Flex justifyContent="center" mb="30px">
				<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled">
					Continue
				</Button>
			</Flex>
		</Flex>
	);
};

// export default ShapesOptionMenu;

export interface ShapesOptionMenuMobileProps {}

export const ShapesOptionMenuMobile: React.FC<ShapesOptionMenuMobileProps> = (props) => {
	const { addElement, removeElement, executeCommand, setSelectedElementsIds } = useEasyActions((store) => store.canvasStore);
	const { canvasState, selectedElement, selectedElementId, canvasHWS } = useEasyState((store) => store.canvasStore);

	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;

	function addRect() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Rectangle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				height: 40,
				width: 40,
				skewX: 0,
				skewY: 0,
			}),
		);
		history.push(basePath + "/design");
	}

	function addCircle() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Circle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				height: 40,
				width: 40,
				radius: 20,
				skewX: 0,
				skewY: 0,
				transformProperties: {
					enabledAnchors: ["middle-left", "middle-right"],
				},
			}),
		);
		history.push(basePath + "/design");
	}

	function addTriangle() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Triangle",
				x: 100 + (cameraW / 2) * Math.random(),
				y: 50 + (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#35b4f3",
				stroke: "#000",
				strokeWidth: 0,
				radius: 60,
				sides: 3,
				skewX: 0,
				skewY: 0,
			}),
		);
		history.push(basePath + "/design");
	}

	function addLine() {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Line",
				x: (cameraW / 2) * Math.random(),
				y: (cameraH / 2) * Math.random(),
				rotationDeg: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				fill: "#ffffff",
				stroke: "#35b4f3",
				strokeWidth: 2,
				points: [73, 70, 340, 70],
				skewX: 0,
				skewY: 0,
			}),
		);
		history.push(basePath + "/design");
	}

	return (
		<Flex flexDir="column" flex={1} my="20px">
			<Heading mb="30px" ml="20px" variant="mdb">
				Shapes
			</Heading>
			<Flex my="20px" flex={1} justifyContent="space-around">
				<Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={addRect}>
					<img src={Rectangle} alt="forward" style={{ height: "70px" }} />
				</Flex>
				<Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={addLine}>
					<img src={Line} alt="forward" style={{ height: "70px" }} />
				</Flex>
				<Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={addTriangle}>
					<img src={Triangle} alt="forward" style={{ height: "70px" }} />
				</Flex>
				<Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={addCircle}>
					<img src={Circle} alt="forward" style={{ height: "70px" }} />
				</Flex>
			</Flex>
		</Flex>
	);
};
