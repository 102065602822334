import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text, Spacer } from "@chakra-ui/layout";
import { Select } from '@chakra-ui/react'
// import { MdArrowDropDown } from '@chakra-ui/icons';
import React, { useEffect, useState, useCallback } from "react";
import { MdLockOutline, MdMailOutline, MdArrowDropDown } from "react-icons/md";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import stepTwo from "./assets/images/step_two.svg";
import PrimaryLogo from "./assets/images/primaryLogo.svg";
import GoogleLogo from "./assets/images/google_logo.svg";
import MicrosoftLogo from "./assets/images/microsoft.svg";
import { completeSignUp } from "./utils/Api/completeSignUp.ts";
import { useToast } from "@chakra-ui/react";
import { history } from "./app/router/history";
import { TokenService } from "./utils/token";
import { useQuery } from "./store/hooks/useQuery";
import { basePath } from "./constants";
import {  useLocation } from "react-router-dom";
import { roleTypesArr } from "./types";

// export interface HoldingPageProps {}

const StepFive = () => {
	const location = useLocation();
	const { formData } = location.state;
	const toast = useToast();
	const [name, setName] = useState<string | null>(null);
	const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
	const [selectedRole, setSelectedRole] = useState<string>(roleTypesArr[0]);
	const [password, setPassword] = useState<string | null>(null);
	const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
	let queryParams = useQuery();
	let token = TokenService.getToken();
	let canvaStep = TokenService.getCanvaStep();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		formData[name] = value;
	};

	async function completeSignup() {
		const fullName = `${formData.firstName} ${formData.lastName}`;
		let response = await completeSignUp(fullName, formData.role, formData.purpose);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "Something went wrong",
			});
			return;
		}
		toast({
			status: "success",
			description: "You have signed up successfully!",
		});
		TokenService.setUserNAME(fullName);
		history.push(basePath + "/dashboard");
	}
	return (
		<Flex id="parentContainer" flexWrap='wrap' justifyContent="flex-end" /* Set to row direction */
			h="100vh" background={theme.colors.white} opacity="1"
		>
			<Flex justifyContent="flex-end" /* Align content to the end */
					h="30px" w="1200px" m="0 50px 0 0" background={theme.colors.white}
			>
				{/* Your content goes here */}
			</Flex>
			<Flex m="35px 100px 0 0" background={theme.colors.white}>

				<Flex m="12px 0 0 100px">
					<Text
						variant="lg"
						cursor="pointer"
						color={theme.colors.gray3}
						onClick={() => {
							history.push(basePath + "/login");
						}}
						display="inline-block">
						{" "}
						Login
					</Text>
				</Flex>
				<Flex m="0 0 0 100px">
					<Button
						h="3rem"
						onClick={async () => {
							history.push(basePath + "/signup");
						}}
						background={theme.colors.secondary}
						border={`3px solid ${theme.colors.black}`}
						color={`${theme.colors.black}`}
						_hover={{
							bg: theme.colors.primary,
						}}>
						Sign up
					</Button>
				</Flex>
			</Flex>
			<Flex h="100%" w="100%" m="35px 50px 0 20px" background={theme.colors.white} gap='10px' justifyContent='space-evenly'>
				<Flex h="80%" w="45%" m="35px 35px 0 100px" justifyContent='center' background={theme.colors.white}>
					<Flex h="650px" w="650px" m="50px 0 0 0" background={theme.colors.white}>
						<img style={{ color: "#ffffff" }} src={stepTwo} />
					</Flex>
				</Flex>

				<Flex h="80%" w="45%" m="35px 0 0 0" background={theme.colors.white} justifyContent='center'>
					<Flex 
						h="650px" w="560px" m="50px 0 0 0" 
						background={theme.colors.white}
						borderRadius={"35px"}
						justifyContent='center'
						flexWrap='wrap'
					>

                        <Flex 
							h="52px" w="430px" m="30px 0 0 0"
							background={theme.colors.white}
							borderRadius={"10px"}
							alignItems={'center'}
                            justifyContent='center'
						>
							<Text variant="xxxlg">
                                Last question, {formData.firstName}. What role best describes you?
                            </Text>
						</Flex>
                        <Flex 
							h="52px" w="419px" m="60px 0 80px 0"
							background={theme.colors.white}
							// border={`3px solid ${theme.colors.primary}`}
							// borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
                            <Select name='role' placeholder='I create audio primarily as a…' icon={<MdArrowDropDown/>}  borderColor="black" border="2px solid" onChange={handleChange}>
                                <option value='author'>Author</option>
                                <option value='blogger/vlogger'>Blogger/Vlogger</option>
                                <option value='church marketer'>Church Marketer</option>
								<option value='Company - Internal comms'>Company - Internal comms</option>
								<option value='Company or brand - marketing'>Company or brand - marketing</option>
								<option value='Conference organiser'>Conference organiser</option>
								<option value='Digital media outlet'>Digital media outlet</option>
								<option value='Events organiser'>Educator</option>
								<option value='Journalist'>Journalist</option>
								<option value='Marketing consultant - agency'>Marketing consultant - agency</option>
								<option value='Musician or Singer'>Musician or Singer</option>
								<option value='Podcaster'>Podcaster</option>
								<option value='Podcast production agency'>Podcast production agency</option>
								<option value='Record label'>Record label</option>
								<option value='Speaker'>Speaker</option>
								<option value='Radio show host'>Radio show host</option>
								<option value='Other'>Other</option>
                            </Select>
						</Flex>
                        <Flex 
							h="52px" w="409px" m="30px 0 0 0"
							background={theme.colors.white}
							// border={`3px solid ${theme.colors.primary}`}
							// borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
                            <Button
                                h="100%"
                                w="100%"
                                onClick={async () => {
									if (formData.role === '' || formData.role === null) {
                                        toast({
                                            status: "info",
                                            title: "Alert!",
                                            description: "please choose your role",
                                        });
                                        return;
                                    }
									await completeSignup();
								}}
                                background={theme.colors.secondary}
                                borderRadius={"10px"}
                                border={`3px solid ${theme.colors.black}`}
                                color={`${theme.colors.black}`}
                                _hover={{
                                    bg: theme.colors.secondary,
                                }}>
                                    <Flex
                                        h="25px" w="100%" m="0 0 0 0"
                                        alignItems={'center'}
                                        justifyContent='center'
                                    >
                                        <Text variant="xlg" color={theme.colors.black}>
                                            Complete set-up
                                        </Text>

                                    </Flex>
                                {/* Sign Up */}
                            </Button>
						</Flex>

						<Flex 
							h="110px" w="400px" m="25px 0 10px 0"
							justifyContent='center'
						>
							<Flex 
								h="100px" w="389px" m="0 0 0 25px"
								justifyContent='center'
                                flexWrap='wrap'
							>
							</Flex>
						</Flex>

                        <Flex 
							h="110px" w="410px" m="25px 0 150px 0"
							justifyContent='center'
						>
							<Flex 
								h="100px" w="100%" m="0 0 0 0"
                                background={theme.colors.white}
                                flexWrap='wrap'

							>
                                <Flex 
                                    h="20px" w="70px" m="0 0 0 0"
                                    background={theme.colors.primary}
                                    border={`1px solid ${theme.colors.black}`}
                                >
                                </Flex>
                                <Spacer/>
                                <Flex 
                                    h="20px" w="70px" m="0 0 0 0"
                                    background={theme.colors.primary}
                                    border={`1px solid ${theme.colors.black}`}
                                >
                                </Flex>
                                <Spacer/>
                                <Flex 
                                    h="20px" w="70px" m="0 0 0 0"
                                    background={theme.colors.primary}
                                    border={`1px solid ${theme.colors.black}`}
                                >
                                </Flex>
                                <Spacer/>
                                <Flex 
                                    h="20px" w="70px" m="0 0 0 0"
                                    background={theme.colors.primary}
                                    border={`1px solid ${theme.colors.black}`}
                                >
                                </Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StepFive;