import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { State } from "easy-peasy";

export class RemoveCommand implements Command<State<CanvasStore>, "RemoveElement"> {
	name = "RemoveElement" as const;
	elements: AllCanvasElements[] = [];
	constructor(public elementIds: string[]) {
		//debugger;
		// console.log("state.canvasState[ele]");
	}

	execute(state: State<CanvasStore>): void {
		//debugger;

		let selElements = state.selectedElementsIds;
		selElements.map((ele, index) => {
			// console.log("state.canvasState[ele]");
			this.elements.push(state.canvasState[ele]);
			delete state.canvasState[ele];
		});

		state.selectedElementId = null;

		state?.konvaTransformerInstance?.nodes([]);

		state?.konvaLayerInstance?.draw();
	}
	undo(state: State<CanvasStore>): void {
		if (this.elements == null) {
			throw new Error("The command is not executed yet");
		}
		this.elements.map((ele, index) => {
			state.canvasState[ele.id] = ele;
		});

		// state.canvasState[this.elementId] = this.element;
		// state.selectedElementId = this.elementId;
		state?.konvaTransformerInstance?.nodes([]);
		state?.konvaLayerInstance?.draw();
	}
}
