import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { IndexComponent } from "./src/main";
import Login from "./src/Login"
type Props = {
	direct_uploads_url: string;
	record: {
		data: {};
	};
};

export default (mainProps: Props) => {
	// React.useEffect(() => {
	//   window.location.href = window.location.origin;
	// }, [])

	return (
		<BrowserRouter>
			<Switch>
				{/* <Route path="/records/*" component={IndexComponent} /> */}
				<Route path="/" component={IndexComponent} />
				<Route path="/login" component={Login} />
				{/* <Route
					path="/"
					exact
					component={() => {
						window.location.href = window.location.origin;
						return null;
					}}
				/> */}
			</Switch>
		</BrowserRouter>
	);
};
