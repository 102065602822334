import React, { useState } from "react";
import debounce from "lodash/debounce";
import day from "dayjs";
import durationPligin from "dayjs/plugin/duration";

import PlaybackControls from "./playback-controls";
import FadeControls from "./fade-controls";
import ZoomControls from "./zoom-controls";

import styles from "./styles.module.scss";
import { useEasyActions, useEasyState } from "../../../store/hooks";
import { Box, Flex } from "@chakra-ui/react";

day.extend(durationPligin);

type Props = {
	waveSurfer: WaveSurfer | null;
	duration: number;
	regionId: string;
};

let fadeFilter: any;

const AudioControls = ({ waveSurfer, regionId, duration }: Props) => {
	let fadeOutTimeout: NodeJS.Timeout;
	const { fadeInOn, fadeOutOn } = useEasyState((state) => state.audioStore);
	const { setFadeInOn, setFadeOutOn } = useEasyActions((state) => state.audioStore);
    const { permissionsData } = useEasyState((store) => store.userDataStore);

	const { isPlaying } = useEasyState((state) => state.visualizationStore);
	const { setIsPlaying } = useEasyActions((state) => state.visualizationStore);

	// const [isPlaying, setIsPlaying] = useState((waveSurfer && waveSurfer.isPlaying()) || false);
	const [pxPerSec, setPxPerSec] = useState(waveSurfer?.defaultParams.minPxPerSec || 10);
	const [isFadeInActive, setFadeInFilterState] = useState(fadeInOn || false);
	const [isFadeOutActive, setFadeOutFilterState] = useState(fadeOutOn || false);
	const handleSeek = debounce(() => {
		if (waveSurfer == null) {
			return;
		}

		const time = waveSurfer.getCurrentTime();
		const { start, end } = waveSurfer.regions.list[regionId as any];
		if (time < start) waveSurfer.setCurrentTime(start);
		if (time > end) waveSurfer.setCurrentTime(end);

		clearTimeout(fadeOutTimeout);
		fadeFilter.gain.cancelScheduledValues(fadeFilter.context.currentTime);
		fadeFilter.gain.setValueAtTime(1, fadeFilter.context.currentTime);
		setEndFade(3);
	}, 300);

	React.useEffect(() => {
		if (waveSurfer) {
			//@ts-ignore
			fadeFilter = waveSurfer.backend.ac.createGain();
			waveSurfer.backend.setFilter(fadeFilter);
			setPxPerSec(waveSurfer.defaultParams.minPxPerSec);
			waveSurfer.on("pause", () => {
				setIsPlaying(false);
			});
			waveSurfer.on("play", () => {
				setIsPlaying(true);
			});
			waveSurfer.on("seek", handleSeek);
		}
	}, [waveSurfer]);

	const setStartFade = (fadeDuration: number) => {
		// by Aleksandr Gulevskikh
		// this Timeout waits while position moves to start of trimmed track
		setTimeout(() => {
			if (waveSurfer == null) {
				return;
			}
			const region = waveSurfer.regions.list[regionId as any];
			const trimmedTrackStartTime = region.start;
			const currentTime = waveSurfer.getCurrentTime();

			if (currentTime <= trimmedTrackStartTime + fadeDuration) {
				// set fade if current position in the necessary region
				const startValue = (currentTime - trimmedTrackStartTime) / fadeDuration;
				const currentFadeDuration =
					currentTime - trimmedTrackStartTime > 0 ? fadeDuration - (currentTime - trimmedTrackStartTime) : fadeDuration;

				fadeFilter.gain.setValueAtTime(startValue > 0 ? startValue : 0, fadeFilter.context.currentTime);
				fadeFilter.gain.linearRampToValueAtTime(1, fadeFilter.context.currentTime + currentFadeDuration);
			}
		}, 0);
	};
	const setEndFade = (fadeDuration: number) => {
		if (waveSurfer == null) {
			return;
		}
		// by Nikita Prokofyev
		const timeoutSec = waveSurfer.regions.list[regionId as any].end - waveSurfer.getCurrentTime() - fadeDuration;
		const timeSec = timeoutSec <= 0 ? 0 : timeoutSec;

		fadeOutTimeout = setTimeout(() => {
			const region = waveSurfer.regions.list[regionId as any];
			const regionEndTime = region.end;
			const currentTime = waveSurfer.getCurrentTime();

			if (Math.round(currentTime) >= Math.round(regionEndTime - fadeDuration)) {
				const currentValue = (regionEndTime - currentTime) / fadeDuration;
				const currentFadeDuration = regionEndTime - currentTime;

				fadeFilter.gain.setValueAtTime(currentValue, fadeFilter.context.currentTime);
				fadeFilter.gain.linearRampToValueAtTime(0, fadeFilter.context.currentTime + currentFadeDuration);
			}
		}, timeSec * 1000);
	};
	const handleZoomIn = () => {
		if (waveSurfer == null) {
			return;
		}
		const pxPerSecNew = pxPerSec + 5;

		setPxPerSec(pxPerSecNew);
		waveSurfer.zoom(pxPerSecNew);
	};
	const handleZoomOut = () => {
		if (waveSurfer == null) {
			return;
		}
		const pxPerSecNew = pxPerSec - 5;

		if (pxPerSecNew < 0) return;

		setPxPerSec(pxPerSecNew);
		waveSurfer.zoom(pxPerSecNew);
	};
	const toggleFadeInFilter = () => {
		setFadeInFilterState((state: boolean) => {
			setFadeInOn(!state);

			return !state;
		});
	};
	const toggleFadeOutFilter = () => {
		setFadeOutFilterState((state: boolean) => {
			setFadeOutOn(!state);

			return !state;
		});
	};
	const handlePlay = () => {
		if (waveSurfer == null) {
			return;
		}
		waveSurfer.play();
		setIsPlaying(true);
		fadeFilter.gain.setValueAtTime(1, 0);

		if (isFadeInActive) {
			setStartFade(3);
		}

		if (isFadeOutActive) setEndFade(3);
	};
	const handlePause = () => {
		if (waveSurfer == null) {
			return;
		}
		waveSurfer.pause();
		setIsPlaying(false);
		clearTimeout(fadeOutTimeout);
	};
	const handleTogglePlay = () => {
		if (!waveSurfer) return;

		const isPlaying = waveSurfer.isPlaying();

		if (isPlaying) {
			handlePause();
			return;
		}

		handlePlay();
	};

	return (
		<>
			<Box display={"flex"} alignItems={"center"} marginTop={"10px"} justifyContent={["center", null, "space-between"]} mb="25px" position="relative">
				<div className={styles["duration-container"]}>{day.duration(duration, "seconds").format("mm:ss")}</div>
				<PlaybackControls onTogglePlay={handleTogglePlay} isPlaying={isPlaying} />
				<Box display={["none", "none", "flex"]}>
					<ZoomControls className="d-none d-sm-flex" onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
				</Box>
			</Box>
			<Flex
				direction="column"
				alignItems={["center", null, "flex-start"]}
				justifyContent="space-between"
				className="d-flex justify-content-between">
				<FadeControls
					isStartFadeOn={isFadeInActive}
					isEndFadeOn={isFadeOutActive}
					onToggleFadeStart={toggleFadeInFilter}
					onToggleFadeEnd={toggleFadeOutFilter}
					permissionsData={permissionsData}
				/>
				<Box display={["flex", "flex", "none"]} mt="20px">
					<ZoomControls className="d-flex d-sm-none" onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
				</Box>
			</Flex>
		</>
	);
};

export default AudioControls;
