import React from "react";

import { WaveSurferType } from "../../../types/index";

import styles from "./styles.module.scss";
import { IS_MOBILE } from "../../../utils/responsive";

let cursorRef: React.MutableRefObject<null | HTMLDivElement>;
let isResizing = false;

const eventNames = {
	down: IS_MOBILE ? "touchstart" : "mousedown",
	move: IS_MOBILE ? "touchmove" : "mousemove",
	up: IS_MOBILE ? "touchend" : "mouseup",
};

type Props = {
	wavesurfer: WaveSurfer;
};

const Cursor = ({ wavesurfer }: Props) => {
	cursorRef = React.useRef(null);
	const initResize = () => {
		isResizing = true;
		const resiziblecontainer = document.querySelector<HTMLElement>("#waveform > wave");

		if (resiziblecontainer) resiziblecontainer.style.setProperty("overflow", "hidden");
	};
	const stopResize = () => {
		isResizing = false;
		const resiziblecontainer = document.querySelector<HTMLElement>("#waveform > wave");

		if (resiziblecontainer) resiziblecontainer.style.setProperty("overflow", "auto hidden");
	};
	const resize = (evt: WindowEventMap) => {
		const playbackContainer = document.querySelector<HTMLElement>("wave > wave");

		if (!isResizing || !playbackContainer) return;

		const { left } = playbackContainer.getBoundingClientRect();
		let lastPoint;

		if (IS_MOBILE) {
			// @ts-ignore
			lastPoint = evt.targetTouches[0].pageX;
		} else {
			// @ts-ignore
			lastPoint = evt.clientX;
		}

		const newWidth = lastPoint - left;

		if (IS_MOBILE) {
			const region = document.querySelector<HTMLElement>("wave > region");
			if (region == null) {
				throw new Error("there is no region element");
			}
			const { width } = region.getBoundingClientRect();
			const seekPoint = newWidth / width;

			wavesurfer.seekTo(seekPoint);
		}

		playbackContainer.style.setProperty("width", `${newWidth}px`);
	};

	React.useEffect(() => {
		if (cursorRef == null || cursorRef.current == null) {
			throw new Error("cursorRef is null");
		}

		cursorRef.current.addEventListener(eventNames.down, initResize, false);
		// @ts-ignore somehow it doesnt accept it but accepts string 'touchmove' or 'mousemove'
		window.addEventListener(eventNames.move, resize, false);
		window.addEventListener(eventNames.up, stopResize, false);

		return () => {
			// @ts-ignore somehow it doesnt accept it but accepts string 'touchmove' or 'mousemove'
			window.removeEventListener(eventNames.move, resize, false);
			window.removeEventListener(eventNames.up, stopResize, false);
			cursorRef?.current?.removeEventListener(eventNames.down, initResize, false);
		};
	}, []);

	return (
		<div className={styles["cursor-wrap"]} ref={cursorRef}>
			<div className={styles["cursor-top"]} />
			<div className={styles["cursor-body"]} />
		</div>
	);
};

export default Cursor;
