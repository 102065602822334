import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { loginApiResponse } from "../../types/ApiTypex/login";
import { useEasyState } from "../../store/hooks";

export async function login(email: string, password: string) {
	return await catchTry(
		mainAxios.post<loginApiResponse>(`${defaultOptions.baseURL}/users/sign_in.json`, {
			user: {
				email: email,
				password: password,
			},
		}),
	);
}
