import { Text } from "@chakra-ui/react";
import React from "react";

export interface FakeIconProps {
	fs?: string;
}

const FakeIcon: React.FC<FakeIconProps> = (props) => {
	return <Text fontSize={props.fs}>🐱‍💻</Text>;
};

export default FakeIcon;
