import { Button, Spinner, Dialog, Text, Classes, Position, Props, Stack } from "@blueprintjs/core";
import React, { useState, useEffect, useRef } from "react";
import HomeLayout from "../../Layout/HomeLayout";
import email from "../../assets/images/email 1.svg";
import twitter from "../../assets/images/twitter 1.svg";
import fb from "../../assets/images/facebook 1.svg";
import { useToast } from "@chakra-ui/react";
import { theme } from "../../config/theme/baseDesign";
import GenerateCanvas from "./GenerateCanvas";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { ModelBoxMenu } from "../../components/home/ModelBoxMenu";
import { createCanvas } from "../../utils/Api/CreateCanvas";
import { TokenService } from "../../utils/token";
import SaveTemplateModel from "./SaveTemplateModel";
import { dataUrlToFile, videoUrlToVideoFile } from "../../utils/base64ToImage";
import OverFlowBox from "../../components/common/OverFlowBox";
import { FacebookShareButton, EmailShareButton, TwitterShareButton } from "react-share";
import { calculateCanvasTransform } from "../../utils/canvasHelpers";
import { videoQualityCheck } from "../../utils/videoQuality";
import { history } from "../../app/router/history";
import { IoCaretForwardSharp, IoPauseSharp } from "react-icons/io5";
import { uploadVideo } from "../../utils/Api/UploadVideo";
import { basePath } from "../../constants";
import { defaultOptions } from "../../config/axios";
import { getRecomendationPlanName } from "../../utils/getRecomendedPlan";
import { isMacintosh, TemplatesTypes } from "../../types/index";
import UpgradeModal from "../../components/UpgradeModal";
import useDisclosure from './useDisclosure';
export interface GenerateProps {}

const Generate: React.FC<GenerateProps> = (props) => {
	const { waveSurfer, isPlaying, videoFinishRatio, audioStream, currentTime } = useEasyState((store) => store.visualizationStore);
	const { resultAudio, AudioFile, regionStart, regionEnd } = useEasyState((store) => store.audioStore);
	const { resetToNull } = useEasyActions((store) => store.audioStore);
	const { setIsPlaying } = useEasyActions((store) => store.visualizationStore);
	const { isOpen: defualtIsOpen, onOpen: defualtOnOpen, onClose: defualtOnClose } = useDisclosure();
	const { isOpen, onOpen, onClose } = useDisclosure();
	let aStream: MediaStream | null = null;
	let vid: HTMLAudioElement | null = null;
	const [vidUrl, SetVidUrl] = useState<string | null>(null);
	const [title, SetTitle] = useState<string>("data");
	const [click, SetClick] = useState<boolean>(true);
	const [description, SetDescription] = useState<string>("data");
	const { isOpen: isOpenSignIn, onOpen: onOpenSignIn, onClose: onCloseSignIn } = useDisclosure();
	const { permissionsData, currentUserDetails, allSubs } = useEasyState((store) => store.userDataStore);
	const { isOpen: isOpenUpgradeModal, onOpen: onOpenUpgradeModal, onClose: onCloseUpgradeModal } = useDisclosure();
	const [file, setFile] = useState(null);
	let videoSaved = false;

	const toast = useToast();
	const {
		canvasState,
		cameraGroupInstance,
		screenShot,
		templatesType,
		konvaLayerInstance,
		KonvaGenerateStageInstance,
		KonvaGenerateLayer,
		generateGroupInstance,
		canvasHWS,
		clickedGenerateCanvas,
		videoUUid,
	} = useEasyState((store) => store.canvasStore);
	const { setScreenShot, setClickedGenerateCanvas, setVideoUUid, setSelectedCanvas } = useEasyActions((store) => store.canvasStore);

	let mediaRecorder = useRef<MediaRecorder | null>(null);
	let parsts = useRef<any[]>([]);
	const vidW = canvasHWS.width;
	const vidH = canvasHWS.height;
	const vidS = canvasHWS.scale;
	let token = TokenService.getToken();
	TokenService.setCanvaStep('/Generate');
	let sideMenu = (
        <div style={{ marginLeft: "20px", marginTop: "80px", display: "flex", flexDirection: "column", flex: 1, height: "100%" }}>
			<Text
				color={token ? "black" : "grey"}
				cursor="pointer"
				onClick={() => {
					if (!token) {
						return;
					}
					history.push(basePath + "/dashboard");
				}}>
				My dashboard
			</Text>
			<Text
				cursor="pointer"
				onClick={() => {
					setSelectedCanvas({});
					resetToNull();
					history.push(basePath + "/upload");
				}}>
				Start new project
			</Text>

                <div className={`${Classes.FLEX_ROW_CONTAINER} ${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`} style={{ marginRight: "30px", marginTop: "20px", marginBottom: "30px", flex: 1, flexDirection: "column" }}>
				{currentUserDetails?.subscription_details?.plan_name == "trial" ? (
					<Text fontSize="14px">
						<Text display="inline-block" cursor="pointer" w="fit-content" color={theme.colors.primary} borderBottom="1px"
						onClick={() => {
							onOpenUpgradeModal();
						}}>
								Upgrade to pro account
						</Text>{" "}
						to unlock up to 40 mins per month
					</Text>
				) : (
					<Text fontSize="14px">
						{currentUserDetails?.subscription_details?.plan_name == "free" ? (
							<Text fontSize="14px">
								<Text display="inline-block" cursor="pointer" w="fit-content" color={theme.colors.primary} borderBottom="1px"
								onClick={() => {
									history.push(basePath + "/signup");
								}}>
										Create a {getRecomendationPlanName(allSubs, currentUserDetails)} account
								</Text>{" "}
								to unlock up to 5 mins per month
							</Text>
						) : null}
					</Text>
				)}
			</div>
		</div>
	);

	function blobToFile(theBlob: Blob, fileName: string): File {
		var b: any = theBlob;
		b.lastModifiedDate = new Date();
		b.name = fileName;
		return b;
	}

	async function postVideoApi(videoFile: File, desc: string, seconds: string) {
		if(!videoSaved){
			videoSaved = true;
			let response = await uploadVideo(videoFile, desc, seconds);
			if (response instanceof Error) {
				toast({
					status: "error",
					description: "There is some problem.",
				});
				return;
			}
			let uuid = response.data.data.attributes.uuid;
			setVideoUUid(uuid);
			// console.log(response.data.data.attributes.uuid);
	  }
	}

	const parentWrapperRef = useRef<HTMLDivElement | null>(null);
	const [[width, height, sc], setCanvasTransform] = useState([350, 250, 1]);

	useEffect(() => {
		if (vidUrl == null) return;
		return () => {
			URL.revokeObjectURL(vidUrl);
		};
	}, [vidUrl]);

	useEffect(() => {
		isMacintosh();
		if (!parentWrapperRef.current) {
			return () => {};
		}

		const element = parentWrapperRef.current;

		const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
			for (let entry of entries) {
				let { width, height } = entry.contentRect;

				const { height: ch, width: cw, scale } = calculateCanvasTransform(templatesType, width, height);
				setCanvasTransform([cw, ch, scale]);
			}
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.unobserve(element);
		};
	}, [parentWrapperRef.current]);

	async function createCanvasData(isDefault: string) {
		let stringJson = JSON.stringify(canvasState);

		if (!screenShot) return console.log(screenShot, "screenShotnull");
		let response = await createCanvas({
			name: title,
			description: description,
			canvasobject: stringJson,
			screenShot: screenShot,
			type: templatesType,
			isDefault: isDefault,
			rendered: "true",
		});
		// console.log("response", response);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some problem please try later.",
			});
			return;
		}
		toast({
			status: "success",
			title: "",
			description: "Saved",
		});
	}
	let canvas = document.querySelector<HTMLCanvasElement>(".generateCanvasParent  canvas");

	function imageUrl() {
		let canvas = document.querySelector<HTMLCanvasElement>(".generateCanvasParent canvas");
		if (!canvas) return;
		let dataURL = canvas?.toDataURL();
		if (!dataURL) return console.log("no screen shot");
		dataUrlToFile(dataURL, "img.png").then((res) => {
			// console.log(res.name, "aa");
			// console.log(res, "aass");
			setScreenShot(res);
		});
	}
	async function timeupdateOnAudio() {
		if (vid == null || regionEnd == null) return;

		// console.log("timeupdate", vid.currentTime, "duration", vid.duration);

		if (vid.currentTime >= Math.min(regionEnd, vid.duration)) {
			vid.pause();
			// console.log("END TIME", vid.currentTime, "duration", vid.duration);
			if (!mediaRecorder.current) return;
			// console.log("ended");
			waveSurfer?.pause();
			mediaRecorder.current.stop();
			const blob = new Blob(parsts.current, {
				type: "video/webm",
			});
			const url = URL.createObjectURL(blob);
			SetVidUrl(url);
			setClickedGenerateCanvas(false);

			const file = await videoUrlToVideoFile(url);
			postVideoApi(file, "hello", String(regionEnd - (regionStart || 0)));
		}
	}

	function audioElement() {
		if (AudioFile == null) return;

		let audioUrl = URL.createObjectURL(AudioFile);
		if (!audioUrl) return;
		vid = document.createElement("audio");
		vid.crossOrigin = "anonymous";
		vid.src = audioUrl;
		let audioCtx = new AudioContext();
		var sourceNode = audioCtx.createMediaElementSource(vid);
		let dest = audioCtx.createMediaStreamDestination();
		sourceNode.connect(dest);
		aStream = dest.stream;
		vid.currentTime = regionStart || 0;
		vid.play();
    vid.addEventListener("timeupdate", async (e) => {
				timeupdateOnAudio();
    });
		return () => {
			URL.revokeObjectURL(audioUrl);
			if (!vid) return;
			vid.removeEventListener("timeupdate", async (e) => {
				await timeupdateOnAudio();
			});
		};
	}

	let videoLoaderRatio = Math.round(videoFinishRatio * 100);
	return (
		<HomeLayout sideMenu={sideMenu}>
			<div
				style={{
                    display: "flex",
					flex: 1,
					flexWrap: "wrap",
					marginLeft: "0px",
					marginRight: "0px",
					"@media (min-width: 768px)": {
						marginLeft: "30px",
					},
					"@media (min-width: 1200px)": {
						marginLeft: "45px",
					},}}>
                <div
                    className={`${Classes.FLEX_CONTAINER} ${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`}
                    style={{
                        margin: "20px",
                        flex: 2,
                        minWidth: "300px",
                        minHeight: templatesType === "portrait" ? "700px" : "300px",
                        flexDirection: "column",}}>
					<OverFlowBox justifyContent="center" alignItems="center" flex={1}>
                            <div
                                className={`${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`}
                                style={{
                                	display: "flex",
                                    height: "100%",
                                    width: "100%",
                                }}
                                ref={parentWrapperRef}>
							{vidUrl === null && <GenerateCanvas width={width} height={height} scale={sc} />}
							{clickedGenerateCanvas && !vidUrl && (
								<div
									background={theme.colors.gray1}
									flexDir="column"
									justifyContent="center"
									flex={1}
									height={height * sc}
									width={width * sc}
									alignItems="center">
                                    <Spinner intent="primary" size={100}/>
									<Text color={theme.colors.primary} fontSize="50px">
										{videoLoaderRatio}%
									</Text>
								</div>
							)}
							{vidUrl != null && (
								<video controls height={height * sc} width={width * sc}>
									<source src={vidUrl} type="video/webm" />
								</video>
							)}
						</div>
					</OverFlowBox>
					{vidUrl === null && !clickedGenerateCanvas && (
						<div className={`${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`}
							style={{
								display: "flex",
								marginTop: "20px",
								marginBottom: "20px",}}>
							{!isPlaying ? (
								<div
									onClick={() => {
										waveSurfer?.play();
									}}>
									<IoCaretForwardSharp size={30} />
								</div>
							) : (
								<div
									onClick={() => {
										waveSurfer?.pause();
									}}>
									<IoPauseSharp size={30} />
								</div>
							)}
						</div>
					)}
				</div>

				<div
					className={`${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`}
					style={{
						flex: 1,}}>
					<div
						className={` ${Classes.FLEX_COLUMN} ${Classes.ALIGN_CENTER}`}
						style={{ marginLeft: "20px", paddingTop: "40%", paddingLeft: "10%"}}>
					    {click && (
                            <Button
                                className={`${Classes.BUTTON} ${Classes.INTENT_PRIMARY}`}
                                style={{
                                    borderRadius: "0.8rem",
                                    height: "40px",
                                    width: "200px",
                                    marginBottom: "20px",
                                }}
                                onClick={() => {
                                    if (
                                        Number(permissionsData?.remaining_monthly_seconds) < 0 &&
                                        Number(permissionsData?.remaining_monthly_seconds) < videoFinishRatio
                                    ) {
                                        toast({
                                            status: "info",
                                            description: `You have remaining ${(
                                                Math.floor(Number(permissionsData?.remaining_monthly_seconds)) / 60
                                            ).toFixed(2)} minutes`,
                                        });
                                        return;
                                    }
                                    imageUrl();
                                    setClickedGenerateCanvas(true);
                                    if (!permissionsData.export_quality) return;
                                    videoQualityCheck(permissionsData.export_quality, templatesType);
                                    audioElement();
                                    if (!canvas) return;
                                    if (!waveSurfer) return;
                                    if (!audioStream) return;
                                    if (!aStream) return;
                                    if (!vid) return;

                                    if (click) {
                                        const stream = canvas.captureStream(60);
                                        let atrack = aStream.getAudioTracks()[0];
                                        let vtrack = stream.getVideoTracks()[0];
                                        let mixedStream = "MediaStream" in window ? new MediaStream([vtrack, atrack]) : stream;
                                        setIsPlaying(true);
                                        waveSurfer?.play();
                                        waveSurfer?.skipBackward(currentTime);
                                        waveSurfer?.setMute(true);
                                        let options = {
                                            mimeType: "video/webm;",
                                            codecs: "vp9",
                                        };
                                        mediaRecorder.current = new MediaRecorder(mixedStream, options);
                                        mediaRecorder.current.start(1000);
                                        mediaRecorder.current.ondataavailable = (e) => {
                                            parsts.current.push(e.data);
                                        };
                                        mediaRecorder.current.onstop = () => {
                                            // console.log("onstop recorder");
                                            const blob = new Blob(parsts.current, {
                                                type: "video/webm",
                                            });
                                            const url = URL.createObjectURL(blob);
                                            SetVidUrl(url);
                                            setClickedGenerateCanvas(false);
                                        };
                                        let MediaTrackSettings = stream.getVideoTracks()[0].getSettings();
                                        let frameRate = MediaTrackSettings.frameRate;
                                        // console.log("vidSettings", MediaTrackSettings, frameRate);
                                    } else if (mediaRecorder.current != null) {
                                        mediaRecorder.current.stop();
                                        const blob = new Blob(parsts.current, {
                                            type: "video/webm",
                                        });
                                        const url = URL.createObjectURL(blob);
                                        SetVidUrl(url);
                                    }

                                    SetClick(!click);
                                }}>
                                Generate Video
                            </Button>
					)}

					<Button
						className={`${Classes.BUTTON} ${Classes.INTENT_PRIMARY}`}
						style={{
							borderRadius: "0.8rem",
							height: "40px",
							width: "200px",
							marginBottom: "20px",
							backgroundColor: vidUrl ? "primary" : "gray",
							color: "white",
							opacity: !(vidUrl && permissionsData.download_in_mp4) ? "0.2" : "1",
							cursor: vidUrl ? "pointer" : "default",
						}}
						onClick={() => {
							if (!vidUrl) return;
							if (!permissionsData.download_in_mp4) {
								return;
							}
							const a = document.createElement("a");
							document.body.appendChild(a);
							a.style = "diaplay:none";
							a.href = vidUrl;
							if (isMacintosh()) {
								a.download = "video.webm";
							} else {
								a.download = "video.mp4";
							}
							a.click();
						}}>
						Download
					</Button>

				<div
					onClick={() => {
						if (!permissionsData.save_template) {
							return;
						}
						onOpen();
					}}
					margin="auto">
					<SaveTemplateModel
						buttonName="Save as template"
						titleDes="Template description"
						titleName=" Template name"
						isOpen={isOpen}
						onClose={onClose}
						createCanvasData={() => createCanvasData("false")}
						SetTitle={SetTitle}
						SetDescription={SetDescription}
					/>
						<Button
							intent="primary"
							minimal={true}
							disabled={!permissionsData.save_template}
							onClick={imageUrl}
							style={{
								borderRadius: "0.8rem",
								height: "40px",
								width: "200px",
								marginBottom: "20px",
								cursor: "pointer",
							}}
						>
							Save as template
						</Button>
					</div>
					{
						<ModelBoxMenu isopen={isOpenSignIn} onclose={onCloseSignIn}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", flex: 1, justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
									Nice template! Log in, or sign up here to save
								</div>
								<div style={{ display: "flex", flex: 1, justifyContent: "space-around", marginTop: "10px", marginBottom: "10px" }}>
									<Button
										onClick={onCloseSignIn}
										bg={theme.colors.primary}
										color="white"
										w={["150px", "150px"]}
										_hover={{ bg: theme.colors.primary }}>
										Cancel
									</Button>
									<Button
										onClick={() => {
											history.push(basePath + "/login");
										}}
										bg={theme.colors.primary}
										color="white"
										w={["150px", "150px"]}
										_hover={{ bg: theme.colors.primary }}>
										Sign in
									</Button>
								</div>
							</div>
						</ModelBoxMenu>
					}
					<SaveTemplateModel
						buttonName=" Save as default template"
						titleDes="Template description"
						titleName="Template name"
						isOpen={defualtIsOpen}
						onClose={defualtOnClose}
						createCanvasData={() => createCanvasData("true")}
						SetTitle={SetTitle}
						SetDescription={SetDescription}
					/>
					{TokenService.getRole() === "true" && (
						<Button
							intent="primary"
							minimal={true}
							onClick={() => {
								defaultOnOpen();
								imageUrl();
							}}
							style={{
								borderRadius: "0.8rem",
								height: "40px",
								width: "200px",
								marginBottom: "20px",
								cursor: "pointer",
								backgroundColor: "#106ba3",
								color: "white",
							}}
						>
							Save as default template
						</Button>
					)}
					<div
						style={{
							display: "flex",
							opacity: !vidUrl || !permissionsData.upload_on_social_media || !videoUUid ? "0.6" : "1",
							margin: "0 20px",
							justifyContent: "center"
						}}
					>
						<Text fontSize="14px" fontWeight="600" color={theme.colors.primary}>
							Share
						</Text>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "120px"
							}}
						>
						<div
							onClickCapture={(e) => {
								if (!videoUUid) {
									e.stopPropagation();
									return;
								}
								if (!permissionsData.upload_on_social_media) {
									onOpenSignIn();
									e.stopPropagation();
									return;
								}
							}}
							style={{
								display: "flex",
							}}
						>
							<FacebookShareButton
								style={{ cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer" }}
								url={!vidUrl && permissionsData.upload_on_social_media ? "" : `${defaultOptions.baseURL}/video/${videoUUid}`}>
								<img
									src={fb}
									style={{
										height: "25px",
										cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer",
										marginRight: "15px",
										marginLeft: "10px",
									}}
								/>
							</FacebookShareButton>
						</div>
						<div
							onClickCapture={(e) => {
								if (!videoUUid) {
									e.stopPropagation();
									return;
								}
								if (!permissionsData.upload_on_social_media) {
									onOpenSignIn();
									e.stopPropagation();
									return;
								}
							}}
							style={{
								display: "flex",
							}}
						>
							<TwitterShareButton
								style={{ cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer" }}
								url={!vidUrl && permissionsData.upload_on_social_media ? "" : `${defaultOptions.baseURL}/video/${videoUUid}`}>
								<img
									src={twitter}
									style={{
										cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer",
										height: "25px",
										marginRight: "15px",
									}}
								/>
							</TwitterShareButton>
						</div>
						<div
							onClickCapture={(e) => {
								if (!videoUUid) {
									e.stopPropagation();
									return;
								}
								if (!permissionsData.upload_on_social_media) {
									onOpenSignIn();
									e.stopPropagation();
									return;
								}
							}}
							style={{
								display: "flex",
							}}
						>
							<EmailShareButton
								style={{ cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer" }}
								url={!vidUrl && permissionsData.upload_on_social_media ? "" : `${defaultOptions.baseURL}/video/${videoUUid}`}>
								<img
									src={email}
									style={{
										cursor: !vidUrl && permissionsData.upload_on_social_media ? "default" : "pointer",
										height: "25px",
										marginRight: "15px",
									}}
								/>
							</EmailShareButton>
						</div>
                    </div>
					</div>
				</div>
			</div>
			</div>
			<UpgradeModal isOpen={isOpenUpgradeModal} onClose={onCloseUpgradeModal} />
		</HomeLayout>
	);
};

export default Generate;
