import CanvasCropTools, {
  CanvasCropToolsMobile,
} from "../../components/CanvasCropTools";
import Link from "../../components/common/Link";
import CanvasEditor from "../../components/home/CanvasEditor";
import HomeOptionMenu from "../../components/home/HomeOptionMenu";
import { theme } from "../../config/theme/baseDesign";
import AppLayout from "../../Layout/AppLayout";
import {
  Button,
  Text,
  FormGroup,
  InputGroup,
  Classes,
  Position,
  Props,
} from "@blueprintjs/core";
import { useMediaQuery } from "react-responsive";
import React, { useMemo, useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiMinusBack, BiMinusFront } from "react-icons/bi";
import { FaRegObjectUngroup } from "react-icons/fa";
import { GrDuplicate } from "react-icons/gr";
import { HiOutlineDuplicate } from "react-icons/hi";
import { ImUndo, ImRedo } from "react-icons/im";
import { MdDeleteForever } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { Route, Router, Switch } from "react-router-dom";
import {
  ShapesOptionMenu,
  ShapesOptionMenuMobile,
} from "../../components/home/ShapesOptionMenu";
import ForwardIcon from "../../assets/images/Forward.svg";
import BackwardIcon from "../../assets/images/Backward.svg";
import Duplicate from "../../assets/images/Duplicate.svg";
import Group from "../../assets/images/Group.svg";
import Delete from "../../assets/images/Bin.svg";
import { useEasyActions, useEasyState } from "../../store/hooks";
import LeftArrow from "../../assets/images/Arrow1.svg";
import RightArrow from "../../assets/images/Arrow2.svg";
import { ShapesProperties } from "../../components/home/ShapesProperties";
import { RemoveCommand } from "../../Commands/CanvasCommanda/RemoveCommand";
import { Buttons } from "../../components/home/Buttons";
import { ChangeZIndexCommand } from "../../Commands/CanvasCommanda/ChangeZIndexCommand";
import { ModelBoxMenu } from "../../components/home/ModelBoxMenu";
import OverFlowBox from "../../components/common/OverFlowBox";
import ImagesOptionMenu from "../../components/home/ImagesOptionMenu";
import ImagesProperties from "../../components/home/ImagesProperties";
import { DuplicateCommand } from "../../Commands/CanvasCommanda/DuplicateCommand";
import TextOptionMenu from "../../components/home/TextOptionMenu";
import AudioVisualizer from "../../components/home/AudioVisualizer";
import CanvasZoomInOutButtons from "./CanvasZoomInOutButtons";
import { IoCaretForwardSharp, IoPauseSharp } from "react-icons/io5";
import AudioVisualizerProperties from "../../components/home/AudioVisualizerProperties";
import Subtitles from "../../components/home/Subtitles";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { GroupCommand } from "../../Commands/AddAndRemove/GroupCommand";
import { TypeGroupElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { RemoveGroupCommand } from "../../Commands/AddAndRemove/RemoveGroupCommand";
import Konva from "konva";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { SubtitleObjectToElement } from "../../utils/CanvesElementObjectToCanvasElement";
import { MotionFlex } from "../../components/common/MotionFlex";
import SeekBar from "../../components/common/SeekBar";
import ProgressBarProperties from "../../components/home/ProgressBarProperties";
import ReactSlider from "react-slider";
import { history } from "../../app/router/history";
import Stickers from "../../components/home/Stickers";
import StickersMobile from "../../components/home/StickersMobile";
import MobileGIF from "../../components/home/StickersForMobile/MobileGIF";
import MobileStickers from "../../components/home/StickersForMobile/MobileStickers";
import MobileEmojis from "../../components/home/StickersForMobile/MobileEmojis";
import ConfirmationToGenerateModel from "../../components/home/ConfirmationToGenerateModel";
import { basePath } from "../../constants";
export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = (props) => {
  let maxWidth = "993px";
  const isSmall = useMediaQuery({ maxWidth: 993 });
  const {
    canvasState,
    selectedElement,
    selectedElementId,
    konvaStageInstance,
    selectedElementsIds,
    konvaTransformerInstance,
    konvaBackgroundInstance,
    konvaLayerInstance,
    selectedElements,
    konva2ndLayerInstance,
    selectedElementsInstance,
    cameraGroupInstance,
  } = useEasyState((store) => store.canvasStore);
  const {
    removeElement,
    updateElement,
    setSelectedElementsIds,
    executeCommand,
    setClickedGenerateCanvas,
  } = useEasyActions((state) => state.canvasStore);
  const { fileName, AudioFile } = useEasyState((store) => store.audioStore);
  const { waveSurfer, isPlaying, videoFinishRatio, currentTime } = useEasyState(
    (store) => store.visualizationStore
  );
  const { regionEnd, regionStart, originalAudio } = useEasyState(
    (store) => store.audioStore
  );
  const { setIsPlaying } = useEasyActions((store) => store.visualizationStore);

  let group = "Group";
  if (selectedElement?.type === "Group") {
    group = "Ungroup";
  }
  let ele = selectedElement;

  const audioDuration = (regionEnd ?? 0) - (regionStart ?? 0);

  function onChangeSeekBar(ratio: any) {
    if (waveSurfer == null) return;

    // console.log("hello");

    const currentTime = audioDuration * ratio + (regionStart ?? 0);

    waveSurfer.setCurrentTime(currentTime);
    waveSurfer.play();
  }
  useEffect(() => {
    if (originalAudio == null) {
      history.push(basePath + "/upload");
    }
  }, [originalAudio]);
  function addGroup() {
    if (selectedElement?.type === "Group") {
      if (!ele) {
        return;
      }
      ele = selectedElement;
      executeCommand(new RemoveGroupCommand(selectedElement.id, ele));
      setSelectedElementsIds([]);
      konvaTransformerInstance?.nodes([]);
      konvaLayerInstance?.draw();
    }
    if (selectedElementsIds.length > 1) {
      executeCommand(new GroupCommand(selectedElementsIds));
      setSelectedElementsIds([]);
      konvaTransformerInstance?.nodes([]);
      konvaLayerInstance?.draw();
    }
  }

  function deleteElement() {
    if (selectedElementsIds) {
      executeCommand(new RemoveCommand(selectedElementsIds));
      setSelectedElementsIds([]);
    }
  }

  const { undoCommand, redoCommand } = useEasyActions(
    (store) => store.canvasStore
  );

  let opacity = 1;

  if (selectedElementsIds.length !== 0) {
    opacity = 1;
  } else {
    opacity = 0.5;
  }

  let canvasTools = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CanvasCropTools
        text="Forward"
        onClick={() =>
          selectedElementId &&
          executeCommand(new ChangeZIndexCommand(selectedElementId, "Up"))
        }
      >
        <div h="20px" w="20px">
          <img style={{ opacity: opacity }} src={ForwardIcon} alt="forward" />
        </div>
      </CanvasCropTools>
      <CanvasCropTools
        text="Backward"
        onClick={() =>
          selectedElementId &&
          executeCommand(new ChangeZIndexCommand(selectedElementId, "Down"))
        }
      >
        <div h="20px" w="20px">
          <img style={{ opacity: opacity }} src={BackwardIcon} alt="Backward" />
        </div>
      </CanvasCropTools>
      <CanvasCropTools text={group} onClick={() => addGroup()}>
        <div h="17px" w="17px">
          <img style={{ opacity: opacity }} src={Group} alt="Group" />
        </div>
      </CanvasCropTools>
      <CanvasCropTools
        text="Duplicate"
        onClick={() => {
          selectedElementId &&
            executeCommand(new DuplicateCommand(selectedElementId));
        }}
      >
        <div h="20px" w="20px">
          <img style={{ opacity: opacity }} src={Duplicate} alt="Duplicate" />
        </div>
      </CanvasCropTools>
      <CanvasCropTools
        text="Delete"
        onClick={() => {
          deleteElement();
        }}
      >
        <div className="Delete" h="20px" w="20px">
          <img style={{ opacity: opacity }} src={Delete} alt="Delete" />
        </div>
      </CanvasCropTools>
    </div>
  );
  let undoRedo = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "10px",
        marginTop: "25px",
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          marginX: ["8px", "15px", "20px"],
        }}
        onClick={() => undoCommand()}
      >
        <div>
          <img src={LeftArrow} />
        </div>
        <Text variant="sm" mt="5px	">
          Undo
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          marginX: ["8px", "15px", "20px"],
        }}
        onClick={() => redoCommand()}
      >
        <div>
          <img src={RightArrow} />
        </div>
        <Text variant="sm" mt="5px">
          Redo
        </Text>
      </div>
    </div>
  );

  let optionMenu = useMemo(() => {
    if (selectedElement == null) {
      return (
        <Switch>
          <Route exact path={basePath + "/design"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                height: "calc(100vh - 100px)",
                overflow: "auto",
              }}
            >
              {" "}
              <HomeOptionMenu />
              <Buttons undoRedo={undoRedo} />
            </div>
          </Route>
          <Route exact path={basePath + "/design/shapes"}>
            <ShapesOptionMenu />
          </Route>
          <Route exact path={basePath + "/design/images"}>
            <ImagesOptionMenu />
          </Route>
          <Route exact path={basePath + "/design/visualization"}>
            <AudioVisualizer />
          </Route>
          <Route exact path={basePath + "/design/Subtitles"}>
            <Subtitles />
          </Route>
          <Route exact path={basePath + "/design/stickers"}>
            <Stickers />{" "}
          </Route>
        </Switch>
      );
    }

    switch (selectedElement.type) {
      case "Rectangle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Circle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Triangle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Line": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Image": {
        return <ImagesProperties key={selectedElementId} />;
      }
      case "Text": {
        return <TextOptionMenu key={selectedElementId} />;
      }
      case "GreenVisualizationElement": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "CongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StringCircularVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StringLineVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "UpperVisualizationElement": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "fullCongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "midCongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "CircularVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StraightProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "CircularProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "SquareProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "Group": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexShrink: 0,
              flex: 1,
            }}
          >
            <HomeOptionMenu />
            <Buttons undoRedo={undoRedo} />
          </div>
        );
      }
      case "Subtitle": {
        return <Subtitles />;
      }
      case "Gif": {
        return <Stickers />;
      }
      case "Sticker": {
        return <Stickers />;
      }
    }
  }, [selectedElement]) ?? (
    <Text> Properties for this element is not yet created. </Text>
  );

  let optionMenuMobile = useMemo(() => {
    if (selectedElement == null) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexShrink: 0,
              flex: 1,
            }}
          >
            <HomeOptionMenu />
            <Buttons undoRedo={undoRedo} />
          </div>
          <Switch>
            <Route path={basePath + "/design/shapes"}>
              <ShapesOptionMenuMobile />
            </Route>
            <Route exact path={basePath + "/design/images"}>
              <ImagesOptionMenu />
            </Route>
            <Route exact path={basePath + "/design/visualization"}>
              <AudioVisualizer />
            </Route>
            <Route exact path={basePath + "/design/Subtitles"}>
              <Subtitles />{" "}
            </Route>
            <Route exact path={basePath + "/design/stickers"}>
              <StickersMobile />{" "}
            </Route>
            <Route exact path={basePath + "/design/stickers/GIFs"}>
              <MobileGIF />{" "}
            </Route>
            <Route exact path={basePath + "/design/stickers/Stickers"}>
              <MobileStickers />{" "}
            </Route>
            <Route exact path={basePath + "/design/stickers/Emojis"}>
              <MobileEmojis />{" "}
            </Route>
          </Switch>
        </>
      );
    }

    switch (selectedElement.type) {
      case "Rectangle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Circle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Triangle": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Line": {
        return <ShapesProperties key={selectedElementId} />;
      }
      case "Text": {
        return <TextOptionMenu key={selectedElementId} />;
      }
      case "Image": {
        return <ImagesProperties key={selectedElementId} />;
      }
      case "GreenVisualizationElement": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "CongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StringCircularVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StringLineVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "UpperVisualizationElement": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "fullCongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "midCongestedVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "CircularVisualization": {
        return <AudioVisualizerProperties key={selectedElementId} />;
      }
      case "StraightProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "SquareProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "CircularProgressBar": {
        return <ProgressBarProperties key={selectedElementId} />;
      }
      case "Group": {
        return (
          <div
            className={Classes.FLEX_CONTAINER}
            style={{
              display: "flex",
            }}
          ></div>
        );
      }
      case "Subtitle": {
        return (
          <div
            className={Classes.FLEX_CONTAINER}
            style={{
              display: "flex",
            }}
          ></div>
        );
      }
      case "Gif": {
        return <MobileGIF />;
      }
      case "Sticker": {
        return <MobileEmojis />;
      }
    }
  }, [selectedElement]) ?? (
    <Text> Properties for this element is not yet created. </Text>
  );

  let date = new Date(1000 * (audioDuration * videoFinishRatio));
  useEffect(() => {
    setClickedGenerateCanvas(false);
  });

  return (
    <AppLayout
      maxWidth={maxWidth}
      optionMenuContentMobile={
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            flexShrink: 0,
          }}
        >
          {optionMenuMobile}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CanvasCropToolsMobile />
          </div>
        </div>
      }
      heading="Bring it to life."
      headingContent={(!isSmall && canvasTools) || undoRedo}
      optionMenuContent={optionMenu}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "calc(100vh - 266px)",
            backgroundColor: "gray",
            width: "100%",
          }}
          id="canvasEditorParent"
        >
          {" "}
          <CanvasEditor />
        </div>
        {!isSmall && (
          <div
            className={`${Classes.FLEX_ROW_CONTAINER} ${Classes.ALIGN_CENTER}`}
            style={{
              width: "100%",
              height: "120px",
              backgroundColor: "white",
              borderTop: "2px solid rgba(126, 126, 126, 0.15)",
              "@media (min-width: 768px)": {
                borderTop: "2px solid rgba(126, 126, 126, 0.15)",
              },
              "@media (min-width: 1024px)": {
                borderTop: "2px solid rgba(126, 126, 126, 0.15)",
              },
              "@media (min-width: 1280px)": {
                borderTop: "2px solid rgba(126, 126, 126, 0.15)",
              },
            }}
          >
            <div
              className={Classes.FLEX_COLUMN_CONTAINER}
              style={{
                width: "100%",
                flex: 1,
                backgroundColor: "white",
              }}
            >
              <div
                className={`${Classes.FLEX_ROW_CONTAINER} ${Classes.ALIGN_CENTER} ${Classes.JUSTIFY_CENTER}`}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {!isPlaying ? (
                  <div
                    onClick={() => {
                      waveSurfer?.setMute(false);
                      waveSurfer?.play();
                    }}
                  >
                    <IoCaretForwardSharp size={30} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      waveSurfer?.pause();
                    }}
                  >
                    <IoPauseSharp size={30} />
                  </div>
                )}
              </div>
              <div>
                <ReactSlider
                  value={videoFinishRatio * 100}
                  onChange={(v) => {
                    onChangeSeekBar(v / 100);
                  }}
                  ariaLabelledby="slider-label"
                  className="seek-bar-horizontal-slider"
                  thumbClassName="seek-bar-thumb"
                  trackClassName="seek-bar-track"
                  renderTrack={(props, state) => {
                    return <div {...props} />;
                  }}
                  renderThumb={(props, state) => <div {...props}></div>}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  padding: "10px",
                }}
              >
                {date.getUTCHours()}:{date.getUTCMinutes()}:
                {date.getUTCSeconds()}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default HomePage;
