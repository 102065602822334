import { GiphyResponse } from "../../types/ApiTypex/Giphy";
import { catchTry } from "../../utils/betterTry";
import { mainAxios } from "../../config/axios";
import { GiphyStickers } from "../../types/ApiTypex/GiphyStickers";

export async function Giphy(limit: number, text: string, apiKey: string) {
  return await catchTry(
    mainAxios.get<GiphyResponse>(
      `https://api.giphy.com/v1/gifs/search?api_key=${apiKey}&limit=${limit}&q=${text}`,
      {}
    )
  );
}
export async function GiphyStickersSearch(
  limit: number,
  text: string,
  apiKey: string
) {
  return await catchTry(
    mainAxios.get<GiphyStickers>(
      `https://api.giphy.com/v1/stickers/search?api_key=${apiKey}&limit=${limit}&q=${text}`,
      {}
    )
  );
}
