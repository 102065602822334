export const TOKEN = "access_token";
export const ROLE = "role";
export const NAME = "name";
export const CANVASTEP = "canva_step";
export const SPEECH_TO_TEXT = "speech_to_text"

interface SpeechTranscript {
    icon: string;
    transcript: string;
    startTime: number;
    endTime: number;
}

type SpeechArray = SpeechTranscript[];

const TokenService = {
    getToken() {
        // debugger;
        return localStorage.getItem(TOKEN);
    },
    getRole() {
        return localStorage.getItem(ROLE);
    },
    getName() {
        return localStorage.getItem(NAME);
    },
    getCanvaStep() {
        return localStorage.getItem(CANVASTEP);
    },
    // getStrip() {
    //     return localStorage.getItem(NAME);
    // },

    setToken(accessToken: string) {
        localStorage.setItem(TOKEN, accessToken);
    },
    setRole(accessRole: string) {
        localStorage.setItem(ROLE, accessRole);
    },
    setUserNAME(accessName: string) {
        localStorage.setItem(NAME, accessName);
    },
    setCanvaStep(canvaStep: string) {
        localStorage.setItem(CANVASTEP, canvaStep);
    },
    clearStorage() {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(NAME);
        localStorage.removeItem(ROLE);
        // console.log("token cleared");
    },
    setSpeechToText(speech: SpeechArray): void {
        localStorage.setItem(SPEECH_TO_TEXT, JSON.stringify(speech));
    },    
    getSpeechText() {
        const speechText = localStorage.getItem(SPEECH_TO_TEXT);
        return speechText ? JSON.parse(speechText) : [];
    },
    removeSpeechText(){
        localStorage.removeItem(SPEECH_TO_TEXT)
    }
    
};

export { TokenService };
