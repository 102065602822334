import { Box, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import styles from "./styles.modile.scss";
import PlaybackControls from "../Audio/controls/playback-controls";
import FadeControls from "../Audio/controls/fade-controls";
import VoiceControls from "../Audio/controls/fade-controls/VoiceControls";

export interface AudioPlayerProps {}

const UploadText: React.FC<AudioPlayerProps> = ({
  srtContent,
  handleSrtContentChange,
}) => {
  return (
    <Box>
      <Textarea
        placeholder="..."
        rows={8}
        marginTop={"14px"}
        border="1px solid #0095a8"
        value={srtContent}
        onChange={handleSrtContentChange}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        marginTop={"10px"}
        justifyContent={"center"}
        mb="25px"
        position="relative"
        width="100%"
      >
        <PlaybackControls />
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Box>
          <Text marginBottom={"5px"} size="lg" fontWeight={600}>
            Time: 00:00
          </Text>
          <Text marginBottom={"20px"}>Speed: 1.25x 1.5x 2x 3x</Text>

          <FadeControls
            isStartFadeOn={true}
            isEndFadeOn={false}
            onToggleFadeStart={() => {}}
            onToggleFadeEnd={() => {}}
            permissionsData={[]}
          />
        </Box>

        <Box>
          <Text marginBottom={"5px"} size="lg" fontWeight={600}>
            Select Voice
          </Text>
          <VoiceControls />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadText;
