import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "../../config/theme/baseDesign";
import { history } from "../../app/router/history";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { basePath } from "../../constants";

export interface StickersMobileProps {}

const StickersMobile: React.FC<StickersMobileProps> = (props) => {
	const { executeCommand, setSelectedElementsIds, setIsReplaceable } = useEasyActions((store) => store.canvasStore);
	const { selectedElementId, selectedElement, konvaTransformerInstance } = useEasyState((store) => store.canvasStore);
	return (
		<Flex flex={1} flexDir="column" mr="20px">
			<Flex
				cursor="pointer"
				onClick={() => {
					history.push(basePath + "/design");
					setSelectedElementsIds([]);
					konvaTransformerInstance?.nodes([]);
				}}
				mt="10px"
				mr="10px"
				justifyContent="flex-end">
				✖
			</Flex>
			<Flex flex={1} mx="30px" flexDir="column">
				<Flex
					color="white"
					onClick={() => {
						history.push(basePath + "/design/stickers/GIFs");
					}}
					my="20px"
					h={["3.5rem", "4rem", "5rem"]}
					rounded="lg"
					justifyContent="center"
					alignItems="center"
					w="100%"
					bg={theme.colors.primary}>
					<Text fontSize={["1.3rem", "1.8rem", "1.8rem"]}>GIFs</Text>
				</Flex>
				{/* <Flex
                color="white"
                onClick={() => {
                    history.push(basePath + "/design/stickers/Stickers");
                }}
                my="20px"
                h={["3.5rem", "4rem", "5rem"]}
                rounded="lg"
                justifyContent="center"
                alignItems="center"
                w="100%"
                bg={theme.colors.primary}
            >
                <Text fontSize={["1.3rem", "1.8rem", "1.8rem"]}>Stickers</Text>
            </Flex> */}
				<Flex
					color="white"
					onClick={() => {
						history.push(basePath + "/design/stickers/Emojis");
					}}
					my="20px"
					h={["3.5rem", "4rem", "5rem"]}
					rounded="lg"
					justifyContent="center"
					alignItems="center"
					w="100%"
					bg={theme.colors.primary}>
					<Text fontSize={["1.3rem", "1.8rem", "1.8rem"]}>Emojis</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StickersMobile;
