import { Box, Button, Flex, Text, useMediaQuery, useDisclosure } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { history } from "../../app/router/history";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { ModelBoxMenu } from "./ModelBoxMenu";
import { theme } from "../../config/theme/baseDesign";
import { dataUrlToFile } from "../../utils/base64ToImage";
import GeneratePopUp from "../../utils/GeneratePopUp";
import { basePath } from "../../constants";

export interface ButtonsProps {
	undoRedo: JSX.Element;
}

export const Buttons: React.FC<ButtonsProps> = (props) => {
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { canvasState, selectedElement, screenShot, cameraGroupInstance, konvaLayerInstance } = useEasyState((store) => store.canvasStore);
	const { setScreenShot } = useEasyActions((store) => store.canvasStore);

	let continueBtn = (
		<Box
			onClick={() => {
				onOpen();
			}}
			margin="auto"
			mt="10px">
			<GeneratePopUp onClose={onClose} isOpen={isOpen} />
			<Button cursor="pointer" variant="styled">
				<Text>Continue</Text>
			</Button>
		</Box>
	);
	let backBtn = (
		<Box
			onClick={() => {
				history.push(basePath + "/design");
			}}
			margin="auto"
			mt="5px">
			<Button cursor="pointer" variant="styled">
				<Text>Back</Text>
			</Button>
		</Box>
	);

	return (
		<Flex justifyContent="center">
			{!isSmall && (
				<Flex mb="30px" flexDir="column">
					{!isSmall && props.undoRedo}
					{!isSmall && backBtn}
					{!isSmall && continueBtn}
				</Flex>
			)}
		</Flex>
	);
};
