import React, { useState } from 'react';

const useDisclosure = (initialIsOpen = false) => {
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    return {
        isOpen,
        onOpen,
        onClose,
    };
};

export default useDisclosure;
