// Function to return the index of the range
// in which K lies and uses binary search
export function findElementInRange<T extends object, SK extends keyof T, EK extends keyof T>(
	arrayOfElements: T[],
	[startRange, endRange]: [SK, EK],
	value: T[SK] & T[EK],
) {
	let low = 0;
	let high = arrayOfElements.length - 1;

	// Binary search
	while (low <= high) {
		// Find the mid element
		const mid = (low + high) >> 1;

		// If element is found
		if (value >= arrayOfElements[mid][startRange] && value <= arrayOfElements[mid][endRange]) return arrayOfElements[mid];
		// Check in first half
		else if (value < arrayOfElements[mid][startRange]) high = mid - 1;
		// Check in second half
		else low = mid + 1;
	}

	// Not found
	return null;
}
