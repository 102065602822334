import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { UpdateNameApiRes } from "../../types/ApiTypex/UpdateApiRes";

export async function updateName(name: string) {
	return await catchTry(
		mainAxios.patch<UpdateNameApiRes>(`${defaultOptions.baseURL}/api/update_profile`, {
			user: {
				full_name: name,
			},
		}),
	);
}
