import { history } from "../../app/router/history";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import Link from "../common/Link";
export interface SelectTemplateModelProps {
	isOpen: boolean;
	onClose: () => void;
}

const SelectTemplateModel: React.FC<SelectTemplateModelProps> = ({ isOpen, onClose, children }) => {
	const { setSelectedCanvas, setSelectedCanvasId } = useEasyActions((store) => store.canvasStore);

	return (
		<Flex flex={1}>
			<Modal size="3xl" isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
				{/* <ModalOverlay /> */}
				<ModalContent border="1px solid grey" rounded="xl" w={["300px", "450px", "700px", "1000px"]} h={["500px", "500px", "550px", "600px"]}>
					<Flex h="20px">
						<ModalCloseButton
							onClick={() => {
								setSelectedCanvasId(null);
							}}
						/>
					</Flex>
					{children}
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default SelectTemplateModel;
