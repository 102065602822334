import { Box, Flex, Grid, useMediaQuery } from "@chakra-ui/react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React from "react";
import { history } from "../app/router/history";
import Logo from "../assets/images/AmpifiLogo.svg";
import { TokenService } from "../utils/token";

export interface HomeLayoutProps {
	sideMenu: JSX.Element;
}

const HomeLayout: React.FC<HomeLayoutProps> = (props) => {
	const [isSmall] = useMediaQuery("(min-width: 993px)");
	let token = TokenService.getToken();
	return (
		<div>
			<Grid
				height="100vh"
				gridTemplateColumns={["1fr", null, null, "1fr 16rem"]}
				gridTemplateRows={["0.8fr auto", null, null, "1fr"]}
				gridTemplateAreas={['"pg""om"', null, null, `"pg om"`]}>
				<Flex flexDirection="column" gridArea="pg">
					{!isSmall && (
						<Flex justifyContent="flex-end" mt="20px" mr="30px">
							<Flex
								onClick={() => {
									if (token == null) return;
									history.push("/dashboard");
								}}
								h="50px"
								w="100px">
								<img src={Logo} />
							</Flex>
						</Flex>
					)}
					<Flex flex={1} w="100%" flexDir="row">
						{props.children}
					</Flex>
				</Flex>
				<Box gridArea="om">
					<Flex borderLeft=" 1px solid rgba(126, 126, 126, 0.15)" flexDirection="column" minH="100%">
						{isSmall && (
							<Flex
								onClick={() => {
									if (token == null) return;
									history.push("/dashboard");
								}}
								minH="6rem"
								justifyContent="flex-end"
								mr="20px"
								alignItems="center">
								<img src={Logo} style={{ height: "30px" }} />
							</Flex>
						)}
						{props.sideMenu}
					</Flex>
				</Box>
			</Grid>
		</div>
	);
};

export default HomeLayout;
