import { action } from "easy-peasy";
import React from "react";
import { UserDataStore } from "../../types/canvasStoreTypes/StoreTypes";

export const userData: UserDataStore = {
	token: null,
	subscriptionDate: null,
	setToken: action((state, payload) => {
		state.token = payload;
	}),
	audioRecordId: null,
	stripeCardId: null,
	isAdmin: false,
	userEmail: null,
	setIsAdmin: action((state, payload) => {
		state.isAdmin = payload;
	}),
	allSubs: [],
	cardId: null,
	setCardId: action((state, payload) => {
		state.cardId = payload;
	}),

	setSubscriptionDate: action((state, payload) => {
		state.subscriptionDate = payload;
	}),

	currentUserDetails: {
		stripe_id: null,
		status: null,
		started_at: null,
		subscription_plan: {
			id: null,
			url: null,
			name: null,
			type: null,
			active: false,
			images: [],
			object: null,
			created: null,
			updated: null,
			livemode: false,
			metadata: {},
			tax_code: null,
			shippable: null,
			attributes: [],
			unit_label: null,
			description: null,
			package_dimensions: null,
			statement_descriptor: null,
		},
	},
	permissionsData: {
		add_emojis: null,
		add_subtitles: null,
		export_quality: null,
		import_font: null,
		audio_fade: null,
		ampifi_watermark: null,
		save_template: null,
		save_templates_duration: null,
		number_of_projects: null,
		number_of_unrendered_projects: null,
		support: null,
		max_video_length: null,
		minutes_per_month: null,
		download_in_mp4: null,
		upload_on_social_media: null,
		storage: null,
		remaining_monthly_seconds: null,
	},

	bankCard: undefined,
	setBankCards: action((state, payload) => {
		state.bankCard = payload;
	}),

	setPermissionsData: action((state, payload) => {
		state.permissionsData = payload;
	}),
	setCurrentUserDetails: action((state, payload) => {
		state.currentUserDetails = payload;
	}),
	setAllSub: action((state, payload) => {
		state.allSubs = payload;
	}),
	setUserEmail: action((state, payload) => {
		state.userEmail = payload;
	}),
	setStripeCardId: action((state, payload) => {
		state.stripeCardId = payload;
	}),
	setAudioRecordId: action((state, payload) => {
		state.audioRecordId = payload;
	}),
};
