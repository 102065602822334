import Konva from "konva";
import { Line } from "konva/lib/shapes/Line";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect } from "react-konva";
import { store } from "../../../store";
import { useEasyState } from "../../../store/hooks";
import { getBarCount, getBarGap, interpolate } from "../../../utils/canvasHelpers";

export interface StringCircularVisualizationProps {
	canvasHeight: number;
	canvasWidth: number;
	waveSurfer: WaveSurfer | null;
	konvaLayerInstance: Konva.Layer | null;
	isPlaying: boolean;
	ele: any;
	analyser: AnalyserNode | null;
	key: string;
	isStatic: boolean;
	fill: string;
}

const StringCircularVisualization: React.FC<StringCircularVisualizationProps> = (props) => {
	// const { audio } = useEasyState((store) => store.visualizationStore);
	const WIDTH = props.canvasWidth * 0.4, // 80% of canvas width
		HEIGHT = props.canvasHeight * 0.4; // 25% of canvas width

	// const vGroupInstance = useRef<Konva.Group | null>(null);
	const [vGroupInstance, setVGroupInstance] = useState<Konva.Group | null>(null);
	const requestAnimationFrameId = useRef<number | null>(null);

	const fftSize = getBarCount(8);
	const noOfBars = fftSize / 2;
	const barMinHeight = 3;
	const fullCircle = 180;
	const rad = (WIDTH / 2) * 0.4;

	const eachBarAngle = fullCircle / noOfBars;
	const colorRef = useRef<string>(props.ele.fill);
	const [line, setLine] = useState<Konva.Line | null>(null);
	useEffect(() => {
		if (vGroupInstance?.children?.[1] instanceof Line) {
			setLine(vGroupInstance?.children?.[1]);
			return;
		}

		initialize();
	}, [vGroupInstance, props.konvaLayerInstance]);
	useEffect(() => {
		// start();

		if (!props.isPlaying) {
			return () => {};
		}

		console.log("rectGroup", vGroupInstance);
		const stop = render();
		return () => {
			setTimeout(stop, 1000);
		};
	}, [props.konvaLayerInstance, props.waveSurfer, props.isPlaying, line]);

	useEffect(() => {
		if (line) {
			line.stroke(props.fill);
		}
	}, [line, props.fill]);
	function initialize() {
		if (vGroupInstance && props.konvaLayerInstance) {
			//debugger;
			let gap = getBarGap(WIDTH, 90, noOfBars);
			let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

			const group = vGroupInstance;

			const points: number[] = [];
			// let i = 0;
			// const theta = i * eachBarAngle + 270;
			// const thetaRad = theta * (Math.PI / 180);
			// const xOffset = Math.cos(thetaRad) * rad;
			// const yOffset = Math.sin(thetaRad) * rad;

			// for (i = 0; i < noOfBars; i++) {
			// 	points.push(xOffset);
			// 	points.push(yOffset - barMinHeight);
			// 	x += barWidth + gap;
			// }

			for (let i = 0; i <= noOfBars + 5; i++) {
				// const bar = bars[i];
				// console.log("bar", Boolean(bar), i, bufferLength);
				const theta = i * eachBarAngle + 90;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = Math.cos(thetaRad) * rad;
				const yOffset = Math.sin(thetaRad) * rad;

				points.push(xOffset);
				points.push(yOffset);
			}

			for (let i = noOfBars; i >= -5; i--) {
				// const bar = bars[i];
				// console.log("bar", Boolean(bar), i, bufferLength);
				const theta = i * eachBarAngle + 90;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = -Math.cos(thetaRad) * rad;
				const yOffset = Math.sin(thetaRad) * rad;

				points.push(xOffset);
				points.push(yOffset);
			}

			let line = new Konva.Line({
				width: eachBarAngle - eachBarAngle * 0.5,
				height: barMinHeight,
				x: WIDTH / 2,
				y: HEIGHT / 2,
				stroke: props.fill,
				fill: "#ffffff00",
				listening: false,
				strokeWidth: 3,
				points: points,
				bezier: true,
				closed: true,
			});

			group.add(line);

			setLine(line);
		}
	}

	function render() {
		// console.log("bars", line);

		if (!props.waveSurfer || line == null) {
			return () => {};
		}
		const konvaLayerInstance = props.konvaLayerInstance;
		if (props.analyser == null) {
			return () => {};
		}
		const analyser = props.analyser;
		// analyser.fftSize = fftSize;
		// let bufferLength = analyser.frequencyBinCount;
		let dataArray = new Uint8Array(noOfBars);

		analyser.getByteFrequencyData(dataArray);

		let gap = getBarGap(WIDTH, 80, noOfBars);
		let barWidth = (WIDTH - gap * (noOfBars - 1)) / noOfBars;

		let isRunning = true;

		const maxHeight = HEIGHT / 2 - rad; // 255 is the max value of signal
		function renderFrame() {
			if (isRunning) {
				requestAnimationFrame(renderFrame);
			}

			analyser.getByteFrequencyData(dataArray);

			let points: number[] = [];
			let j = 0;
			for (var i = 0; i < noOfBars; i++) {
				let barHeight = dataArray[i] * interpolate(maxHeight, 0, 255, 0, dataArray[i] / 255);

				// const bar = bars[i];
				// console.log("bar", Boolean(bar), i, bufferLength);
				const theta = i * eachBarAngle + 90;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = Math.cos(thetaRad) * (rad + barHeight);
				const yOffset = Math.sin(thetaRad) * (rad + barHeight);

				points.push(xOffset);
				points.push(yOffset);
			}

			for (var i = noOfBars - 1; i >= 0; i--) {
				let barHeight = dataArray[i] * interpolate(maxHeight, 0, 255, 0, dataArray[i] / 255);

				// const bar = bars[i];
				// console.log("bar", Boolean(bar), i, bufferLength);
				const theta = i * eachBarAngle + 90;
				const thetaRad = theta * (Math.PI / 180);

				const xOffset = -Math.cos(thetaRad) * (rad + barHeight);
				const yOffset = Math.sin(thetaRad) * (rad + barHeight);

				if (yOffset != yOffset || xOffset != xOffset) {
					//debugger;
				}

				points.push(xOffset);
				points.push(yOffset);
			}

			// console.log("bar", Boolean(bar), i, bufferLength);
			line?.points(points);

			// konvaLayerInstance.draw();
		}

		renderFrame();

		return () => {
			isRunning = false;
		};
	}
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}
	return (
		<Group
			key={props.ele.id}
			data-id={props.ele.id}
			ref={setVGroupInstance}
			opacity={props.ele.opacity}
			{...extra}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			y={props.ele.y}
			x={props.ele.x}
			height={HEIGHT}
			width={WIDTH}
			rotation={props.ele.rotationDeg}>
			<Rect height={HEIGHT} width={WIDTH} fill="#2f00ff00" />
		</Group>
	);
};

export default StringCircularVisualization;
