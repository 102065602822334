import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import TextIcon from "../../assets/images/TextIcon.svg";
import Visualizer from "../../assets/images/Visualizer.svg";
import WaveForm from "../../assets/images/WaveForm.svg";
import OverFlowBox from "../common/OverFlowBox";
// import { MobileShapesMenu } from "./ShapesOptionMenu";
import { v4 as uuid } from "uuid";
import { history } from "../../app/router/history";
import Extras from "../../assets/images/EXTRAS.svg";
import ImageIcon from "../../assets/images/IMAGE.svg";
import Shapes from "../../assets/images/SHAPES.svg";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { basePath } from "../../constants";
import { useEasyActions } from "../../store/hooks";
import { TokenService } from "../../utils/token";
export interface HomeOptionMenuProps {}

const HomeOptionMenu: React.FC<HomeOptionMenuProps> = (props) => {
  const [isSmall] = useMediaQuery("(max-width: 993px)");
  const [isSmallScroll] = useMediaQuery("(max-width: 620px)");
  const [isSmallForPadding] = useMediaQuery("(max-width: 380px)");
  const [clickedText, setClickedText] = useState("");

  const { setSelectedElementsIds, executeCommand } = useEasyActions(
    (state) => state.canvasStore
  );

  const scrollParent = useRef<null | HTMLDivElement>(null);

  const mobileButtonsDimension = ["40px", "50px", "50px", "60px"];
  const radius = ["md", "md", "lg", "xl"];

  function programmaticallyScroll(dir: "left" | "right") {
    if (!scrollParent.current) {
      return;
    }

    scrollParent.current.scrollBy({
      top: 0,
      left: 100 * (dir === "left" ? -1 : 1),
      behavior: "smooth",
    });
  }
  let minH = !isSmall ? "360px" : "0px";

  function onText(text, id) {
    // console.log("----->", text, id);
    // setSelectedElementsIds(id);
    // if (id) {
    //   return;
    // }

    executeCommand(
      new AddCommand({
        id: uuid(),
        type: "Text",
        rotationDeg: 0,
        width: undefined,
        x: 100 + Math.random() * 230,
        y: 130 + Math.random() * 100,
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        text: text ? text : "Add Text",
        alignment: "left",
        bgColor: "#ffffff00",
        fontSize: 20,
        isBold: false,
        isItalic: false,
        skewX: 0,
        skewY: 0,
        transformProperties: {
          enabledAnchors: ["middle-left", "middle-right"],
        },
        fill: "#000000",
        fontStyle: "normal",
        fontFamily: "Roboto",
      })
    );
  }

  const srtValue = `1
00:00:00,000 --> 00:00:01,500
For www.forom.com

2
00:00:01,500 --> 00:00:02,500
<i>Tonight's the night.</i>

3
00:00:03,000 --> 00:00:15,000
<i>And it's going to happen
again and again --</i>`;

  const icon = "● ●";

  const parseSRT = (srt) => {
    const lines = srt.split("\n");
    const textLines = [];

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes("-->")) {
        i++;
        if (i < lines.length) {
          const text = lines[i].replace(/<\/?[^>]+(>|$)/g, "").trim();
          if (text) {
            textLines.push({
              icon,
              transcript:text,
              id: Math.floor(100000 + Math.random() * 900000),
            });
          }
        }
      }
    }
    return textLines;
  };

  const parsedLines = parseSRT(srtValue);

  const [currentTextId, setCurrentTextId] = useState(null);

  let getAudioText = TokenService.getSpeechText();

  const dataToBeMapped = getAudioText?.length > 0 ? getAudioText : parsedLines



  return (
    <Flex flexDir="column" flex={1}>
      <Box
        flex={1}
        w="100%"
        justifyContent="center"
        alignItems="center"
        // minH={minH}
      >
        {!isSmall && (
          <Flex flexDir="column">
            <Heading mb="30px" ml="30px" variant="mdb" mt="34px">
              Tools
            </Heading>
          </Flex>
        )}
        <Flex
          flexDir="row"
          borderTop={[
            "2px solid rgba(126, 126, 126, 0.15)",
            null,
            null,
            "none",
          ]}
        >
          {isSmallScroll && (
            <Flex
              cursor="pointer"
              alignItems="center"
              colorscheme="black"
              onClick={() => programmaticallyScroll("left")}
              aria-label="Search database"
            >
              <RiArrowLeftSLine color="black" size={25} />
            </Flex>
          )}
          <OverFlowBox flexDir="column" minH="80px">
            <Grid
              pl={isSmallForPadding ? "80px" : "0px"}
              ref={scrollParent}
              overflow="auto"
              gridTemplateColumns={[
                "repeat(6,50px)",
                "repeat(6,65px)",
                null,
                "60px 70px",
              ]}
              gap="auto"
              className="hideScroll"
              minH="80px"
              w="100%"
              columnGap={["12px", "18px", "24px", "30px"]}
              rowGap="15px"
              // pl={isSmall ? "10px" : "0px"}
              justifyContent="center"
            >
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() => history.push(basePath + "/design/images")}
                  mb="5px"
                  cursor="pointer"
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  justifyContent="center"
                  rounded={radius}
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img src={ImageIcon} alt="Text" style={{ width: "100%" }} />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md">
                    Image
                  </Text>
                )}
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() => {
                    onText();
                  }}
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  cursor="pointer"
                  justifyContent="center"
                  rounded={radius}
                  mb="5px"
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img
                    src={TextIcon}
                    alt="Text"
                    style={{ width: "calc(100% - 65%)" }}
                  />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md">
                    Text
                  </Text>
                )}
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() =>
                    history.push(basePath + "/design/visualization")
                  }
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  cursor="pointer"
                  justifyContent="center"
                  rounded={radius}
                  mb="5px"
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img src={Visualizer} style={{ width: "calc(100% - 55%)" }} />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md">
                    Visualizations
                  </Text>
                )}
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() => history.push(basePath + "/design/shapes")}
                  cursor="pointer"
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  justifyContent="center"
                  rounded={radius}
                  mb="5px"
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img src={Shapes} style={{ width: "100%" }} />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md" textDecoration="none">
                    Shapes
                  </Text>
                )}
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() => history.push(basePath + "/design/stickers")}
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  rounded={radius}
                  mb="5px"
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img src={Extras} style={{ width: "100%" }} />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md">
                    Extras
                  </Text>
                )}
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  onClick={() => history.push(basePath + "/design/subtitles")}
                  boxShadow=" 0px 3px 2px #c4c4c4"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  rounded={radius}
                  mb="5px"
                  minH={mobileButtonsDimension}
                  minW={mobileButtonsDimension}
                  bg="primary"
                >
                  <img
                    src={WaveForm}
                    alt="Text"
                    style={{ width: "calc(100% - 65%)" }}
                  />
                </Flex>
                {!isSmall && (
                  <Text textAlign="center" variant="md">
                    Subtitles
                  </Text>
                )}
              </Flex>
            </Grid>
          </OverFlowBox>

          {isSmallScroll && (
            <Flex
              cursor="pointer"
              alignItems="center"
              colorscheme="black"
              onClick={() => programmaticallyScroll("right")}
              aria-label="Search database"
            >
              <RiArrowRightSLine size={25} color="black" />
            </Flex>
          )}
        </Flex>
      </Box>
      <Box marginX={2} border={"1px solid grey"} borderRadius={"10px"}>
        {dataToBeMapped?.map((line, index) => (
          <Box
            cursor={"pointer"}
            key={index}
            onClick={() => {
              // console.log("------->", line);
              setClickedText(line);
              onText(line.transcript, line.id);
            }}
            p={2}
            contentEditable
            // onInput={(e) => {
            //   const updatedText = e.target.innerText;
            //   setClickedText(updatedText);
            //   console.log(currentTextId, updatedText);
            //   if (currentTextId) {
            //     onText(updatedText, currentTextId);
            //   }
            // }}
            backgroundColor={clickedText?.transcript === line?.transcript && "red.200"}
            borderRadius={"10px"}
          >
            <span>{line?.icon}</span>
            <span style={{color:"black"}}>{line?.transcript}</span>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default HomeOptionMenu;
