import Konva from "konva";

export function findKonvaElementByName<T extends Konva.Node>(name: string, siblings: Konva.Node[]): T | null {
	return siblings.find((s) => s.attrs["name"] === name) as T | null;
}

export function selectableElement(element: Konva.Node) {
	let elementToFindIn: null | Konva.Node = element;

	while (elementToFindIn != null) {
		if (elementToFindIn.attrs["name"] == "object") {
			return elementToFindIn;
		}
		elementToFindIn = elementToFindIn.parent;
	}

	return null;
}
