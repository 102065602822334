import React from "react";
import { Arc, Circle, Group, Rect } from "react-konva";
export interface CircularProgressBarProps {
	ele: any;
	videoFinishRatio: number;
	isStatic: boolean;
	fillBar: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = (props) => {
	// console.log(props.videoFinishRatio * 100);
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}
	return (
		<Group
			{...extra}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			opacity={props.ele.opacity}
			key={props.ele.id}
			data-id={props.ele.id}
			x={props.ele.x}
			y={props.ele.y}
			rotation={props.ele.rotationDeg}>
			<Circle radius={props.ele.radius} fill={props.ele.fill}></Circle>
			<Arc width={0} angle={props.videoFinishRatio * 360} innerRadius={props.ele.radius} outerRadius={0} fill={props.fillBar}></Arc>
		</Group>
	);
};

export default CircularProgressBar;
