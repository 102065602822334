import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { setup } from "@ali_nawaz/style-kit";
import { Router } from "react-router";

import App from "./app/App";
import { store } from "./store";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { theme } from "./config/theme/baseDesign";
import { history } from "./app/router/history";
setup();
// console.log(window.Konva, "konvaaaa");

export function IndexComponent() {
	return (
		<StrictMode>
			<StoreProvider store={store}>
				<ChakraProvider theme={theme}>
					<CSSReset />
					<Router history={history}>
						<App />
					</Router>
				</ChakraProvider>
			</StoreProvider>
		</StrictMode>
	);
}
