import { Button, Flex, Input, InputGroup, InputRightElement, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GiClick } from "react-icons/gi";
import { HiCursorClick, HiOutlineCursorClick } from "react-icons/hi";
import WebFontLoader from "webfontloader";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { theme } from "../../config/theme/baseDesign";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { Item } from "../../types/ApiTypex/Fonts";
import { TextElement, TypeTextElement } from "../../types/canvasStoreTypes/CanvesTypes";
import OverFlowBox from "../common/OverFlowBox";
import { debounce } from "../../utils/debounce";
import { RiArrowDownSLine } from "react-icons/ri";
import { Buttons } from "./Buttons";
import { fontsNameList } from "../../data/fontsName";

export interface DataListDropdownProps {
	fonts: Item[];
	onClickListItem(fontName: string): void;
}

type DropDownState = "searching" | "dropDown" | "closed";

const DataListDropdown: React.FC<DataListDropdownProps> = (props) => {
	const { selectedElement } = useEasyState((store) => store.canvasStore);
	const { permissionsData } = useEasyState((store) => store.userDataStore);
	const toast = useToast();
	let selectedTextElement = selectedElement as TextElement;
	if (!selectedTextElement) return <></>;
	const { selectedElementId } = useEasyState((store) => store.canvasStore);
	const { executeCommand } = useEasyActions((state) => state.canvasStore);
	const [text, setText] = useState(selectedTextElement.fontFamily ?? "roboto");

	const [fontFamilies, setFontFamilies] = useState<string[]>([]);
	const [filteredLoadedFonts, setFilteredLoadedFonts] = useState<string[]>([]);
	const [dropDownState, setDropDownState] = useState<DropDownState>("closed");
	const showInOneGo = 10;

	const fontsListToShow = dropDownState === "dropDown" ? fontFamilies : filteredLoadedFonts;

	const allFontFamilies = fontsNameList;
	// useMemo(() => props.fonts.map((f) => f.family), [props.fonts]);

	// console.log("allFontFamilies", allFontFamilies.slice(0, 100));

	function loadNewFonts(font: string[]) {
		if (font.length == 0) return;

		WebFontLoader.load({
			google: {
				families: [...font],
			},
			fontactive: (...font) => {},
		});
	}
	useEffect(() => {
		loadTenFonts();
	}, [props.fonts]);

	const blur = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("textDropdownContainer")?.contains(e.target as any)) {
			setDropDownState("closed");
		}
	}, []);

	useEffect(() => {
		if (dropDownState == "closed") {
			window.removeEventListener("click", blur);
			return;
		}

		window.addEventListener("click", blur);
		return () => {
			window.removeEventListener("click", blur);
		};
	}, [dropDownState]);

	const searchFontsDebounce = useCallback(debounce(loadTenSearchFonts, 300), [allFontFamilies, text]);

	function loadTenSearchFonts(text: string) {
		// if (!text) return;
		// console.log(text);
		const newFonts = allFontFamilies.filter((f) => f.toLowerCase().includes(text.toLowerCase())).slice(0, 10);
		loadNewFonts(newFonts);
		setFilteredLoadedFonts(newFonts);
	}

	function loadTenFonts() {
		const newFonts = allFontFamilies.slice(fontFamilies.length, showInOneGo + fontFamilies.length);
		loadNewFonts(newFonts);
		setFontFamilies((fs) => [...fs, ...newFonts]);
	}

	return (
		<Flex position="relative" flex={1} flexDir="column" id="textDropdownContainer">
			<Flex>
				<Flex border="1px solid #eeee" rounded="lg" overflow="hidden">
					<Input
						value={text}
						border="none"
						onChange={(e) => {
							searchFontsDebounce(e.target.value);
							setText(e.target.value);
						}}
						onClick={() => {
							setDropDownState("searching");
						}}
						onFocus={(e) => {
							setDropDownState("searching");
						}}
					/>
					<Flex
						cursor="pointer"
						w="40px"
						h="100%"
						justifyContent="center"
						alignItems="center"
						onClick={() => {
							setDropDownState((v) => (v === "dropDown" ? "closed" : "dropDown"));
						}}>
						<RiArrowDownSLine size={20} color={theme.colors.primary} />
					</Flex>
				</Flex>
			</Flex>
			<Flex
			// border="1px" bg="red" h="100%"
			>
				{dropDownState !== "closed" && (
					<Flex
						flexDir="column"
						cursor="pointer"
						zIndex="50"
						bottom="0"
						height="50vh"
						transform="translateY(100%)"
						overflow=" auto"
						position="absolute">
						<Flex zIndex="50" flexDir="column" minW="150px" maxW="150px" border="2px solid #f1f1f1">
							{fontsListToShow?.map((font: string) => {
								return (
									<Flex
										color={!permissionsData.import_font ? "grey" : "black"}
										zIndex="50"
										key={font}
										fontFamily={font}
										background="white"
										p="10px"
										onClick={() => {
											if (!selectedElementId) {
												return;
											}
											if (!permissionsData.import_font) {
												setDropDownState("closed");
												// toast({
												//     status: "info",
												//     description: "This is available in Pro Plan.",
												// });
												return;
											}
											setDropDownState("closed");
											props.onClickListItem(font);
											loadNewFonts([font]);
											setText(font);
											executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, fontFamily: font }]));
										}}>
										{font}
									</Flex>
								);
							})}
						</Flex>

						{dropDownState === "dropDown" && (
							<Flex
								zIndex="50"
								bg="#ffffff"
								p="10px"
								borderBottom="1px solid #f0f0f0"
								justifyContent="center"
								fontSize="14px"
								onClick={() => {
									if (!permissionsData.import_font) {
										setDropDownState("closed");
										return;
									}
									loadTenFonts();
									// console.log("onClick");
								}}>
								Show more
								<Flex alignItems="center" pl="5px" color={theme.colors.gray3}>
									<GiClick />
								</Flex>
							</Flex>
						)}
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default DataListDropdown;
