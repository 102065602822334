import { allSubscriptionsArr } from "../types/ApiTypex/AllSubscriptionsType";
import { SubscriptionStoreType } from "../types/User/currentUserDetails";
import { TokenService } from "./token";
export function getRecomendationPlanName(allSubscriptions: allSubscriptionsArr[], currentDetail0fUser: SubscriptionStoreType | undefined) {
	let currentPlanName = currentDetail0fUser?.subscription_details?.plan_name
	let currentPlanId = currentDetail0fUser?.subscription_plan?.id;
	let token = TokenService.getToken();
	if (!token) {
		return "trial";
	}
	// if (token && currentPlanId ) {
	// 	return "Pro";
	// }
	if (!currentPlanName || (allSubscriptions.length == 0 && token)) {
		return "Pro";
	}
	if (currentPlanId == "1") {
		return "contact us";
	}
	// for (let x = 0; x <= allSubscriptions.length; x++) {
	// 	if (currentPlanName == allSubscriptions[x].attributes.name) {
	// 		if (x === allSubscriptions.length - 1) {
	// 			return "contact us";
	// 		}
	// 		let rec = allSubscriptions[x + 1].attributes.name;
	// 		return rec;
	// 	}
	// }
	return "contact us";
}
