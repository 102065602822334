import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasObject } from "../../types/ApiTypex/CreateCanvas";
import { TemplatesTypes } from "../../types";

type UpdateCanvasProps = {
    id: string;
    name?: string;
    description?: string;
    type?: TemplatesTypes;
    isDefault?: string;
    details?: string;
    screenShot?: File;
    recordId?: string | null;
};

export async function updateCanvasApi({
    id,
    name,
    description = "",
    type = "landscape",
    isDefault = "false",
    details = "",
    screenShot,
    recordId,
}: UpdateCanvasProps) {
    let token = TokenService.getToken;
    let bodyFormData = new FormData();
    // debugger;
    if (name) {
        bodyFormData.append("canvas[name]", type + ":" + name);
    }
    if (description) {
        bodyFormData.append("canvas[description]", description);
    }
    if (details) {
        bodyFormData.append("canvas[details]", details);
    }
    if (screenShot) {
        bodyFormData.append("canvas[picture]", screenShot);
    }
    if (recordId) {
        bodyFormData.append("canvas[record_id]", recordId);
    }
    bodyFormData.append("canvas[default]", isDefault);

    return await catchTry(
        mainAxios.patch<CanvasObject>(`${defaultOptions.baseURL}/api/canvas/${id}`, bodyFormData, {
            headers: {
                Authorization: token,
            },
        })
    );
}
