import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { VideoRootObject } from "../../types/ApiTypex/VideoTypes";

export async function uploadVideo(video: File, desc: string, seconds: string) {
	let token = TokenService.getToken;
	// debugger;
	let bodyFormData = new FormData();
	bodyFormData.append("video[file]", video);
	bodyFormData.append("video[description]", desc);
	bodyFormData.append("video[seconds]", seconds);

	return await catchTry(
		mainAxios.post<VideoRootObject>(`${defaultOptions.baseURL}/api/videos`, bodyFormData, {
			headers: {
				Authorization: token,
			},
		}),
	);
}
