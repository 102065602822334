import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { createSubscriptionRes } from "../../types/ApiTypex/CreateSubscriptionResp";

export async function CreateSubPlan(subId: number, cardId: string) {
	let token = TokenService.getToken;

	return await catchTry(
		mainAxios.post<createSubscriptionRes>(
			`${defaultOptions.baseURL}/api/subscriptions`,
			{
				subscription: {
					subscription_id: subId,
					card_token: cardId,
				},
			},
			{
				headers: {
					Authorization: token,
				},
			},
		),
	);
}
