import React, { useRef } from "react";
import { Box, Button, Flex, Input, Text, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";

export interface AudioContentProps {}

const AudioContent: React.FC<AudioContentProps> = (props) => {
	const fileUpload = useRef<null | HTMLInputElement>(null);
	const { setAudioFile } = useEasyActions((state) => state.audioStore);
	const { AudioFile } = useEasyState((state) => state.audioStore);
	const [isSmall] = useMediaQuery("(max-width: 993px)");

	function file(e: any) {
		// console.log("file", e.target.files[0]);
		setAudioFile(e.target.files[0]);
	}

	return (
		<Flex flex={1} flexDir="column">
			{/* <Flex flex={1} mx={["70px", "70px", "80px", "100px"]} mt="10px">
				<Flex color="grey" alignItems="center" flex={1} border="1px solid grey" h="40px" rounded="lg" mr="20px">
					<Text nooflines={1}>{AudioFile?.name.replace(/[/n/r]/g, "")}</Text>
				</Flex>
				<Button cursor="pointer" variant="styled" onClick={() => fileUpload.current?.click()}>
					Browse
				</Button>
				<Box display="none">
					<input multiple={false} ref={fileUpload} type="file" id="upFile" onChange={(e) => file(e)} />
				</Box>
			</Flex> */}
		</Flex>
	);
};

export default AudioContent;
