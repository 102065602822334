import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { TokenService } from "../token";
import { unrenderedListObj } from "../../types/ApiTypex/unrenderedList";

export async function allUnrenderedCanvas() {
    let token = TokenService.getToken;

    return await catchTry(
        mainAxios.get<unrenderedListObj>(`${defaultOptions.baseURL}/api/canvas/unrendered`, {
            headers: {
                Authorization: token,
            },
        })
    );
}
