import React, { useRef } from "react";
import { Flex, Grid } from "@chakra-ui/react";
import downArrow from "../../../assets/images/Chevron.svg";
import { Text } from "@chakra-ui/layout";
export interface EmojiProps {}

const Emoji: React.FC<EmojiProps> = (props) => {
	const scrollParent = useRef<null | HTMLDivElement>(null);

	function programmaticallyScrollDown(dir: "down") {
		if (!scrollParent.current) {
			return;
		}
		// scrollParent.current.scrollBy(0, 700);
		scrollParent.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}
	return (
		<Flex mx="30px" flexDir="column">
			<Text variant="md" color="gray">
				Browse Giphy
			</Text>
			<Flex justifyContent="center" mb="10px" alignItems="center" overflow="auto" flexDir="column">
				<Grid
					ref={scrollParent}
					overflow="auto"
					className="hideScroll"
					justifyContent="center"
					alignItems="center"
					m="2px"
					id="gridImg"
					mt="10px"
					gridTemplateColumns="repeat(3, 1fr)"
					columnGap="27px"
					rowGap="20px"
					// mb="20px"
					h="18vh">
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>{" "}
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
					<Flex cursor="pointer" h="40px" bg="gray.200" overflow="hidden" borderRadius="50%" w="40px">
						<img
							// ref={imageRef}
							onClick={(e) => {}}
							style={{
								objectFit: "cover",
								width: "100%",
								height: "100%",
							}}
							// src={url}
						/>
					</Flex>
				</Grid>
			</Flex>
			<Flex onClick={() => programmaticallyScrollDown("down")} id="scrollIcon" cursor="pointer" justifyContent="center" flex={1} mb="10px">
				<img src={downArrow} alt="arrow" style={{ height: "12px" }} />
			</Flex>
		</Flex>
	);
};

export default Emoji;
