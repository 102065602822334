import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { TokenService } from "../record/src/utils/token";
import { useEasyActions } from "../record/src/store/hooks";
import { IndexComponent } from "../record/src/main";
import StepOne from "../record/src/StepOne"
type Props = {
	user_info: JSON;
};

export default (mainProps: Props) => {
	let token = mainProps.user_info.data.attributes.authentication_token;
	
	
	TokenService.setRole("false");
	TokenService.setToken(token);
	TokenService.setUserNAME(mainProps.user_info.data.attributes.full_name);
	// console.log(token);
	// console.log(mainProps);
	return (
		<BrowserRouter>
			<Switch>
				{/* <Route path="/records/*" component={IndexComponent} /> */}
				<Route path="/" component={IndexComponent} />
				{/* <Route path="/step_one" component={StepOne} /> */}
				{/* <Route
					path="/"
					exact
					component={() => {
						window.location.href = window.location.origin;
						return null;
					}}
				/> */}
			</Switch>
		</BrowserRouter>
	);
};
