//@ts-ignore
import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
//@ts-ignore
import {
	//@ts-ignore
	MdCalendarViewMonth,
	//@ts-ignore
	MdCreditCard,
	//@ts-ignore
	MdPersonOutline,
	//@ts-ignore
} from "react-icons/md";
import { history } from "./app/router/history";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import { useToast } from "@chakra-ui/toast";
import { useEasyActions, useEasyState } from "./store/hooks";
import { stripeCardApi } from "./utils/Api/StripeCardApi";
import { CreateSubPlan } from "./utils/Api/CreateSubPlan";
import { getAllSubscriptions } from "./utils/Api/GetAllSubscriptions";
import { useParams } from "react-router";
import { basePath } from "./constants";
import { currentUserDetailsApi } from "./utils/Api/currentUserApi";
import { TokenService } from "./utils/token";

export interface SubscriptionsFormProps {}

const SubscriptionsForm: React.FC<SubscriptionsFormProps> = (props) => {
	const {
		setToken,
		setUserEmail: setUserName,
		setStripeCardId,
		setAllSub,
		setCardId,
		setCurrentUserDetails,
		setBankCards,
		setPermissionsData,
	} = useEasyActions((state) => state.userDataStore);
	const { stripeCardId, allSubs } = useEasyState((state) => state.userDataStore);
	const toast = useToast();
	const [name, setName] = useState<string | null>(null);
	const [cardNumber, setCardNumber] = useState<string | null>(null);
	const [expiryMonth, setExpiryMonth] = useState<string | null>(null);
	const [expiryYear, setExpiryYear] = useState<string | null>(null);
	const [cvcNumber, setCVCnumber] = useState<string | null>(null);
	const [planName, setPlanName] = useState<string | null>(null);
	const { sub } = useParams<{ sub: string }>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isSubscriptionProcessed, setIsSubscriptionProcessed] = useState(false);
	let token = TokenService.getName();
	async function currentUserDetailsApiData() {
		if (!token) return;
		let response = await currentUserDetailsApi();
		if (response instanceof Error) return;
		let currentUserData = response.data.data.attributes.subscriptions.find((sp) => sp.status === "active");
		let bankCard = response.data.data.attributes.bank_cards[0];
		setCurrentUserDetails(currentUserData);
		setBankCards(bankCard);
		setPermissionsData(response.data.data.attributes.permissions);
	}

	async function stripeCardSubApi(name: string, cardNumber: string, setExpiryMonth: string, expiryYear: string, cvcNumber: string) {
		// debugger;

		let resp = await stripeCardApi(name, cardNumber, setExpiryMonth, expiryYear, cvcNumber);
		if (resp instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: resp.message,
			});
			return;
		}
		let stripeCardId = resp.data.id;
		setStripeCardId(stripeCardId);
		// debugger;
		let res = await CreateSubPlan(Number(sub), stripeCardId);
		if (res instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: res.message,
			});
			return;
		}
		if ("error" in res.data) {
			toast({
				status: "error",
				description: res.data.error,
			});
			return;
		}
		toast({
			status: "success",
			description: "Subscribed.",
		});
		setCardId(res.data.data.id);
		history.push(basePath + "/dashboard");
	}

	async function getAllSubscriptionsApi() {
		let response = await getAllSubscriptions();
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: response.message,
			});
			return;
		}
		if (response instanceof Error) return;
		let allSub = response.data.data;
		setAllSub(allSub);
		setIsLoading(false);
		// console.log(response);
	}

	function selectedPlanId() {
		// debugger;
		for (let x = 0; x < allSubs.length; x++) {
			if (allSubs.length == 0) return;
			let includes = allSubs[x].id;
			if (includes == sub) {
				let planNam = allSubs[x].attributes.name;
				setPlanName(planNam);
				return;
			}
		}
		return;
	}
	useEffect(() => {
		getAllSubscriptionsApi();
	}, []);

	useEffect(() => {
		// debugger;
		selectedPlanId();
	}, [allSubs]);

	return (
		<Flex flexDir="column" h="100vh" background={theme.colors.primary} opacity="1">
			<Flex pr="6px" justifyContent="flex-end" h="30px" w="100%" m="30px 50px 0 0">
				<img src={Logo} />
			</Flex>
			<Flex justifyContent="center" flex={1} alignItems="center">
				<Flex py="30px" h="auto" w="30rem" background="white" rounded="md">
					<Flex w="100%" flexDir="column" justifyContent="center" mx="3rem">
						<Flex justifyContent="center" alignItems="center" mb="5px">
							<Text fontSize="2rem" fontWeight="700">
								{planName}
							</Text>
						</Flex>
						<Flex flexDir="column" marginBottom="10px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Name
							</Text>

							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdPersonOutline opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="text"
									onChange={(e) => {
										setName(e.target.value);
									}}
									placeholder="Enter your name"
								/>
							</InputGroup>
						</Flex>
						<Flex flexDir="column" marginBottom="10px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Card Number
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdCreditCard opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="number"
									onChange={(e) => {
										setCardNumber(e.target.value);
									}}
									placeholder="Enter card number"
								/>
							</InputGroup>
						</Flex>
						<Flex flexDir="column" marginBottom="10px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Expiry month
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdCalendarViewMonth opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="month"
									onChange={(e) => {
										// console.log(e.target.value, "yyyy-MM-dd");
										let splitedDate = e.target.value.split("-");
										setExpiryMonth(splitedDate[1]);
										setExpiryYear(splitedDate[0]);
									}}
									placeholder="Enter expiry date"
								/>
							</InputGroup>
						</Flex>
						{/* <Flex flexDir="column" marginBottom="10px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								Expiry Year
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdCalendarViewMonth opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="number"
									onChange={(e) => {
										setExpiryYear(e.target.value);
									}}
									placeholder="Enter expiry year"
								/>
							</InputGroup>
						</Flex> */}
						<Flex flexDir="column" marginBottom="10px">
							<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px" padding="0 0 8px 0">
								CVC
							</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<MdCreditCard opacity="0.4" color="#292d34" size="23px" />} />
								<Input
									letterSpacing="0.9px"
									// opacity={0.7}
									fontWeight="400"
									fontSize="14px"
									w="100%"
									type="number"
									onChange={(e) => {
										setCVCnumber(e.target.value);
									}}
									placeholder="Enter CVC number"
								/>
							</InputGroup>
						</Flex>
						<Button
							isLoading={isSubscriptionProcessed}
							h="3rem"
							onClick={async () => {
								if (isSubscriptionProcessed === true) return;

								if (name === null || cardNumber === null || expiryYear === null || expiryMonth === null || cvcNumber === null) {
									toast({
										status: "info",
										title: "Alert!",
										description: "please fill the following option",
									});
									return;
								}
								setIsSubscriptionProcessed(true);
								await stripeCardSubApi(name, cardNumber, expiryMonth, expiryYear, cvcNumber);
								await currentUserDetailsApiData();
								setIsSubscriptionProcessed(false);
							}}
							background={theme.colors.primary}
							color="white"
							_hover={{
								bg: theme.colors.primary,
							}}>
							Subscribe
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default SubscriptionsForm;
