import { TemplatesTypes } from "../../types";
import { urlToFile } from "../base64ToImage";
import { canvasDetailApi } from "./CanvasDetails";
import { createCanvas } from "./CreateCanvas";

export async function dublicateApi(id: string, name: string, description: string, type: TemplatesTypes) {
    const template = await canvasDetailApi(id);

    if (template instanceof Error) {
        return template;
    }

    const {
        data: {
            data: { attributes },
        },
    } = template;

    const imageFile = await urlToFile(attributes.picture_url);

    const canvesRes = await createCanvas({
        name: name,
        description: description,
        canvasobject: attributes.details,
        screenShot: imageFile,
        type: type,
        isDefault: String(attributes.default),
    });

    if (canvesRes instanceof Error) {
        return canvesRes;
    }
}
