import { TemplateStore } from "../../types/canvasStoreTypes/StoreTypes";
import { action } from "easy-peasy";

export const template: TemplateStore = {
    templatesData: [
        {
            image: "https://images.unsplash.com/photo-1627000129017-ac22ce2ffb0d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80",
            name: "Template name",
            description: `Template description, description of template`,
        },
        {
            image: "https://images.unsplash.com/photo-1627000129017-ac22ce2ffb0d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80",
            name: "Template name",
            description: `Template description, description of template`,
        },
    ],
    allTemplate: [],
    setAllTemplates: action((state, payload) => {
        state.allTemplate = payload;
    }),
    defaultCanvas: [],
    setDefaultCanvas: action((state, payload) => {
        state.defaultCanvas = payload;
    }),
    templatesKind: null,
    setTemplatesKind: action((state, payload) => {
        state.templatesKind = payload;
    }),
};
