import { useEasyActions } from "../../store/hooks";
import { Box, Flex, Heading, Input, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { stringify } from "uuid";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { useEasyState } from "../../store/hooks/index";
import { useState } from "react";
import { useStoreState } from "easy-peasy";
import { debounce } from "../../utils/debounce";
import { ShapeCanvasElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { history } from "../../app/router/history";
import ColorSelector from "../common/ColorSelector";
import { ColorPicker, useColor } from "react-color-palette";
import { basePath } from "../../constants";

export interface ShapesPropertiesProps {}

export const ShapesProperties: React.FC<ShapesPropertiesProps> = (props) => {
	const { removeElement, updateElement, setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);
	const { canvasState, selectedElement, selectedElementId, selectedElements } = useEasyState((store) => store.canvasStore);
	let selectedShapeElement = selectedElement as ShapeCanvasElement;
	const [strokeWidth, setStrokeWidth] = useState(selectedShapeElement?.strokeWidth ?? 0);
	const [transparency, setTransparency] = useState(selectedShapeElement?.opacity ?? 0);
	const [fillColor, setFillColor] = useState(selectedShapeElement?.fill ?? "#ffff");
	const [strokeColor, setStrokeColor] = useState(selectedShapeElement?.stroke ?? "#000000");
	const [displayColorPickerFill, setDisplayColorPickerFill] = useState(false);
	const [displayColorPickerStroke, setDisplayColorPickerStroke] = useState(false);
	const [stroke, setStroke] = useState(selectedShapeElement?.stroke);
	const [colorFill, setColorFill] = useState(selectedShapeElement?.fill);
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [displayStrokePicker, setDisplayStrokePicker] = useState(false);

	function onChangeFill(e: any) {
		// console.log(e);
		if (selectedElementId == null) {
			return;
		}
		executeCommand(new UpdateCommand<ShapeCanvasElement>([{ id: selectedElementId, fill: e.hex }]));
		// setFillColor(e);
	}

	function onChangeStroke(e: any) {
		// console.log(e);
		if (selectedElementId == null) {
			return;
		}
		executeCommand(new UpdateCommand<ShapeCanvasElement>([{ id: selectedElementId, stroke: e.hex }]));
	}

	function transparencyFun(e: any) {
		if (selectedElementId == null) {
			return;
		}

		executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
	}
	function StrokeDebounceFun(e: any) {
		if (selectedElementId == null) {
			return;
		}
		executeCommand(new UpdateCommand<ShapeCanvasElement>([{ id: selectedElementId, strokeWidth: e }]));
		// console.log(e);
	}
	const onChangeFillDebounce = useCallback(debounce(onChangeFill, 500), [onChangeFill, selectedElementId]);
	const onChangeStrokeDebounce = useCallback(debounce(onChangeStroke, 500), [onChangeStroke, selectedElementId]);
	const transparencyDebounce = useCallback(debounce(transparencyFun, 500), [selectedElementId]);
	const StrokeDebounce = useCallback(debounce(StrokeDebounceFun, 500), [selectedElementId]);

	const blurColor = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayColorPicker(false);
		}
	}, []);

	useEffect(() => {
		if (displayColorPicker == false) {
			window.removeEventListener("click", blurColor);
			return;
		}

		window.addEventListener("click", blurColor);
		return () => {
			window.removeEventListener("click", blurColor);
		};
	}, [displayColorPicker]);

	const blurStroke = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayStrokePicker(false);
		}
	}, []);

	useEffect(() => {
		if (displayStrokePicker == false) {
			window.removeEventListener("click", blurStroke);
			return;
		}

		window.addEventListener("click", blurStroke);
		return () => {
			window.removeEventListener("click", blurStroke);
		};
	}, [displayStrokePicker]);

	return (
		<Flex flexDir="column" flex={1} mb="20px">
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							setSelectedElementsIds([]);
							history.push(basePath + "/design");
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Heading ml="35px" mb="30px" variant="mdb">
					Shapes
				</Heading>
			</Flex>

			<Flex flexDir="column" mx="35px">
				{selectedElement?.type !== "Line" && (
					<Flex mb="30px" flex={1}>
						<Flex justifyContent="space-between" flex={1}>
							<Text variant="lg">Fill</Text>

							<Flex
								w="25px"
								h="25px"
								bg={colorFill}
								position="relative"
								borderRadius="40%"
								onClick={() => {
									// if (displayColorPickerHighlight) {
									// 	setDisplayColorPickerHighlight(false);
									// }
									setDisplayColorPicker(true);
									// console.log("fill", displayColorPicker);
								}}>
								{displayColorPicker && (
									<Flex id="ColorContainer" bottom="0" left="-150" transform="translateY(100%)" zIndex="20" position="absolute">
										<Flex border=" 1px solid #80808052" borderRadius=" 10px">
											<ColorSelector
												color={colorFill}
												onChange={(e) => {
													setColorFill(e.hex);
													onChangeFillDebounce(e);
												}}
											/>
										</Flex>
									</Flex>
								)}
							</Flex>
							{/* <input
								value={fillColor}
								type="color"
								onChange={(e) => {
									onChangeFillDebounce(e.target.value);
								}}
							/> */}
						</Flex>
						<Flex flex={1}></Flex>
					</Flex>
				)}

				<Flex mb="30px" flexDir="column">
					<Flex flexDir="row">
						<Flex mb="20px" flex={1} variant="lg">
							Line Weight
						</Flex>
						<Text variant="md">{strokeWidth} px</Text>
					</Flex>
					<input
						type="range"
						id="vol"
						name="vol"
						value={strokeWidth}
						max={25}
						min={0}
						step={1}
						onChange={(e) => {
							let value = Number(e.target.value);
							StrokeDebounce(value);
							setStrokeWidth(value);
						}}></input>

					{/* <Slider
						onChange={(e) => {
							setStrokeWidth(e);
						}}
						onChangeEnd={(e) => {
							if (selectedElementId == null) {
								return;
							}
							setStrokeWidth(e);
							executeCommand(new UpdateCommand<ShapeCanvasElement>([{ id: selectedElementId, strokeWidth: e }]));
							console.log(e);
						}}
						max={25}
						min={0}
						value={strokeWidth}
						mt="20px"
						aria-label="slider-ex-1">
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb bg="primary" />
					</Slider> */}
				</Flex>

				<Flex mb="30px" flex={1}>
					<Flex justifyContent="space-between" flex={1}>
						<Text variant="lg">stroke</Text>

						<Flex
							w="25px"
							h="25px"
							bg={stroke}
							position="relative"
							borderRadius="40%"
							onClick={() => {
								// if (displayColorPickerHighlight) {
								// 	setDisplayColorPickerHighlight(false);
								// }
								setDisplayStrokePicker(true);
								// console.log("fill", displayStrokePicker);
							}}>
							{/* {displayColorPickerStroke && (
								<Flex
									border="1px solid #eeeeee"
									bottom="0"
									// left="-100"
									right="-50"
									transform="translateY(100%)"
									zIndex="20"
									position="absolute">
									<ColorSelector
										color={strokeColor}
										onChange={(e) => {
											onChangeStrokeDebounce(e.hex);
											setStrokeColor(e.hex);
										}}
									/>
								</Flex>
							)} */}
							{displayStrokePicker && (
								<Flex id="ColorContainer" bottom="0" left="-150" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={stroke}
											onChange={(e) => {
												setStroke(e.hex);
												onChangeStrokeDebounce(e);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
					</Flex>
					<Flex flex={1}></Flex>
				</Flex>

				<Flex flexDir="column">
					<Flex flexDir="row">
						<Flex flex={1} variant="lg" mb="20px">
							Transparency
						</Flex>
						<Text variant="md">{Math.floor(transparency * 100)}%</Text>
					</Flex>
					{/* <Slider
						min={0}
						max={1}
						step={0.01}
						mt="20px"
						aria-label="slider-ex-1"
						defaultValue={transparency}
						onChange={(e) => {
							setTransparency(e);
						}}
						onChangeEnd={(e) => {
							if (selectedElementId == null) {
								returnd
							}
							setTransparency(e);
							executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
							console.log(e);
						}}>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb bg="primary" />
					</Slider> */}

					<input
						type="range"
						id="vol"
						name="vol"
						min="0"
						max="1"
						value={transparency}
						step={0.01}
						// defaultValue={transparency}
						onChange={(e) => {
							let value = Number(e.target.value);

							transparencyDebounce(value);
							setTransparency(value);
						}}></input>
				</Flex>
			</Flex>
		</Flex>
	);
};
