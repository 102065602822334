import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text, Spacer } from "@chakra-ui/layout";
import React, { useEffect, useState, useCallback } from "react";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import stepTwo from "./assets/images/step_two.svg";
import PrimaryLogo from "./assets/images/primaryLogo.svg";
import GoogleLogo from "./assets/images/google_logo.svg";
import MicrosoftLogo from "./assets/images/microsoft.svg";
import { signup } from "./utils/Api/signup";
import { useToast } from "@chakra-ui/react";
import { history } from "./app/router/history";
import { TokenService } from "./utils/token";
import { useQuery } from "./store/hooks/useQuery";
import { basePath } from "./constants";
import { Link } from "react-router-dom";
import { roleTypesArr } from "./types";

// export interface HoldingPageProps {}

interface FormData {
	firstName: string;
	lastName: string;
	role: string;
	purpose: string;
}

const StepTwo = () => {
	const [formData, setFormData] = useState<FormData>({
		firstName: '',
		lastName: '',
		role: '',
		purpose: '',
	});
	const toast = useToast();
	const [name, setName] = useState<string | null>(null);
	const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
	const [selectedRole, setSelectedRole] = useState<string>(roleTypesArr[0]);
	const [password, setPassword] = useState<string | null>(null);
	const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
	let queryParams = useQuery();
	let token = TokenService.getToken();
	let canvaStep = TokenService.getCanvaStep();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	return (
		<Flex id="parentContainer" flexWrap='wrap' justifyContent="flex-end" /* Set to row direction */
			h="100vh" background={theme.colors.white} opacity="1"
		>
			<Flex justifyContent="flex-end" /* Align content to the end */
					h="30px" w="1200px" m="0 50px 0 0" background={theme.colors.white}
			>
				{/* Your content goes here */}
			</Flex>
			<Flex m="35px 100px 0 0" background={theme.colors.white}>
				<Flex m="12px 0 0 100px">
					<Text
						variant="lg"
						cursor="pointer"
						color={theme.colors.gray3}
						onClick={() => {
							history.push(basePath + "/login");
						}}
						display="inline-block">
						{" "}
						Login
					</Text>
				</Flex>
				<Flex m="0 0 0 100px">
					<Button
						h="3rem"
						onClick={async () => { 
							history.push(basePath + "/signup");
						}}
						background={theme.colors.secondary}
						border={`3px solid ${theme.colors.black}`}
						color={`${theme.colors.black}`}
						_hover={{
							bg: theme.colors.primary,
						}}>
						Sign up
					</Button>
				</Flex>
			</Flex>
			<Flex h="100%" w="100%" m="35px 50px 0 20px" background={theme.colors.white} gap='10px' justifyContent='space-evenly'>
				<Flex h="80%" w="45%" m="35px 35px 0 100px" justifyContent='center' background={theme.colors.white}>
					<Flex h="650px" w="650px" m="50px 0 0 0" background={theme.colors.white}>
						<img style={{ color: "#ffffff" }} src={stepTwo} />
					</Flex>
				</Flex>

				<Flex h="80%" w="45%" m="35px 0 0 0" background={theme.colors.white} justifyContent='center'>
					<Flex
						h="650px" w="560px" m="50px 0 0 0"
						background={theme.colors.white}
						borderRadius={"35px"}
						justifyContent='center'
						flexWrap='wrap'
					>

						<Flex
							h="52px" w="430px" m="30px 0 0 0"
							background={theme.colors.white}
							borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
							<Text variant="xxxlg">
								What is your first name?
							</Text>
						</Flex>
						<Flex
							h="52px" w="419px" m="30px 0 100px 0"
							background={theme.colors.white}
							// border={`3px solid ${theme.colors.primary}`}
							// borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
							<Input
								variant='flushed'
								name='firstName'
								borderBottomWidth='1px'
								borderColor={theme.colors.black}
								onChange={handleChange}
								_focus={{
									borderBottomWidth: '2px', // Adjust the thickness as needed
								}}
							/>
						</Flex>
						<Flex
							h="52px" w="409px" m="30px 0 0 0"
							background={theme.colors.white}
							// border={`3px solid ${theme.colors.primary}`}
							// borderRadius={"10px"}
							alignItems={'center'}
							justifyContent='center'
						>
							<Button
								h="100%"
								w="100%"
								onClick={async () => {
									if (formData.firstName === '' || formData.firstName === null) {
										toast({
											status: "info",
											title: "Alert!",
											description: "please fill your first name",
										});
										return;
									}
									history.push(basePath + "/step_three", { formData });
								}}
								background={theme.colors.secondary}
								borderRadius={"10px"}
								border={`3px solid ${theme.colors.black}`}
								color={`${theme.colors.black}`}
								_hover={{
									bg: theme.colors.secondary,
								}}>
								<Flex
									h="25px" w="100%" m="0 0 0 0"
									alignItems={'center'}
									justifyContent='center'
								>
									<Text variant="xlg" color={theme.colors.black}>
										Continue
									</Text>

								</Flex>
								{/* Sign Up */}
							</Button>
						</Flex>

						<Flex
							h="110px" w="400px" m="25px 0 10px 0"
							justifyContent='center'
						>
							<Flex
								h="100px" w="389px" m="0 0 0 25px"
								justifyContent='center'
								flexWrap='wrap'
							>
							</Flex>
						</Flex>

						<Flex
							h="110px" w="430px" m="25px 0 150px 0"
							justifyContent='center'
						>
							<Flex
								h="100px" w="100%" m="0 0 0 25px"
								background={theme.colors.white}
								flexWrap='wrap'

							>
								<Flex
									h="20px" w="70px" m="0 0 0 0"
									background={theme.colors.primary}
									border={`1px solid ${theme.colors.black}`}
								>
								</Flex>
								<Spacer />
								<Flex
									h="20px" w="70px" m="0 0 0 0"
									background={theme.colors.gray2}
									border={`1px solid ${theme.colors.black}`}
								>
								</Flex>
								<Spacer />
								<Flex
									h="20px" w="70px" m="0 0 0 0"
									background={theme.colors.gray2}
									border={`1px solid ${theme.colors.black}`}
								>
								</Flex>
								<Spacer />
								<Flex
									h="20px" w="70px" m="0 0 0 0"
									background={theme.colors.gray2}
									border={`1px solid ${theme.colors.black}`}
								>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StepTwo;