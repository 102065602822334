import React from "react";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import {
	circleObjectToElement,
	GifObjectToElement,
	ImageObjectToElement,
	LineObjectToElement,
	reactObjectToElement,
	SubtitleObjectToElement,
	TextObjectToElement,
	TriangleObjectToElement,
} from "../../utils/CanvesElementObjectToCanvasElement";
import CircularVisualization from "../canves/visualization/CircularVisualization";
import GreenVisualization from "../canves/visualization/GreenVisualization";
import StraightProgressBar from "../canves/ProgressBar/StraightProgressBar";
import CircularProgressBar from "../canves/ProgressBar/CircularProgressBar";
import SquareProgressBar from "../canves/ProgressBar/SquareProgressBar";
import StringCircularVisualization from "../canves/visualization/StringCircularVisualization";
import StringLineVisualization from "../canves/visualization/StringLineVisualization";
import CongestedVisualization from "../canves/visualization/CongestedVisualization";
import FullCongestedVisualization from "../canves/visualization/fullCongestedVisualization";
import MidCongestedVisualization from "../canves/visualization/midCongestedVisualization";
import UpperHalfVisualization from "../canves/visualization/UpperHalfVisualization";
import { Group } from "react-konva";
import { store } from "../../store";

export interface AllCanvasElementRenderProps {
	stateToRender: Record<string, AllCanvasElements>;
	isStatic: boolean;
}

const AllCanvasElementRender: React.FC<AllCanvasElementRenderProps> = ({ stateToRender, isStatic }) => {
	let konvaStageInstance = store.getState().canvasStore.konvaStageInstance;
	let konvaTransformerInstance = store.getState().canvasStore.konvaTransformerInstance;
	let konvaLayerInstance = store.getState().canvasStore.konvaLayerInstance;
	let canvasHWS = store.getState().canvasStore.canvasHWS;
	let isPlaying = store.getState().visualizationStore.isPlaying;
	let analyser = store.getState().visualizationStore.analyser;
	let videoFinishRatio = store.getState().visualizationStore.videoFinishRatio;
	let waveSurfer = store.getState().visualizationStore.waveSurfer;
	let subtitleWithTime = store.getState().visualizationStore.subtitleWithTime;
	const cameraW = canvasHWS.width;
	const cameraH = canvasHWS.height;
	const res: JSX.Element[] = [];

	for (const ele of Object.values(stateToRender)) {

		switch (ele.type) {
			case "Rectangle":
				res.push(reactObjectToElement(ele, ele.id, isStatic));
				break;
			case "Circle":
				res.push(circleObjectToElement(ele, ele.id, isStatic));
				break;

			case "Triangle":
				res.push(TriangleObjectToElement(ele, ele.id, isStatic));
				break;

			case "Line":
				res.push(LineObjectToElement(ele, ele.id, isStatic));
				break;
			case "Image":
				res.push(<ImageObjectToElement ele={ele} key={ele.id} isStatic={isStatic} />);
				break;
			case "WaterMark":
				res.push(<ImageObjectToElement ele={ele} key={ele.id} isStatic={isStatic} />);
				break;
			case "Gif":
				res.push(<GifObjectToElement ele={ele} key={ele.id} isStatic={isStatic} />);
				break;
			case "Sticker":
				res.push(<ImageObjectToElement ele={ele} key={ele.id} isStatic={isStatic} />);
				break;
			case "Text":
				res.push(
					<TextObjectToElement
						ele={ele}
						key={ele.id}
						konvaStageInstance={konvaStageInstance}
						isStatic={isStatic}
						konvaTransformerInstance={konvaTransformerInstance}
					/>,
				);
				break;
			case "GreenVisualizationElement":
				res.push(
					<GreenVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						fill={ele.fill}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasWidth={cameraW}
						canvasHeight={cameraH}
						analyser={analyser}
						isStatic={isStatic}
					/>,
				);
				break;
			case "CircularVisualization":
				res.push(
					<CircularVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasWidth={cameraW}
						canvasHeight={cameraH}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "UpperVisualizationElement":
				res.push(
					<UpperHalfVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasHeight={cameraH}
						canvasWidth={cameraW ?? 400}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "CongestedVisualization":
				res.push(
					<CongestedVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasHeight={cameraH}
						canvasWidth={cameraW ?? 400}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "StringLineVisualization":
				res.push(
					<StringLineVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasHeight={cameraH}
						canvasWidth={cameraW ?? 400}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;

			case "StringCircularVisualization":
				res.push(
					<StringCircularVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasHeight={cameraH}
						canvasWidth={cameraW ?? 400}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "fullCongestedVisualization":
				res.push(
					<FullCongestedVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasWidth={cameraW ?? 400}
						canvasHeight={cameraH}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "midCongestedVisualization":
				res.push(
					<MidCongestedVisualization
						key={ele.id}
						isPlaying={isPlaying}
						ele={ele}
						konvaLayerInstance={konvaLayerInstance}
						waveSurfer={waveSurfer}
						canvasWidth={cameraW ?? 400}
						canvasHeight={cameraH}
						analyser={analyser}
						isStatic={isStatic}
						fill={ele.fill}
					/>,
				);
				break;
			case "StraightProgressBar":
				res.push(
					<StraightProgressBar
						key={ele.id}
						isStatic={isStatic}
						ele={ele}
						// fill={ ele.fill }
						videoFinishRatio={videoFinishRatio}
					/>,
				);
				break;
			case "SquareProgressBar":
				res.push(
					<SquareProgressBar
						key={ele.id}
						isStatic={isStatic}
						ele={ele}
						// fill={ ele.fill }
						videoFinishRatio={videoFinishRatio}
					/>,
				);
				break;

			case "CircularProgressBar":
				res.push(
					<CircularProgressBar
						key={ele.id}
						isStatic={isStatic}
						ele={ele}
						fillBar={ele.fillBar}
						// fill={ ele.fill }
						videoFinishRatio={videoFinishRatio}
					/>,
				);
				break;
			case "Group": {
				let extra = {};

				if (isStatic) {
					extra = {
						name: "object",
						draggable: isStatic,
					};
				}
				res.push(
					<Group
						key={ele.id}
						x={ele.x}
						data-id={ele.id}
						y={ele.y}
						scaleX={ele.scaleX}
						rotation={ele.rotationDeg}
						scaleY={ele.scaleY}
						opacity={ele.opacity}
						skewX={ele.skewX}
						skewY={ele.skewY}
						height={ele.height}
						width={ele.width}
						{...extra}>
						<AllCanvasElementRender stateToRender={ele.children} isStatic={false} />
					</Group>,
				);
				break;
			}

			case "Subtitle":
				res.push(<SubtitleObjectToElement ele={ele} key={ele.id} isStatic={isStatic} text={subtitleWithTime || "Subtitle"} />);
				break;
			default:
				break;
		}
	}
	return <>{res}</>;
};

export default AllCanvasElementRender;
