import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, Text, Spacer } from "@chakra-ui/layout";
import React, { useEffect, useState, useCallback } from "react";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { useEasyActions } from "./store/hooks";
import { theme } from "./config/theme/baseDesign";
import Logo from "./assets/images/AmpifiLogoWhite.svg";
import stepTwo from "./assets/images/step_two.svg";
import PrimaryLogo from "./assets/images/primaryLogo.svg";
import GoogleLogo from "./assets/images/google_logo.svg";
import MicrosoftLogo from "./assets/images/microsoft.svg";
import { login } from "./utils/Api/login";
import { useToast } from "@chakra-ui/react";
import { history } from "./app/router/history";
import { TokenService } from "./utils/token";
import { useQuery } from "./store/hooks/useQuery";
import { basePath } from "./constants";
import { Link } from "react-router-dom";
import { roleTypesArr } from "./types";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import NavigationBar from "./NavigationBar";
import useScreenWidth from "./components/useScreenWidth";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { AUTHENTICATE_SOCIAL_AUTH_USER } from "./utils/api_routes";

const msalConfig = {
    auth: {
        clientId: process.env.MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: process.env.MICROSOFT_REDIRECT_URI,

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};
const msalInstance = new PublicClientApplication(msalConfig);

export interface LoginProps { }
const clientId = process.env.GOOGLE_CLIENT_ID
const Login: React.FC<LoginProps> = (props) => {
	const toast = useToast();
	const screenWidth = useScreenWidth();
	const { setToken, setUserEmail, setCardId, setSubscriptionDate } = useEasyActions((state) => state.userDataStore);
	const [email, setEmail] = useState<string | null>(null);
	const [password, setPassword] = useState<string | null>(null);
	const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
	const [csrfToken, setCsrfToken] = useState<string>('');
	let queryParams = useQuery();
	let token = TokenService.getToken();
	let canvaStep = TokenService.getCanvaStep();

	useEffect(() => {


		if (queryParams.get("sub") != null && token) {


			history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
		}
		function start() {
			gapi.client.init({
				clientId: clientId,
				scope: "profile email",
				cookie_policy: 'single_host_origin',
				response_type: 'code',
			})
		};
		gapi.load('client:auth2', start);
		const fetchCsrfToken = async () => {
			try {
			  const response = await axios.get('/csrf_token');
			  setCsrfToken(response.data.authenticity_token);
			} catch (error) {
				console.error('Error fetching CSRF token:', error);
			}
		};

		fetchCsrfToken();
	}, []);

	const onFailure = (res) => {
		// console.log("Login Failed! res: ", res);
	}
	async function LoginInfo(email: string, pasward: string) {
		let response = await login(email, pasward);
		if (response instanceof Error) {
			if (response.message.includes("Email not verified")) {
				toast({
					status: "error",
					title: "Error!",
					description: "Email not verified.",
				});
				return;
			} else {
				toast({
					status: "error",
					title: "Error!",
					description: "Invalid Email or password.",
				});
				return;
			}

		}
		let token = response.data.data.attributes.authentication_token;
		if ("admin" in response.data.data.attributes) {
			let user_role = response.data.data.attributes.admin;
			TokenService.setRole("" + user_role);
		} else {
			TokenService.setRole("false");
		}
		TokenService.setToken(token);
		setUserEmail(response.data.data.attributes.email);
		TokenService.setUserNAME(response.data.data.attributes.full_name);

		if ("valid_till" in response.data.data.attributes.subscription_details) {
			setSubscriptionDate(response.data.data.attributes.subscription_details?.valid_till);
		}
		if (queryParams.get("sub") != null && token) {
			history.push(`${basePath}/subscription/${queryParams.get("sub")}`);
			return;
		}
		toast({
			status: "success",
			description: "You have logged in successfully!",
		});
		if (response.data.data.attributes.role === null){
			history.push(basePath + "/step_one");
		} else {
			history.push(basePath + "/dashboard");
		}
	}


	const handleGoogleLogin = async (response) => {
		if (response && !response.error) {
			try {
				var data = {
					provider: "google_oauth2",
					uid: response.Ca,
					id_token: response.tokenId,
					info: {
						email: response.profileObj.email
					}
				}
				const serverResponse = await fetch(AUTHENTICATE_SOCIAL_AUTH_USER, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken,
					},
					body: JSON.stringify(data)
				});
				const responseData = await serverResponse.json();
				TokenService.setToken(responseData.data.attributes.authentication_token);
				TokenService.setUserNAME(responseData.data.attributes.full_name);
				TokenService.setRole(String(Boolean(responseData.data.attributes.admin)));
				if (responseData.data.attributes.full_name === null){
					history.push(basePath + "/step_one");
				} else {
					history.push(basePath + "/dashboard");
				}
				toast({
					status: "success",
					description: "You are successfully Logged in.",
				});
			} catch (error) {
				console.error('Error sending data to server:', error);
			}
		} else {
			console.error('Google authentication failed');
		}
	};

    const handleMicrosoftLogin = async () => {
        try {
            const loginResponse = await msalInstance.loginPopup({
                scopes: ['openid', 'profile', 'user.read'],
            });

            const response = await fetch(AUTHENTICATE_SOCIAL_AUTH_USER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                body: JSON.stringify({
                    idToken: loginResponse.idToken.rawIdToken,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                TokenService.setToken(data.token);
                history.push(basePath + "dashboard");
                toast({
                    status: "success",
                    description: "You have logged in successfully!",
                });
            } else {
                console.error('Failed to authenticate with Microsoft:', response.statusText);
                toast({
                    status: "error",
                    title: "Error!",
                    description: "Failed to authenticate with Microsoft.",
                });
            }
        } catch (error) {
            console.error('Error logging in with Microsoft:', error);
            toast({
                status: "error",
                title: "Error!",
                description: "Failed to authenticate with Microsoft.",
            });
        }
    };

	return (
		<>
			<NavigationBar />
			<Flex id="parentContainer" flexWrap='wrap' justifyContent="flex-end" /* Set to row direction */
				background={theme.colors.white} opacity="1"
			>
				<Flex h="100%" w="100%"
					background={theme.colors.white} gap='10px' justifyContent='space-evenly'>
					{screenWidth <= 955 ? <> </> :
						<Flex h="80%" w="45%" justifyContent="center" background={theme.colors.white}>
							<Flex h="500px" w="500px" m="50px 0 0 0" background={theme.colors.white}>
								<img style={{ color: "#ffffff" }} src={stepTwo} />
							</Flex>
						</Flex>
					}
					<Flex h="70%" w="45%"
						background={theme.colors.white} justifyContent='center'>
						<Flex
							h="650px" w="560px"
							background={theme.colors.white}
							borderRadius={"35px"}
							justifyContent='center'
							flexWrap='wrap'
						>

							<Flex
								h="52px" w="430px"
								m="30px 0 0 0"
								background={theme.colors.white}
								borderRadius={"10px"}
								alignItems={'center'}
								justifyContent='center'
							>
								<Text variant="xxxlg">
									Login
								</Text>
							</Flex>

							<Flex h="42px" w="100vw"
								background={theme.colors.white}>
								<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px"
								>
								</Text>

								<InputGroup>
									<InputLeftElement pointerEvents="none" children={<MdMailOutline opacity="0.4" color="#292d34" size="25px" />} />
									<Input
										letterSpacing="0.9px"
										fontWeight="400"
										fontSize="14px"
										variant='flushed'
										w="100%"
										type="text"
										borderBottomWidth='1px'
										borderColor={theme.colors.black}
										color="black"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
										placeholder="example@gmail.com"
									/>
								</InputGroup>
							</Flex>
							<Flex h="42px" w="100vw"
								background={theme.colors.white}>
								<Text fontSize="11px" color="#292d34" fontWeight="500" lineHeight="11px"
								>
								</Text>

								<InputGroup>
									<InputLeftElement pointerEvents="none" children={<MdLockOutline opacity="0.4" color="#292d34" size="23px" />} />
									<Input
										letterSpacing="0.9px"
										fontWeight="400"
										fontSize="14px"
										w="100%"
										type="password"
										variant="flushed"
										borderBottomWidth='1px'
										borderColor={theme.colors.black}
										onChange={(e) => {
											setPassword(e.target.value);
										}}
										placeholder="password"
									/>
								</InputGroup>
							</Flex>
							<Flex
								h="52px" w="100vw"
								background={theme.colors.white}
								alignItems={'center'}
								justifyContent='center'
							>
								<Button
									h="100%"
									w="100%"
									background={theme.colors.secondary}
									borderRadius={"10px"}
									border={`3px solid ${theme.colors.black}`}
									color={`${theme.colors.black}`}
									_hover={{
										bg: theme.colors.secondary,
									}}
									onClick={async () => {
										if (email === null || password === null) {
											toast({
												status: "info",
												title: "Alert!",
												description: "please fill the following option",
											});
											return;
										}
										await LoginInfo(email, password);
									}}>
									<Flex
										h="25px" w="100%" m="0 0 0 0"
										alignItems={'center'}
										justifyContent='center'
									>
										<Text variant="xlg" color={theme.colors.black}>
											Continue
										</Text>

									</Flex>
									{/* Sign Up */}
								</Button>
							</Flex>
							<Flex
								h="34px" w="390px"
								alignItems={'center'}
								justifyContent='center'
							>
								<Text variant="xxlg" color={theme.colors.black}>
									OR
								</Text>
							</Flex>
							<Flex
								h="52px" w="415px" m="0 0 0 0"
								background={theme.colors.white}
								border={`3px solid ${theme.colors.black}`}
								borderRadius={"10px"}
								alignItems={'center'}
							>
								<GoogleLogin
									clientId={clientId}
									onSuccess={handleGoogleLogin}
									onFailure={onFailure}
									cookiePolicy={'single-host-origin'}
									isSignedIn={false}
									render={renderProps => (
										<Button
											h="100%"
											w="100%"
											onClick={renderProps.onClick}
											borderRadius={"10px"}
											color={`${theme.colors.black}`}
										>
											<Flex
												h="25px" w="25px" m="0 0 0 0"
											>
												<img src={GoogleLogo} />
											</Flex>
											<Flex
												h="25px" w="200px" m="0 0 0 40px"
												alignItems={'center'}
											>
												<Text variant="xlg">
													Login with Google
												</Text>

											</Flex>
										</Button>
									)}
								>

								</GoogleLogin>
							</Flex>

							<Flex
								h="52px" w="415px" m="0 0 0 0"
								background={theme.colors.white}
								border={`3px solid ${theme.colors.black}`}
								borderRadius={"10px"}
								alignItems={'center'}
							>
								<Button
									h="100%"
									w="100%"
                                    onClick={handleMicrosoftLogin}
									borderRadius={"10px"}
									color={`${theme.colors.black}`}
								>
									<Flex
										h="25px" w="25px" m="0 0 0 44px"
									>
										<img src={MicrosoftLogo} />
									</Flex>
									<Flex
										h="25px" w="250px" m="0 0 0 40px"
										alignItems={'center'}
									>
										<Text variant="xlg">
											Login with Microsoft
										</Text>

									</Flex>
								</Button>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default Login;