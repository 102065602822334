import { Center, CenterProps, Spinner } from "@chakra-ui/react";
import React from "react";

export interface LoadingContainerProps extends CenterProps {
    isLoading?: boolean;
}

const LoadingContainer: React.FC<LoadingContainerProps> = ({ isLoading, ...props }) => {
    if (isLoading) {
        return (
            <Center flex={1} h="100%" w="100%" {...props}>
                <Spinner size="xl" />
            </Center>
        );
    }

    return <>{props.children}</>;
};

export default LoadingContainer;
