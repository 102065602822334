import { State } from "easy-peasy";
import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { v4 as uuid } from "uuid";

export class DuplicateCommand implements Command<State<CanvasStore>, "DuplicateElement"> {
	name = "DuplicateElement" as const;
	newElement: AllCanvasElements | null = null;
	newElementId: string;
	constructor(public selectedElementId: string) {
		this.newElementId = uuid();
	}

	execute(state: State<CanvasStore>): void {
		let element = state.canvasState[this.selectedElementId];
		this.newElement = JSON.parse(JSON.stringify(element));
		if (this.newElement == null) {
			throw new Error("impossible happend");
			return;
		}
		this.newElement.id = this.newElementId;
		this.newElement.x = this.newElement.x + 20;
		this.newElement.y = this.newElement.y + 20;

		state.canvasState[this.newElement.id] = this.newElement;
	}

	undo(state: State<CanvasStore>): void {
		if (this.newElement == null) {
			throw new Error("impossible happend");
			return;
		}
		delete state.canvasState[this.newElement.id];
		if (state.selectedElementId === this.newElement.id) {
			state.selectedElementId = null;
		}
	}
}
