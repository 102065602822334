import React from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import WaveSurfer from "wavesurfer.js";
import { sleep } from "../../utils";
import { debounce } from "../../utils/debounce";
import { copy, cut } from "../../utils/Audio/manipulate";

export function handlesAudio(
  setAudioFile: (file: File | null) => void,
  setRegionStart: (val: number | null) => void,
  setRegionEnd: (val: number | null) => void,
  setFileName: (file: string) => void,
  setOriginalAudio: (blob: Blob) => void,
  setResultAudio: (blob: Blob) => void,
  setOriginalAudioUrl: (url: string | null) => void,
  setOriginal: (bol: boolean) => void,
  setResult: (bol: boolean) => void,
  resetToNull: () => void,
  setSubtitles: (arr: []) => void,
  waveSurfer: WaveSurfer | null,
  inputRef: HTMLInputElement | null,
  setRegionCreated: (val: boolean) => void,
  setName: (nam: string) => void,
  setSelectedFile: (file: File | null) => void
) {
  const regionId = "Trim";
  const handleLoad = async () => {
    //debugger;
    resetToNull();
    await sleep(100);
    if (inputRef?.files?.[0] == null || waveSurfer == null) {
      console.error("null problem in handleLoad()");
      return;
    }
    const file = inputRef.files[0];
    setAudioFile(file);

    if (file) {
      setSelectedFile(file); 
      setRegionStart(null);
      setRegionEnd(null);
      if (waveSurfer.regions.list[regionId as any]) {
        waveSurfer.regions.list[regionId as any].remove();
        setRegionCreated(false);
      }
      setName(file.name);
      const reader = new FileReader();
      reader.onload = (evt: ProgressEvent<FileReader>) => {
        // @ts-ignore
        const blob = new window.Blob([new Uint8Array(evt.target.result)]);
        // @ts-ignore
        blob.name = file.name;
        setOriginalAudio(blob);
        setResultAudio(blob);
        setOriginalAudioUrl(null);
        setFileName(file.name);
        // console.log(file.name);
        setOriginal(true);
        setResult(true);
        waveSurfer.loadBlob(blob);
        // setSubtitles([]);
      };
      reader.onerror = function (err) {
        console.error("An error ocurred reading the file: ", err);
      };
      reader.readAsArrayBuffer(file);
    }
    handleUpdateConfig();
  };

  const handleUpdateConfig = debounce(() => {
    // console.log("1rst");

    if (!waveSurfer) return;
    // console.log("2rst");

    let trimmedBlob;
    const region = waveSurfer.regions.list[regionId as any];
    const { start, end } = region;
    const duration = waveSurfer.getDuration();
    const regionDuration = end - start;

    if (duration === regionDuration) {
      trimmedBlob = copy(region, waveSurfer);
    } else {
      trimmedBlob = cut(region, waveSurfer);
    }

    //@ts-ignore
    trimmedBlob.name = "result_audio.mp3";

    setResultAudio(trimmedBlob);
    setRegionStart(start);
    setRegionEnd(end);
    // setOriginalAudio(trimmedBlob);
    // console.log(trimmedBlob, start, "trimmedBlob");
  }, 500);

  handleLoad();
}
