import * as KonvaValue from "konva";

export type GlobalKonvaType = typeof KonvaValue.default;

export interface Command<T, Name extends string> {
	name: Name;
	execute(state: T): void;
	undo(state: T): void;
}

export type WaveSurferType = {
	play: Function;
	pause: Function;
	getCurrentTime: Function;
	zoom: Function;
	seekTo: (point: number) => void;
	isPlaying: () => boolean;
	regions: { list: Object[] };
	defaultParams?: { minPxPerSec: number };
	on: (event: string, callback: Function) => void;
	setCurrentTime: (time: number) => void;
	backend: {
		setFilter: (filter: Object) => void;
		ac: {
			createGain: Function;
		};
	};
};

type _NumbersFrom0ToN<Nr extends number> = Nr extends Nr
	? number extends Nr
		? number
		: Nr extends 0
		? never
		: _NumbersFrom0ToNRec<Nr, [], 0>
	: never;

type _NumbersFrom0ToNRec<Nr extends number, Counter extends any[], Accumulator extends number> = Counter["length"] extends Nr
	? Accumulator
	: _NumbersFrom0ToNRec<Nr, [any, ...Counter], Accumulator | Counter["length"]>;

export type NrRange<Start extends number, End extends number> = Exclude<_NumbersFrom0ToN<End>, _NumbersFrom0ToN<Start>>;

export type VisualizerBarRange = NrRange<5, 16>;

export type Subtitle = {
	endTime: number;
	id: string;
	startTime: number;
	text: string;
};
export type CanvasDimension = "16:9" | "9:16" | "1:1";

export type TemplatesTypes = "landscape" | "square" | "portrait";
export type TemplatesTypeSelection = "all" | TemplatesTypes;

export const roleTypesArr = [
	"Author",
	"Blogger",
	"Company or Brand",
	"Educator",
	"Journalist",
	"Marketing Agency",
	"Media Company",
	"Musician (producer)",
	"Musician (singer)",
	"Non-profit organization",
	"Podcaster",
	"Public Speaker",
	"Radio Station Show Host",
	"Record Label",
	"Social Media Influencer",
	"Vlogger",
];
export type FontsDataArr = { label: any; value: any }[];

export type Vector = {
	x: number;
	y: number;
};

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function isMacintosh() {
	return navigator.userAgent.indexOf("Mac") > -1;
}

export function isSpecialKeyPressing(e: MouseEvent) {
	if (isMacintosh()) {
		return e.metaKey;
	}

	return e.ctrlKey;
}
