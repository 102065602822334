import { action, computed, unstable_effectOn } from "easy-peasy";
import { store } from "..";
import { VisualizationStore } from "../../types/canvasStoreTypes/StoreTypes";
import { getBarCount } from "../../utils/canvasHelpers";
import { findElementInRange } from "../../utils/findInRanges";
import { Subtitle } from "../../types/index";

// console.log("hello");

export const visualizationStore: VisualizationStore = {
	currentSubtitle: "",
	currentTime: 0,
	remainingAudio: 0,
	isPlaying: false,
	isLoadingSubtitles: false,
	subtitles: [],
	waveSurfer: null,
	audioStream: computed([(a) => a.waveSurfer], (waveSurfer) => {
		//debugger;
		if (!waveSurfer) return null;
		//@ts-ignore
		// let audioCtx = waveSurfer?.backend.ac;
		let audioCtx = new AudioContext();
		let dest = audioCtx.createMediaStreamDestination();
		let aStream = dest.stream;

		// var sourceNode = audioCtx.createMediaElementSource();
		// sourceNode.connect(dest);
		return aStream;
	}),

	audioContext: computed([(s) => s.waveSurfer], (waveSurfer) => {
		// if (audio == null) {
		// 	store.getState().visualizationStore.audioContext.close();
		// }

		return waveSurfer?.backend?.getAudioContext() ?? null;

		let context = new AudioContext();

		return context;
	}),

	videoFinishRatio: computed(
		[
			(state) => {
				return state.currentTime;
			},
			(state, gs) => gs.audioStore.regionStart,
			(state, gs) => gs.audioStore.regionEnd,
		],
		(currentTime: number, regionStart, regionEnd) => {
			const duration = (regionEnd ?? 0) - (regionStart ?? 0);
			const time = currentTime - (regionStart ?? 0);

			return time / duration || 0;
		},
	),
	analyser: computed(
		[
			(state) => {
				return state.waveSurfer;
			},
		],
		(waveSurfer) => {
			return (waveSurfer?.backend as any)?.analyser;

			// if (!audio) {
			// 	return null;
			// }

			// let context = new AudioContext();
			// let src = context.createMediaElementSource(audio);
			// let analyser = context.createAnalyser();
			// src.connect(analyser);
			// analyser.connect(context.destination);
			// const fftSize = getBarCount(9);
			// analyser.fftSize = fftSize;
			// return analyser;
		},
	),

	subtitleWithTime: computed(
		[
			(state) => {
				return state.subtitles;
			},
			(state) => {
				return state.currentTime;
			},
		],
		(subtitles, time) => {
			const currentSubtitles = findElementInRange(subtitles, ["startTime", "endTime"], time);
			return currentSubtitles?.text ?? "";
		},
	),

	// audio: computed([(s, gstore) => s.waveSurfer, (state) => state.subtitles], (waveSurfer, subtitles) => {
	// 	console.log("+++++++++++++AUDIO IS CHANGED");

	// 	if (!waveSurfer) {
	// 		return null;
	// 	}

	// 	return
	// 	const audio = new Audio(URL.createObjectURL(audioFile));

	// 	const timeupdate = (e: Event) => {
	// 		const currentSubtitle = findElementInRange(subtitles, ["startTime", "endTime"], audio.currentTime);
	// 		if (currentSubtitle) {
	// 			store.getActions().visualizationStore.setCurrentSubtitle(currentSubtitle.text);
	// 		}
	// 		if (e.timeStamp) {
	// 			store.getActions().visualizationStore.setCurrentTime(audio.currentTime);
	// 		}
	// 	};
	// 	audio?.removeEventListener("timeupdate", timeupdate);
	// 	audio.addEventListener("timeupdate", timeupdate);

	// 	audio.addEventListener("play", (e: Event) => {
	// 		store.getActions().visualizationStore.setIsPlaying(true);
	// 		store.getActions().visualizationStore.setCurrentSubtitle("");
	// 	});

	// 	audio.addEventListener("pause", (e: Event) => {
	// 		console.log("change", e);
	// 		store.getActions().visualizationStore.setIsPlaying(false);
	// 	});

	// 	audio.addEventListener("ended", (e: Event) => {
	// 		store.getActions().visualizationStore.setCurrentSubtitle("");
	// 	});

	// 	audio.load();
	// 	return audio;
	// }),

	setIsLoadingSubtitles: action((state, payload) => {
		state.isLoadingSubtitles = payload;
	}),
	setCurrentTime: action((state, payload) => {
		state.currentTime = payload;
	}),
	onAudioChange: unstable_effectOn([(state) => state.waveSurfer, (state) => state.analyser], (actions, { current: [wavesurfer, anan] }) => {
		console.log("working unstable_effectOn normal", wavesurfer);

		// wavesurfer?.on("audioprocess ", (e) => {
		// 	console.log("audioprocess", e);
		// });

		return () => {
			console.log("working unstable_effectOn callback");
		};
	}),

	setWaveSurfer: action((state, payload) => {
		state.waveSurfer = payload;
	}),

	setIsPlaying: action((state, payload) => {
		state.isPlaying = payload;
	}),

	setCurrentSubtitle: action((state, payload) => {
		state.currentSubtitle = payload;
	}),
	setRemainingAudio: action((state, payload) => {
		state.remainingAudio = payload;
	}),

	setSubtitles: action((state, payload) => {
		state.subtitles = payload;
	}),
};
