import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { AuthenticatedPermissionRes, PermissionsInfo, UnauthenticatedPermissionRes } from "../../types/User/permissons";
import { TokenService } from "../token";

export async function permissionApi() {
	let token = TokenService.getToken();

	return await catchTry(
		mainAxios.get<AuthenticatedPermissionRes | UnauthenticatedPermissionRes>(`${defaultOptions.baseURL}/api/permissions`, {
			headers: {
				Authorization: token,
			},
		}),
	);
}
