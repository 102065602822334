import { State } from "easy-peasy";
import React from "react";
import { Command } from "../../types";
import { AllCanvasElements, TypeGroupElement, GroupElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { v4 as uuid } from "uuid";
import { useEasyState } from "../../store/hooks";
import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Shape, ShapeConfigHandler } from "konva/lib/Shape";

export class RemoveGroupCommand implements Command<State<CanvasStore>, "RemoveGroupCommand"> {
	name = "RemoveGroupCommand" as const;
	groupId: string;
	ele: GroupElement;
	constructor(groupId: string, ele: GroupElement) {
		//debugger;

		this.groupId = groupId;
		this.ele = ele;
	}

	execute(state: State<CanvasStore>): void {
		const group: GroupElement = state.canvasState[this.groupId] as GroupElement;

		const groupInstance = state.selectedElementsInstance[group.id] as Konva.Group;
		const cameraGroupInstance = state.cameraGroupInstance?.attrs;

		// console.log(
		// 	groupInstance.children?.map((c) => ({
		// 		...c.getAbsolutePosition(),
		// 		...c.getAbsoluteScale(),
		// 	})),
		// );
		//debugger;
		for (const child of groupInstance.children ?? []) {
			const id = child.attrs["data-id"];
			child.attrs["name"] = "object";

			const { x, y } = child.getAbsolutePosition();
			// const { x: sx, y: sy } = child.getAbsoluteScale();
			// const x = child.attrs.x;
			// const y = child.attrs.y;
			// const sx = child.attrs.scaleX;
			// const sy = child.attrs.scaleY;
			const ro = child.getAbsoluteRotation();
			// console.log("group.children[id]", child);
			state.canvasState[id] = group.children[id];
			state.canvasState[id].scaleX = child.attrs.scaleX * groupInstance.attrs.scaleX;
			state.canvasState[id].scaleY = child.attrs.scaleY * groupInstance.attrs.scaleY;
			state.canvasState[id].x = x - cameraGroupInstance.x;
			state.canvasState[id].y = y - cameraGroupInstance.y;
			state.canvasState[id].rotationDeg = ro;
		}

		delete state.canvasState[this.groupId];
	}

	undo(state: State<CanvasStore>): void {
		const trans = state.konvaTransformerInstance;
		if (!trans) {
			return;
		}

		const group: GroupElement = {
			id: this.groupId,
			type: "Group",
			scaleX: trans.scaleX(),
			scaleY: trans.scaleY(),
			opacity: 1,
			x: trans.x(),
			y: trans.y(),
			skewX: trans.skewX(),
			skewY: trans.skewY(),
			height: trans.height(),
			width: trans.width(),
			rotationDeg: 0,
			children: {},
		};

		for (const id of state.selectedElementsIds) {
			delete state.selectedElementsInstance[id].attrs["name"];

			group.children[id] = state.canvasState[id];
			delete state.canvasState[id];
		}

		state.canvasState[this.groupId] = group;
	}
}
