import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CanvasRootObject } from "../../types/ApiTypex/AllCanvas";
import { TokenService } from "../token";

export async function allCanvasApi() {
    let token = TokenService.getToken;
    return await catchTry(
        mainAxios.get<CanvasRootObject>(`${defaultOptions.baseURL}/api/canvas`, {
            headers: {
                Authorization: token,
            },
        })
    );
}
