import { NrRange, VisualizerBarRange } from "../types";
import { TemplatesTypes } from "../types/index";
export const MIN_BAR_SIZE = 2;

export function interpolate(y1: number, y2: number, x1: number, x2: number, x: number) {
	return y1 + (x - x1) * ((y2 - y1) / (x2 - x1));
}

export function getBarCount(sampleSize: VisualizerBarRange) {
	return 2 ** sampleSize;
}

export function getBarGap(elementWidth: number, gapPercentage: number, noOfBars: number) {
	const maxBarSize = elementWidth / noOfBars;
	const maxWidthWhichCanBeShrined = maxBarSize - MIN_BAR_SIZE;
	return maxWidthWhichCanBeShrined * (gapPercentage / 100);
}

export function calculateCanvasTransform(type: TemplatesTypes, maxWidth: number, maxHeight: number) {
	let ratio = maxWidth / maxHeight;
	if (type == "landscape" && ratio >= 16 / 9) {
		//debugger;
		let canvasH = 35 * 9;
		let canvasW = 35 * 16;
		let scale = maxHeight / canvasH;

		return { width: canvasW, height: canvasH, scale: scale };
	}

	if (type == "landscape" && ratio < 16 / 9) {
		let canvasH = 35 * 9;
		let canvasW = 35 * 16;
		let scale = maxWidth / canvasW;

		return { width: canvasW, height: canvasH, scale: scale };
	}

	if (type == "portrait" && ratio >= 9 / 16) {
		let canvasH = 35 * 16;
		let canvasW = 35 * 9;
		let scale = maxHeight / canvasH;
		return { width: canvasW, height: canvasH, scale: scale };
	}
	if (type == "portrait" && ratio < 9 / 16) {
		let canvasH = 35 * 16;
		let canvasW = 35 * 9;
		let scale = maxWidth / canvasW;
		return { width: canvasW, height: canvasH, scale: scale };
	}

	if (type == "portrait" && ratio < 9 / 16) {
		let canvasH = 35 * 16;
		let canvasW = 35 * 9;
		let scale = maxWidth / canvasW;
		return { width: canvasW, height: canvasH, scale: scale };
	}

	if (type == "square") {
		let canvasDim = 35 * 16;
		let smallestDimension = Math.min(maxHeight, maxWidth);
		let scale = smallestDimension / canvasDim;
		return { width: canvasDim, height: canvasDim, scale: scale };
	}
	return { width: 555, height: 555, scale: 1 };
}

export function calculateCanvasPosition(stageWidth: number, stageHeight: number, newScale: number) {
	const newAbsoluteDem = {
		height: stageHeight * newScale,
		width: stageWidth * newScale,
	};

	const deltaDim = {
		height: newAbsoluteDem.height - stageHeight,
		width: newAbsoluteDem.width - stageWidth,
	};

	const newPos = {
		x: -deltaDim.width / 2,
		y: -deltaDim.height / 2,
	};

	return newPos;
}
