import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";
import { history } from "../../app/router/history";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import Line from "../../assets/images/LineA.svg";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { TextElement, TypeTextElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { debounce } from "../../utils/debounce";
import UnderLine from "./UnderLine";
import dropdown from "../../assets/images/dropdown.svg";
import { useToast } from "@chakra-ui/react";
import { fonts } from "../../utils/Api/fonts";
import { FontsApi, Item } from "../../types/ApiTypex/Fonts";
import { Spinner } from "@chakra-ui/react";
import WebFontLoader from "webfontloader";
import justify from "../../assets/images/Justify.svg";
import center from "../../assets/images/Center.svg";
import left from "../../assets/images/Left.svg";
import right from "../../assets/images/Rightalign.svg";
import { theme } from "../../config/theme/baseDesign";
import { TextStore } from "../../types/canvasStoreTypes/StoreTypes";
import { FiSearch } from "react-icons/fi";
import DataListDropdown from "./DataListDropdown";
import { FontsDataArr } from "../../types";
import { Color, ColorPicker, useColor } from "react-color-palette";
import { ChromePicker, ColorResult, RGBColor, SketchPicker } from "react-color";
import ColorSelector from "../common/ColorSelector";
import { rgba } from "polished";
import { basePath } from "../../constants";
export interface TextOptionMenuProps {}

const TextOptionMenu: React.FC<TextOptionMenuProps> = (props) => {
	const { setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);
	const { canvasState, selectedElement, selectedElementId, konvaStageInstance, konvaTransformerInstance, selectedElementsInstance } = useEasyState(
		(store) => store.canvasStore,
	);
	const { setApiRes } = useEasyActions((state) => state.textStore);
	const { apiRes, isAutoCompleteOpen } = useEasyState((store) => store.textStore);
	const [isSmall] = useMediaQuery("(max-width: 993px)");

	let selectedTextElement = selectedElement as TypeTextElement;
	// const highlightUpload = useRef<null | HTMLInputElement>(null);
	// const filledUpload = useRef<null | HTMLInputElement>(null);
	const [transparency, setTransparency] = useState(selectedTextElement?.opacity ?? 0);
	const [fontSize, setFontSize] = useState(selectedTextElement?.fontSize ?? 20);
	const toast = useToast();
	const [loaded, setLoaded] = useState(true);
	// const [highlightedColor, setHighlightedColor] = useState("#ffffff");
	// const [filledColor, seFilledColor] = useState(selectedTextElement?.fill ?? "#000000");
	const [selectedFont, setSelectedFont] = useState(selectedTextElement?.fontFamily ?? "roboto");
	// const inputRef = useRef<null | HTMLInputElement>(null);
	// const [text, setText] = useState("");
	const [color, setColor] = useState(selectedTextElement?.bgColor);
	const [colorFill, setColorFill] = useState<string>(rgba(selectedTextElement?.fill, 1));

	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [displayColorPickerHighlight, setDisplayColorPickerHighlight] = useState(false);

	const blurHighlight = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ChromeContainer")?.contains(e.target as any)) {
			setDisplayColorPickerHighlight(false);
		}
	}, []);

	useEffect(() => {
		if (displayColorPickerHighlight == false) {
			window.removeEventListener("click", blurHighlight);
			return;
		}

		window.addEventListener("click", blurHighlight);
		return () => {
			window.removeEventListener("click", blurHighlight);
		};
	}, [displayColorPickerHighlight]);
	const blurColor = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayColorPicker(false);
		}
	}, []);
	useEffect(() => {
		if (displayColorPicker == false) {
			window.removeEventListener("click", blurColor);
			return;
		}

		window.addEventListener("click", blurColor);
		return () => {
			window.removeEventListener("click", blurColor);
		};
	}, [displayColorPicker]);

	let bg = "none";
	if (selectedTextElement.fontStyle == "bold") {
		bg = theme.colors.gray1;
	}
	let bgItalics = "none";
	if (selectedTextElement.fontStyle == "italic") {
		bgItalics = theme.colors.gray1;
	}
	function highlightColor(color: string) {
		if (selectedElementId == null) {
			return;
		}

		executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, bgColor: color }]));
	}
	function fillColor(e: string) {
		if (selectedElementId == null) {
			return;
		}
		// console.log("fillcolor");

		executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, fill: e }]));
	}

	function transparencyFun(e: any) {
		if (selectedElementId == null) {
			return;
		}
		// console.log("transparency", transparency);

		executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
	}

	const highlightColorDebounce = useCallback(debounce(highlightColor, 500), [selectedElementId]);
	const fillColorDebounce = useCallback(debounce(fillColor, 500), [selectedElementId]);
	const transparencyDebounce = useCallback(debounce(transparencyFun, 500), [selectedElementId]);

	let clientId = "AIzaSyB9qDuz51G32yV1qdT2QTDuqNupECdUKac";
	async function onClickFontsHandler() {
		// const response = await fonts(clientId);
		// if (response instanceof Error) {
		// 	toast({
		// 		status: "error",
		// 		title: "Error!",
		// 		description: "There is some error please try la7ter.",
		// 	});
		// 	return;
		// }
		// setApiRes(response.data.items);
	}
	useMemo(() => {
		setFont(selectedFont);
	}, [selectedFont]);
	// useEffect(() => {
	// 	onClickFontsHandler();
	// 	console.log("onClickFontsHandler");
	// }, []);

	function setFont(font: string) {
		setLoaded(false);

		WebFontLoader.load({
			google: {
				families: [font, "arial"],
			},
			fontactive: (font) => {
				setLoaded(true);

				if (selectedElementId == null) {
					return;
				}

				executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, fontFamily: selectedFont }]));
			},
		});
	}

	function file(e: any) {
		const file = e.target.files[0];
	}

	return (
		<Flex flexDir="column" flex={1} h="calc(100vh - 100px)" overflow="auto">
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							history.push(basePath + "/design");
							setSelectedElementsIds([]);
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Heading ml="35px" mb="30px" variant="mdb">
					Text
				</Heading>
			</Flex>

			<Flex flex={1} mx="30px" flexDir="column">
				<Flex mb="35px" flexDir="row" alignItems="center">
					<Text variant="lg" mr="15px">
						Font
					</Text>
					{apiRes && <DataListDropdown onClickListItem={() => {}} fonts={apiRes} />}
				</Flex>

				<Flex mb="35px">
					<Text variant="lg" mr="25px">
						Size
					</Text>
					<Flex flex={1} justifyContent="space-around">
						<Flex
							cursor="pointer"
							border="1px solid grey"
							borderRadius="40%"
							w="25px"
							h="25px"
							justifyContent="center"
							alignItems="center"
							onClick={() => {
								if (selectedElementId == null) {
									return;
								}
								executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, fontSize: fontSize }]));
								setFontSize(fontSize - 1);
								konvaTransformerInstance?.nodes(Object.values(selectedElementsInstance));
							}}>
							<Box>-</Box>
						</Flex>
						<Flex justifyContent="center" alignItems="center">
							{fontSize}
						</Flex>
						<Flex
							cursor="pointer"
							border="1px solid grey"
							borderRadius="40%"
							w="25px"
							justifyContent="center"
							alignItems="center"
							h="25px"
							onClick={() => {
								if (selectedElementId == null) {
									return;
								}
								executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, fontSize: fontSize }]));
								setFontSize(fontSize + 1);
								konvaTransformerInstance?.nodes(Object.values(selectedElementsInstance));
							}}>
							<Box>+</Box>
						</Flex>
					</Flex>
				</Flex>

				<Flex mb="35px" justifyContent="space-between">
					<Flex
						cursor="pointer"
						justifyContent="center"
						alignItems="center"
						flexDir="column"
						onClick={() => {
							if (displayColorPickerHighlight) {
								setDisplayColorPickerHighlight(false);
							}
							setDisplayColorPicker(true);
							// console.log("fill", displayColorPicker);
						}}>
						<Flex position="relative" mb="10px" flexDir="column" justifyContent="center" alignItems="center">
							<Flex>A</Flex>

							<UnderLine color={colorFill} />
							{displayColorPicker && (
								<Flex id="ColorContainer" left="-6" bottom="0" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={colorFill}
											onChange={(e) => {
												let color = rgba(e.hex, e.rgb.a);

												setColorFill(color);
												fillColorDebounce(color);
											}}
											// hideHSV
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
						<Text variant="md">Color</Text>
					</Flex>

					<Flex
						cursor="pointer"
						justifyContent="center"
						alignItems="center"
						flexDir="column"
						onClick={() => {
							if (displayColorPicker) {
								setDisplayColorPicker(false);
							}

							setDisplayColorPickerHighlight(true);

							// console.log(displayColorPickerHighlight);
						}}>
						<Flex position="relative" mb="10px" flexDir="column" justifyContent="center" alignItems="center">
							<Flex justifyContent="center" h="25px" w="25px" bg={color}>
								A
							</Flex>
							{displayColorPickerHighlight && (
								<Flex id="ChromeContainer" bottom="0" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={color}
											onChange={(e) => {
												let color = rgba(e.hex, e.rgb.a);
												setColor(color);
												highlightColorDebounce(color);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
						<Text variant="md">Highlight</Text>
					</Flex>
					<Flex
						cursor="pointer"
						justifyContent="center"
						alignItems="center"
						flexDir="column"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}

							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, fontStyle: "bold" }]));
						}}>
						<Flex mb="10px" h="25px" w="25px" justifyContent="center" bg={bg}>
							<b>A</b>
						</Flex>
						<Text variant="md">Bold</Text>
					</Flex>
					<Flex
						cursor="pointer"
						justifyContent="center"
						alignItems="center"
						flexDir="column"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}
							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, fontStyle: "italic" }]));
						}}>
						<Flex mb="10px" bg={bgItalics} h="25px" w="25px" justifyContent="center">
							<i>A</i>
						</Flex>
						<Text variant="md">Italics</Text>
					</Flex>
				</Flex>

				<Flex mb="35px" justifyContent="space-between">
					<Flex
						cursor="pointer"
						flexDir="column"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}
							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, alignment: "left" }]));
						}}>
						<img src={left} style={{ height: "16px" }} />
						<Text mt="10px" variant="md" w="30px">
							Left align
						</Text>
					</Flex>
					<Flex
						cursor="pointer"
						flexDir="column"
						justifyContent="flex-start"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}
							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, alignment: "center" }]));
						}}>
						<img src={center} style={{ height: "16px" }} />
						<Text variant="md" mt="10px">
							Center
						</Text>
					</Flex>
					<Flex
						// h="50px"
						cursor="pointer"
						flexDir="column"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}
							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, alignment: "right" }]));
						}}>
						<img src={right} style={{ height: "16px" }} />
						<Text mt="10px" variant="md" w="30px">
							Right align
						</Text>
					</Flex>
					<Flex
						cursor="pointer"
						flexDir="column"
						onClick={() => {
							if (selectedElementId == null) {
								return;
							}
							executeCommand(new UpdateCommand<TextElement>([{ id: selectedElementId, ...selectedElement, alignment: "justify" }]));
						}}>
						<img src={justify} style={{ height: "16px" }} />
						<Text variant="md" mt="10px">
							Justify
						</Text>
					</Flex>
				</Flex>

				<Flex mb="30px" flexDir="column">
					<Flex flexDir="row">
						<Flex mb="20px" flex={1} variant="lg">
							Transparency
						</Flex>
						<Text variant="md">{Math.floor(transparency * 100)}%</Text>
					</Flex>
					<input
						type="range"
						id="vol"
						name="vol"
						min="0"
						max="1"
						step={0.01}
						value={transparency}
						onChange={(e) => {
							let value = Number(e.target.value);
							transparencyDebounce(value);
							setTransparency(value);
						}}></input>
					{/* <Slider
						min={0}
						max={1}
						step={0.01}
						mt="20px"
						defaultValue={transparency}
						onChange={(e) => {
							setTransparency(e);
						}}
						onChangeEnd={(e) => {
							if (selectedElementId == null) {
								return;
							}
							setTransparency(e);
							executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
						}}
						aria-label="slider-ex-1">
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb bg="primary" />
					</Slider> */}
				</Flex>
			</Flex>

			{!isSmall && (
				<Flex flexDir="column">
					<Flex justifyContent="center" mb="5px">
						<Button
							cursor="pointer"
							onClick={() => {
								history.push(basePath + "/design");
								setSelectedElementsIds([]);
							}}
							variant="styled"
							textDecoration="none">
							Back
						</Button>
					</Flex>
					<Flex justifyContent="center" mb="30px">
						<Button
							cursor="pointer"
							onClick={() => {
								history.push(basePath + "/design");
								setSelectedElementsIds([]);
							}}
							variant="styled">
							Continue
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default TextOptionMenu;
