import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CanvasRootObject } from "../../types/ApiTypex/AllCanvas";
import { TokenService } from "../token";

export async function defaultCanvasApi() {
    // let token = TokenService.getToken;

    return await catchTry(
        mainAxios.get<CanvasRootObject>(`${defaultOptions.baseURL}/api/canvas/show_defaults`, {
            // headers: {
            //     Authorization: token,
            // },
        })
    );
}
