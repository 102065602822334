import { catchTry } from "../betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { useEasyState } from "../../store/hooks";
import { SignUpResponse } from "../../types/ApiTypex/Signup";

export async function completeSignUp(fullName: string, role: string, purpose: string) {
	return await catchTry(
		mainAxios.post<SignUpResponse>(`${defaultOptions.baseURL}/api/users/complete_signup`, {
			user: {
				full_name: fullName,
                role: role,
				purpose: purpose,
			},
		}),
	);
}
