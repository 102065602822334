import React, { useCallback, useState, useEffect } from "react";
import { Button, Flex, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { debounce } from "../../utils/debounce";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { ProgressBar } from "../../types/canvasStoreTypes/CanvesTypes";
import { history } from "../../app/router/history";
import { useColor, ColorPicker } from "react-color-palette";
import ColorSelector from "../common/ColorSelector";
import { basePath } from "../../constants";

export interface ProgressBarPropertiesProps {}

const ProgressBarProperties: React.FC<ProgressBarPropertiesProps> = (props) => {
	const { removeElement, updateElement, setSelectedElementsIds, executeCommand } = useEasyActions((state) => state.canvasStore);
	const { canvasState, selectedElement, selectedElementId } = useEasyState((store) => store.canvasStore);
	let selectedProgressBar = selectedElement as ProgressBar;

	const [transparency, setTransparency] = useState(selectedProgressBar?.opacity ?? 0);

	const [fillColor, setFillColor] = useState(selectedProgressBar?.fill ?? "#d3ff82");
	const [fillBar, setFillBar] = useState(selectedProgressBar?.fillBar ?? "#beff25");
	const [isSmall] = useMediaQuery("(max-width: 993px)");
	// const [displayColorPickerFill, setDisplayColorPickerFill] = useState(false);
	// const [displayColorPickerFillMovingBar, setDisplayColorPickerFillMovingBar] = useState(false);
	const [colorFill, setColorFill] = useState(selectedProgressBar?.fill);
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [colorFillBar, setColorFillBar] = useState(selectedProgressBar?.fillBar);
	const [displayColorBarPicker, setDisplayColorBarPicker] = useState(false);
	function onChangeMovingBarFill(e: any) {
		// console.log(e);
		if (selectedProgressBar.id == null) {
			return;
		}
		executeCommand(new UpdateCommand<ProgressBar>([{ id: selectedProgressBar.id, fillBar: e.hex }]));
		setFillBar(e);
	}
	function onChangeFill(e: any) {
		// console.log(e);
		if (selectedProgressBar.id == null) {
			return;
		}
		executeCommand(new UpdateCommand<ProgressBar>([{ id: selectedProgressBar.id, fill: e.hex }]));
		setFillColor(e);
	}

	function transparencyFun(e: any) {
		if (selectedElementId == null) {
			return;
		}
		// console.log("transparency", transparency);

		executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
	}

	const onChangeFillDebounce = useCallback(debounce(onChangeFill, 500), [onChangeFill, selectedElementId]);
	const onChangeFillDebounceMovingBar = useCallback(debounce(onChangeMovingBarFill, 500), [onChangeMovingBarFill, selectedElementId]);
	const transparencyDebounce = useCallback(debounce(transparencyFun, 500), [selectedElementId]);

	const blurColor = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayColorPicker(false);
		}
	}, []);

	useEffect(() => {
		if (displayColorPicker == false) {
			window.removeEventListener("click", blurColor);
			return;
		}

		window.addEventListener("click", blurColor);
		return () => {
			window.removeEventListener("click", blurColor);
		};
	}, [displayColorPicker]);
	const blurColorBar = useCallback((e: MouseEvent) => {
		if (e.target && !document.getElementById("ColorContainer")?.contains(e.target as any)) {
			setDisplayColorBarPicker(false);
		}
	}, []);

	useEffect(() => {
		if (displayColorBarPicker == false) {
			window.removeEventListener("click", blurColorBar);
			return;
		}

		window.addEventListener("click", blurColorBar);
		return () => {
			window.removeEventListener("click", blurColorBar);
		};
	}, [displayColorBarPicker]);

	return (
		<Flex flexDir="column" flex={1} mb="20px">
			<Flex flexDir="column">
				<Flex flex={1} justifyContent="flex-end" mr="20px">
					<Flex
						cursor="pointer"
						onClick={() => {
							setSelectedElementsIds([]);
							history.push(basePath + "/design");
						}}
						w="40px"
						mt="10px"
						mr={["10px", null, "0px"]}
						justifyContent="flex-end">
						✖
					</Flex>
				</Flex>
				<Heading ml="35px" mb="40px" variant="mdb">
					Visualizations
				</Heading>
			</Flex>

			<Flex flexDir="column" mx="35px" mb="30px" flex={1}>
				<Flex mb="30px">
					<Flex justifyContent="space-between" flex={1}>
						<Text variant="lg">Color</Text>

						<Flex
							w="25px"
							h="25px"
							bg={colorFill}
							position="relative"
							borderRadius="40%"
							onClick={() => {
								setDisplayColorPicker(true);
								// console.log("fill", displayColorPicker);
							}}>
							{displayColorPicker && (
								<Flex id="ColorContainer" bottom="0" left="-150" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={colorFill}
											onChange={(e) => {
												setColorFill(e.hex);
												onChangeFillDebounce(e);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
					</Flex>
					<Flex flex={1}></Flex>
				</Flex>

				<Flex mb="30px">
					<Flex justifyContent="space-between" flex={1}>
						<Text variant="lg">Color</Text>
						<Flex
							w="25px"
							h="25px"
							bg={colorFillBar}
							position="relative"
							borderRadius="40%"
							onClick={() => {
								setDisplayColorBarPicker(true);
								// console.log("fill", displayColorBarPicker);
							}}>
							{displayColorBarPicker && (
								<Flex id="ColorContainer" bottom="0" left="-150" transform="translateY(100%)" zIndex="20" position="absolute">
									<Flex border=" 1px solid #80808052" borderRadius=" 10px">
										<ColorSelector
											color={colorFillBar}
											onChange={(e) => {
												setColorFillBar(e.hex);
												onChangeFillDebounceMovingBar(e);
											}}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>
					</Flex>
					<Flex flex={1}></Flex>
				</Flex>

				<Flex flexDir="column">
					<Flex flexDir="row">
						<Flex flex={1} variant="lg">
							Transparency
						</Flex>
						<Text variant="md">{Math.floor(transparency * 100)}%</Text>
					</Flex>
					<input
						value={transparency}
						type="range"
						id="vol"
						name="vol"
						min="0"
						max="1"
						step={0.01}
						onChange={(e) => {
							let value = Number(e.target.value);
							transparencyDebounce(value);
							setTransparency(value);
						}}></input>

					{/* <Slider
						min={0}
						max={1}
						step={0.01}
						mt="20px"
						defaultValue={transparency}
						onChange={(e) => {
							setTransparency(e);
						}}
						onChangeEnd={(e) => {
							if (selectedProgressBar == null) {
								return;
							}
							setTransparency(e);
							executeCommand(new UpdateCommand([{ id: selectedProgressBar.id, opacity: e }]));
							console.log(e);
						}}
						aria-label="slider-ex-1">
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb bg="primary" />
					</Slider> */}
				</Flex>
			</Flex>

			{!isSmall && (
				<Flex flexDir="column">
					<Flex justifyContent="center" mb="5px">
						<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled" textDecoration="none">
							Back
						</Button>
					</Flex>
					<Flex justifyContent="center" mb="30px">
						<Button cursor="pointer" onClick={() => history.push(basePath + "/design")} variant="styled">
							Continue
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default ProgressBarProperties;
