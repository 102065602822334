import { Button, Flex, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { history } from "../../app/router/history";
import { AllCanvasElements, ImageElement } from "../../types/canvasStoreTypes/CanvesTypes";
import { UpdateCommand } from "../../Commands/CanvasCommanda/UpdateCommand";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import ImagesOptionMenu from "./ImagesOptionMenu";
import debounce from "lodash/debounce";
import OverFlowBox from "../common/OverFlowBox";
import { basePath } from "../../constants";

export interface ImagesPropertiesProps {}

const ImagesProperties: React.FC<ImagesPropertiesProps> = (props) => {
	const { canvasState, isReplaceable, selectedElement, selectedElementId, konvaStageInstance, konvaTransformerInstance } = useEasyState(
		(store) => store.canvasStore,
	);
	const { removeElement, setIsReplaceable, updateElement, executeCommand, setSelectedElementsIds } = useEasyActions((state) => state.canvasStore);
	const [isSmall] = useMediaQuery("(max-width: 993px)");

	function curvatureFun(e: any) {
		if (selectedElementId == null) {
			// console.log("null");
			return;
		}
		// console.log("curvature", e);

		executeCommand(new UpdateCommand<ImageElement>([{ id: selectedElementId, br: e }]));
	}
	// const [isReplaceable, setIsReplaceable] = useState(false);
	const [replaceAbleEle, setReplaceAbleEle] = useState<AllCanvasElements | null>(null);
	const selectedImage = selectedElement as ImageElement;
	const [transparency, setTransparency] = useState(selectedImage?.opacity ?? 0);
	const [curvature, setCurvature] = useState(selectedImage?.br ?? 1);
	const transparencyDebounce = useCallback(debounce(transparencyFun, 500), [selectedElementId]);
	const curvatureDebounce = useCallback(debounce(curvatureFun, 300), [selectedElementId, curvature]);

	function transparencyFun(e: any) {
		if (selectedElementId == null) {
			return;
		}
		// console.log("transparency", transparency);

		executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
	}
	const scrollParent = useRef<null | HTMLDivElement>(null);

	function programmaticallyScroll(dir: "down") {
		if (!scrollParent.current) {
			return;
		}
		// scrollParent.current.scrollBy(0, 700);
		scrollParent.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}

	return (
		<>
			{isReplaceable ? (
				<ImagesOptionMenu isReplaceable={isReplaceable} replaceAbleEle={replaceAbleEle} />
			) : (
				<Flex flexDir="column" flex={1}>
					<Flex flexDir="column" mb="10px">
						<Flex flex={1} justifyContent="flex-end" mr="20px">
							<Flex
								cursor="pointer"
								onClick={() => {
									history.push(basePath + "/design");
									setSelectedElementsIds([]);
									konvaTransformerInstance?.nodes([]);
								}}
								w="40px"
								mt="10px"
								mr={["10px", null, "0px"]}
								justifyContent="flex-end">
								✖
							</Flex>
						</Flex>
						<Heading ml="30px" mb="20px" variant="mdb">
							Images
						</Heading>
					</Flex>
					<Flex flexDir="column" mx="35px" flex={1} ref={scrollParent}>
						<Flex flexDir="column" flex={1}>
							<Flex mb="20px" flexDir="column">
								<Flex flexDir="row">
									<Flex flex={1} variant="lg" mb="10px">
										Transparency
									</Flex>
									<Text variant="md">{Math.floor(transparency * 100)}%</Text>
								</Flex>
								<input
									type="range"
									id="vol"
									name="vol"
									min="0"
									max="1"
									step={0.01}
									value={transparency}
									onChange={(e) => {
										let value = Number(e.target.value);
										transparencyDebounce(value);
										setTransparency(value);
									}}></input>
								{/* <Slider
									min={0}
									max={1}
									step={0.01}
									mt="15px"
									aria-label="slider-ex-1"
									defaultValue={transparency}
									onChange={(e) => {
										setTransparency(e);
									}}
									onChangeEnd={(e) => {
										if (selectedElementId == null) {
											return;
										}
										setTransparency(e);
										executeCommand(new UpdateCommand([{ id: selectedElementId, opacity: e }]));
										console.log(e);
									}}>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb bg="primary" />
								</Slider> */}
							</Flex>

							<Flex mb="20px" flexDir="column">
								<Flex flexDir="row">
									<Flex flex={1} variant="lg" mb="10px">
										Curvature
									</Flex>
									<Text variant="md">{Math.floor(curvature * 100)}%</Text>
								</Flex>
								<input
									type="range"
									id="vol"
									name="vol"
									max={1}
									min={0.3}
									step={0.01}
									value={curvature}
									onChange={(e) => {
										let value = Number(e.target.value);
										curvatureDebounce(value);
										setCurvature(value);
									}}></input>

								{/* <Slider
									onChange={(e) => {
										setCurvature(e);
									}}
									onChangeEnd={(e) => {
										if (selectedElementId == null) {
											console.log("null");
											return;
										}
										console.log(canvasState[selectedElementId]);

										setCurvature(e);
										executeCommand(new UpdateCommand<ImageElement>([{ id: selectedElementId, br: curvature }]));
										console.log(e);
									}}
									max={1}
									min={0.3}
									step={0.001}
									value={curvature}
									mt="15px"
									aria-label="slider-ex-1">
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb bg="primary" />
								</Slider> */}
							</Flex>
							<Flex mb="20px" alignItems="center" flexDir="column">
								<Button
									variant="styled"
									w="160px"
									onClick={() => {
										if (selectedElementId == null) {
											// console.log("null");
											return;
										}
										const bigSide = Math.max(selectedImage.height, selectedImage.width);
										const smallSide = Math.min(selectedImage.height, selectedImage.width);

										const altitude = smallSide / 2;

										const base = bigSide / 2;

										const hypogeous = Math.sqrt(altitude ** 2 + base ** 2);

										// for  perfect circle the value of br is "br = altitude / hypogeous"
										const br = altitude / hypogeous;

										setCurvature(br);
										executeCommand(new UpdateCommand<ImageElement>([{ id: selectedElementId, br: br }]));
									}}>
									Perfect Circle
								</Button>
							</Flex>

							<Text
								onClick={() => {
									// console.log("go");
									setIsReplaceable(true);
									setReplaceAbleEle(selectedElement);
									// console.log(isReplaceable);
								}}
								color="primary"
								fontWeight="bold"
								variant="md"
								mb="20px">
								Replace image
							</Text>
						</Flex>
						{!isSmall && (
							<Flex flexDir="column">
								<Flex justifyContent="center" mb="5px">
									<Button
										cursor="pointer"
										onClick={() => {
											history.push(basePath + "/design");
											setSelectedElementsIds([]);
										}}
										variant="styled"
										textDecoration="none">
										Back
									</Button>
								</Flex>
								<Flex justifyContent="center" mb="30px">
									<Button
										cursor="pointer"
										onClick={() => {
											history.push(basePath + "/design");
											setSelectedElementsIds([]);
										}}
										variant="styled">
										Continue
									</Button>
								</Flex>
							</Flex>
						)}
					</Flex>
				</Flex>
			)}
		</>
	);
};

export default ImagesProperties;
