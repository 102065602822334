import { ImageStore } from "../../types/canvasStoreTypes/StoreTypes";
import { action } from "easy-peasy";
export const imageStore: ImageStore = {
	imageFile: null,

	setImageFile: action((state, payload) => {
		state.imageFile = payload;
	}),

	apiRes: [],
	setApiRes: action((state, payload) => {
		state.apiRes = payload;
	}),
	text: "bird",
	setText: action((state, payload) => {
		state.text = payload;
	}),
};
