import React, { useRef } from "react";
import { Box, Button, Flex, Input, Text, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";

export interface AudioContentMobileProps {}

const AudioContentMobile: React.FC<AudioContentMobileProps> = (props) => {
	const fileUpload = useRef<null | HTMLInputElement>(null);
	const { setAudioFile } = useEasyActions((state) => state.audioStore);
	const { AudioFile } = useEasyState((state) => state.audioStore);
	// const [isSmall] = useMediaQuery("(max-width: 550px)");

	function file(e: any) {
		// console.log("file", e.target.files[0]);
		setAudioFile(e.target.files[0]);
	}

	return (
		<Flex flex={1}>
			{/* <Flex flex={1} mx={["30px", "50px"]} mt="10px" flexDir="column">
				<Flex color="grey" alignItems="center" border="1px solid grey" h="50px" rounded="lg">
					<Text nooflines={1}>{AudioFile?.name.replace(/[/n/r]/g, "")}</Text>
				</Flex>
				<Flex w="100%" mt="20px">
					<Button
						cursor="pointer"
						variant="styled"
						flex={1}
						bg="primary"
						color="white"
						radius="0.8rem"
						height="50px"
						onClick={() => fileUpload.current?.click()}>
						Browse
					</Button>
				</Flex>
				<Box display="none">
					<input multiple={false} ref={fileUpload} type="file" id="upFile" onChange={(e) => file(e)} />
				</Box>
			</Flex> */}
		</Flex>
	);
};

export default AudioContentMobile;
