import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CanvasRootObject } from "../../types/ApiTypex/AllCanvas";
import { TokenService } from "../token";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasObject } from "../../types/ApiTypex/CreateCanvas";

export async function canvasDetailApi(id: string | number) {
    let token = TokenService.getToken;

    return await catchTry(
        mainAxios.get<CanvasObject>(`${defaultOptions.baseURL}/api/canvas/${id}`, {
            headers: {
                Authorization: token,
            },
        })
    );
}
