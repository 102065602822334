import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import {
  IoCaretForwardCircleOutline,
  IoCaretForwardSharp,
  IoPauseSharp,
} from "react-icons/io5";
import { IoMdSkipBackward } from "react-icons/io";
import Forward from "../assets/images/Forward.svg";
import Backward from "../assets/images/Backward.svg";
import Duplicate from "../assets/images/Duplicate.svg";
import Group from "../assets/images/Group.svg";
import Delete from "../assets/images/Bin.svg";
import { RemoveCommand } from "../Commands/CanvasCommanda/RemoveCommand";
import { useEasyActions, useEasyState } from "../store/hooks";
import { ChangeZIndexCommand } from "../Commands/CanvasCommanda/ChangeZIndexCommand";
import { DuplicateCommand } from "../Commands/CanvasCommanda/DuplicateCommand";
import { v4 as uuid } from "uuid";
import { TypeImageElement } from "../types/canvasStoreTypes/CanvesTypes";
import { RemoveGroupCommand } from "../Commands/AddAndRemove/RemoveGroupCommand";
import { GroupCommand } from "../Commands/AddAndRemove/GroupCommand";
import { history } from "../app/router/history";
import { dataUrlToFile } from "../utils/base64ToImage";
import GeneratePopUp from "../utils/GeneratePopUp";
import { useDisclosure } from "@chakra-ui/react";

export interface CanvasCropToolsProps {
  text: string;
  onClick: () => void;
}

const CanvasCropTools: React.FC<CanvasCropToolsProps> = (props) => {
  return (
    <Flex
      as="button"
      cursor="pointer"
      flexDir="column"
      mr="15px"
      justifyContent="center"
      alignItems="center"
      onClick={props.onClick}
    >
      <Flex w="25px" h="30px" alignItems="center" justifyContent="center">
        <Box>{props.children}</Box>
      </Flex>
      <Text variant="sm" w="45px">
        {props.text}
      </Text>
    </Flex>
  );
};
export default CanvasCropTools;

export interface CanvasCropToolsMobileProps {}
export const CanvasCropToolsMobile: React.FC<CanvasCropToolsMobileProps> = (
  props
) => {
  const {
    selectedElementId,
    selectedElement,
    canvasState,
    selectedElementsIds,
    konvaLayerInstance,
    konvaTransformerInstance,
  } = useEasyState((store) => store.canvasStore);
  const { waveSurfer, isPlaying, currentTime } = useEasyState(
    (store) => store.visualizationStore
  );
  const { regionStart } = useEasyState((store) => store.audioStore);
  const { setSelectedElementsIds, executeCommand, setScreenShot } =
    useEasyActions((state) => state.canvasStore);
  const { setImageFile } = useEasyActions((state) => state.imageStore);
  const { setCurrentTime } = useEasyActions(
    (state) => state.visualizationStore
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const { waveSurfer, isPlaying, videoFinishRatio, currentTime } = useEasyState((store) => store.visualizationStore);
  const [isSmallScrollTools] = useMediaQuery("(max-width: 377px)");
  const scrollParent = useRef<null | HTMLDivElement>(null);
  let imageSelectedElement = selectedElement as TypeImageElement;
  function deleteElement() {
    if (selectedElementsIds) {
      executeCommand(new RemoveCommand(selectedElementsIds));
      setSelectedElementsIds([]);
    }

    if (selectedElement == imageSelectedElement) {
      setImageFile(null);
    }
  }

  function programmaticallyScroll(dir: "left" | "right") {
    if (!scrollParent.current) {
      return;
    }
    ``;

    scrollParent.current.scrollBy({
      top: 0,
      left: 100 * (dir === "left" ? -1 : 1),
      behavior: "smooth",
    });
  }

  let opacity = 1;

  if (selectedElementsIds.length !== 0) {
    opacity = 1;
  } else {
    opacity = 0.5;
  }

  let group = "Group";
  if (selectedElement?.type === "Group") {
    group = "Ungroup";
  }
  let ele = selectedElement;

  function imageUrl() {
    // debugger;
    let dataURL = konvaLayerInstance?.toDataURL({ pixelRatio: 3 });
    if (!dataURL) return;
    dataUrlToFile(dataURL, "img.png").then((res) => {
      // console.log(res.name, "aa");
      // console.log(res, "aass");
      setScreenShot(res);
    });
  }

  return (
    <Grid
      gridTemplateColumns={["1fr auto", "1fr auto "]}
      width="100%"
      alignItems="center"
      flex={1}
    >
      <Flex
        flexDir="row"
        mt="5px"
        h="50px"
        justifyContent="center"
        alignItems="center"
        flex={1}
        px="10px"
      >
        {/* {isSmallScrollTools && (
					<Flex colorScheme="black" onClick={() => programmaticallyScroll("left")} aria-label="Search database">
						<RiArrowLeftSLine color="black" size={25} />
					</Flex>
				)} */}

        {/* <OverFlowBox height="30px"> */}
        <Flex
          flexDir="row"
          alignItems="center"
          justifyContent="center"
          flex={1}
          ref={scrollParent}
          overflow="auto"
          className="hideScroll"
        >
          <Flex
            cursor="pointer"
            flexShrink={0}
            mr="20px"
            w="25px"
            h="30px"
            alignItems="center"
            justifyContent="center"
            onClick={() =>
              selectedElementId &&
              executeCommand(new ChangeZIndexCommand(selectedElementId, "Up"))
            }
          >
            <Box>
              <img src={Forward} style={{ opacity: opacity }} />
            </Box>
          </Flex>
          <Flex
            cursor="pointer"
            flexShrink={0}
            w="25px"
            mr="20px"
            h="30px"
            alignItems="center"
            justifyContent="center"
            onClick={() =>
              selectedElementId &&
              executeCommand(new ChangeZIndexCommand(selectedElementId, "Down"))
            }
          >
            <Box>
              <img src={Backward} style={{ opacity: opacity }} />
            </Box>
          </Flex>
          <Flex
            onClick={() => {
              if (selectedElement?.type === "Group") {
                if (!ele) {
                  return;
                }
                ele = selectedElement;
                executeCommand(new RemoveGroupCommand(selectedElement.id, ele));
                setSelectedElementsIds([]);
                konvaTransformerInstance?.nodes([]);
                konvaLayerInstance?.draw();
              }
              if (selectedElementsIds.length > 1) {
                executeCommand(new GroupCommand(selectedElementsIds));
                setSelectedElementsIds([]);
                konvaTransformerInstance?.nodes([]);
                konvaLayerInstance?.draw();
              }
            }}
            cursor="pointer"
            flexShrink={0}
            w="25px"
            mr="20px"
            h="30px"
            alignItems="center"
            justifyContent="center"
          >
            <Box h="20px" w="20px">
              <img src={Group} style={{ opacity: opacity }} />
            </Box>
          </Flex>
          <Flex
            onClick={() => {
              // let element;
              // let id = uuid();
              // if (selectedElementId) {
              // 	element = canvasState[selectedElementId];
              // }

              // console.log(selectedElementId);

              selectedElementId &&
                executeCommand(new DuplicateCommand(selectedElementId));

              // console.log("run");
            }}
            cursor="pointer"
            flexShrink={0}
            w="25px"
            mr="20px"
            h="30px"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <img src={Duplicate} style={{ opacity: opacity }} />
            </Box>
          </Flex>
          <Flex
            cursor="pointer"
            flexShrink={0}
            w="25px"
            h="30px"
            alignItems="center"
            justifyContent="center"
            onClick={deleteElement}
          >
            <Box h="25px" w="25px">
              <img src={Delete} style={{ opacity: opacity }} />
            </Box>
          </Flex>
        </Flex>
        {/* </OverFlowBox> */}
        {/* {isSmallScrollTools && (
					<Flex colorScheme="black" onClick={() => programmaticallyScroll("right")} aria-label="Search database">
						<RiArrowRightSLine size={25} color="black" />
					</Flex>
				)} */}
      </Flex>

      <Flex
        mr={["0px", "30px", "110px", "150px"]}
        flex={1}
        my="10px"
        gridColumn={["1/span 2", "auto/span 1"]}
        mx="20px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          flex={1}
          rounded="md"
          alignItems="center"
          margin="auto"
          justifyContent="center"
          mr="10px	"
        >
          <Flex
            border="1px solid grey"
            flexDir="row"
            rounded="lg"
            p="10px"
            alignItems="center"
          >
            {/* <Box
							onClick={() => {
								console.log("hello");
								audio?.play();
							}}>
							<IoCaretForwardSharp size={30} />
						</Box>
 */}

            {!isPlaying ? (
              <Box
                onClick={() => {
                  waveSurfer?.play();
                }}
              >
                <IoCaretForwardSharp size={30} />
              </Box>
            ) : (
              <Box
                onClick={() => {
                  waveSurfer?.pause();
                }}
              >
                <IoPauseSharp size={30} />
              </Box>
            )}

            <Box
              ml="10px"
              onClick={() => {
                // if (!waveSurfer) return;
                // console.log("current", currentTime);
                waveSurfer?.setCurrentTime(regionStart ?? 0);
                // setCurrentTime(0);
              }}
            >
              <IoMdSkipBackward size={30} />
            </Box>
          </Flex>
        </Flex>
        <Button
          onClick={() => {
            onOpen();
          }}
          cursor="pointer"
          flex={[null, 1]}
          width="100%"
          variant="styled"
          mr={["0px", "0px", "10px", "50px"]}
        >
          <GeneratePopUp onClose={onClose} isOpen={isOpen} />

          <Text>Continue</Text>
        </Button>
      </Flex>
    </Grid>
  );
};
