import { catchTry } from "../betterTry";
// import { mainAxios } from "../../config/axios";
import axios from "axios";
import { StripCardResponse } from "../../types/ApiTypex/StripCardTypes";

export async function stripeCardApi(name: string, cardNumber: string, expiryMonth: string, expiryYear: string, cvc: string) {
	let bodyFormData = new URLSearchParams();
	bodyFormData.append("card[name]", name);
	bodyFormData.append("card[number]", cardNumber);
	bodyFormData.append("card[exp_month]", expiryMonth);
	bodyFormData.append("card[exp_year]", expiryYear);
	bodyFormData.append("card[cvc]", cvc);

	return await catchTry(
		axios.post<StripCardResponse>("https://api.stripe.com/v1/tokens", bodyFormData.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: "Bearer pk_test_51Jgy7NJgfueteqHSxGcPsbLzC0kmbFNWVWWzljzPfeUmHi80zPxK4alnkPN7ZTAmxAI2yHkcsFjxT4tRbEbrfPG100T4m0fgCx",
			},
		}),
	);
}
