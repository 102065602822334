import { Box, Flex, Center } from "@chakra-ui/react";
import React, { useMemo, useEffect, useRef, useState, Ref } from "react";
import { Group, Layer, Rect, Stage } from "react-konva";
import AllCanvasElementRender from "../../components/home/AllCanvasElementRender";
import { theme } from "../../config/theme/baseDesign";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { IS_MOBILE } from "../../utils/responsive";
import { calculateCanvasTransform, calculateCanvasPosition } from "../../utils/canvasHelpers";
import { UserTypes } from "../../components/ChooseFormat/FakeDataArray";
import { TemplatesTypes } from "../../types";
import { videoQualityCheck } from "../../utils/videoQuality";
import { IoCaretForwardSharp, IoPauseSharp } from "react-icons/io5";
import { AddCommand } from "../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import WaterMark from "../../assets/images/Watermark.svg";
import { TokenService } from "../../utils/token";
import { ImageObjectToElement } from "../../utils/CanvesElementObjectToCanvasElement";
export interface GenerateCanvasProps {
	// setwidth: (width: number) => void;
	// setheight: (height: number) => void;
	width: number;
	height: number;
	scale: number;
}

const GenerateCanvas: React.FC<GenerateCanvasProps> = ({ width, height, scale }) => {
	const { canvasState, canvasHWS, stageScale, stagePosition, canvasParentHW, konvaTransformerInstance, templatesType, clickedGenerateCanvas } =
		useEasyState((store) => store.canvasStore);
	const { isPlaying, analyser, waveSurfer, videoFinishRatio, subtitleWithTime } = useEasyState((store) => store.visualizationStore);
	const { permissionsData, currentUserDetails } = useEasyState((store) => store.userDataStore);
	const { executeCommand } = useEasyActions((store) => store.canvasStore);
	let token = TokenService.getToken();
	const canvasElementsToRender = useMemo(() => {
		return <AllCanvasElementRender stateToRender={canvasState} isStatic={false} />;
	}, [canvasState, subtitleWithTime, isPlaying, videoFinishRatio, waveSurfer, konvaTransformerInstance, canvasHWS]);
	// const { height: stageDimensionsY, width: stageDimensionsX } = canvasParentHW;

	const {
		height: genH,
		scale: genS,
		width: genW,
	} = useMemo(() => {
		if (!permissionsData.export_quality) {
			return videoQualityCheck("720p", templatesType);
		}
		return videoQualityCheck(permissionsData.export_quality, templatesType);
	}, [templatesType]);

	let canWidth = clickedGenerateCanvas ? genW * genS : width * scale;
	let canHeight = clickedGenerateCanvas ? genH * genS : height * scale;
	// console.log(canWidth, canHeight, "canWidth, canHeight");

	function addImage(url: string, h: number, w: number) {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "WaterMark",
				x: w * 0.02,
				y: h * 0.03,
				scaleX: 1,
				scaleY: 1,
				opacity: 0.5,
				br: 1,
				url: url,
				height: 20,
				width: 100,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
				zIndex: 1,
			}),
		);
	}
	useEffect(() => {
		// debugger;
		if (!token) {
			addImage(WaterMark, canHeight, canWidth);
			return;
		}
		if (!currentUserDetails?.subscription_plan?.name || currentUserDetails?.subscription_plan?.name === "free") {
			addImage(WaterMark, canHeight, canWidth);
			return;
		}
	}, []);
	return (
		<Center
			display={clickedGenerateCanvas ? "none" : "block"}
			position={clickedGenerateCanvas ? "absolute" : "relative"}
			top={clickedGenerateCanvas ? "100000px" : "1px"}
			zIndex={clickedGenerateCanvas ? -1 : 1}
			className="generateCanvasParent"
			// flexDir="column"
			width={clickedGenerateCanvas ? genW * genS : width * scale}
			height={clickedGenerateCanvas ? genH * genS : height * scale}>
			<Stage
				name="generateStage"
				scaleX={clickedGenerateCanvas ? genS : scale}
				scaleY={clickedGenerateCanvas ? genS : scale}
				width={clickedGenerateCanvas ? genW * genS : width * scale}
				height={clickedGenerateCanvas ? genH * genS : height * scale}
				// x={posX}
				// y={posY}
				zIndex={clickedGenerateCanvas ? -1 : 1}
				style={{ background: "white", boxShadow: " 0px 0px 3px 2px #e6e6e6" }}>
				<Layer name="generateLayer">
					<Rect width={width} height={height} fill="white" />

					{canvasElementsToRender}
					{/* <ImageObjectToElement
						ele={{
							id: uuid(),
							type: "WaterMark",
							x: 10,
							y: 10,
							scaleX: 1,
							scaleY: 1,
							opacity: 0.5,	
							br: 1,
							url: WaterMark,
							height: 20,
							width: 100,
							skewY: 0,
							skewX: 0,
							rotationDeg: 0,
							zIndex: 1,
						}}
						key={"chida"}
						isStatic={true}
					/> */}
				</Layer>
			</Stage>
		</Center>
	);
};

export default GenerateCanvas;
