import React, { useCallback, useMemo, useRef, useState } from "react";
import { Flex, Text, InputGroup, InputLeftElement, Input, Grid, useToast } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { useEasyActions, useEasyState } from "../../../store/hooks";
import { history } from "../../../app/router/history";
import { emojiNames } from "../../../data/emojiNames";
import { AddCommand } from "../../../Commands/CanvasCommanda/AddShapes";
import { v4 as uuid } from "uuid";
import downArrow from "../../../assets/images/Chevron.svg";
import { gitEmojiList, gitEmojiMap } from "../../../data/gitEmojiObj";
import { basePath } from "../../../constants";

export interface MobileEmojisProps {}

const MobileEmojis: React.FC<MobileEmojisProps> = (props) => {
	const { executeCommand, setSelectedElementsIds, setIsReplaceable } = useEasyActions((store) => store.canvasStore);
	const { selectedElementId, selectedElement, konvaTransformerInstance, canvasHWS } = useEasyState((store) => store.canvasStore);
	const { permissionsData } = useEasyState((store) => store.userDataStore);
	const scrollParent = useRef<null | HTMLDivElement>(null);
	const [text, setText] = useState("");
	const [emojiList, setGitEmojiList] = useState<string[]>(gitEmojiList as string[]);
	const imageRef = useRef<null | HTMLImageElement>(null);
	const [numberOfImages, setNumberOfImages] = useState(20);
	const [hasMore, setHasMore] = useState(false);
	const toast = useToast();
	const [apiRes, setApiRes] = useState<string[] | null>();
	let cameraH = canvasHWS.height;
	let cameraW = canvasHWS.width;
	function getFilteredData() {
		let filteredData = emojiList.filter((n) => n.includes(text.trim() === "" ? "face" : text));
		let slicedData = filteredData.slice(0, numberOfImages);
		setApiRes(slicedData);
		setHasMore(filteredData.length > 0);
	}

	let imageInOneTime = 3 * 6;
	function addImage(url: string) {
		executeCommand(
			new AddCommand({
				id: uuid(),
				type: "Sticker",
				x: (cameraW / 2) * Math.random() + 20,
				y: (cameraH / 2) * Math.random(),
				scaleX: 1,
				scaleY: 1,
				opacity: 1,
				br: 1,
				url: url,
				height: 100,
				width: 100,
				skewY: 0,
				skewX: 0,
				rotationDeg: 0,
			}),
		);
		// setImage(imageRef.current);
	}
	function programmaticallyScrollDown(dir: "down") {
		if (!scrollParent.current) {
			return;
		}
		// scrollParent.current.scrollBy(0, 700);
		scrollParent.current.scrollBy({
			top: 100,
			left: 0,
			behavior: "smooth",
		});
	}

	const observer = useRef<IntersectionObserver | null>(null);
	const lastImgRef = useCallback(
		(node) => {
			// console.log(node);
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setNumberOfImages((numberOfImages) => numberOfImages + 18);
				}
			});
			if (node) {
				observer.current.observe(node);
			}
		},
		[hasMore],
	);

	useMemo(() => getFilteredData(), [numberOfImages, text, emojiList]);

	return (
		<Flex mx="30px" flexDir="column">
			<Flex justifyContent="flex-end" mr="20px">
				<Flex
					cursor="pointer"
					onClick={() => {
						history.push(basePath + "/design");
						setSelectedElementsIds([]);
						konvaTransformerInstance?.nodes([]);
					}}
					w="40px"
					mt="10px"
					mr={["10px", null, "0px"]}
					justifyContent="flex-end">
					✖
				</Flex>
			</Flex>
			<Text fontSize="18px" fontWeight="600" my="20px">
				Select Emoji
			</Text>
			<Flex flex={1}>
				<InputGroup>
					<InputLeftElement pointerEvents="none" mt="10px" children={<FiSearch size={20} />} />
					<Input
						my="5px"
						type="text"
						h="50px"
						value={text}
						onChange={(e) => {
							setText(e.target.value);
						}}
					/>
				</InputGroup>
			</Flex>
			<Flex overflow="auto" ref={scrollParent} height="200px" my="20px" flexDir="column">
				<Grid
					className="hideScroll"
					justifyContent="center"
					alignItems="center"
					m="2px"
					id="gridImg"
					mt="10px"
					gridAutoFlow="row"
					gridTemplateColumns="repeat(auto-fit,60px)"
					columnGap="27px"
					rowGap="20px"
					// mb="20px"
				>
					{apiRes?.map((img, idx) => {
						// console.log(img);
						return (
							<Flex ref={lastImgRef} key={img} cursor="pointer" h="40px" w="40px">
								<img
									onError={() => {
										console.count("Image Broken");
										const newEmojiList = [...emojiList];
										newEmojiList.splice(newEmojiList.indexOf(img), 1);
										setGitEmojiList(newEmojiList);
									}}
									ref={imageRef}
									onClick={(e) => {
										if (!permissionsData.add_emojis) {
											toast({
												status: "info",
												description: "This is available in Pro Plan.",
											});
											return;
										}
										addImage(gitEmojiMap[img as keyof typeof gitEmojiMap]);
									}}
									style={{
										objectFit: "cover",
										width: "100%",
										height: "100%",
									}}
									src={gitEmojiMap[img as keyof typeof gitEmojiMap]}
								/>
							</Flex>
						);
					})}
				</Grid>
			</Flex>
			<Flex
				onClick={() => {
					programmaticallyScrollDown("down");

					// setNumberOfImages(numberOfImages + imageInOneTime);
				}}
				id="scrollIcon"
				cursor="pointer"
				justifyContent="center"
				my="10px">
				<img src={downArrow} alt="arrow" style={{ height: "15x" }} />
			</Flex>
		</Flex>
	);
};

export default MobileEmojis;
