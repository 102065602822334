import {
  Box,
  Flex,
  Grid,
  Heading,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { history } from "../app/router/history";
import Logo from "../assets/images/AmpifiLogo.svg";
import SideBar from "../components/SideBar";
import { useEasyState } from "../store/hooks";
import { TokenService } from "../utils/token";

export interface AppLayoutProps {
  optionMenuContent: JSX.Element;
  optionMenuContentMobile: JSX.Element;
  headingContent?: JSX.Element | boolean;
  heading: string;
  maxWidth: string;
}

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const [isSmall] = useMediaQuery("(min-width: 993px)");
  const { originalAudio } = useEasyState((store) => store.audioStore);
  const toast = useToast();
  const disableMessage =
    originalAudio == null ? "Please upload audio first" : null;
  let token = TokenService.getToken();


  return (
    <Grid
      height="100vh"
      gridTemplateColumns={["1fr", null, null, "9rem 1fr 16rem"]}
      gridTemplateRows={["1fr auto auto", null, null, "1fr"]}
      gridTemplateAreas={['"pg""om""sb"', null, null, `"sb pg om"`]}
    >
      <Flex
        gridArea="sb"
        borderRight={[null, null, null, "1px solid rgba(126, 126, 126, 0.15)"]}
        borderTop={["1px solid rgba(126, 126, 126, 0.15)", null, null, null]}
      >
        <SideBar />
      </Flex>
      <Flex id="pageArea" flexDirection="column" gridArea="pg">
        <Flex
          flexShrink={0}
          flexDir="column"
          borderBottom={
            props.heading == "Bring it to life."
              ? "2px solid rgba(126, 126, 126, 0.15)"
              : "none"
          }
        >
          {!isSmall && (
            <Flex
              onClick={() => {
                if (token == null) return;
                history.push("/dashboard");
              }}
              minH="6rem"
              justifyContent="flex-end"
              mr="20px"
              alignItems="center"
            >
              <Box _hover={{ cursor: "pointer" }} display="inline-block">
                <img src={Logo} style={{ height: "35px" }} />
              </Box>
            </Flex>
          )}
          <Flex flexDirection="row" pl={[".8rem", ".8rem", "2.5rem", "2.5rem"]}>
            {/* <Box flex={1} maxW={130}></Box> */}

            <Heading
              variant="arrHead"
              minH={["4.5rem", "6rem", "8rem"]}
              className="d-flex align-items-center w-100 text-center"
              // ml="20px"
              // ml={intpW("px", [1250, 50], [1150, 50], [1070, 30], [1028, 10])}
            >
              <Flex alignItems="center" margin="auto" h="100%">
                {props.heading}
              </Flex>
            </Heading>
            <Box flex={1} alignItems="center">
              {/* {props.heading === "Upload your audio." ? (
                                <Flex h="100%" flex={1} alignItems="center" justifyContent="flex-end"> */}
              {/* <Button
                                        onClick={() => {
                                            // debugger;
                                            // console.log("helo");
                                            if (disableMessage != null) {
                                                toast({ description: disableMessage });
                                                return;
                                            }

                                            history.push(basePath + "/signup");
                                        }}
                                        fontSize={!isSmall ? "12" : "14"}
                                        my="7px"
                                        mx="10px"
                                        bg={theme.colors.primary}
                                        color="white"
                                        w={["60px", "100px"]}
                                        _hover={{ bg: theme.colors.primary }}
                                    >
                                        signup
                                    </Button> */}
              {/* </Flex>
                            ) : (
                                <></>
                            )} */}
            </Box>

            {props.headingContent && <Flex>{props.headingContent}</Flex>}
          </Flex>
        </Flex>
        <Flex flex={1} w="100%" flexDir="row" width={"80%"} mx={"auto"}>
          {props.children}
        </Flex>
      </Flex>
      <Box gridArea="om">
        <Flex
          borderLeft=" 1px solid rgba(126, 126, 126, 0.15)"
          flexDirection="column"
          minH="100%"
        >
          {isSmall && (
            <Flex
              onClick={() => {
                if (token == null) return;
                history.push("/dashboard");
              }}
              minH="6rem"
              justifyContent="flex-end"
              mr="20px"
              alignItems="center"
            >
              <Box _hover={{ cursor: "pointer" }} display="inline-block">
                <img src={Logo} style={{ height: "30px" }} />
              </Box>
            </Flex>
          )}
          {!isSmall ? (
            <Flex flex={1} flexShrink={0}>
              {props.optionMenuContentMobile}
            </Flex>
          ) : (
            <Flex flex={1}>{props.optionMenuContent}</Flex>
          )}
        </Flex>
      </Box>
    </Grid>
  );
};

export default AppLayout;
