import React, { useState, useEffect } from 'react';
import upgrade from "../assets/images/upgrade.svg";
import monthPlan from "../assets/images/month_plan.svg";
import { basePath } from "../constants";
import { TokenService } from "../utils/token";
import { theme } from "../config/theme/baseDesign";
import { currentUserDetailsApi } from "../utils/Api/currentUserApi";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Box,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Text, SimpleGrid, Image, Center
} from '@chakra-ui/react';
import useScreenWidth from './useScreenWidth';
interface UpgradeModalProps {
	isOpen: boolean;
	onClose: () => void;
}

function subscribe_plan(plan) {
	let token = TokenService.getToken();
	const dataToSend = { plan: plan };
	fetch(basePath + '/api/subscriptions', {
		method: "POST", // or "GET" or any other HTTP method
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Token ${token}`
		},
		body: JSON.stringify(dataToSend),
		// Additional options like body, etc., can be included here
	})
		.then(response => response.json())
		.then(data => {
			window.location.href = data.checkout_url;
		})
		.catch(error => {
			console.error("Error:", error);
		});
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ isOpen, onClose }) => {
	const [selectedPlan, setSelectedPlan] = React.useState<string>("monthly");
	const [disableClose, setDisableClose] = React.useState<boolean>(false);
	let token = TokenService.getToken();
	async function currentUserDetailsApiData() {
		if (!token) return;
		let response = await currentUserDetailsApi();
		if (response instanceof Error) return;
		let currentUserData = response.data.data.attributes;
		setDisableClose(currentUserData?.subscription_details?.trial_end);

	}
	useEffect(() => {
		currentUserDetailsApiData();
		// onOpenUpgradeModal();
	}, []);

	const screenWidth = useScreenWidth();
	return (
		<Box p={4} color='white'>
			<Modal isCentered isOpen={isOpen} onClose={!disableClose ? onClose : undefined}>
				<ModalOverlay />
				<ModalContent h="770px" w={screenWidth < 500 ? "400px" : "1350px"} maxW="unset" borderRadius="35px" border="4px solid #000">
					<ModalCloseButton />
					<ModalBody>
						<Flex h="51px" w="100%" m="78px 0 0 0" background={theme.colors.white} justifyContent="center">
							<Flex
								h="50px" w={screenWidth < 500 ? "90px" : "180px"}
								m="0 50px 0 0"
								background={theme.colors.white}
								alignItems={'center'}
								justifyContent='center'
							>
								<Button
									h="100%"
									w="100%"
									onClick={() => setSelectedPlan("monthly")}
									background={selectedPlan === "monthly" ? theme.colors.primary : theme.colors.white}
									color={selectedPlan === "monthly" ? `${theme.colors.white}` : `${theme.colors.black}`}
									_active={{
										bg: '#dddfe2',
										transform: 'scale(0.98)',
										borderColor: '#bec3c9',
									}}
								>
									<Flex h="25px" w="100%" m="0 0 0 0" alignItems={'center'} justifyContent='center'>
										<Text variant={screenWidth < 500 ? "xl" : "xlg"} color={selectedPlan === "monthly" ? theme.colors.white : theme.colors.black}>
											Monthly
										</Text>
									</Flex>
								</Button>
							</Flex>

							<Flex
								h="50px" w={screenWidth < 500 ? "90px" : "180px"}
								background={theme.colors.white}
								alignItems={'center'}
								justifyContent='center'
							>
								<Button
									h="100%"
									w="100%"
									onClick={() => setSelectedPlan("annual")}
									background={selectedPlan === "annual" ? theme.colors.primary : theme.colors.white}
									color={selectedPlan === "annual" ? `${theme.colors.white}` : `${theme.colors.black}`}
									_active={{
										transform: 'scale(0.98)',
										borderColor: '#bec3c9',
									}}
								>
									<Flex h="25px" w="100%" m="0 0 0 0" alignItems={'center'} justifyContent='center'>
										<Text variant={screenWidth < 500 ? "xl" : "xlg"} color={selectedPlan === "annual" ? theme.colors.white : theme.colors.black}>
											Annually
										</Text>
									</Flex>
								</Button>
							</Flex>
						</Flex>

						{selectedPlan === "monthly" && (
							<SimpleGrid columns={screenWidth <= 500 ? 1 : 2} spacing={10}>
								{screenWidth <= 500 ? <></> :
									<Box height='auto' m='50px 0 0 0'>
										<Image style={{ color: "#ffffff", height: 'auto', width: 'auto' }} src={upgrade} />
									</Box>
								}
								<Box height='auto'>

									<Flex
										h="128px" w="100%" m="0 0 0 0"
										justifyContent='center' 
									>
										<Flex
											h="128px" w="160px" m="0 0 0 0" 
										>
											<Text variant="xxxxxlg" color="#171717">
												£12
											</Text>
										</Flex>
									</Flex>

									<Flex
										h="auto" w="auto" 
										alignItems={'center'}
										justifyContent='center'
										marginBottom='25px'
									>
										<Text variant="lgbm">
											Per month Billed monthly
										</Text>
									</Flex>

									<Flex
										h="52px" w="auto" 
										background={theme.colors.white}
										alignItems={'center'}
										justifyContent='center'
									>
										<Button
											h="100%"
											w="70%"
											name="monthly"
											onClick={async () => {
												subscribe_plan('monthly');
											}}
											background={theme.colors.primary}
											borderRadius={"10px"}
											border={`3px solid ${theme.colors.black}`}
											color={`${theme.colors.white}`}
											_active={{
												bg: '#dddfe2',
												transform: 'scale(0.98)',
												borderColor: '#bec3c9',
											}}
										>
											<Flex h="25px" w="100%" m="0 0 0 0" alignItems={'center'} justifyContent='center'>
											
												<Text variant="xlg" color={theme.colors.white}>
													Continue
												</Text>
											</Flex>
										</Button>
									</Flex>

									<Box
										alignItems={'center'}
										justifyContent='center'
									>
										<Center>
										<Image style={{height: 'auto', width: 'auto',marginLeft:'62px', marginTop:'25px' }} src={monthPlan} />
										</Center>
									</Box>
								</Box>

							</SimpleGrid>
						)}


{selectedPlan === "annual" && (
							<SimpleGrid columns={screenWidth <= 500 ? 1 : 2} spacing={10}>
								{screenWidth <= 500 ? <></> :
									<Box height='auto' m='50px 0 0 0'>
										<Image style={{ color: "#ffffff", height: 'auto', width: 'auto' }} src={upgrade} />
									</Box>
								}
								<Box height='auto'>

									<Flex
										h="128px" w="100%" m="0 0 0 0"
										justifyContent='center' 
									>
										<Flex
											h="128px" w="160px" m="0 0 0 0" 
										>
											<Text variant="xxxxxlg" color="#171717">
												£96
											</Text>
										</Flex>
									</Flex>

									<Flex
										h="auto" w="auto" 
										alignItems={'center'}
										justifyContent='center'
										marginBottom='25px'
									>
										<Text variant="lgbm">
										Per year Billed annually
										</Text>
									</Flex>

									<Flex
										h="52px" w="auto" 
										background={theme.colors.white}
										alignItems={'center'}
										justifyContent='center'
									>
										<Button
											h="100%"
											w="70%"
											name="monthly"
											onClick={async () => {
												subscribe_plan('yearly');
											}}
											background={theme.colors.primary}
											borderRadius={"10px"}
											border={`3px solid ${theme.colors.black}`}
											color={`${theme.colors.white}`}
											_active={{
												bg: '#dddfe2',
												transform: 'scale(0.98)',
												borderColor: '#bec3c9',
											}}
										>
											<Flex h="25px" w="100%" m="0 0 0 0" alignItems={'center'} justifyContent='center'>
											
												<Text variant="xlg" color={theme.colors.white}>
													Continue
												</Text>
											</Flex>
										</Button>
									</Flex>

									<Box
										alignItems={'center'}
										justifyContent='center'
									>
										<Center>
										<Image style={{height: 'auto', width: 'auto',marginLeft:'62px', marginTop:'25px' }} src={monthPlan} />
										</Center>
									</Box>
								</Box>

							</SimpleGrid>
						)}
						

					</ModalBody>
					<ModalFooter>
						{/* Add any additional footer content if needed */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default UpgradeModal;
