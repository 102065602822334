import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { setup } from "@ali_nawaz/style-kit";
import { Router } from "react-router";
import "@blueprintjs/core/lib/css/blueprint.css";
import App from "./src/app/App";
import { store } from "./src/store";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { theme } from "./src/config/theme/baseDesign";
import { history } from "./src/app/router/history";
setup();

export function IndexComponent() {
	return (
		<StrictMode>
			<StoreProvider store={store}>
				<ChakraProvider theme={theme}>
					<CSSReset />
					<Router history={history}>
						<App />
					</Router>
				</ChakraProvider>
			</StoreProvider>
		</StrictMode>
	);
}
