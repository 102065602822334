import React from 'react';
import { Button, useTheme } from '@chakra-ui/react'; 
const CustomButton = ({ onClick, background, color, children }) => {
  const theme = useTheme(); 
  
  return (
    <Button
      w="180px"
      mb="10px"
      h="3rem"
      onClick={onClick} 
      background={background || theme.colors.primary} 
      color={color || "white"} 
      _hover={{ bg: background || theme.colors.primary }} 
    >
      {children} 
    </Button>
  );
};

export default CustomButton;
