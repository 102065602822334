import { catchTry } from "../../utils/betterTry";
import { defaultOptions, mainAxios } from "../../config/axios";
import { CurrentUserRootObject } from "../../types/User/currentUserDetails";
import { TokenService } from "../token";

export async function currentUserDetailsApi() {
	let token = TokenService.getToken();
	return await catchTry(
		mainAxios.get<CurrentUserRootObject>(`${defaultOptions.baseURL}/api/profile`, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				token: token,
			},
		}),
	);
}
