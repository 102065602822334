import React, { useEffect, useMemo, useState } from "react";
import { allUnrenderedCanvas } from "../../utils/Api/AllUnrenderedCanvas";
import { useToast, Flex, Text, Heading, Grid, Button, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { AllUnrenderedList } from "../../types/ApiTypex/unrenderedList";
import ChooseTemplateCard from "../../components/ChooseFormat/ChooseTemplateCard";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import OverFlowBox from "../../components/common/OverFlowBox";
import { canvasDetailApi } from "../../utils/Api/CanvasDetails";
import { history } from "../../app/router/history";
import { theme } from "../../config/theme/baseDesign";
import { TokenService } from "../../utils/token";
import HomeLayout from "../../Layout/HomeLayout";
import { b64toBlob } from "../../utils/base64ToImage";
import LoadingContainer from "../../utils/LoadingContainer";
import { basePath } from "../../constants";
import SaveTemplateModel from "../Generate/SaveTemplateModel";
import { updateCanvasApi } from "../../utils/Api/UpdateCanvas";
import axios from "axios";
import Logo from "../../assets/images/AmpifiLogo.svg";

export interface UnrenderedCanvasListProps {}

const UnrenderedCanvasList: React.FC<UnrenderedCanvasListProps> = (props) => {
	const toast = useToast();
	const { setUnrenderedList, setUnrenderedTitle } = useEasyActions((store) => store.unrenderedStore);
	const { unrenderedList } = useEasyState((store) => store.unrenderedStore);
	const { selectedCanvasId, canvasState, selectedTemplatesType, templatesType, selectedCanvasDes, selectedCanvasName } = useEasyState(
		(store) => store.canvasStore,
	);
	const { setOriginalAudio, setResultAudio } = useEasyActions((store) => store.audioStore);
	const { setSelectedCanvas, setTemplatesType, setSelectedTemplates } = useEasyActions((store) => store.canvasStore);
	const {} = useEasyActions((store) => store.templateStore);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isloading, setIsloadind] = useState(true);
	const [title, SetTitle] = useState<string | null>(selectedCanvasName);
	const [description, SetDescription] = useState<string | null>(selectedCanvasDes);
	const [isSmall] = useMediaQuery("(min-width: 993px)");
	let token = TokenService.getToken();

	let vid;
	useEffect(() => {
		SetTitle(selectedCanvasName);
		SetDescription(selectedCanvasDes);
	}, [selectedCanvasName, selectedCanvasDes]);
	async function getAllUnrenderedCanvas() {
		let response = await allUnrenderedCanvas();
		// console.log("response unrendered", response);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some problem please try later.",
			});
			return;
		}
		let unrendered: AllUnrenderedList[] = [];
		response.data.data.forEach((temp, ind) => {
			if (temp.attributes.rendered == false) {
				unrendered.push(temp);
			}
		});
		setUnrenderedList(unrendered);
		setIsloadind(false);
	}

	useEffect(() => {
		getAllUnrenderedCanvas();
	}, []);

	async function updateCanvas(name: string, description: string, id: string) {
		let response = await updateCanvasApi({ id: id, name: name, description: description, type: templatesType });
		// console.log("response", response);

		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}

		toast({
			status: "success",
			title: "Your template is sucessfully updated",
			description: "",
		});
	}

	let landscape: AllUnrenderedList[] = [];
	let square: AllUnrenderedList[] = [];
	let portrait: AllUnrenderedList[] = [];

	if (unrenderedList != []) {
		unrenderedList.forEach((tem, ind) => {
			let name = tem.attributes.name;
			let splited = name.split(":");

			let type = splited[0];
			if (type === "landscape") {
				landscape.unshift({
					...tem,
					attributes: {
						...tem.attributes,
						name: name,
					},
				});
			}
			if (type === "portrait") {
				portrait.unshift({
					...tem,
					attributes: {
						...tem.attributes,
						name: name,
					},
				});
			}
			if (type === "square") {
				square.unshift({
					...tem,
					attributes: {
						...tem.attributes,
						name: name,
					},
				});
			}
		});
	}

	async function canvasDetail(id: string) {
		let response = await canvasDetailApi(id);
		// console.log("response", response);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some problem please try later.",
			});
			return;
		}
		let parsedState = JSON.parse(response.data.data.attributes.details);
		setSelectedCanvas(parsedState);
		let name = response.data.data.attributes.name;
		let splitedname = name.split(":");
		setUnrenderedTitle(splitedname[1]);
		let audio = response.data.data.attributes.record.data.attributes.original_audio_url;
		let fileName = response.data.data.attributes.record.data.attributes.filename;
		axios.get(audio);
	}

	function blobToFile(theBlob: Blob, fileName: string): File {
		var b: any = theBlob;
		b.lastModifiedDate = new Date();
		b.name = fileName;
		return b;
	}
	let allUnrenderedCanvasList: JSX.Element[] = [];

	let landscapeList: JSX.Element[] = [];
	let portraitList: JSX.Element[] = [];
	let squareList: JSX.Element[] = [];

	if (landscape != []) {
		landscape.forEach((canvas: AllUnrenderedList, index) => {
			landscapeList.push(
				<ChooseTemplateCard
					colWidth={2}
					key={canvas.id}
					selectedElement={selectedCanvasId}
					selected={canvas.id === selectedCanvasId}
					type="landscape"
					temp={canvas}
					ind={index}
					onDel={() => {
						const newArr = [...unrenderedList];
						newArr.splice(
							newArr.findIndex((i) => i.id == canvas.id),
							1,
						);
						setUnrenderedList(newArr);
					}}
					getAllCanvas={getAllUnrenderedCanvas}
					rendered={false}
				/>,
			);
		});
	}
	if (square != []) {
		square.forEach((canvas: AllUnrenderedList, index) => {
			squareList.push(
				<ChooseTemplateCard
					key={canvas.id}
					selectedElement={selectedCanvasId}
					selected={canvas.id === selectedCanvasId}
					type="square"
					temp={canvas}
					ind={index}
					onDel={() => {
						const newArr = [...unrenderedList];
						newArr.splice(
							newArr.findIndex((i) => i.id == canvas.id),
							1,
						);
						setUnrenderedList(newArr);
					}}
					getAllCanvas={getAllUnrenderedCanvas}
					rendered={false}
				/>,
			);
		});
	}
	if (portrait != []) {
		portrait.forEach((canvas: AllUnrenderedList, index) => {
			portraitList.push(
				<ChooseTemplateCard
					key={canvas.id}
					selectedElement={selectedCanvasId}
					selected={canvas.id === selectedCanvasId}
					type="portrait"
					temp={canvas}
					ind={index}
					onDel={() => {
						const newArr = [...unrenderedList];
						newArr.splice(
							newArr.findIndex((i) => i.id == canvas.id),
							1,
						);
						setUnrenderedList(newArr);
					}}
					getAllCanvas={getAllUnrenderedCanvas}
					rendered={false}
				/>,
			);
		});
	}

	let sideMenu = (
		<Flex
			flexDir={["row", "row", "row", "column"]}
			pt="30px"
			gridGap={"15px"}
			flex={1}
			justifyContent={["center", "center", "center", "flex-end"]}
			alignItems="center">
			<Button
				disabled={selectedCanvasId == null}
				id="editButton"
				bg={theme.colors.primary}
				color="white"
				w={["110px", "150px", "150px", "150px"]}
				onClick={() => {
					onOpen();
				}}>
				Edit Name
			</Button>
			{selectedCanvasId && description && title !== null && (
				<SaveTemplateModel
					titleDes="Edit template description"
					titleName="Edit template name"
					buttonName="Edit"
					isOpen={isOpen}
					des={description}
					name={title}
					onClose={onClose}
					createCanvasData={async () => {
						await updateCanvas(title, description, selectedCanvasId);
						await getAllUnrenderedCanvas();
					}}
					SetTitle={SetTitle}
					SetDescription={SetDescription}
				/>
			)}
			<Button
				id="continueButton"
				disabled={selectedCanvasId == null}
				onClick={() => {
					if (!selectedCanvasId) {
						return;
					}
					// debugger;
					canvasDetail(selectedCanvasId);
					history.push(basePath + "/design");
				}}
				bg={theme.colors.primary}
				color="white"
				w={["120px", "150px", "150px", "150px"]}>
				Continue
			</Button>
		</Flex>
	);

	return (
		<Flex flex={1} minH="100vh">
			<Flex m="20px 30px 30px 30px" flex={1} flexDir="column" justifyContent="center">
				<Text fontSize="30px" my="20px  " fontWeight="700">
					Unrendered Projects
				</Text>
				<Flex flex={1} flexDir="column">
					<Flex borderBottom="1px solid #e5e4e4" alignItems="flex-start" justifyContent="space-around" mx="12px" mb="5px">
						<Flex
							cursor="pointer"
							onClick={() => {
								setSelectedTemplates("landscape");
							}}
							background={selectedTemplatesType === "landscape" ? "#f3f3f3" : "white"}
							color={selectedTemplatesType === "landscape" ? "black" : theme.colors.gray3}
							flex={1}
							justifyContent="center"
							rounded="sm"
							p="5px">
							Landscape
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => {
								setSelectedTemplates("portrait");
							}}
							background={selectedTemplatesType === "portrait" ? "#f3f3f3" : "white"}
							color={selectedTemplatesType === "portrait" ? "black" : theme.colors.gray3}
							flex={1}
							justifyContent="center"
							rounded="sm"
							p="5px">
							Portrait
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => {
								setSelectedTemplates("square");
							}}
							flex={1}
							background={selectedTemplatesType === "square" ? "#f3f3f3" : "white"}
							color={selectedTemplatesType === "square" ? "black" : theme.colors.gray3}
							justifyContent="center"
							// mx="4px"
							rounded="sm"
							p="5px">
							Square
						</Flex>
						<Flex
							cursor="pointer"
							onClick={() => {
								setSelectedTemplates("all");
							}}
							background={selectedTemplatesType === "all" ? "#f3f3f3" : "white"}
							color={selectedTemplatesType === "all" ? "black" : theme.colors.gray3}
							flex={1}
							justifyContent="center"
							rounded="sm"
							p="5px">
							All
						</Flex>
					</Flex>
				</Flex>
				<Flex flexDirection="column" mt="10px" h="100%" overflow="auto">
					{/* <OverFlowBox> */}
					{/* <LoadingContainer isLoading={isloading}> */}
					{selectedTemplatesType === "all" && (
						<OverFlowBox>
							{landscapeList.length > 0 && (
								<Text ml="30px" fontWeight="500" fontSize="20px">
									Landscape
								</Text>
							)}
							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{landscapeList}
							</Grid>
							{portraitList.length > 0 && (
								<Text ml="30px" fontWeight="500" fontSize="20px">
									Portrait
								</Text>
							)}

							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{portraitList}
							</Grid>

							{squareList.length > 0 && (
								<Text ml="30px" fontWeight="500" fontSize="20px">
									Square
								</Text>
							)}
							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{squareList}
							</Grid>
						</OverFlowBox>
					)}

					{selectedTemplatesType === "landscape" && (
						<OverFlowBox>
							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{landscapeList}
							</Grid>
						</OverFlowBox>
					)}
					{selectedTemplatesType === "portrait" && (
						<OverFlowBox>
							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{portraitList}
							</Grid>
						</OverFlowBox>
					)}
					{selectedTemplatesType === "square" && (
						<OverFlowBox>
							<Grid
								justifyContent="center"
								alignItems="center"
								justifyItems="center"
								gridTemplateColumns="repeat(auto-fill,minmax(250px,1fr))"
								gap={2}>
								{squareList}
							</Grid>
						</OverFlowBox>
					)}
				</Flex>
				{!isSmall && (
					<Flex justifyContent="flex-end" alignItems="center">
						{sideMenu}
					</Flex>
				)}
			</Flex>
			{isSmall && (
				<Flex pb="30px" w="16rem" borderLeft=" 1px solid rgba(126, 126, 126, 0.15)" flexDirection="column" minH="100%">
					<Flex
						justifyContent="flex-end"
						onClick={() => {
							if (token == null) return;
							history.push("/dashboard");
						}}
						mt="32px"
						pr="6px"
						h="30px"
						w="100%">
						<img src={Logo} />
					</Flex>
					{sideMenu}
				</Flex>
			)}
		</Flex>
	);
};

export default UnrenderedCanvasList;
