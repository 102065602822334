export const templatesArray: Templates[] = [
	{
		image: "",
		name: "Template name",
		description: `"Template name
    Template description, description of template"`,
	},
	{
		image: "",
		name: "Template name",
		description: `"Template name
    Template description, description of template"`,
	},
];
export interface Templates {
	image: string;
	name: string;
	description: string;
}

export type UserTypes = "freeSU" | "freeN" | "Pro";
export type templatesType = "default" | "saved" | "unrendered";
