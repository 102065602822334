import { Command } from "../../types";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { State, StateMapper } from "easy-peasy";
import { ChangeZIndexCommand } from "./ChangeZIndexCommand";
import { RemoveCommand } from "./RemoveCommand";
import { UpdateCommand } from "./UpdateCommand";
import { AddCommand } from "./AddShapes";
import { DuplicateCommand } from "./DuplicateCommand";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { GroupCommand } from "../AddAndRemove/GroupCommand";
import { RemoveGroupCommand } from "../../Commands/AddAndRemove/RemoveGroupCommand";

type CmdRecord<keys extends string> = {
	[key in keys]: { new (...args: any): Command<State<CanvasStore>, key> };
};

export type AllCommands =
	| ChangeZIndexCommand
	| RemoveCommand
	| UpdateCommand<AllCanvasElements>
	| AddCommand
	| DuplicateCommand
	| GroupCommand
	| RemoveGroupCommand;
export const commands: CmdRecord<AllCommands["name"]> = {
	ChangeZIndex: ChangeZIndexCommand,
	RemoveElement: RemoveCommand,
	UpdateElement: UpdateCommand,
	AddElement: AddCommand,
	DuplicateElement: DuplicateCommand,
	GroupCommand: GroupCommand,
	RemoveGroupCommand: RemoveGroupCommand,
};

export function objToCommandInstance<T extends AllCommands>(commandObj: T): T {
	const CommandClass = commands[commandObj.name];

	Object.setPrototypeOf(commandObj, CommandClass.prototype);
	return commandObj;
}

export function commandInstanceToObj<T extends AllCommands>(commandObj: T): T {
	Object.setPrototypeOf(commandObj, Object.prototype);
	return commandObj;
}
