import React from "react";
import { Arc, Group, Line, Rect, Ring } from "react-konva";

export interface SquareProgressBarProps {
	ele: any;
	isStatic: boolean;
	videoFinishRatio: number;
}

const SquareProgressBar: React.FC<SquareProgressBarProps> = (props) => {
	let width = 200;
	let height = 20;
	let x = 30;
	let y = 50;

	let w = props.videoFinishRatio * width;

	let fill1 = 0;
	let fill2 = 0;

	if (props.videoFinishRatio < 0.5) {
		fill1 = props.videoFinishRatio * width * 2;
		fill2 = 0;
	} else if (props.videoFinishRatio > 0.5) {
		fill1 = width;
		fill2 = (props.videoFinishRatio - 0.5) * (width * 2 - 20);
	}
	let extra;
	if (props.isStatic) {
		extra = {
			name: "object",
			draggable: true,
		};
	}
	return (
		<Group
			opacity={props.ele.opacity}
			key={props.ele.id}
			data-id={props.ele.id}
			{...extra}
			x={props.ele.x}
			y={props.ele.y}
			scaleX={props.ele.scaleX}
			scaleY={props.ele.scaleY}
			rotation={props.ele.rotationDeg}>
			<Rect x={-150} y={50} height={width} width={width} fill={"#ff000000"}></Rect>

			<Rect height={height} width={width} fill={props.ele.fill} x={30} y={250} rotation={270}></Rect>
			<Rect height={height} width={width} fill={props.ele.fill} x={-130} y={50} rotation={90}></Rect>
			<Rect height={height} width={width} fill={props.ele.fill} x={-150} y={230} rotation={0}></Rect>
			<Rect height={height} width={width} fill={props.ele.fill} x={50} y={70} rotation={180}></Rect>

			<Rect height={height} width={fill2} fill={props.ele.fillBar} x={30} y={250} rotation={270}></Rect>
			<Rect height={height} width={fill1} fill={props.ele.fillBar} x={-150} y={230} rotation={0}></Rect>
			<Rect height={height} width={fill1} fill={props.ele.fillBar} x={50} y={70} rotation={180}></Rect>
			<Rect height={height} width={fill2} fill={props.ele.fillBar} x={-130} y={50} rotation={90}></Rect>
		</Group>
	);
};

export default SquareProgressBar;
