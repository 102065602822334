import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import styles from "./styles.module.scss"
import flagOne from "../../../../assets/images/flagOne.png"



const VOICE_CONTROLS =[
    {
        label:"Personalised",
        icon:""
    },
    {
        label:"John",
        icon:flagOne
    },
    {
        label:"Marta",
        icon:""
    },
    {
        label:"Olive",
        icon:flagOne
    }
]

const VoiceControls = () => {
  return (
    <Flex direction="column">
     {VOICE_CONTROLS.map((v:any)=>(
         <Flex alignItems="baseline" className="d-flex align-items-baseline">
         <input
           type="radio"
           readOnly
           className={styles["radio-button"]}
         />
        <Box display={"flex"} alignItems={"center"} gap="5px">
        <Box
           as="label"
           ml="15px"
           className={`ml-3 ${styles["radio-button-label"]}`}
         >
           {v.label}
           
         </Box>
         <img src={v.icon} width={"20px"} height={"20px"}/>
        </Box>
       </Flex>
     ))}

    </Flex>
  );
};

export default VoiceControls
