import { State } from "easy-peasy";
import React from "react";
import { Command } from "../../types";
import { AllCanvasElements } from "../../types/canvasStoreTypes/CanvesTypes";
import { CanvasStore } from "../../types/canvasStoreTypes/StoreTypes";
import { v4 as uuid } from "uuid";
import { GroupElement } from "../../types/canvasStoreTypes/CanvesTypes";
import Konva from "konva";

export class GroupCommand implements Command<State<CanvasStore>, "GroupCommand"> {
	name = "GroupCommand" as const;
	groupId: string;
	constructor(public ids: string[]) {
		// debugger;
		this.groupId = uuid();
	}
	execute(state: State<CanvasStore>): void {
		const trans = state.konvaTransformerInstance;
		if (!trans) {
			return;
		}

		const group: GroupElement = {
			id: this.groupId,
			type: "Group",
			scaleX: trans.scaleX(),
			scaleY: trans.scaleY(),
			opacity: 1,
			x: 0,
			y: 0,
			skewX: trans.skewX(),
			skewY: trans.skewY(),
			height: trans.height(),
			width: trans.width(),
			rotationDeg: 0,
			children: {},
		};

		for (const id of this.ids) {
			delete state.selectedElementsInstance[id].attrs["name"];

			group.children[id] = state.canvasState[id];
			delete state.canvasState[id];
		}
		state.canvasState[this.groupId] = group;
	}

	undo(state: State<CanvasStore>): void {
		const group: GroupElement = state.canvasState[this.groupId] as GroupElement;
		const groupInstance = state.selectedElementsInstance[group.id] as Konva.Group;
		// console.log(
		// 	groupInstance.children?.map((c) => ({
		// 		...c.getAbsolutePosition(),
		// 		...c.getAbsoluteScale(),
		// 	})),
		// );
		if (!group.children) {
			return;
		}

		const cameraGroupInstance = state.cameraGroupInstance?.attrs;

		for (const child of groupInstance.children ?? []) {
			const id = child.attrs["data-id"];
			child.attrs["name"] = "object";

			const { x, y } = child.getAbsolutePosition();
			const { x: sx, y: sy } = child.getAbsoluteScale();
			const ro = child.getAbsoluteRotation();
			// console.log("group.children[id]", child);

			state.canvasState[id] = group.children[id];
			state.canvasState[id].scaleX = child.attrs.scaleX * groupInstance.attrs.scaleX;
			state.canvasState[id].scaleY = child.attrs.scaleY * groupInstance.attrs.scaleY;
			state.canvasState[id].x = x - cameraGroupInstance.x;
			state.canvasState[id].y = y - cameraGroupInstance.y;
			state.canvasState[id].rotationDeg = ro;
		}

		for (const id of this.ids) {
			group.children[id] = state.canvasState[id];
		}

		delete state.canvasState[this.groupId];
	}
}
