import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Button,
  Flex,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { history } from "../../app/router/history";
import AudioPlayer from "../../components/Audio/AudioPlayer";
import { theme } from "../../config/theme/baseDesign";
import { basePath } from "../../constants";
import AppLayout from "../../Layout/AppLayout";
import { store } from "../../store";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { currentUserDetailsApi } from "../../utils/Api/currentUserApi";
import { getAllSubscriptions } from "../../utils/Api/GetAllSubscriptions";
import { permissionApi } from "../../utils/Api/PermissionApi";
import { getRecomendationPlanName } from "../../utils/getRecomendedPlan";
import { TokenService } from "../../utils/token";
import Audio from "../../../../../components/Icons/Audio";
import TextIcon from "../../../../Icons/Text";
import UpgradeModal from "../../components/UpgradeModal"

export interface AudioPageProps {}

const AudioPage: React.FC<AudioPageProps> = (props) => {
  const fileUpload = useRef<null | HTMLInputElement>(null);
  const [selectedTab, setSelectedTab] = useState("audio");
  const [srtContent, setSrtContent] = useState("");
  const { setAudioFile, setIsValidAudio } = useEasyActions(
    (state) => state.audioStore
  );
  const { originalAudio, regionEnd, regionStart, isValidAudio } = useEasyState(
    (store) => store.audioStore
  );
  const {
    userEmail: userName,
    permissionsData,
    currentUserDetails,
    allSubs,
  } = useEasyState((store) => store.userDataStore);
  const { setPermissionsData, setCurrentUserDetails, setAllSub } =
    useEasyActions((store) => store.userDataStore);
  const {
    isOpen: isOpenUpgradeModal,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();
  const toast = useToast();
  // const [isValidAudio, setIsValidAudio] = useState(false);
  const [recomendedPlan, setRecomendedPlan] = useState<string | null>(null);
  const [isSmall] = useMediaQuery("(max-width: 993px)");
  const disableMessage =
    originalAudio == null ? "Please upload audio first" : null;
  const [currentPlanName, setCurrentPlanName] = useState<string | null>(null);
  // debugger;
  function file(e: any) {
    // console.log("file", e.target.files[0]);
    setAudioFile(e.target.files[0]);
    TokenService.setCanvaStep("/upload");
  }
  let loginUserName = TokenService.getName();
  let token = TokenService.getToken();
  // debugger;
  useEffect(() => {
    // console.log("1st");
    permissionApiData();
    if (token) {
      currentUserDetailsApiData();
    }
    getAllSubscriptionsApi();
  }, []);

  useEffect(() => {
    // console.log("2st");
    let permission =
      store.getState().userDataStore.permissionsData?.max_video_length;
    // console.log(permission, permissionsData?.max_video_length, regionEnd);
    if (regionEnd && permissionsData && regionStart != null) {
      permission == null ? (permission = "60") : permission;
      let num = Number(permission);
      let totalDiff = regionEnd - regionStart;
      let isvalid = totalDiff <= num;
      setIsValidAudio(isvalid);
      // console.log(
      //   "totalDiff",
      //   totalDiff,
      //   isvalid,
      //   num,
      //   permissionsData?.max_video_length,
      //   regionEnd
      // );
    }
  }, [regionEnd, permissionsData, regionStart]);

  async function permissionApiData() {
    let response = await permissionApi();
    if (response instanceof Error) return;
    if (!("data" in response.data)) {
      setPermissionsData(response.data.permissions);
    } else {
      setPermissionsData(response.data.data.attributes.permissions);
    }
  }
  async function currentUserDetailsApiData() {
    if (!token) return;
    let response = await currentUserDetailsApi();
    let currentUserData = response.data.data.attributes;
    setCurrentUserDetails(currentUserData);
    if ("plan_name" in response.data.data.attributes.subscription_details) {
      setCurrentPlanName(
        response.data.data.attributes.subscription_details?.plan_name
      );
    }
    // debugger;
  }
  async function getAllSubscriptionsApi() {
    let response = await getAllSubscriptions();
    if (response instanceof Error) return;
    let allSub = response.data.data;
    setAllSub(allSub);
  }


  const icon = "● ●";

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [speechResult , setSpeechResult] = useState([])
  const [isLoading , setIsLoading] = useState(false)



  const handleSubmit = async (file) => {

    const apiKey = "AIzaSyCAF13T-jgI2tgDkA3EK9QTu8utARA5BSw"; 
    const reader = new FileReader();

    reader.onloadend = async () => {
      const audioBase64 = reader?.result?.split(",")[1]; 

      const requestBody = {
        config: {
          encoding: "MP3", 
          sampleRateHertz: 44100, 
          languageCode: "en-US",
        },
        audio: {
          content: audioBase64,
        },
      };

      try {
        setIsLoading(true)
        const response = await fetch(
          `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();
        setSpeechResult(data?.results)
      } catch (error) {
        console.error("Error:", error);
      }finally{
        setIsLoading(false)
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


  const formattedTranscripts = speechResult?.length > 0 ? speechResult.map((item, index) => {
    const startTime = index === 0 ? 0 : speechResult[index - 1]?.resultEndTime;
    const endTime = item.resultEndTime;
    return {
        icon,
        transcript: item.alternatives[0].transcript,
        startTime,
        endTime
    };
}) : [];


  const handleSave = async()=>{
  TokenService.setSpeechToText(formattedTranscripts);
  }

  const handleRemove = async()=>{
    TokenService.removeSpeechText()
  }


  let AudioOptionMenu = (
    <Flex
      justifyContent="flex-end"
      flexDir="column"
      flex={1}
      alignItems="center"
    >
      <Flex flexDir="column" flex={1} alignItems="flex-end" w="100%">
        <Flex
          mt="7px"
          mr="25px"
          cursor="pointer"
          fontSize={!isSmall ? "18" : "18"}
          borderBottom="1px solid black"
        >
          {loginUserName == null ? (
            <Text
              onClick={() => {
                history.push(basePath + "/login");
              }}
            >
              login
            </Text>
          ) : (
            <Text
              onClick={() => {
                history.push(basePath + "/dashboard");
              }}
            >
              {loginUserName}
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex gap={"10px"} flex={10}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          cursor={"pointer"}
          height={"fit-content"}
          onClick={() => setSelectedTab("audio")}
        >
          <Box
            border={
              selectedTab === "audio"
                ? "1px solid #0095a8"
                : "1px solid #A9A9A9"
            }
            padding={2}
            borderRadius={"6px"}
            backgroundColor={selectedTab === "audio" ? "#0095a8" : "#efefef"}
            marginBottom={"4px"}
          >
            <Audio fill={selectedTab === "audio" ? "white" : "black"} />
          </Box>
          <Text fontSize="sm">Audio</Text>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={async() => {
            setSelectedTab("text")
            await handleRemove()
          }}
        >
          <Box
            border={
              selectedTab === "text" ? "1px solid #0095a8" : "1px solid #A9A9A9"
            }
            padding={2}
            borderRadius={"6px"}
            backgroundColor={selectedTab === "text" ? "#0095a8" : "#efefef"}
            marginBottom={"4px"}
          >
            <TextIcon fill={selectedTab === "text" ? "white" : "black"} />
          </Box>
          <Text fontSize="sm">Text</Text>
        </Box>
      </Flex>

      {!isValidAudio && originalAudio ? (
        <Flex mb="30px" flexDir="column">
          <Text
            color={isValidAudio ? "black" : "red"}
            fontWeight="500"
            borderBottom="1px"
            fontSize="14px"
          >
            Audio limit: {permissionsData?.max_video_length} secs
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      <Flex mx="30px" mb="30px" flexDir="column">
        {currentUserDetails?.subscription_details?.plan_name == "trial" ? (
          <Text fontSize="14px">
            <Text
              display="inline-block"
              cursor="pointer"
              w="fit-content"
              color={theme.colors.primary}
              borderBottom="1px"
              onClick={() => {
                onOpenUpgradeModal();
              }}
            >
              {/* <a href=basePath + "/sigup"> */}
              Upgrade to pro account
              {/* </a>{" "} */}
            </Text>{" "}
            to unlock up to 40 mins per month
          </Text>
        ) : (
          <Text fontSize="14px">
            {currentUserDetails?.subscription_details?.plan_name == "free" ? (
              <Text fontSize="14px">
                <Text
                  display="inline-block"
                  cursor="pointer"
                  w="fit-content"
                  color={theme.colors.primary}
                  borderBottom="1px"
                  onClick={() => {
                    history.push(basePath + "/signup");
                  }}
                >
                  {/* <a href=basePath + "/sigup"> */}
                  Create a{" "}
                  {getRecomendationPlanName(allSubs, currentUserDetails)}{" "}
                  account
                  {/* </a>{" "} */}
                </Text>{" "}
                to unlock up to 5 mins per month
              </Text>
            ) : null}
          </Text>
        )}
      </Flex>

      <Button
       onClick={async (e) => {
        // if (!formattedTranscripts || !srtContent) {
        //     return;
        // }
        await handleSave();
        history.push(basePath + "/design");
    }}
    
        mb="30px"
        variant="styled"
        opacity={formattedTranscripts?.length > 0 || srtContent ? "1" : "0.5"}
      >
       Continue
      </Button>
    </Flex>
  );

  let maxWidth = "993px";
  return (
    <AppLayout
      heading="Upload your audio or text."
      optionMenuContent={AudioOptionMenu}
      optionMenuContentMobile={<Flex></Flex>}
      headingContent={<Flex></Flex>}
      maxWidth={maxWidth}
    >
      <AudioPlayer
        selectedTab={selectedTab}
        srtContent={srtContent}
        setSrtContent={setSrtContent}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        handleSubmit={handleSubmit}
        speechResult={speechResult}
        isLoading={isLoading}
        formattedTranscripts={formattedTranscripts}
      />
      <UpgradeModal isOpen={isOpenUpgradeModal} onClose={onCloseUpgradeModal} />
    </AppLayout>
  );
};

export default AudioPage;
