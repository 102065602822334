import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Flex, Grid, Heading, Skeleton, Text, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { useEasyActions, useEasyState } from "../../store/hooks";
import { Datum } from "../../types/ApiTypex/AllCanvas";
import { deleteCanvasApi } from "../../utils/Api/DeleteCanvas";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { allCanvasApi } from "../../utils/Api/AllCanvasApi";
import { style } from "wavesurfer.js/src/util";
import Duplicate from "../../assets/images/Duplicate.svg";
import { CgDuplicate } from "react-icons/cg";
import SaveTemplateModel from "../../pages/Generate/SaveTemplateModel";
import { dublicateApi } from "../../utils/Api/DublicateTemplate";
import { TemplatesTypes } from "../../types";
import { duplicateApi } from "../../utils/Api/DublicateApi";
import { ModelBoxMenu } from "../home/ModelBoxMenu";
import { theme } from "../../config/theme/baseDesign";
import { TokenService } from "../../utils/token";
import { AllUnrenderedList } from "../../types/ApiTypex/unrenderedList";

export interface ChooseTemplateCardProps {
	temp: Datum | AllUnrenderedList;
	ind: number;
	colWidth?: number;
	selectedElement: string | null;
	selected?: boolean;
	type: TemplatesTypes;
	onDel: () => void;
	getAllCanvas: () => void;
	rendered: boolean;
}

const ChooseTemplateCard: React.FC<ChooseTemplateCardProps> = ({
	getAllCanvas,
	temp,
	ind,
	type,
	selected,
	onDel,
	selectedElement,
	rendered: unrendered,
	colWidth = 1,
}) => {
	const [display, setDisplay] = useState<boolean>(false);
	const { setAllTemplates } = useEasyActions((store) => store.templateStore);
	const { setSelectedCanvasId, setSelectedCanvasName, setSelectedCanvasDes, setTemplatesType } = useEasyActions((store) => store.canvasStore);
	const { isOpen: isOpenDub, onOpen: onOpenDub, onClose: onCloseDub } = useDisclosure();
	const { isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel } = useDisclosure();
	const { selectedCanvasId, canvasState, templatesType, selectedCanvasDes, selectedCanvasName } = useEasyState((store) => store.canvasStore);
	const [titleDub, SetTitleDub] = useState<string | null>(selectedCanvasName);
	const [descriptionDub, SetDescriptionDub] = useState<string | null>(selectedCanvasDes);
	const { allTemplate, templatesData, templatesKind } = useEasyState((store) => store.templateStore);
	const { unrenderedCanvasId } = useEasyState((store) => store.unrenderedStore);
	const { setUnrenderedCanvasId } = useEasyActions((store) => store.unrenderedStore);

	const toast = useToast();

	function tempName() {
		let splitedNam = temp.attributes.name.split(":");
		return splitedNam[1];
	}
	async function dublicate(id: string, name: string, description: string, type: TemplatesTypes, unrendered: boolean) {
		const res = await duplicateApi(id, name, description, type, unrendered);
		if (res instanceof Error) {
			toast({
				status: "error",
				description: res.message,
			});
		}
		toast({
			status: "success",
			description: "Duplicated",
		});
		getAllCanvas();
	}

	async function deleteCanvas(id: string) {
		let response = await deleteCanvasApi(id);
		// debugger;
		// console.log("response", response);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "There is some internet problem please try later.",
			});
			return;
		}
		toast({
			status: "success",
			title: "",
			description: "Deleted",
		});
		getAllCanvas();
	}
	useEffect(() => {
		SetTitleDub(selectedCanvasName);
		SetDescriptionDub(selectedCanvasDes);
	}, [selectedCanvasName, selectedCanvasDes]);
	let role = TokenService.getRole();

	return (
		<Flex
			// boxShadow="0px 0px 8px 4px #e2e0e0"
			id="templateContainer"
			cursor="pointer"
			_hover={{ boxShadow: selected ? "" : "0px 0px 1px 2px #e2e0e0 !important" }}
			style={{ boxShadow: selected ? "0px 0px 1px 2px #0095A8" : "" }}
			transition=".3s all"
			position="relative"
			gridColumn={`auto / span ${colWidth}`}
			w={templatesType === "portrait" ? "80%" : "90%"}
			onClick={() => {
				// debugger;
				setSelectedCanvasId(temp.id);
				// console.log(temp.id);
				if (unrendered === false) {
					setUnrenderedCanvasId(temp.id);
				}
				let name = temp.attributes.name;
				setDisplay(true);
				// @ts-ignore
				setTemplatesType(type);
				// console.log("temp.type", temp.type, type);
				setSelectedCanvasDes(temp.attributes.description);
				setSelectedCanvasName(name);
			}}
			key={ind}
			onMouseEnter={(e) => {
				setDisplay(true);
			}}
			onMouseLeave={() => {
				setDisplay(false);
			}}
			m="15px"
			flexDir="column">
			{(display && role === "true") || (display && role === "false" && (templatesKind === "saved" || "unrendered")) ? (
				<Flex
					right={2}
					position="absolute"
					cursor="pointer"
					onClick={() => {
						onOpenDel();
					}}
					justifyContent="flex-end">
					✖
				</Flex>
			) : null}
			{selectedCanvasId && titleDub && descriptionDub !== null && (
				<ModelBoxMenu isopen={isOpenDel} onclose={onCloseDel}>
					<Flex flexDir="column">
						<Flex flex={1} justifyContent="center" my="20px">
							Do you want to delete it?
						</Flex>
						<Flex flex={1} justifyContent="space-around" my="10px" mb="10px">
							<Button
								onClick={onCloseDel}
								bg={theme.colors.primary}
								color="white"
								w={["150px", "150px"]}
								_hover={{ bg: theme.colors.primary }}>
								Cancel
							</Button>
							<Button
								onClick={() => {
									deleteCanvas(temp.id);
									onCloseDel();
									onDel();
								}}
								bg={theme.colors.primary}
								color="white"
								w={["150px", "150px"]}
								_hover={{ bg: theme.colors.primary }}>
								Delete
							</Button>
						</Flex>
					</Flex>
				</ModelBoxMenu>
			)}
			{(display && role === "true") || (display && role === "false" && (templatesKind === "saved" || "unrendered")) ? (
				<Flex
					right={7}
					top={1}
					position="absolute"
					cursor="pointer"
					onClick={() => {
						onOpenDub();
					}}
					justifyContent="flex-end">
					<CgDuplicate size={18} />
				</Flex>
			) : null}
			{selectedCanvasId && titleDub && descriptionDub !== null && (
				<SaveTemplateModel
					buttonName="Duplicate"
					titleDes="Template description"
					titleName="Template name"
					des={descriptionDub}
					name={titleDub}
					isOpen={isOpenDub}
					onClose={() => {
						onCloseDub();
						setDisplay(false);
					}}
					createCanvasData={async () => {
						await dublicate(selectedCanvasId, titleDub, descriptionDub, templatesType, unrendered);
					}}
					SetTitle={SetTitleDub}
					SetDescription={SetDescriptionDub}
				/>
			)}
			<Flex
				style={{
					aspectRatio: (type === "portrait" && "9 / 16") || (type === "landscape" && "16 / 9") || "1 / 1",
				}}
				w={templatesType === "portrait" ? "100%" : "100%"}
				border="1px solid #f4f4f4">
				{/* {unrendered !== true ? ( */}
				<img
					style={{
						aspectRatio: (type === "portrait" && "9 / 16") || (type === "landscape" && "16 / 9") || "1 / 1",
						objectFit: "cover",
					}}
					src={temp.attributes.picture_url}
				/>
				{/* ) : (
                        <></>
                    )} */}
			</Flex>

			<Flex justifyContent="center" alignItems="center" p="15px" flex={1} flexDir="column">
				<Flex
					h="42px"
					width="200px"
					overflow="hidden"
					textOverflow="ellipsis"
					alignItems="flex-start"
					textAlign="center"
					justifyContent="center">
					<Heading fontSize="18px">{tempName()}</Heading>
				</Flex>

				<Text
					width="200px"
					h="42px"
					fontSize="16px"
					overflow="hidden"
					justifyContent="center"
					alignItems="center"
					textOverflow="ellipsis"
					textAlign="center">
					{temp.attributes.description}
				</Text>
			</Flex>
		</Flex>
	);
};

export default ChooseTemplateCard;
