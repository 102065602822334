import { Flex, Text } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";
import { history } from "../app/router/history";
import { theme } from "../config/theme/baseDesign";
import HomeLayout from "../Layout/HomeLayout";
import Folder from "../assets/images/folderWhite.svg";
import Unrendered from "../assets/images/unrendered.svg";
import { useMediaQuery } from "@chakra-ui/media-query";
import { useDisclosure } from "@chakra-ui/hooks";
import { subscribe } from "../utils/Api/subscribe";
import { useEasyActions, useEasyState } from "../store/hooks";
import { Button, useToast,Stack } from "@chakra-ui/react";
import { createCanvas } from "../utils/Api/CreateCanvas";
import { TokenService } from "../utils/token";
import { basePath } from "../constants";
import { currentUserDetailsApi } from "../utils/Api/currentUserApi";
import {  ModalOverlay } from '@chakra-ui/react'
import {  AllUnrenderedList } from "../types/ApiTypex/unrenderedList";
import { allUnrenderedCanvas } from "../utils/Api/AllUnrenderedCanvas";
import UpgradeModal from "./UpgradeModal";
import useScreenWidth from "./useScreenWidth";
import { MONTHS } from "../types";

export interface DashboardProps { }

const Dashboard: React.FC<DashboardProps> = (props) => {
	const [isSmall] = useMediaQuery("(min-width: 800px)");
	const { unrenderedDes, unrenderedTitle, unrenderedList } = useEasyState((store) => store.unrenderedStore);
	const { templatesType } = useEasyState((store) => store.canvasStore);
	const { permissionsData, currentUserDetails, subscriptionDate, allSubs } = useEasyState((store) => store.userDataStore);
	const { setSelectedCanvasId, setSelectedCanvas } = useEasyActions((store) => store.canvasStore);
	const { setCurrentUserDetails, setBankCards, setPermissionsData, setSubscriptionDate } = useEasyActions((store) => store.userDataStore);
	const { resetToNull } = useEasyActions((store) => store.audioStore);
	const { setUnrenderedDes, setUnrenderedTitle, setUnrenderedCanvasId, setUnrenderedList } = useEasyActions((store) => store.unrenderedStore);
	const { setTemplatesKind } = useEasyActions((store) => store.templateStore);
	const [plan, setPlan] = React.useState<string | null>(null);
	const toast = useToast();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenModel, onOpen: onOpenModel, onClose: onCloseModel } = useDisclosure();
	const { isOpen: isOpenUnrendered, onOpen: onOpenUnrendered, onClose: onCloseUnrendered } = useDisclosure();
	const { isOpen: isOpenUpgradeModal, onOpen: onOpenUpgradeModal, onClose: onCloseUpgradeModal } = useDisclosure();
	const screenWidth = useScreenWidth();

	const [isloading, setIsloadind] = useState(true);
	let token = TokenService.getToken();
	async function getAllUnrenderedCanvas() {
		let response = await allUnrenderedCanvas();
		if (response instanceof Error) {
			return;
		}
		let unrendered: AllUnrenderedList[] = [];
		response.data.data.forEach((temp, ind) => {
			if (temp.attributes.rendered == false) {
				unrendered.push(temp);
			}
		});
		// console.log(unrendered.length);
		setUnrenderedList(unrendered);
		setIsloadind(false);
	}
	async function currentUserDetailsApiData() {
		if (!token) return;
		let response = await currentUserDetailsApi();
		if (response instanceof Error) return;
		let currentUserData = response.data.data.attributes;
		setCurrentUserDetails(currentUserData);
		if ("valid_till" in response.data.data.attributes.subscription_details) {
			setSubscriptionDate(response.data.data.attributes.subscription_details?.valid_till);
		}

		if ("plan_name" in response.data.data.attributes.subscription_details) {
			setPlan(response?.data?.data?.attributes?.subscription_details?.plan_name);
		}

		let bankCard = response.data.data.attributes.bank_cards[0];
		setBankCards(bankCard);
		setPermissionsData(response.data.data.attributes.permissions);
	}
	useEffect(() => {
		let loginUserName = TokenService.getName();
		getAllUnrenderedCanvas();
		const fetchData = async () => {
			const userDetails = await currentUserDetailsApiData();
			if (currentUserDetails?.subscription_details?.upgrade_alert) {
				onOpenUpgradeModal();
			}
			if (loginUserName == null || loginUserName == 'null') {
				history.push(basePath + "/step_one");
			}

		};
		fetchData();
	}, []);
	let menuItems = [
		{
			label: "Account settings",
			onClick: () => history.push(basePath + "/accountsetting")
		},
		{
			label: "Upgrade",
			onClick: onOpenUpgradeModal
		},
		{
			label: "Log out",
			onClick: sign_out
		}
	];
	
	let sideMenu = (
		<Stack mb="20px" mt="80px" flexDir="column" spacing={4} align='center' >
			{menuItems.map((item, index) => (
				 <Button key={index} colorScheme='teal' variant='link' onClick={item.onClick}>
				 {item.label}
			   </Button>
			))}
		</Stack>
	);

	async function createCanvasData() {
		if (!(unrenderedTitle && unrenderedDes)) {
			return;
		}
		let response = await createCanvas({ name: unrenderedTitle, description: unrenderedDes, type: templatesType, rendered: "false" });
		// console.log("response", response);
		if (response instanceof Error) {
			toast({
				status: "error",
				title: "Error!",
				description: "there is some problem",
			});
			return;
		}
		setUnrenderedCanvasId(response.data.data.id);
	}
	let now = new Date();
	function getTime() {
		let hours = now.getHours();
		if (hours < 12 && hours >= 5) {
			return "Good morning";
		}
		if (hours < 18 && hours >= 12) {
			return "Good afternoon";
		}
		if (hours < 5 || hours >= 18) {
			return "Good evening";
		}
		return "Good morning";
	}

	function remainingTimeSec() {
		return (permissionsData?.remaining_monthly_seconds % 60);
	}

	function formatDate(inputDate: string): string {
		const date = new Date(inputDate);

		const options: Intl.DateTimeFormatOptions = {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		};

		return date.toLocaleDateString('en-US', options);
	}

	function subDate() {
		let newDate = new Date();
		newDate.setMonth(newDate.getMonth() + 1);
		let monthName = MONTHS[newDate.getMonth()];
		let repDate = {
			year: newDate.getFullYear(),
			month: monthName,
			day: newDate.getDate(),
		};
		return repDate;
	}

	async function sign_out() {

		try {
			const response = await fetch(basePath + '/users/sign_out', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response.ok) {
				TokenService.clearStorage();
				toast({
					status: 'success',
					description: 'You are logged out.',
				});
				window.location.href = 'https://www.ampifi.co';
			} else {
				console.error('Logout failed');
				toast({
					status: 'error',
					description: 'Logout failed. Please try again.',
				});
			}
		} catch (error) {
			console.error('Error during logout:', error);
			toast({
				status: 'error',
				description: 'An error occurred during logout. Please try again.',
			});
		}
	}

	function subscribe_plan(plan) {
		const dataToSend = { plan: plan };
		fetch(basePath + '/api/subscriptions', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${token}`
			},
			body: JSON.stringify(dataToSend),
		})
			.then(response => response.json())
			.then(data => {
				window.location.href = data.checkout_url;
			})
			.catch(error => {
				console.error("Error:", error);
			});
	}

	const OverlayOne = () => (
		<ModalOverlay
			bg='blackAlpha.300'
			backdropFilter='blur(10px) hue-rotate(90deg)'
		/>
	)

	const OverlayTwo = () => (
		<ModalOverlay
			bg='none'
			backdropFilter='auto'
			backdropInvert='80%'
			backdropBlur='2px'
		/>
	)


	const components = [
		{
		  title: "Start new project",
		  description: "Use the ampifi design wizard to bring your audio to life",
		  image: Folder,
		  onClick: () => {
			if (isloading === true) return;
			if (unrenderedList.length >= Number(permissionsData.number_of_unrendered_projects)) {
			  onOpenUnrendered();
			  return;
			}
			createCanvasData();
			resetToNull();
			setSelectedCanvas({});
			history.push(basePath + "/upload");
		  },
		  mlMaping :"0px",
		  mrMaping :"0px",
		  mrRight  :"20px",
		  mrLeft:"0px"
		},
		{
		  title: "Unrendered projects",
		  description: "Finalise or discard your unfinished projects",
		  image: Unrendered,
		  onClick: () => {
			setTemplatesKind("unrendered");
			history.push(basePath + "/unrendered");
		  },
		  mlMaping :"0px",
		  mrMaping :"0px",
		  mrRight:"0px",
		  mrLeft:"20px"
		}
	  ];

	return (
		<HomeLayout  sideMenu={sideMenu}>
			<Flex flex={1} flexDir="column" m={["30px", "30px", "50px", "50px"]}>
				<Flex flexDir="row">
					<Text fontSize={["30px", "40px", "45px", "45px"]} fontWeight="700">
						{getTime()},
					</Text>
				</Flex>
				<Text fontSize={["30px", "40px", "45px", "45px"]} color={theme.colors.primary} fontWeight="700">{currentUserDetails?.full_name}.</Text>
				<Flex my="40px" flexDir={!isSmall ? "column" : "row"}>
					<Flex flexDir="column" maxW={isSmall ? "140px" : "100%"}>
						<Text mb={!isSmall ? "8px" : "15px"} lineHeight="21px" fontSize="18px">
							<Text fontWeight="bold" 	color={theme.colors.primary}>
								{Math.floor(Number(permissionsData?.remaining_monthly_seconds) / 60)} min {remainingTimeSec()} sec{" "}
							</Text>
							remaining
						</Text>
						<Text lineHeight="16px" fontSize="12px">
							Your allowance will be replenished on
							<Text fontWeight="bold" color={theme.colors.primary}>
								{" "}
								{formatDate(currentUserDetails?.subscription_details?.replenish_date)}
							</Text>
						</Text>
					</Flex>
					<Flex h="100%" justifyContent={isSmall ? "center" : ""} alignItems="center">
						<Flex mx="40px" my={isSmall ? "1px" : "20px"} w={isSmall ? "2px" : "50px"} h={!isSmall ? "2px" : "50px"} bg="gray.100"></Flex>
					</Flex>
					<Flex flexDir="column" maxW={isSmall ? "140px" : "100%"}>
						<Text mb={!isSmall ? "8px" : "15px"} lineHeight="21px" fontSize="18px">
							You have a <b>{plan == 'trial' ? "trial" : "Pro"} </b>
							account
						</Text>

						{plan === "trial" && (
							<Text lineHeight="16px" fontSize="12px">
								Unlock new features by{" "}
								<Text
									onClick={() => {
										onOpenUpgradeModal();
									}}
									cursor="pointer"
									lineHeight="10px"
									display="inline-block"
									color='blue'
									style={{
										borderBottom: "1px solid #000",
									}}>
									upgrading your account
								</Text>
							</Text>
						)}
					</Flex>
					<Flex h="100%" justifyContent={isSmall ? "center" : ""} alignItems="center">
						<Flex mx="40px" my={isSmall ? "1px" : "20px"} w={isSmall ? "2px" : "50px"} h={!isSmall ? "2px" : "50px"} bg="gray.100"></Flex>
					</Flex>
					<Flex flexDir="column" maxW={isSmall ? "140px" : "100%"}>
						<Text mb={!isSmall ? "8px" : "15px"} lineHeight="21px" fontSize="18px" fontWeight="600">
							Need some help?
						</Text>
						<a href="mailto:help@ampifi.co">
							<Text lineHeight="10px" fontSize="12px" borderBottom="1px" display="inline-block" color='blue'>
								Get in touch
							</Text>
						</a>
					</Flex>
				</Flex>

				<Flex flex={1} flexDir={!isSmall ? "column" : "row"}>
				{components.map((component, index) => (
				<Flex
					key={index}
					opacity={isloading ? "0.6" : "1"}
					cursor={isloading ? "default" : "pointer"}
					my="10px"
					mr={screenWidth <= 850 ? "0px" : component.mrRight}
					ml={!isSmall && index === 1 ? component.mlMaping : component.mrMaping}
					color="white"
					p="20px"
					rounded="lg"
					flexDir="column"
					flex={1}
					h="130px"
					background={theme.colors.primary}
					onClick={component.onClick}
				>
					<Flex fontSize="20px" mb="10px" fontWeight="600">
					{component.title}
					</Flex>
					<Flex fontSize="12px">{component.description}</Flex>
					<Flex flex={1} justifyContent="flex-end">
					<img src={component.image} style={{ height: "35px", width: "35px" }} alt="" />
					</Flex>
				</Flex>
				))}
			</Flex>

			</Flex>
			<UpgradeModal isOpen={isOpenUpgradeModal} onClose={onCloseUpgradeModal} />
		</HomeLayout>
	);
};

export default Dashboard;
